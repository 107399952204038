import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '@containers/common/Header';
import { setWindowWidth } from '@reducers/ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.setHeader();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setHeader();
      window.scrollTo(0, 0);
      this.props.currentUrl(window.location.pathname);
      this.props.setWindowWidth(window.innerWidth);
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  setHeader = () => {}

  updateWindowDimensions() {
    // this.props.windowWidth(window.innerWidth);
    this.props.setWindowWidth(window.innerWidth);
  }

  render() {
    return (
      <>
        {this.state.showHeader && <Header />}
        {this.props.children}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setWindowWidth,
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(ScrollToTop));
