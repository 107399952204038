import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import otp from './otp';
import oauth from './oauth';
import localization from './localization';
import { register } from './register';
import password from './password';
import ui from './ui';
import video from './video';
import music from './music';
import profile from './profile';
import feedback from './feedback';
import trending from './trending';
import postDetails from './postDetails';
import portalContentDetails from './portalContentDetails';
import like from './like';
import history from './history';
import comment from './comment';
import profilePic from './profilePic';
import playlist from './playlist';
import favourite from './favourite';
import album from './album';
import rbt from './rbt';
import suggestion from './suggestion';
import payment from './payment';
import livestream from './livestream';
import competition from './competition';

const appReducer = combineReducers({
  router: routerReducer,
  otp,
  oauth,
  password,
  localization,
  register,
  ui,
  profile,
  profilePic,
  video,
  music,
  history,
  feedback,
  trending,
  postDetails,
  portalContentDetails,
  like,
  comment,
  playlist,
  favourite,
  album,
  rbt,
  suggestion,
  payment,
  livestream,
  competition,
  form: formReducer
});

// Setup root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
