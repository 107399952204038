/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, useMediaQuery, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserDetails } from '../../reducers/otp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { generateOtp, verifyOtp, oAuth, googleLogin } from '../../reducers/otp';
import { evinaAndCharge, getPaymentServiceOffers, saveconsentLog } from '../../reducers/payment';
import { enterCompetition } from '../../reducers/competition';
import Modal from '@material-ui/core/Modal';
import MobileOtp from '../common/MobileOtp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { EN } from '@localization';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { APP_CONFIG } from '@constants';
import { getUtagDataForLogin, saveLocationUrl, getSubscription } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
    top: {
        margin: 'auto',
        marginTop: '3%',
        // width: '95%',
        borderRadius: '10px',
        background: '#00000099',
        borderRadius: '20px',
        height: 'auto',
        // minHeight: '65vh',
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backdropFilter: 'blur(50px)',
        filter: 'drop-shadow(6px 6px 20px #000000)',
        [theme.breakpoints.between('1200', '1400')]: {
            marginTop: '0% !important',
        },
        [theme.breakpoints.between('1024', '1599')]: {
            // backdropFilter: 'blur(100px)',
            height: 'auto',
            minHeight: '50vh',
            width: '35%',
            // background: '#00000099',
        },
        [theme.breakpoints.up('1600')]: {
            height: 'auto',
            minHeight: '50vh',
            width: '27%',
            // background: '#00000099',
        },
        '@media (min-height: 720px) and (max-height: 900px)': {
            marginTop: '3%',
        },
        '@media (min-height: 901px) and (max-height: 1080px)': {
            marginTop: '9%',
        },
    },
    mobiletop: {
        margin: 'auto',
        marginTop: '25%',
        width: '95%',
        borderRadius: '10px',
        background: '#00000099',
        borderRadius: '20px',
        height: 'auto',
        // minHeight: '65vh',
        // width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backdropFilter: 'blur(50px)',
        filter: 'drop-shadow(6px 6px 20px #000000)',
    },
    btns: {
        width: '100%',
        gap: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        [theme.breakpoints.down('1410')]: {
            marginTop: '0vh',
        },
        marginTop: '5vh',
        marginBottom: '2vh',
        // padding: '20px',
    },
    payBtn: {
        borderRadius: '25px',
        backgroundColor: '#E1E1E1',
        '&:hover': {
            backgroundColor: '#E1E1E1',
        },
        color: '#000000',
        width: '80%',
        margin: 'auto',
        height: '50px',
        textTransform: 'capitalize',
        fontSize: '1.125rem',
        opacity: '100%',
        cursor: 'pointer'
    },
    cancelBtn: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        color: '#959595',
        width: '100%',
        margin: 'auto',
        height: '50px',
        textTransform: 'capitalize',
        fontSize: '1.125rem',
        opacity: '100%',
        cursor: 'pointer'
    },
    songImg: {
        height: '10vw',
        width: '24vw',
        [theme.breakpoints.down('1410')]: {
            marginTop: '30px',
        },
        // marginTop: '50px',
        [theme.breakpoints.down('1024')]: {
            height: '15vh',
            width: '75vw',
            borderRadius: '8px'
        },
        borderRadius: '8px',
        objectFit: 'cover'
    },
    firstData: {
        color: theme.palette.titleClr,
        opacity: '71%',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.25rem'
        },
        fontSize: '1.875rem',

        [theme.breakpoints.down('1410')]: {
            marginTop: '45px'
        },
        marginTop: '40px'

    },
    loaderbackdrop: {
        zIndex: 10000
    },
    circleLoader: {
        color: theme.palette.selectedTxtClr,
    },
    premText: {
        color: theme.palette.selectedTxtClr,
        fontSize: '1.2rem',
        fontWeight: 900,
        opacity: '100%',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.5rem',
        },
    },
}));

const PaymentData = {

    FirstData: 'Get Premium',
    ForSubscribeData: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.getPremiumTitle,
    ForSubscribedTrialData: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.getFreeTrailText,
    SecondData: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.premiumDirectionMsg,
    ForTrailSecondData: 'First day FREE*',
    ThirdData: 'By Subscribing, you agree to the Terms & Conditions of the service.',
    confirmationMsg: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.premiumConfirmationMsg,
    redirectionMsg: ', you will now be redirected to the homepage.',
    secondConfirmationMsg: 'Please note that data charges apply.',
    freeTrailText: `Terms and Conditions apply`,
    competitionEnterMsg: 'Competition Entered',
    resultsMsg: 'Results will be Out Soon'
}

const PremiumPayment = (props) => {
    const classes = useStyles();
    const [paymentDone, setPaymentDone] = useState(false);
    const [forSubscribe, setForSubscribe] = useState(true);
    const [checkMobNum, setCheckMobNum] = useState('');
    const mobileView = useMediaQuery('(max-width:1024px)');
    const [phoneNo, setPhoneNo] = useState(0);
    const [otp, setOtpNo] = useState('');
    const [OTPCorrect, setOTPCorrect] = useState(false);
    const [isOtpScreen, setIsOtpScreen] = useState(false);
    const serviceUrl = window.location.href;
    const [freeTrailText, setFreeTrailText] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loginFlow, setLoginFlow] = useState(false)

    let premiumImage;
    if (APP_CONFIG.artistDetails) {
        premiumImage = APP_CONFIG.artistDetails.properties.premiumSubscribeImg;
    }

    useEffect(() => {
        if (props.isUserLoggedIn) {
            props.getUserDetails().then((result) => {
                if (loginFlow) {
                    getUtagDataForLogin(result.responseList[0]?.userSurrogateId);
                }
                if (result.responseList[0].mobileNumber === null) {
                    setCheckMobNum(false);
                }
                else {
                    setCheckMobNum(true);
                }
            })
            setLoginFlow(false);
        }
    }, [])

    useEffect(() => {
        if (props.isUserLoggedIn || (window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value'))) {
            if (props.serviceOffers?.responseList?.length === 2) {
                if (props.serviceOffers?.responseList[0]?.promoCode === 'TRIAL' || props.serviceOffers?.responseList[1]?.promoCode === 'TRIAL') {
                    setFreeTrailText(true);
                } else {
                    setFreeTrailText(false);
                }
            } else {
                setFreeTrailText(false);
            }
        } else {
            setFreeTrailText(true);
        }
    }, [props.serviceOffers, props.isUserLoggedIn])

    const getAcrOrMob = () => {
        let acrOrMob = '';
        if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
            acrOrMob = window.localStorage.getItem('phone');
        } else if (window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) {
            acrOrMob = window.sessionStorage.getItem('he_acr_value');
        }
        return acrOrMob;
    }

    function checkPayment() {
        const pageUrl = window.location.href;
        // const pageUrl = '/AicSubscribe';
        const umrid = window.localStorage.getItem('acrValue');
        setLoader(true);
        let acrOrMob = getAcrOrMob();
        // if (acrOrMob) {
        //     //log consent
        //     props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, EN.SECOND_CONSENT_APPROVAL);
        // }
        let longPackageId = '';
        let shortPackageId = '';
        if (props.serviceOffers?.responseList?.length === 1) {
            if (props.serviceOffers?.responseList[0]?.longPackageId) {
                longPackageId = props.serviceOffers?.responseList[0]?.longPackageId;
                shortPackageId = props.serviceOffers?.responseList[0]?.shortPackageId ? props.serviceOffers?.responseList[0]?.shortPackageId : '';
            } else if (props.serviceOffers?.responseList[0]?.status === "4") {
                handleClose(true, false);
                props.setErrorMsg(EN.SUSPENDED_MSG);
                props.setErrorHeading(EN.ERROR);
                props.setOpenError(true);
                setLoader(false);
            } else if (props.serviceOffers?.responseList[0]?.status !== "1" && props.serviceOffers?.responseList[0]?.status !== "5") {
                handleClose(true, false);
                setLoader(false);
                props.showLoginNotification(EN.CONTACT_CC_MSG, 'error');
            }
        } else if (props.serviceOffers?.responseList?.length === 2) {
            if (props.serviceOffers?.responseList[0]?.promoCode === 'TRIAL') {
                if (props.serviceOffers?.responseList[0]?.longPackageId) {
                    longPackageId = props.serviceOffers?.responseList[0]?.longPackageId;
                    shortPackageId = props.serviceOffers?.responseList[0]?.shortPackageId ? props.serviceOffers?.responseList[0]?.shortPackageId : '';
                }
            } else if (props.serviceOffers?.responseList[1]?.promoCode === 'TRIAL') {
                if (props.serviceOffers?.responseList[1]?.longPackageId) {
                    longPackageId = props.serviceOffers?.responseList[1]?.longPackageId;
                    shortPackageId = props.serviceOffers?.responseList[1]?.shortPackageId ? props.serviceOffers?.responseList[1]?.shortPackageId : '';
                }
            }
        } else {
            handleClose(true, false);
            setLoader(false);
            props.showLoginNotification(EN.CONTACT_CC_MSG, 'error');
        }
        saveLocationUrl(window.location.href)
        getSubscription();
        if (longPackageId !== '' && acrOrMob) {
            // window.location.href = `http://wpayapi.onmohub.com/subscribe?svc=${APP_CONFIG.SERVICEID}&a=${APP_CONFIG.APPID}&prurl=${pageUrl}&banner=${getContentPath(premiumImage)}&umrid=${umrid}&mode=PORTAL&agent=aic"`
            // props.evinaAndCharge(encodeURIComponent(acrOrMob), longPackageId, shortPackageId, '', serviceUrl).then((data) => {

            // setLoader(false);
            // getUtagDataForSubscription(props.mediaDetails, data.message.replace(/[^0-9]/g, ""));
            // if (props.competitionPage) {
            //     props.getPaymentServiceOffers(encodeURIComponent(acrOrMob), window.location.href).then((data) => {
            //         props.enterCompetition(props.competitionDetails && props.competitionDetails[0].id, data.responseList[0]?.id).then((data) => {
            //             setPaymentDone(true)
            //             // props.setOpenModal()
            //         }).catch((error) => {
            //             handleClose(true, false);
            //             props.showLoginNotification(error.error_description, 'error');
            //         })
            //     })
            // } else {
            //     setPaymentDone(true);
            // }
            // if (props.isRedirect) {
            //     setTimeout(() => {
            //         props.history.push('/homepage');
            //     }, 5000);
            // }
            // }).catch((error) => {
            //     handleClose(true, false);
            //     setLoader(false);
            //     if (props.competitionPage && props.activeTab === 'Active') {
            //         props.checkUserEnrollment(props.competitionDetails)
            //     }
            //     if (error?.errorMessage?.toUpperCase().includes('INSUFFICIENT_FUNDS')) {
            //         props.setErrorMsg(EN.INSUFFICIENT_BALANCE_MSG);
            //         props.setErrorHeading(EN.PAYMENT_FAILED);
            //         props.setOpenError(true);
            //     } else if (error?.errorMessage?.toUpperCase().includes('USER_INVALID')) {
            //         props.showLoginNotification(EN.INVALID_USER_ERROR_MSG, 'error');
            //     } else {
            //         props.showLoginNotification(EN.SOMETHING_WENT_WRONG, 'error');
            //     }
            // });
        }
    };

    const handleClosewithConsent = () => {
        let acrOrMob = getAcrOrMob();
        if (acrOrMob) {
            //log consent
            if (props.forSubscribe) {
                props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, EN.FIRST_CONSENT_REJECTION);
            } else {
                props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, EN.SECOND_CONSENT_REJECTION);
            }
        }
        handleClose(false, false);
    }

    const handleClose = (isDelayRequired, enableTimer) => {
        setIsOtpScreen(false);
        props.handleClose(enableTimer);
        if (paymentDone) {
            let acrOrMob = getAcrOrMob();
            if (acrOrMob) {
                props.getPaymentServiceOffers(encodeURIComponent(acrOrMob), serviceUrl);
            }
        } else {
            if (window.location.href.includes('/post/detail?postId=')) {
                if (isDelayRequired) {
                    setTimeout(() => {
                        props.history.goBack();
                    }, 1500);
                } else {
                    props.history.goBack();
                }
            }
        }
    }

    const getOauthToken = userData => {
        const oAuthData = {
            username: userData,
            password: '',
        };
        props
            .oAuth(oAuthData)
            .then(data => {
                console.log('authData', data);
                setLoginFlow(true)
                window.localStorage.setItem('access_token', data.access_token);
                window.localStorage.setItem('allowedServices', data.allowedServices);
            })
            .catch(error => {
                console.log('erroraccess');
            });
    };

    const setPhoneNumber = e => {
        setPhoneNo(e.target.value);
    };

    const handleGetOPT = () => {
        const data = {
            mobileNumber: Number(phoneNo),
        };
        props
            .generateOtp(data)
            .then(data => {
                // setVerifyFields(true);
                // setLoginFields(false);
            })
            .catch(error => {
                console.log('execute', error);
            });
    };

    const verifyClick = () => {
        const data = {
            mobileNumber: Number(phoneNo),
            otp: Number(otp),
        };
        props.verifyOtp(data).then(data => {
            getOauthToken(phoneNo);
            alert("OTP is correct");
            setOTPCorrect(true);
            console.log('data', data);
            setCheckMobNum(true);

        }).catch(error => {
            alert("Opps!! OTP is Incorrect");
            console.log('errrr', error);
        });
        setOtpNo('');  // to make the otp box empty again after clicking verify btn 
    };

    // const checkOTPstatus = () => {
    //     if (OTPCorrect) {
    //         setCheckMobNum(true);
    //     } else {
    //         alert("Opps!! OTP is Incorrect");
    //     }
    // }

    return (
        <>
            <Modal
                open={props.open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        backdropFilter: 'blur(7px)',
                        // backgroundColor: '#707070',
                        // opacity: '60%',
                        boxShadow: 'none',
                    },
                }}
            >
                <div className={mobileView ? classes.mobiletop : classes.top}>
                    {checkMobNum === false &&
                        <MobileOtp
                            setPhoneNumber={setPhoneNumber}
                            handleGetOPT={() => handleGetOPT()}
                            setOtpNo={setOtpNo}
                            verifyClick={verifyClick}
                            // checkOTPstatus={checkOTPstatus}
                            handleCloseFn={handleClose}
                            otp={otp}
                            phoneNo={phoneNo}
                            mainHead="Login"
                            isOtpScreen={isOtpScreen}
                        />}

                    {(!paymentDone) && (<>
                        <div style={{ marginTop: mobileView ? '0px' : '65px' }}>
                            <img className={classes.songImg} src={props.thumbnail} alt="img" />
                        </div>
                        <div className={classes.firstData}>
                            <span className='bold'>{PaymentData.FirstData}</span>
                        </div>
                        <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '30px 25px 30px 25px' : '15px 60px 20px 60px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>
                            {/* {(props.first) ? PaymentData.ForSubscribeData: PaymentData.SecondData} */}
                            <span className='bold'> {(props.forSubscribe) && PaymentData.ForSubscribeData}</span>
                            <span className='bold'> {(props.forSubscribe && freeTrailText) && PaymentData.ForSubscribedTrialData}</span>
                            <span className='bold'> {(!props.forSubscribe) && PaymentData.SecondData}</span>
                            <span className='bold'> {(!props.forSubscribe && freeTrailText) && PaymentData.ForTrailSecondData}</span>
                            <div className='bold mt-20'> {(!props.forSubscribe) && PaymentData.ThirdData}</div>
                            <div className='bold mt-20'> {(props.forSubscribe) && PaymentData.freeTrailText}</div>
                        </div>

                        <div className={classes.btns}>
                            <Button
                                className={classes.payBtn}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    if (props.first && props.forSubscribe && !checkMobNum) {
                                        props.loginRedirect()
                                    }
                                    if (props.forSubscribe) {
                                        props.handlePremiumSubscribe()
                                    }
                                    if (!props.forSubscribe) {
                                        checkPayment()
                                    }
                                }}
                            >
                                {console.log(props.first && props.forSubscribe && !checkMobNum)}
                                <span className='bold'> {(props.forSubscribe) && 'SUBSCRIBE'}</span>
                                <span className='bold'> {(!props.forSubscribe) && 'CONFIRM'}</span>
                            </Button>
                            <div
                                className={classes.cancelBtn}
                                variant="contained"
                                onClick={handleClosewithConsent}
                            >
                                <span className='bold'>NO THANKS</span>
                            </div>
                        </div>
                    </>)}

                    {/* {(paymentDone) && (<> */}
                    {/* <HighlightOffIcon style={{ fontSize: '40px', marginLeft: '90%', cursor: 'pointer', color: 'grey', marginTop: '25px', marginRight: '25px' }}
                            onClick={() => handleClose(false, props.competitionPage && true)} /> */}
                    {/* <div>
                            <img className={classes.songImg} src={props.thumbnail} alt="img" />
                        </div> */}
                    {/* <div style={{ color: '#dcdcdc', fontSize: '2.25rem', marginTop: '50px', opacity: '100%' }}><span className='bold'>Thank You</span></div> */}
                    {/* <div style={{ color: '#dcdcdc', fontSize: '0.875rem', opacity: '56%', }}>All Premium Content</div> */}
                    {/* <div className={classes.premText} style={{ marginBottom: mobileView ? '3vh' : '4vh' }}><CheckCircleIcon style={{ marginRight: '10px' }} />You have subscribed successfully</div> */}
                    {/* <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '0px 30px 15px' : '0px 50px 20px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>{PaymentData.confirmationMsg}<span>{props.isRedirect && PaymentData.redirectionMsg}</span></div> */}
                    {/* <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '0px 30px 15px' : '0px 50px 20px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>{PaymentData.secondConfirmationMsg}</div> */}
                    {/* {props.competitionPage &&
                            <>
                                <div className={classes.premText} style={{ lineHeight: '27px', textAlign: 'center' }}>{PaymentData.competitionEnterMsg}</div>
                                <div style={{ color: '#DCDCDC', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '10px 30px 45px' : '20px 50px 60px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>{PaymentData.resultsMsg}</div>
                            </>
                        } */}
                    {/* </>)} */}
                    <Backdrop className={classes.loaderbackdrop} open={loader}>
                        <CircularProgress className={classes.circleLoader} />
                    </Backdrop>
                </div>
            </Modal>
        </>
    );
};
const mapStateToProps = state => {
    return {
        userDetails: state.otp.userData,
        rbtCallertunePayment: state.rbt.rbtCallertunePayment,
        serviceOffers: state.payment.serviceOffers,
        isUserLoggedIn: state.oauth.isUserLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getUserDetails,
    generateOtp,
    verifyOtp,
    oAuth,
    googleLogin,
    evinaAndCharge,
    getPaymentServiceOffers,
    saveconsentLog,
    enterCompetition,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PremiumPayment));


