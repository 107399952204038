/*eslint-disable*/
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Textfield from '@formElements/TextField';
import TextArea from '@formElements/TextArea';
import ReactSelect from '@formElements/ReactSelect';
import DatePicker from '@formElements/DatePicker';
import FileUpload from '@formElements/FileUpload';
import { EN } from '@localization';
import { ERROR_LABEL } from '@constants';
import { reduxForm } from 'redux-form';

const MEDIA_TYPE_OPTIONS = [{ label: 'Music', value: 1 }, { label: 'Video', value: 2 }];
const MODE_OPTIONS = [{ label: 'Free', value: 'free' }, { label: 'Premium', value: 'paid' }];
const GENRE_OPTIONS = [{ label: 'Hip_Hop' }, { label: 'Soul' }, { label: 'Melody' }];
const ALBUM_OPTIONS = [{ label: 'Yash', value: 1 }, { label: 'Kiccha', value: 2 }, { label: 'RShetty', value: 3 }];

const useStyles = makeStyles((theme) => ({
  top: {
    background: theme.palette.bgThemeColor,
    margin: '15px',
    borderRadius: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      background: '#242424',
    },
    [theme.breakpoints.up('sm')]: {
      background: theme.palette.bgThemeColor,
    }
  },
  btns: {
    width: '100%',
    gap: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
  },
  uploadLabel: {
    color: '#b5b5b5',
    fontFamily: 'Arial',
    fontSize: '2.3125rem',
    textTransform: 'uppercase'
  },
  uploadBtn: {
    borderRadius: '15px',
    backgroundColor: 'rgb(0, 210, 241)',
    [theme.breakpoints.between('0', '1024')]: {
      color: theme.palette.selectedTxtClr,
      fontSize: '0.8em',
      height: 30,
    },
    [theme.breakpoints.between('1025', '5000')]: {
      background: `linear-gradient(126deg, ${theme.palette.selectedTxtClr}, #181D44)`,
      width: '8em',
      color: '#ffffff',
      fontSize: '1.125rem',
    },
    color: theme.palette.titleClr,
    width: '150px',
    textTransform: 'capitalize',
    fontWeight: '300',
  },
  cancelBtn: {
    borderRadius: '15px',
    color: theme.palette.titleClr,
    backgroundColor: '#4a4a4a',
    width: '150px',
    textTransform: 'capitalize',
    fontWeight: '300',
    [theme.breakpoints.down('1024')]: {
      fontSize: '1.125rem'
    },
  },
  modalTopDiv: {
    maxHeight: 'calc(100vh - 210px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  paper: {
    margin: 'auto',
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    background: theme.palette.bgThemeColor,
    border: `2px solid ${theme.palette.bgThemeColor}`,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UploadModal = (props) => {
  const classes = useStyles();
  const [mediaType, setMediaType] = useState(props.mediaType);
  const modeDefault = props.isEdit ? props.initialValues.mode : 'free';
  const [mode, setMode] = useState(modeDefault);
  const genreDefault = props.isEdit ? props.initialValues.genre : -1;
  const [genre, setGenre] = useState(genreDefault);
  const categoryDefault = props.isEdit ? props.initialValues.category ? props.initialValues.category : -1 : -1;
  const [category, setCategory] = useState(categoryDefault);
  const albumDefault = props.isEdit && props.initialValues.albumId && props.initialValues.albumName ? props.initialValues.albumId + '-' + props.initialValues.albumName : -1;
  const [album, setAlbum] = useState(albumDefault);
  const releasedAtDefault = props.isEdit ? props.initialValues.releasedAt : '';
  const expiryAtDefault = props.isEdit ? props.initialValues.expiryAt : '';
  const [releasedAt, setReleasedAt] = useState(releasedAtDefault);
  const [expiryAt, setExpiryAt] = useState(expiryAtDefault);
  const initialData = props.isEdit ? {
    id: props.initialValues.id,
    title: props.initialValues.title,
    description: props.initialValues.description,
    tags: props.initialValues.tags,
    internalTags: props.initialValues.internalTags,
    mediaType: props.mediaType,
    genre: genreDefault,
    releasedAt: releasedAtDefault,
    mode: modeDefault,
    expiryAt: expiryAtDefault,
    lyrics: props.initialValues.lyrics,
    clipId: props.initialValues.clipId,
    category: categoryDefault

  } : {
    mediaType: props.mediaType,
    genre: genreDefault,
    releasedAt: releasedAtDefault,
    mode: modeDefault,
    expiryAt: expiryAtDefault,
    category: categoryDefault
  };
  const [formData, setFormData] = useState(initialData);
  const [displayErrorMsgForFile, setDisplayErrorMsgForFile] = useState(false);
  const [errorMsgForFile, setErrorMsgForFile] = useState('');

  const [displayErrorMsgForThumbnail, setDisplayErrorMsgForThumbnail] = useState(false);
  const [errorMsgForThumbnail, setErrorMsgForThumbnail] = useState('');

  const audioTypes = ['audio/mp3', 'audio/mpeg', 'audio/aac', 'audio/ac3', 'audio/aiff', 'audio/x-aiff', 'audio/eac3', 'audio/opus', 'audio/pcm', 'audio/wav', 'audio/x-ms-wma', 'audio/vorbis', 'audio/x-m4a', 'audio/x-flac', 'audio/flac', 'audio/ogg'];
  //                      mp3           mpeg        aac           ac3         aiff           aiff              eac3         opus        pcm          wav            wma/wma2         vorbis          m4a           flac            flac         ogg
  const videoTypes = ['video/mp4', 'video/3gpp', 'video/webm', 'video/x-matroska', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv', 'video/x-flv', 'video/h264', 'video/mp2t'];
  //                      mp4           3gpp         webm               mkv               mov                  avi                wmv                flv          h264      mpeg2-ts
  const imageTypes = ['image/jpg', 'image/png', 'image/jpeg'];

  const [fileType, setFileType] = props.mediaType === 1 ? useState(audioTypes) : useState(videoTypes);
  const [fileTitle, setFileTitle] = props.mediaType === 1 ? useState(EN.MUSIC) : useState(EN.VIDEO);

  const createFormDataAndUpload = () => {
    const values = formData;
    const formDataToUpload = new FormData();

    formDataToUpload.append('isEdit', false);
    formDataToUpload.append('title', values.title);
    formDataToUpload.append('artistName',  window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).artistName);
    
    if (values && values.description) {
      formDataToUpload.append('description', values.description);
    }
    if (values && values.tags) {
      formDataToUpload.append('tags', values.tags);
    }
    if (values && values.internalTags) {
      formDataToUpload.append('internalTags', values.internalTags);
    }
    if (values.mediaType === 2) {
      formDataToUpload.append('mediaType', 'Video');
      if (values && values.category) {
        formDataToUpload.append('category', values.category);
      }
    } else {
      formDataToUpload.append('mediaType', 'Music');
      if (values && values.genre) {
        formDataToUpload.append('genre', values.genre);
      }
      if (values && values.albumId) {
        formDataToUpload.append('albumId', values.albumId);
      }
      if (values && values.albumName) {
        formDataToUpload.append('albumName', values.albumName);
      }
      if (values && values.lyrics) {
        formDataToUpload.append('lyrics', values.lyrics);
      }
    }
    if (values && values.releasedAt) {
      formDataToUpload.append('releasedAt', new Date(values.releasedAt));
    }
    if (values && values.expiryAt) {
      formDataToUpload.append('expiryAt', new Date(values.expiryAt));
    }
    if (values && values.mode) {
      formDataToUpload.append('mode', values.mode);
    }
    if (values && values.mediaFile) {
      formDataToUpload.append('mediaFile', values.mediaFile);
    }
    if (values && values.thumbnailFile) {
      formDataToUpload.append('thumbnailFile', values.thumbnailFile);
    }
    props.uploadMediaFile(formDataToUpload);
  };

  const createFormDataAndEdit = () => {
    const values = formData;
    const formDataToUpload = new FormData();

    formDataToUpload.append('isEdit', true);
    formDataToUpload.append('id', values.id);
    formDataToUpload.append('title', values.title);
    formDataToUpload.append('artistName',  window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).artistName);

    if (values && values.description) {
      formDataToUpload.append('description', values.description);
    }
    if (values && values.tags) {
      formDataToUpload.append('tags', values.tags);
    }
    if (values && values.internalTags) {
      formDataToUpload.append('internalTags', values.internalTags);
    }
    if (values.mediaType === 2) {
      formDataToUpload.append('mediaType', 'Video');
      if (values && values.category) {
        formDataToUpload.append('category', values.category);
      }
    } else {
      formDataToUpload.append('mediaType', 'Music');
      if (values && values.clipId) {
        formDataToUpload.append('clipId', values.clipId);
      }
      if (values && values.genre) {
        formDataToUpload.append('genre', values.genre);
      }
      if (values && values.albumId) {
        formDataToUpload.append('albumId', values.albumId);
      }
      if (values && values.albumName) {
        formDataToUpload.append('albumName', values.albumName);
      }
      if (values && values.removeAlbum) {
        formDataToUpload.append('removeAlbum', values.removeAlbum);
      }
      if (values && values.lyrics) {
        formDataToUpload.append('lyrics', values.lyrics);
      }
    }
    if (values && values.releasedAt) {
      formDataToUpload.append('releasedAt', new Date(values.releasedAt));
    }
    if (values && values.expiryAt) {
      formDataToUpload.append('expiryAt', new Date(values.expiryAt));
    }
    if (values && values.mode) {
      formDataToUpload.append('mode', values.mode);
    }
    if (values && values.thumbnailFile) {
      formDataToUpload.append('thumbnailFile', values.thumbnailFile);
    }
    if (values && values.deleteThumbnail) {
      formDataToUpload.append('deleteThumbnail', values.deleteThumbnail);
    }
    props.editMediaDetails(formDataToUpload);
  };

  const resetErrorDataOfFile = () => {
    setDisplayErrorMsgForFile(false);
    setErrorMsgForFile('');
  };

  const resetErrorDataOfThumbnail = () => {
    setDisplayErrorMsgForThumbnail(false);
    setErrorMsgForThumbnail('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.isEdit) {
      if (formData.deleteThumbnail && (!formData.thumbnailFile || formData.thumbnailFile === '' || formData.thumbnailFile === undefined)) {
        setDisplayErrorMsgForThumbnail(true);
        setErrorMsgForThumbnail(ERROR_LABEL.SELECT_FILE);
      } else {
        resetErrorDataOfFile();
        resetErrorDataOfThumbnail();
        createFormDataAndEdit();
      }
    } else {
      if (!formData.mediaFile || formData.mediaFile === '' || formData.mediaFile === undefined) {
        setDisplayErrorMsgForFile(true);
        setErrorMsgForFile(ERROR_LABEL.SELECT_FILE);
      } else if (!formData.thumbnailFile || formData.thumbnailFile === '' || formData.thumbnailFile === undefined) {
        setDisplayErrorMsgForThumbnail(true);
        setErrorMsgForThumbnail(ERROR_LABEL.SELECT_FILE);
      } else {
        resetErrorDataOfFile();
        resetErrorDataOfThumbnail();
        createFormDataAndUpload();
      }
    }
  };

  const changeFileTypeAndTitle = (e) => {
    if (e === 1) {
      setFileTitle(EN.MUSIC);
      setFileType(audioTypes);
    } else {
      setFileTitle(EN.VIDEO);
      setFileType(videoTypes);
    }
  };

  const onChangeSetFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const onChangeOfMediaType = (e) => {
    setMediaType(e);
    onChangeSetFormData('mediaType', e);
    changeFileTypeAndTitle(e);
    resetErrorDataOfFile();
  };

  const onChangeOfTitle = (e) => {
    onChangeSetFormData('title', e);
  };

  const onChangeOfDescription = (e) => {
    onChangeSetFormData('description', e);
  };

  const onChangeOfLyrics = (e) => {
    onChangeSetFormData('lyrics', e);
  }
  const onChangeOfTags = (e) => {
    onChangeSetFormData('tags', e);
  };

  const onChangeOfInternalTags = (e) => {
    onChangeSetFormData('internalTags', e);
  };

  const onChangeOfGenre = (e) => {
    setGenre(e);
    onChangeSetFormData('genre', e);
  };

  const onChangeOfCategory = (e) => {
    setCategory(e);
    onChangeSetFormData('category', e);
  }

  const onChangeOfAlbum = (e) => {
    setAlbum(e);
    if (e !== -1) {
      const albumArray = e.split('-');
      delete formData.removeAlbum;
      setFormData({
        ...formData,
        'albumId': albumArray[0],
        'albumName': albumArray[1],
      });
    } else {
      delete formData.albumId;
      delete formData.albumName;
      onChangeSetFormData('removeAlbum', true);
    }
  }

  const onChangeOfReleasedAtDate = (e) => {
    setReleasedAt(e);
    onChangeSetFormData('releasedAt', e);
  };
  const onChangeOfExpiryAtDate = (e) => {
    setExpiryAt(e);
    onChangeSetFormData('expiryAt', e);
  }
  const onChangeOfClipId = (e) => {
    onChangeSetFormData('clipId', e);
  }

  const onChangeOfMode = (e) => {
    setMode(e);
    onChangeSetFormData('mode', e);
  }

  const onChangeOfFile = (e) => {
    if (e && e.length === 1) {
      onChangeSetFormData('mediaFile', e[0]);
    } else {
      delete formData.mediaFile;
    }
  };

  const onChangeOfThumbnail = (e) => {
    if (e === -1) {
      delete formData.thumbnailFile;
      onChangeSetFormData('deleteThumbnail', true);
    } else if (e && e.length === 1) {
      onChangeSetFormData('thumbnailFile', e[0]);
    } else {
      delete formData.thumbnailFile;
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <div className={classes.top}>
          <div className={classes.header}>
            <div className={classes.uploadLabel}>
              <p><b>{props.isEdit ? props.modalHeading : EN.UPLOAD}</b></p>
            </div>
          </div>

          <div className={classes.modalTopDiv}>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <Textfield
                      id="title"
                      type="text"
                      label={EN.TITLE}
                      placeholder={EN.SONG_NAME}
                      errorLabel={ERROR_LABEL.TITLE}
                      displayLabel
                      required={false}
                      onChange={onChangeOfTitle}
                      defaultValue={props.isEdit ? props.initialValues.title : ''}
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <TextArea
                      id="description"
                      label={EN.DESCRIPTION}
                      placeholder={EN.SONG_DESCRIPTION}
                      maxRows="5"
                      minRows="5"
                      displayLabel
                      onChange={onChangeOfDescription}
                      defaultValue={props.isEdit ? props.initialValues.description : ''}
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <TextArea
                      id="tags"
                      label={EN.TAGS}
                      placeholder={EN.TAGS_EX}
                      minRows="2"
                      maxRows="2"
                      displayLabel
                      onChange={onChangeOfTags}
                      defaultValue={props.isEdit ? props.initialValues.tags : ''}
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <TextArea
                      id="internalTags"
                      label={EN.INTERNAL_TAGS}
                      placeholder={EN.INTERNAL_TAGS_EX}
                      minRows="2"
                      maxRows="2"
                      displayLabel
                      onChange={onChangeOfInternalTags}
                      defaultValue={props.isEdit ? props.initialValues.internalTags : ''}
                    />
                  </div>
                </div>
                {mediaType === 2 && (
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <ReactSelect
                        id="category"
                        label={EN.CATEGORY}
                        options={props.categoryList}
                        displayLabel
                        value={category}
                        onChange={onChangeOfCategory}
                        isNone
                        disabled={false}
                        required={false}
                      />
                    </div>
                  </div>
                )}
                {mediaType === 1 && (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <ReactSelect
                          id="genre"
                          label={EN.GENRE}
                          options={props.genreList}
                          // options={GENRE_OPTIONS}
                          displayLabel
                          value={genre}
                          onChange={onChangeOfGenre}
                          isNone
                          disabled={false}
                          required={false}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <ReactSelect
                          id="album"
                          label={EN.ALBUM}
                          options={props.albumList}
                          // options={ALBUM_OPTIONS}
                          displayLabel
                          value={album}
                          onChange={onChangeOfAlbum}
                          isNone
                          disabled={false}
                          required={false}
                        />
                      </div>
                    </div>
                    {props.isEdit && (
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <Textfield
                            id="clipId"
                            type="text"
                            label={EN.CLIPID}
                            placeholder={EN.CLIPID}
                            displayLabel
                            required={false}
                            onChange={onChangeOfClipId}
                            defaultValue={props.initialValues.clipId}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <DatePicker
                      label={EN.RELEASED_AT}
                      id="releasedAt"
                      displayLabel
                      required={false}
                      onChange={onChangeOfReleasedAtDate}
                      format="MM/dd/yyyy"
                      value={releasedAt}
                      defaultRequired
                      disabled={props.isEdit && true}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <ReactSelect
                      id="mode"
                      label={EN.MODE}
                      placeholder={EN.MODE}
                      options={MODE_OPTIONS}
                      displayLabel
                      value={mode}
                      onChange={onChangeOfMode}
                      isNone={false}
                      // disabled={props.isEdit}
                      required
                      errorMsg=""
                      displayErrorMsg={false}
                    />
                  </div>
                </div>
                {!props.isEdit && (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <ReactSelect
                          id="mediaType"
                          label={EN.MEDIA_TYPE}
                          placeholder={EN.MEDIA_TYPE}
                          options={MEDIA_TYPE_OPTIONS}
                          displayLabel
                          value={mediaType}
                          onChange={onChangeOfMediaType}
                          isNone={false}
                          disabled={props.isEdit}
                          required
                          errorMsg=""
                          displayErrorMsg={false}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group" id="fileUpload">
                        <FileUpload
                          label={fileTitle}
                          id={fileTitle}
                          required
                          displayLabel
                          type="file"
                          multiple={false}
                          fileTypes={fileType}
                          buttonName={EN.SELECT}
                          onChange={onChangeOfFile}
                          displayErrorMsg={displayErrorMsgForFile}
                          errorMsg={errorMsgForFile}
                          resetErrorDataOfFile={resetErrorDataOfFile}
                          displayImg={false}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group" id="thumbnailUpload">
                    <FileUpload
                      label={EN.THUMBNAIL}
                      id={EN.THUMBNAIL}
                      required
                      displayLabel
                      type="file"
                      multiple={false}
                      fileTypes={imageTypes}
                      buttonName={EN.SELECT}
                      onChange={onChangeOfThumbnail}
                      defaultFile={props.isEdit ? props.initialValues.thumbnail : ''}
                      displayErrorMsg={displayErrorMsgForThumbnail}
                      errorMsg={errorMsgForThumbnail}
                      resetErrorDataOfFile={resetErrorDataOfThumbnail}
                      displayImg={false}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <DatePicker
                      label={EN.EXPIRY_AT}
                      id="expiryAt"
                      displayLabel
                      required={false}
                      onChange={onChangeOfExpiryAtDate}
                      format="MM/dd/yyyy"
                      value={expiryAt}
                      minDateRequired={true}
                      defaultRequired
                    />
                  </div>
                </div>
                {mediaType === 1 && (
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <TextArea
                        id="description"
                        label={EN.LYRICS}
                        placeholder={EN.SONG_LYRICS}
                        maxRows="5"
                        minRows="5"
                        displayLabel
                        onChange={onChangeOfLyrics}
                        defaultValue={props.isEdit ? props.initialValues.lyrics : ''}
                      />
                    </div>
                  </div>
                )}
                <div className={classes.btns}>
                  <Button
                    className={classes.cancelBtn}
                    variant="contained"
                    onClick={() => props.setUploadModalOpen(false)}
                  >
                    <b>{EN.CANCEL}</b>
                  </Button>
                  <Button
                    type="submit"
                    className={classes.uploadBtn}
                    variant="contained"
                    color="secondary"
                  >
                    <b>{props.isEdit ? EN.UPDATE : EN.UPLOAD}</b>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'UploadModal', // a unique identifier for this form
})(UploadModal);
