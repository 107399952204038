export default {
  MOBILE_NUMBER: 'Please enter valid mobile number',
  PASSWORD: 'Please enter valid password',
  EMAIL_ID: 'Please enter valid email',
  REENTER_PASSWORD: 'Please re-enter password',
  FIRST_NAME: 'Please enter your first name',
  LAST_NAME: 'Please enter your last name',
  TITLE: 'Please enter the title',
  SELECT_VALID_FILE_TYPE: 'Please select a valid file type',
  SELECT_FILE: 'Please select a file',
  DESCRIPTION: 'Please enter the description',
  ARTIST_BIO: 'Please enter the bio of artist',
  LANDING_PAGE_TEXT: 'Please enter the landingpage text',
  MAX_FILE_NUMBER_EXCEEDED: 'You can select maximum 3 files',
  TWITTER_HANDLE: 'Please provide Twitter account',
  FB_HANDLE: 'Please provide Facebook account',
  ALBUM_NAME: 'Please enter album name',
  INSTAGRAM_HANDLE: 'Please provide Instagram account',
  SELECT_START_TIME: 'Please select start time',
  SELECT_END_TIME: 'Please select end time',
  INVALID_URL: 'Invalid Url',
  EVENT_URL_REQUIRED: 'Event Url required',
  START_TIME_NOT_LESS_THAN_CURRENT: 'Start time cannot be less than current time',
  START_TIME_NOT_GREATER_THAN_END_TIME: 'Start time cannot be greater than end time',
  END_TIME_NOT_LESS_THAN_CURRENT: 'End time cannot be less than current time',
  END_TIME_NOT_LESS_THAN_START_TIME: 'End time cannot be less than start time',
  START_DATE_NOT_LESS_THAN_CURRENT: 'Start date cannot be less than current date',
  START_DATE_NOT_GREATER_THAN_END_DATE: 'Start date cannot be greater than end date',
  END_DATE_NOT_LESS_THAN_CURRENT: 'End date cannot be less than current date',
  END_DATE_NOT_LESS_THAN_START_DATE: 'End date cannot be less than start date',
  SELECT_START_DATE: 'Please select start date',
  SELECT_END_DATE: 'Please select end date',
};
