/* eslint-disable */
export const GET_HISTORY_REQUESTED = 'history/GET_HISTORY_REQUESTED';
export const GET_HISTORY_SUCCESS = 'history/GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'history/GET_HISTORY_FAILURE';
export const POST_HISTORY_REQUESTED = 'history/POST_HISTORY_REQUESTED';
export const POST_HISTORY_SUCCESS = 'history/POST_HISTORY_SUCCESS';
export const POST_HISTORY_FAILURE = 'history/POST_HISTORY_FAILURE';

export const HISTORY_MUSIC_LIST_EMPTY = 'history/HISTORY_MUSIC_LIST_EMPTY';

 
const initialState = {
 loading: false,
 loaded: false,
 error: false,
 historyDetails: [],
 historyDetailsCount: 0,
};
 
export default (state = initialState, action) => {
 console.log('action.requests', action.result);
 // console.log();
 switch (action.type) {
   case GET_HISTORY_REQUESTED: {
     return {
       ...state,
       loading: true,
       loaded: false
     };
   }
   case GET_HISTORY_SUCCESS: {
     return {
       ...state,
       loading: false,
       error: false,
       loaded: true,
       historyDetails: [...state.historyDetails, ...action.result.responseList],
       historyDetailsCount:action.result.totalCount
     };
   }
   case GET_HISTORY_FAILURE: {
     return {
       ...state,
       loading: false,
       error: true,
       loaded: false
     };
   }
   case POST_HISTORY_REQUESTED: {
     return {
       ...state,
       loading: true,
       loaded: false
     };
   }
   case POST_HISTORY_SUCCESS: {
     return {
       ...state,
       loading: false,
       error: false,
       loaded: true,
     };
   }
   case POST_HISTORY_FAILURE: {
     return {
       ...state,
       loading: false,
       loaded: false,
       error: true
     };
   }
   case HISTORY_MUSIC_LIST_EMPTY: {
    console.log('%cempty', 'color:red');
    return {
      ...state,
      loading: false,
      error: false,
      loaded: true,
      historyDetails: []
    };
  }
   default:
     return state;
 }
};
export const updateHistoryList = (formData) => {
 return {
   types: [POST_HISTORY_REQUESTED, POST_HISTORY_SUCCESS, POST_HISTORY_FAILURE],
   promise: (client) => client.post('updateHistoryList', {
     data: formData,
   })
 };
};
 
export const getHistoryPlayed = (pageNo, pageSize) => {
 return {
   types: [GET_HISTORY_REQUESTED, GET_HISTORY_SUCCESS, GET_HISTORY_FAILURE],
   promise: (client) => client.get(`getHistoryPlayed?pageNo=${pageNo}&pageSize=${pageSize}`)
 };
};

export const emptyHistoryMusicPlayedList = () => {
  return {
    type: HISTORY_MUSIC_LIST_EMPTY
  };
};