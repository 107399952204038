/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { EN } from '@localization';
import { makeStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import {
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookShareButton,
  // LinkedinIcon,
  TwitterIcon,
  // LinkedinShareButton,
  TwitterShareButton
} from 'react-share';
import CloseIcon from '@material-ui/icons/Close';
import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';

const useStyles = makeStyles((theme) => ({
  srcUrl: {
    backgroundColor: '#F7F8FA',
    padding: '1px',
    borderRadius: '16px',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '0.9375rem',
    lineHeight: '46px'
  },
  successmsg: {
    color: theme.palette.selectedTxtClr,
    marginLeft: '10px',
    width:'100vw',
    height:'20px',
    marginTop: '10px',
    fontSize: '0.8rem',
    fontFamily: 'SegoeUISemiBold !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      background: '#242424',
    },
    [theme.breakpoints.up('sm')]: {
      background: theme.palette.bgThemeColor,
    }
  },
  deleteLabel: {
    color: '#b5b5b5',
    fontFamily: 'Arial',
    fontSize: '2.3125rem',
    textTransform: 'uppercase',
    marginBottom: '20px'
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    marginTop: '13%',
    width: '42vw',
    background: theme.palette.bgThemeColor,
    border: `2px solid ${theme.palette.bgThemeColor}`,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
  },
  top: {
    background: theme.palette.bgThemeColor,
    margin: '15px',
    borderRadius: '10px',
  },
  copyBtn: {
    marginBottom: 5,
    textTransform: 'capitalize',
    borderRadius: '50px',
    height: 43,
    backgroundColor: '#2b2b2b',
    fontFamily: 'SegoeUI',
    [theme.breakpoints.between('0', '1024')]: {
      color: theme.palette.selectedTxtClr,
      fontSize: '0.8em',
      height: 30,
    },
    [theme.breakpoints.between('1025', '5000')]: {
      background: `linear-gradient(126deg, ${theme.palette.selectedTxtClr}, #181D44)`,
      width: '6em',
      color: '#ffffff',
      fontSize: '1.125rem',
    },
  },
  copyurl: {
    backgroundColor: 'grey',
    cursor:'pointer',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
}));

const SocialShareModal = (props) => {
  const classes = useStyles();
  const [shareUrl , setShareUrl] = useState('');
  // const shareUrl = 'http://49.206.233.178:5000/celebrityadmin/videoplayer?videoId=62d5566e9e13fd6b0cd4a4aa';
  const [copiedSuccess, setCopiedSuccess] = useState('');
  const windowHeight = `${window.innerHeight}`;

  useEffect(() => {
    if(shareUrl === '') {
      getUrl();
    }
  });

  const getUrl = () => {
    let url = '';
    switch(props.mediaType) {
      case 'Music' :
        url = '/musicdetail?musicId=';
        break;
      case 'Video' :
        url = '/videoplayer?videoId=';
        break;
      case 'Post' :
        url = '/post/detail?postId=';
        break;
      case 'LiveEvent' : 
        url = '/liveplayer?videoId=';
        break;
      default:
          break;
    }
    url = window.location.protocol + '//' + window.location.hostname + url + props.mediaId;
    console.log("share url =>", url);
    setShareUrl(url);
   }

  const copyShareUrl = () => {
    copy(shareUrl);
    setCopiedSuccess('Copied successfully!!!');
    setTimeout(() => {
      setCopiedSuccess('');
    }, 2000);
  };

  return (
    <>
      <div className={classes.paper}>
        <CloseIcon style={{ fontSize: '40px', position: 'absolute', cursor: 'pointer', color: 'white', right: '2%', top: '7%' }}
          onClick={props.handleCloseShare}
        />
        <div className={classes.top}>
          <div className={classes.header}>
            <div className={classes.deleteLabel}><b style={{textTransform:'capitalize'}}>{EN.SHARE}</b></div>
          </div>
          <div className="row ml-10" style={{ display: 'flex',justifyContent:'center' }}>
            {shareUrl && (
              <div style={{ width: '8vw' }}>
                <div className={classes.copyurl} onClick={copyShareUrl}>
                  <FilterNoneRoundedIcon style={{ fontSize: '40px', color: '#fff' }} />
                </div>
                <div className="mt-10 white">
                  <span style={{ paddingLeft: '8px' }}> Copy link</span>
                </div>
                <div className={classes.successmsg}>
                  {copiedSuccess}
                </div>
              </div>
            )}
            <div style={{ width: '8vw' }}>
              <WhatsappShareButton
                url={shareUrl}
                className="mr-40"
                beforeOnClick={() => props.toggle()}
                windowHeight={windowHeight}
                title={props.title}
                separator={props.separator}
              >
                <WhatsappIcon size={80} round />
                <div className="mt-10 white">
                  <span> WhatsApp</span>
                </div>
              </WhatsappShareButton>
            </div>
            <div style={{ width: '8vw' }}>
              <FacebookShareButton
                url={shareUrl}
                className="mr-40"
                beforeOnClick={() => props.toggle()}
                windowHeight={windowHeight}
                quote={props.quote}
                hashtag={props.hashtag}
              >
                <FacebookIcon size={80} round />
                <div className="mt-10 white">
                  <span> Facebook</span>
                </div>
              </FacebookShareButton>
            </div>
            <div style={{ width: '110px' }}>
              <TwitterShareButton
                url={shareUrl}
                // className="mr-40"
                beforeOnClick={() => props.toggle()}
                windowHeight={windowHeight}
                title={props.title}
                // via="artist-portal"
                hashtags={props.hashtag}
              >
                <TwitterIcon size={80} round />
                <div className="mt-10 white">
                  <span> Twitter</span>
                </div>
              </TwitterShareButton>
            </div>
            {/* <LinkedinShareButton
             url={shareUrl}
             className="mr-40"
             beforeOnClick={() => props.toggle()}
             windowHeight={windowHeight}
             title={props.title}
             // source="artist-portal"
             summary={props.quote}
           >
             <LinkedinIcon size={50} round />
             <div className="mt-10 white">
               <span> LinkedIn</span>
             </div>
           </LinkedinShareButton> */}
          </div>
          {/* {shareUrl && (
           <div className="row mt-20">
             <div style={{ width: '90%', display:'flex', flexDirection:'row' }}>
               <div className="col-xs-10 col-sm-10 col-md-10">
                 <div className={classes.srcUrl}>
                   <span id="srcUrlId" className="pl-15">{shareUrl}</span>
                 </div>
               </div>
               <div className="col-xs-2 col-sm-2 col-md-2">
                 <button className={classes.copyBtn} type="button" onClick={copyShareUrl}>
                   {EN.COPY}
                 </button>
               </div>
             </div>
             <div className="smallFont">
               <div className={classes.successmsg}>
                 {copiedSuccess}
               </div>
             </div>
           </div>
         )} */}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'SocialShareModal', // a unique identifier for this form
})(SocialShareModal);
