export const GET_POST_REQUESTED = 'post/GET_POST_REQUESTED';
export const GET_POST_SUCCESS = 'post/GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'post/GET_POST_FAILURE';

export const POST_LIST_EMPTY = 'post/POST_LIST_EMPTY';

export const POST_DATA_REQUESTED = 'post/POST_DATA_REQUESTED';
export const POST_DATA_SUCCESS = 'post/POST_DATA_SUCCESS';
export const POST_DATA_FAILURE = 'post/POST_DATA_FAILURE';

export const GET_POST_DETAILS_INFO_REQUESTED = 'post/GET_POST_DETAILS_INFO_REQUESTED';
export const GET_POST_DETAILS_INFO_SUCCESS = 'post/GET_POST_DETAILS_INFO_SUCCESS';
export const GET_POST_DETAILS_INFO_FAILURE = 'post/GET_POST_DETAILS_INFO_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  postDetailsList: [],
  postDetailsListCount: 0,
  postDetailsInfo: [],

};

export default (state = initialState, action) => {
  console.log(action.result);
  switch (action.type) {
    case GET_POST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_POST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        postDetailsListCount: action.result.totalCount,
        postDetailsList: [...state.postDetailsList, ...action.result.responseList]
      };
    }
    case GET_POST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_POST_DETAILS_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_POST_DETAILS_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        postDetailsInfo: action.result.responseList
      };
    }
    case GET_POST_DETAILS_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case POST_LIST_EMPTY: {
      console.log('%cempty', 'color:red');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        postDetailsList: []
      };
    }
    case POST_DATA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case POST_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case POST_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const getPostDetailsList = (pageNo, pageSize, searchKey, filterBy) => {
  let filter = 'live';
  if (filterBy !== undefined) {
    filter = filterBy;
  }
  return {
    types: [GET_POST_REQUESTED, GET_POST_SUCCESS, GET_POST_FAILURE],
    promise: (client) => client.get(`getPostDetailsList?pageNo=${pageNo}&pageSize=${pageSize}&search=${searchKey}&filterBy=${filter}`)
  };
};

export const emptyPostDetailsList = () => {
  console.log('%cempty', 'color:red');
  return {
    type: POST_LIST_EMPTY
  };
};

export const getPostDetailsMedia = (url) => {
  return {
    types: [GET_POST_REQUESTED, GET_POST_SUCCESS, GET_POST_FAILURE],
    promise: (client) => client.get(`getPostDetailsMedia?url=${url}`)
  };
};

export const postData = (data) => {
  return {
    types: [POST_DATA_REQUESTED, POST_DATA_SUCCESS, POST_DATA_FAILURE],
    promise: (client) => client.post('postData', {
      data,
    })
  };
};

export const getPostDetailsInfo = (data) => {
  return {
    types: [GET_POST_DETAILS_INFO_REQUESTED, GET_POST_DETAILS_INFO_SUCCESS, GET_POST_DETAILS_INFO_FAILURE],
    promise: (client) => client.get(`getPostDetailsList/detail?postId=${data}`)
  };
};
