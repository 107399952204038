import APIConfig from './api';

const TITLE = '';
const artistDetails = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'));

export default {
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
  profile_photo_base_url: `${APIConfig.hostname}/PortalUser/user/avatar`,
  profile_photo_base_url_activetone: `${APIConfig.hostname}/PortalContent/music/rbt/thumbnail`,
  AES_KEY: 'B!bZNWTM4X+mFIh2',
  AES_IV: '5sMTXymHI*Kc94a3',
  PUBLIC_KEY: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnqJEy6FQYcD1NSnJmi3nqjCBX+QwYqwYuIk3+MqAl5gg/v+cyM+w9JzRhLd1VLGkzKhzXzW8/PlmXVTH7Bx+MJ2bTEXf0S0T3ApLiTXl9xvq9JCgBpiYBoF2klTa9Br7wmS8JRDgoD6puX+UsMoMWvSBMkKV7ak95rE4eJgPHrvGea/LPkxrlcGKOq7s3K7Oi5i89l9zIrNu0vtsR/YH/2UI99CrXz0kB4YcpJKN9iUGMtPCNq6b24Gw0vF0I/3Eq4vo0Lg6mdLp5DzcSD5I5RRxj8gd6lGV45tauL+4kDVSH4hZocPLN0FzqGvN5KzgdPwT8Mro4tezym9kcFuUKQIDAQAB',
  SERVICEID: 2911,
  APPID: 1478,
  artistDetails
};

export const GOOGLE_KEY = 'AIzaSyBgMi_tKS5WRdH_RrCmdApIdCQCEZRIOyE';
