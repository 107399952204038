/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { makeStyles, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    FacebookIcon,
    WhatsappIcon,
    WhatsappShareButton,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share';
import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';
import copy from 'copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    SharePopup: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: '260px',
        borderRadius: '20px 20px 0px 0px',
        alignItems: 'center',
        background: '#363636',
        zIndex: 99,
        '& > svg': {
            margin: theme.spacing(2),
        },
    },
    copyurl: {
        backgroundColor: 'grey',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'15px'

    },
    successmsg: {
        color: theme.palette.selectedTxtClr,
        width:'100vw',
        marginTop: '8px',
        marginLeft: '8px',
        fontSize: '20px',
        fontFamily: 'SegoeUISemiBold !important'
    },
    circleLoader: {
        color: theme.palette.selectedTxtClr,
    },
    closeIcon: {
        color: theme.palette.selectedTxtClr,
    }
}));

const SocialShareModalmob = (props) => {
    const classes = useStyles();
    const [shareUrl , setShareUrl] = useState('');
    const [copiedSuccess, setCopiedSuccess] = useState('');
    const windowHeight = `${window.innerHeight}`;

    useEffect(() => {
        if(shareUrl === '') {
            setTimeout(() => {
                getUrl();
            }, 1000);
        }
      });
    
    const getUrl = () => {
    let url = '';
    switch(props.mediaType) {
        case 'Music' :
        url = '/musicdetail?musicId=';
        break;
        case 'Video' :
        url = '/videoplayer?videoId=';
        break;
        case 'Post' :
        url = '/post/detail?postId=';
        break;
        case 'LiveEvent' : 
        url = '/liveplayer?videoId=';
        break;
        default:
            break;
    }
    url = window.location.protocol + '//' + window.location.hostname + url + props.mediaId;
    console.log("share url =>", url);
    setShareUrl(url);
    }

    const copyShareUrl = () => {
        copy(shareUrl);
        setCopiedSuccess('Copied successfully!!!');
        setTimeout(() => {
            setCopiedSuccess('');
        }, 2000);
    };

    return (
        <>
            <Grid className={classes.SharePopup}>
                {/* <div style={{ display: 'flex', marginTop: 5 }}>
                    <hr style={{ width: '30%', border: '2px solid #474747' }} />
                </div> */}
                <div style={{ paddingLeft: '1.125rem', paddingRight: '1.125rem',marginTop: 25 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.125rem', color: '#ababab'}}>
                        <p style={{marginLeft:'10px'}}>Share</p>
                        <CloseIcon className={classes.closeIcon} onClick={props.handleCloseShare} />
                    </div>

                </div>
                {shareUrl ? (
                    <div className="row ml-20" style={{ display: 'flex' }}>
                        <div style={{ width: '25vw' }}>
                            <div className={classes.copyurl} onClick={copyShareUrl}>
                                <FilterNoneRoundedIcon style={{ fontSize: '25px', color:'#fff' }} />
                            </div>
                            <div className="mt-10 white">
                                <span style={{ paddingLeft: '8px' }}> Copy link</span>
                            </div>
                            <div className={classes.successmsg}>
                                {copiedSuccess}
                            </div>
                        </div>
                        <div style={{ width: '24vw' }}>
                            <WhatsappShareButton
                                url={shareUrl}
                                className="mr-40"
                                windowHeight={windowHeight}
                                title={props.title}
                                separator={props.separator}
                                beforeOnClick={() => props.handleCloseShare()}
                            >
                                <WhatsappIcon size={50} round />
                                <div className="mt-10 white">
                                    <span> WhatsApp</span>
                                </div>
                            </WhatsappShareButton>
                        </div>
                        <div style={{ width: '24vw' }}>
                            <FacebookShareButton
                                url={shareUrl}
                                className="mr-40"
                                windowHeight={windowHeight}
                                quote={props.quote}
                                hashtag={props.hashtag}
                                beforeOnClick={() => props.handleCloseShare()}
                            >
                                <FacebookIcon size={50} round />
                                <div className="mt-10 white">
                                    <span> Facebook</span>
                                </div>
                            </FacebookShareButton>
                        </div>
                        <div style={{ width: '24vw' }}>
                            <TwitterShareButton
                                url={shareUrl}
                                className="mr-40"
                                windowHeight={windowHeight}
                                title={props.title}
                                // via="artist-portal"
                                hashtags={props.hashtag}
                                beforeOnClick={() => props.handleCloseShare()}
                            >
                                <TwitterIcon size={50} round />
                                <div className="mt-10 white">
                                    <span> Twitter</span>
                                </div>
                            </TwitterShareButton>
                        </div>
                    </div>
                ) : (
                    <div style={{ margin: 'auto' , width: '20%' }}>
                        <CircularProgress className={classes.circleLoader} />
                    </div>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {

    },
    dispatch
);
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SocialShareModalmob)
);