import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EN } from '@localization';
import { reduxForm } from 'redux-form';

const useStyles = makeStyles((theme) => ({
  top: {
    background: theme.palette.bgThemeColor,
    margin: '15px',
    borderRadius: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      background: '#242424',
    },
    [theme.breakpoints.up('sm')]: {
      background: theme.palette.bgThemeColor,
    }
  },
  btns: {
    width: '100%',
    gap: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
  },
  deleteLabel: {
    color: '#b5b5b5',
    fontFamily: 'Arial',
    fontSize: '2.3125rem',
    textTransform: 'uppercase'
  },
  deleteBtn: {
    borderRadius: '15px',
    backgroundColor: 'rgb(0, 210, 241)',
    color: theme.palette.titleClr,
    width: '150px',
    textTransform: 'capitalize',
    fontWeight: '300',
    [theme.breakpoints.between('0', '1024')]: {
      color: theme.palette.selectedTxtClr,
      fontSize: '0.8em',
      height: 30,
    },
    [theme.breakpoints.between('1025', '5000')]: {
      background: `linear-gradient(126deg, ${theme.palette.selectedTxtClr}, #181D44)`,
      width: '8em',
      color: '#ffffff',
      fontSize: '1.125rem',
    },
  },
  cancelBtn: {
    borderRadius: '15px',
    color: theme.palette.titleClr,
    backgroundColor: '#4a4a4a',
    width: '150px',
    textTransform: 'capitalize',
    fontWeight: '300',
    [theme.breakpoints.down('1024')]: {
      fontSize: '1.125rem'
    },
  },
  modalTopDiv: {
    maxHeight: 'calc(100vh - 210px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  paper: {
    margin: 'auto',
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    background: theme.palette.bgThemeColor,
    border: `2px solid ${theme.palette.bgThemeColor}`,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  deleteMsgDiv: {
    color: '#b5b5b5',
    fontFamily: 'Arial',
    fontSize: '1.3125rem',
  }
}));

const ConfirmationModal = (props) => {
  const classes = useStyles();

  const handleSubmit = () => {
    props.deleteItem();
  };

  return (
    <>
      <div className={classes.paper}>
        <div className={classes.top}>
          <div className={classes.header}>
            <div className={classes.deleteLabel}>
              <p><b>{props.heading}</b></p>
            </div>
          </div>

          <div className={classes.modalTopDiv}>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className={classes.deleteMsgDiv}>
                  {props.deleteMsg}
                </div>
              </div>
            </div>
            <div className={classes.btns}>
              <Button
                className={classes.cancelBtn}
                variant="contained"
                onClick={() => props.setDeleteModalOpen(false)}
              >
                <b>{EN.CANCEL}</b>
              </Button>
              <Button
                onClick={() => handleSubmit()}
                className={classes.deleteBtn}
                variant="contained"
                color="secondary"
              >
                <b>{props.heading}</b>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'ConfirmationModal', // a unique identifier for this form
})(ConfirmationModal);
