/* eslint-disable */
import React from 'react';

import { Card, CardContent, Box, makeStyles } from '@material-ui/core';
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import { getContentPath } from '../../helpers/utils';
import { APP_CONFIG } from '@constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    margin: '0 auto',
    borderRadius: 25,
    background: '#242424',
    [theme.breakpoints.up('sm')]: {
      // marginTop: 100,
      minWidth: '60%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    background: '#242424',

    width: '100%',
    justifyContent: 'space-around',
    background: `linear-gradient(to right,#000, ${theme.palette.selectedTxtClr})`,
  },
  image: {
    width: 100,
    borderRadius: 25,
    backgroundImage: `url(${getContentPath(APP_CONFIG.artistDetails.properties.profileImage)})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}));

const PremierBanner = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <div className={classes.details}>
          <Box className={classes.image} />
          <CardContent style={{ fontSize: '1rem', fontFamily: 'Arial', }}>
            <b style={{ color: '#FFFFFF' }}>Get All Premium</b>
          </CardContent>
          <img style={{ alignSelf: 'center', height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />
        </div>
      </Card>
    </>
  );
};
export default PremierBanner;
