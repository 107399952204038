/*eslint-disable*/
import "date-fns";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { EN } from '@localization';
import {
  // styled,
  makeStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  label: {
    color: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    fontSize: '1.25rem'
  },
  errorLabelSpan: {
    color: '#EE255C',
    fontSize: '0.8rem',
    fontFamily: 'SegoeUISemiBold !important'
  },
}));

export default function MaterialUIPickers(props) {
  const classes = useStyles();
  const {
    label,
    id,
    required,
    displayLabel,
    onChange,
    format,
    value,
    minDateRequired,
    defaultRequired,
    errorMsg,
    displayErrorMsg,
    disabled
  } = props;
  const [selectedDate, setSelectedDate] = useState(value ? value : defaultRequired ? new Date() : null);

  const handleDateChange = (date) => {
    console.log("date", date);
    setSelectedDate(date);
    onChange(date);
  };
  const today = new Date();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {displayLabel && (
        <InputLabel className={classes.label}>
          <span className="text-capitalize ml-10">{label}</span>
          {required ? EN.REQUIRED : ''}
        </InputLabel>)}
      <Grid container className={classes.dateContainer} id="dateContainer">
          <KeyboardDatePicker
            // disableToolbar
            variant="inline"
            format={format}
            margin="normal"
            id={id}
            value={selectedDate}
            onChange={handleDateChange}
            minDate={minDateRequired ? today.setDate(today.getDate()) : undefined}
            disabled={disabled}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
      </Grid>
      {displayErrorMsg && (
          <span className="col-xs-12 col-sm-12 col-md-9 pl-0" key={displayErrorMsg}>
            <span className={classes.errorLabelSpan}>
              {errorMsg}
            </span>
          </span>)}
    </MuiPickersUtilsProvider>
  );
}
