//  This page is Ads main page to display when end user clicked on Ads.
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import LoginModal from '@containers/Modals/LoginModal';
import { EN } from '@localization';
import ErrorModal from '@containers/Modals/ErrorModal';
import { APP_CONFIG } from '@constants';
import NotificationMessage from '../NotificationMessage';
import { saveconsentLog } from '../../reducers/payment';
import { getContentPath } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  MainbackGround: {
    position: 'fixed',
    margin: 'auto',
    overflowY: 'scroll',
    backgroundColor: 'black',
    width: '100%',
    minWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  maindiv: {
    position: 'absolute',
    top: '43%',
    width: '100vw',
    [theme.breakpoints.up('1024')]: {
      width: '500px',
    },
  },
  backImg: {
    backgroundRepeat: 'no-repeat',
    color: theme.palette.titleClr,
    height: '100vh',
    [theme.breakpoints.down('1024')]: {
      width: '412px',
    },
    width: '500px',
    backgroundSize: 'cover',
  },
  firstText: {
    fontSize: '32px',
    color: theme.palette.selectedTxtClr,
    display: 'flex',
    justifyContent: 'center',
  },
  SecondText: {
    fontSize: '21px',
    margin: '30px 20px 0px',
    lineHeight: '25px',
  },
  ButtonStyle: {
    maxWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  getAccessBtn: {
    margin: '40px 0 0 20px',
    borderRadius: '50px',
    background: theme.palette.selectedTxtClr,
    color: theme.palette.titleClr,
    height: '50px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.selectedTxtClr,
      color: theme.palette.titleClr,
    },
    fontSize: '16px',
  },
  LastText: {
    fontSize: '18px',
    margin: '20px 20px 0px',
    lineHeight: '22px',
  },
}));

function AdsAccess(props) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  let advImage;
  if (APP_CONFIG.artistDetails && params.get('campaignType') === '1') {
    advImage = APP_CONFIG.artistDetails.properties.campaignType1Img;
  } else if (params.get('campaignType') === '2') {
    advImage = APP_CONFIG.artistDetails.properties.campaignType2Img;
  }

  const content = {
    firstText: 'celebrityconnect.co.za',
    SecondText: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).properties.advTitle,
    LastText: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).properties.advSubTitle,
  };

  const getAcrOrMob = () => {
    let acrOrMob = '';
    if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
      acrOrMob = window.localStorage.getItem('phone');
    } else if (window.sessionStorage.getItem('is_he_user') === 'true' && window.sessionStorage.getItem('he_acr_value')) {
      acrOrMob = window.sessionStorage.getItem('he_acr_value');
    }
    return acrOrMob;
  };

  const saveConsentLogsForArtistPortal = (message) => {
    const acrOrMob = getAcrOrMob();
    if (acrOrMob) {
      const serviceUrl = window.location.href;
      props.saveconsentLog(acrOrMob, EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, message);
    }
  };

  const calls = (w, d, s, l, i) => {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || []; w[l].push({
      'gtm.start':

        new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];

    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
  };

  useEffect(() => {
    calls(window, document, 'script', 'dataLayer', 'GTM-KSN4S23');
  }, []);
  useEffect(() => {
    if (props.serviceOffers?.responseList[0]?.status === '1' || props.serviceOffers?.responseList[0]?.status === 1) {
      history.push('/homepage');
    }
  }, [props.serviceOffers]);

  useEffect(() => {
    if (((window.sessionStorage.getItem('is_he_user') === 'true' && window.sessionStorage.getItem('he_acr_value')) || props.isUserLoggedIn) && window.location.pathname === '/DMLandingPage' && redirect) {
      saveConsentLogsForArtistPortal(EN.FIRST_CONSENT_APPROVAL);
      if (params.get('campaignType')) {
        history.push(`./DMConfirmPage?${window.location.href.split('?')[1]}`);
      } else {
        history.push('./DMConfirmPage');
      }
    }
  }, [props.isUserLoggedIn, redirect]);

  const showLoginNotification = (message, msgtype) => {
    setOpenNoti(true);
    setMsg(message);
    setMsgType(msgtype);
  };

  const handleAccess = () => {
    if (window.sessionStorage.getItem('is_he_user') === 'false' && !props.isUserLoggedIn) {
      setOpenLogin(true);
    }
    if (props.serviceOffers?.responseList[0]?.status) {
      switch (props.serviceOffers?.responseList[0]?.status) {
        case '1' || 1: // Active
          history.push('/homepage');
          break;
        case '4' || 4: // Suspended
          setErrorMsg(EN.SUSPENDED_MSG);
          setErrorHeading(EN.ERROR);
          setOpenError(true);
          break;
        case '5' || 5: // Grace Period
          showLoginNotification(EN.GRACE_PERIOD_MSG, 'warning');
          break;
        default:
          showLoginNotification(EN.CONTACT_CC_MSG, 'error');
          break;
      }
    } else {
      setRedirect(true);
    }
  };

  const closeLoginModal = () => {
    setOpenLogin(false);
  };

  const handleCloseForNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNoti(false);
  };

  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KSN4S23"
          title="load"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <div className={classes.MainbackGround}>
        <div className={classes.backImg} style={{ backgroundImage: `url("${getContentPath(advImage)}")` }}>
          <div className={classes.maindiv}>
            <div className={classes.firstText}><span className="bold">{content.firstText}</span></div>
            <div className={classes.SecondText}><span className="bold">{content.SecondText}</span></div>
            <div className={classes.ButtonStyle}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.getAccessBtn}
                onClick={handleAccess}
              >
                <span className="bold">GET ACCESS NOW</span>
              </Button>
            </div>
            <div className={classes.LastText}>{content.LastText}</div>
          </div>
        </div>
      </div>
      <NotificationMessage open={openNoti} msgType={msgType} msg={msg} handleClose={handleCloseForNotify} />
      <LoginModal openLogin={openLogin} setOpenLogin={setOpenLogin} closeLoginModal={closeLoginModal} showLoginNotification={showLoginNotification} />
      <ErrorModal openError={openError} setOpenError={setOpenError} errorMsg={errorMsg} errorHeading={errorHeading} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: state.oauth.isUserLoggedIn,
    serviceOffers: state.payment.serviceOffers,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveconsentLog
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdsAccess)
);
