export const GET_COMPETITION_LIST_REQUESTED = 'competition/GET_COMPETITION_LIST_REQUESTED';
export const GET_COMPETITION_LIST_SUCCESS = 'competition/GET_COMPETITION_LIST_SUCCESS';
export const GET_COMPETITION_LIST_FAILURE = 'competition/GET_COMPETITION_LIST_FAILURE';

export const COMPETITION_LIST_EMPTY = 'competition/COMPETITION_LIST_EMPTY';

export const UPLOAD_COMPETITION_REQUESTED = 'competition/UPLOAD_COMPETITION_REQUESTED';
export const UPLOAD_COMPETITION_SUCCESS = 'competition/UPLOAD_COMPETITION_SUCCESS';
export const UPLOAD_COMPETITION_FAILURE = 'competition/UPLOAD_COMPETITION_FAILURE';

export const GET_COMPETITION_DETAILS_INFO_REQUESTED = 'competition/GET_COMPETITION_DETAILS_INFO_REQUESTED';
export const GET_COMPETITION_DETAILS_INFO_SUCCESS = 'competition/GET_COMPETITION_DETAILS_INFO_SUCCESS';
export const GET_COMPETITION_DETAILS_INFO_FAILURE = 'competition/GET_COMPETITION_DETAILS_INFO_FAILURE';

export const DELETE_COMPETITION_REQUESTED = 'competition/DELETE_COMPETITION_REQUESTED';
export const DELETE_COMPETITION_SUCCESS = 'competition/DELETE_COMPETITION_SUCCESS';
export const DELETE_COMPETITION_FAILURE = 'competition/DELETE_COMPETITION_FAILURE';

export const GET_PARTICIPANTS_LIST_REQUESTED = 'competition/GET_PARTICIPANTS_LIST_REQUESTED';
export const GET_PARTICIPANTS_LIST_SUCCESS = 'competition/GET_PARTICIPANTS_LIST_SUCCESS';
export const GET_PARTICIPANTS_LIST_FAILURE = 'competition/GET_PARTICIPANTS_LIST_FAILURE';

export const GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_REQUESTED = 'competition/GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_REQUESTED';
export const GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_SUCCESS = 'competition/GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_SUCCESS';
export const GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_FAILURE = 'competition/GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_FAILURE';

export const CHECK_USER_ENROLLMENT_REQUESTED = 'competition/CHECK_USER_ENROLLMENT_REQUESTED';
export const CHECK_USER_ENROLLMENT_SUCCESS = 'competition/CHECK_USER_ENROLLMENT_SUCCESS';
export const CHECK_USER_ENROLLMENT_FAILURE = 'competition/CHECK_USER_ENROLLMENT_FAILURE';

export const ENTER_COMPETITION_REQUESTED = 'competition/ENTER_COMPETITION_REQUESTED';
export const ENTER_COMPETITION_SUCCESS = 'competition/ENTER_COMPETITION_SUCCESS';
export const ENTER_COMPETITION_FAILURE = 'competition/ENTER_COMPETITION_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  detailsloaded: false,
  error: false,
  success: false,
  competitionList: [],
  competitionCount: 0,
  competitionDetailsInfo: [],
  participantsList: [],
  participantsCount: 0,
  activeUpcomingCompetitionDetails: [],
  userEnrollmentStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPETITION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_COMPETITION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        competitionCount: action.result.totalCount,
        competitionList: [...state.competitionList, ...action.result.responseList]
      };
    }
    case GET_COMPETITION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_COMPETITION_DETAILS_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        detailsloaded: false
      };
    }
    case GET_COMPETITION_DETAILS_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        detailsloaded: true,
        competitionDetailsInfo: action.result
      };
    }
    case GET_COMPETITION_DETAILS_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        detailsloaded: true
      };
    }
    case COMPETITION_LIST_EMPTY: {
      console.log('%cempty', 'color:red');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        competitionList: []
      };
    }
    case UPLOAD_COMPETITION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPLOAD_COMPETITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPLOAD_COMPETITION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case DELETE_COMPETITION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_COMPETITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_COMPETITION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case GET_PARTICIPANTS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_PARTICIPANTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        participantsCount: action.result.totalCount,
        participantsList: action.result.responseList
      };
    }
    case GET_PARTICIPANTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        activeUpcomingCompetitionDetails: action.result.responseList
      };
    }
    case GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case CHECK_USER_ENROLLMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        success: false
      };
    }
    case CHECK_USER_ENROLLMENT_SUCCESS: {
      console.log('exeeeeeeeee', action.result.responseList);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        success: true,
        userEnrollmentStatus: action.result.responseList
      };
    }
    case CHECK_USER_ENROLLMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        loaded: false,
      };
    }
    case ENTER_COMPETITION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ENTER_COMPETITION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ENTER_COMPETITION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const getCompetitionList = (pageNo, pageSize, status, filterValues) => {
  const filterByStartFrom = filterValues?.startDateFrom ? filterValues.startDateFrom : '';
  const filterByStartTo = filterValues?.startDateTo ? filterValues.startDateTo : '';
  const filterByEndFrom = filterValues?.endDateFrom ? filterValues.endDateFrom : '';
  const filterByEndTo = filterValues?.endDateTo ? filterValues.endDateTo : '';
  const search = filterValues?.title ? filterValues.title : '';

  return {
    types: [GET_COMPETITION_LIST_REQUESTED, GET_COMPETITION_LIST_SUCCESS, GET_COMPETITION_LIST_FAILURE],
    promise: (client) => client.get(`getCompetitionList?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}&status=${status}&startFrom=${filterByStartFrom}&startTo=${filterByStartTo}&endFrom=${filterByEndFrom}&endTo=${filterByEndTo}`)
  };
};

export const emptyCompetitionList = () => {
  console.log('%cempty', 'color:red');
  return {
    type: COMPETITION_LIST_EMPTY
  };
};

export const getCompetitionDetails = (id) => {
  return {
    types: [GET_COMPETITION_DETAILS_INFO_REQUESTED, GET_COMPETITION_DETAILS_INFO_SUCCESS, GET_COMPETITION_DETAILS_INFO_FAILURE],
    promise: (client) => client.get(`getCompetitionDetails?competitionId=${id}`)
  };
};

export const uploadCompetition = (data) => {
  return {
    types: [UPLOAD_COMPETITION_REQUESTED, UPLOAD_COMPETITION_SUCCESS, UPLOAD_COMPETITION_FAILURE],
    promise: (client) => client.post('uploadCompetition', {
      data,
    })
  };
};

export const deleteCompetition = (id) => {
  return {
    types: [DELETE_COMPETITION_REQUESTED, DELETE_COMPETITION_SUCCESS, DELETE_COMPETITION_FAILURE],
    promise: (client) => client.del(`deleteCompetition?competitionId=${id}`, {
    })
  };
};

export const getParticipantsList = (pageNo, pageSize, status, competitionId) => {
  return {
    types: [GET_PARTICIPANTS_LIST_REQUESTED, GET_PARTICIPANTS_LIST_SUCCESS, GET_PARTICIPANTS_LIST_FAILURE],
    promise: (client) => client.get(`getParticipantsList?pageNo=${pageNo}&pageSize=${pageSize}&competitionId=${competitionId}&status=${status}`)
  };
};

export const uploadCompetitionWinners = (data) => {
  return {
    types: [UPLOAD_COMPETITION_REQUESTED, UPLOAD_COMPETITION_SUCCESS, UPLOAD_COMPETITION_FAILURE],
    promise: (client) => client.post('uploadCompetitionWinners', {
      data,
    })
  };
};

export const endCompetition = (id) => {
  return {
    types: [DELETE_COMPETITION_REQUESTED, DELETE_COMPETITION_SUCCESS, DELETE_COMPETITION_FAILURE],
    promise: (client) => client.post(`endCompetition?competitionId=${id}`, {
    })
  };
};

export const getActiveUpcomingCompetitionDetails = (status) => {
  return {
    types: [GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_REQUESTED, GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_SUCCESS, GET_ACTIVE_UPCOMING_COMPETITION_DETAILS_FAILURE],
    promise: (client) => client.get(`getActiveUpcomingCompetitionDetails?status=${status}`)
  };
};

export const checkUserEnrollment = (id, subscriptionId) => {
  return {
    types: [CHECK_USER_ENROLLMENT_REQUESTED, CHECK_USER_ENROLLMENT_SUCCESS, CHECK_USER_ENROLLMENT_FAILURE],
    promise: (client) => client.get(`checkUserEnrollment?competitionId=${id}&subscriptionId=${subscriptionId}`)
  };
};

export const enterCompetition = (competitionId, subscriptionId) => {
  return {
    types: [ENTER_COMPETITION_REQUESTED, ENTER_COMPETITION_SUCCESS, ENTER_COMPETITION_FAILURE],
    promise: (client) => client.post(`enterCompetition?competitionId=${competitionId}&subscriptionId=${subscriptionId}`, {
    })
  };
};
