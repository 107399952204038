/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { CgChevronRight as RightIcon } from '@react-icons/all-files/cg/CgChevronRight';
import { CgChevronLeft as LeftIcon } from '@react-icons/all-files/cg/CgChevronLeft';
import { getCategoryList } from '../../../reducers/video';
import useDetectBtn from './useDetectBtn';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: 'flex',
    overflowX: 'scroll',
    '@media (min-width:970px)': {
      width: 'calc(90vw - 60px)',
    },
    '@media (min-width:1440px)': {
      width: 'calc(90vw - 60px)',
    },
    '@media (min-width:1848px)': {
      width: 'calc(100vw - 23vw)',
    },
    '@media (max-width:960px)': {
      width: '90vw',
    }
  },
  tabItems: {
    display: 'flex',
    overflowX: 'scroll',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    whiteSpace: 'nowrap',
    '@media (max-width:960px)': {
      margin: '.5rem .5rem',
      padding: '.3rem 1rem',
    },
    '@media (min-width:960px)': {
      margin: '0.4rem .5rem',
      padding: '.5rem 2rem',
    },
    background: 'linear-gradient(180deg, #ffffff24, #6a6a6a2b)',
    color: '#ffffffcc',
    cursor: 'pointer',
    '&:hover, &:focus &:active': {
      background: `linear-gradient(180deg, rgb(0 0 0 / 0%) , ${theme.palette.selectedTxtClr} )`,
      boxShadow:
        '#333333ba 1px 1px 1px 1px inset, rgb(255 255 255 / 0%) -1px 0px 2px 0px inset',
      fontWeight: 900,
    },
  },
  firstBtn: {
    background: `linear-gradient(180deg, rgb(0 0 0 / 0%) , ${theme.palette.selectedTxtClr} )`,
    boxShadow:
      '#333333ba 1px 1px 1px 1px inset, rgb(255 255 255 / 0%) -1px 0px 2px 0px inset',
    fontWeight: 900,
  }
}));

const ScrollTab = (props) => {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');
  const [selectedTab, setSelectedTab] = useState(0);
  const [scrollBtn, setScrollBtn] = useState([]);
  const first = useRef();
  const last = useRef();

  const firstShown = useDetectBtn(first);
  const lastShown = useDetectBtn(last);
  useEffect(() => {
    props.getCategoryList('all').then((res) => {
      const updatedList = res.map((item) => ({ ...item, label: item.title, value: item.title }));
      if (res.length > 0) updatedList.unshift({label: 'all', value: 'All'})
      setScrollBtn(updatedList);
    });
  }, []);

  useEffect(() => {
  }, [lastShown, firstShown]);

  const handleTabClicked = (item, index) => {
    setSelectedTab(index);
    props.clicked(item, index);
  };

  return (
    <div className={cn(classes.tabContainer,'paddingI')}>
      {(!firstShown && !mobileView) && <LeftIcon style={{ height: '50px', width: '50px' }} color="gray" />}
      <div className={classes.tabItems}>
        {scrollBtn.map((item, index) => {
          if (index === 0) return <button type="button" key={item.value} ref={first} className={cn(classes.btn, selectedTab === index && classes.firstBtn)} onClick={() => handleTabClicked(item, index)}>{item.value}</button>;
          if (index + 1 === scrollBtn.length) return <button type="button" key={item.value} ref={last} className={cn(classes.btn, selectedTab === index && classes.firstBtn)} onClick={() => handleTabClicked(item, index)}>{item.value}</button>;
          else return <button type="button" key={item.value} className={cn(classes.btn, selectedTab === index && classes.firstBtn)} onClick={() => handleTabClicked(item, index)}>{item.value}</button>;
        })}
      </div>
      {(!lastShown && !mobileView) && <RightIcon style={{ height: '50px', width: '50px' }} color="gray" />}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCategoryList
  },
  dispatch
);

export default withRouter(connect(null, mapDispatchToProps)(ScrollTab));
