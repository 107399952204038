/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
// import { useHistory } from 'react-router-dom';
// import ReactJkMusicPlayer from 'react-jinke-music-player';
// import 'react-jinke-music-player/assets/index.css';
import './musicplayer.scss';
import song from '../../assets/music/a.mp3';
import image4 from '@assets/images/profilepic.png';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactMusicPlayer from '../ReactMusicPlayer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@material-ui/core';
import {
  postFavouriteMusic
} from '../../reducers/favourite';
import { getSuggestionswithoutGenre, getSuggestionsWithGenre, clearSuggestionswithGenre, clearSuggestionswithoutGenre } from '../../reducers/suggestion';
import { saveconsentLog } from '../../reducers/payment';
import { getRBTStatus } from '../../reducers/rbt'
import NotificationMessage from '../NotificationMessage';
import Modal from '@material-ui/core/Modal';
import SocialShareModal from '../../containers/Modals/SocialShareModal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CallerTune from '../Music/CallerTune';
import LoginModal from '@containers/Modals/LoginModal';
import { EN } from '@localization';

const useStyles = makeStyles((theme) => ({
  got: {
    background: 'red'
  },
  buttonThemeColor: {
    backgroundColor: theme.palette.selectedTxtClr,
    color: theme.palette.titleClr,
    '&:hover': {
      background: theme.palette.selectedTxtClr,
      color: theme.palette.titleClr,
    },
  }
}));
const MusicPlayer = (props) => {
  const [audioLists, setAudioLists] = useState([props?.audioObject || {}]);
  const [paid, setPaid] = useState('');
  const [lyricToggle, setLyricToggle] = useState(false);
  const [prevNext, setPrevNext] = useState('');
  const [clipId, setClipId] = useState(null);
  const [RBTStatus, setRBTStatus] = useState('');
  const [id, setId] = useState('');
  const [genre, setGenre] = useState('');
  const mobile = useMediaQuery('(max-width:1024px')
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  // const [lyricsModalOpen, setLyricsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openCallerTune, setOpenCallerTune] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showLyrics, setShowLyrics] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);

  const isAdminPage = window.location.pathname.includes('/celebrityadmin') ? true : false;
  const isProductAdminPage = window.location.pathname.includes('/productadmin') ? true : false;

  const [showWelcomeTone, setShowWelcomeTone] = useState(true);

  useEffect(() => {
    console.log('called now 1')
    if (props.audioObject) {
      const {
        contentPath: musicSrc,
        thumbnail: cover,
        title: name,
        artistName: singer,
        id: id,
        mediaId: mediaId,
        ...rest
      } = { ...props.audioObject };
      const receivedMusic = Object.assign({}, {
        musicSrc,
        cover,
        name,
        singer,
        id,
        mediaId,
        ...rest
      })
      setAudioLists([receivedMusic])
      setClipId(props.audioObject.clipId);
      setPaid(props.audioObject.mode)
      setId(props.audioObject.id || props.audioObject.mediaId);
      setGenre(props.audioObject.genre)
      setShowLyrics(props.audioObject.lyrics);
      if (!isAdminPage && !isProductAdminPage) callFunctions(props.audioObject.id || props.audioObject.mediaId, props.audioObject.genre)
    }
  }, [props.audioObject]);

  useEffect(() => {
    console.log('called now 2')

  }, [audioLists]);

  useEffect(() => {
    console.log('called now 3')
    // console.log('props.playUpdatedAudioPlaylistMode', props.playUpdatedAudioPlaylistMode)
    if (props.playUpdatedAudioPlaylistMode === 'notSuggestions') {
      // console.log('after 1 props.playUpdatedAudioPlaylistMode', props.playUpdatedAudioPlaylistMode)
      const newArrayOfObj = props.playUpdatedAudioPlaylist.map(({
        contentPath: musicSrc,
        thumbnail: cover,
        title: name,
        artistName: singer,
        id: id,
        mediaId: mediaId,
        ...rest
      }) => ({
        musicSrc,
        cover,
        name,
        singer,
        id,
        mediaId,
        ...rest
      }));
      let newPlaylist = [...newArrayOfObj]
      // console.log('after 1 props.playUpdatedAudioPlaylistMode ll', audioLists[0].name)
      // console.log('after 1 props.playUpdatedAudioPlaylistMode', newArrayOfObj)

      const index = newPlaylist.findIndex(object => object.name === audioLists[0].name)
      if (index !== 0 && index > 0) {
        //  console.log('index', index)
        newPlaylist = [newPlaylist[index], ...newArrayOfObj]
      }
      setAudioLists(newPlaylist);
      console.log('newPlaylist', newPlaylist)

    }
    if (props.playUpdatedAudioPlaylistMode === 'suggestions') {
      console.log('props.suggestionsWithGenre.length 1', props.suggestionsWithGenre.length)
      console.log('props.suggestionsWithGenre.length 2', props.suggestionsWithoutGenre.length)
      if(props.suggestionsWithGenre.length>0){
      const newArrayOfObj = props.suggestionsWithGenre.map(({
        contentPath: musicSrc,
        thumbnail: cover,
        title: name,
        artistName: singer,
        id: id,
        mediaId: mediaId,
        ...rest
      }) => ({
        musicSrc,
        cover,
        name,
        singer,
        id,
        mediaId,
        ...rest
      }));
      const newPlaylist = [...audioLists, ...newArrayOfObj]

      console.log('newPlaylist', newPlaylist)

      setAudioLists(newPlaylist);
      }
      if(props.suggestionsWithoutGenre.length>0){
        const newArrayOfObj = props.suggestionsWithoutGenre.map(({
          contentPath: musicSrc,
          thumbnail: cover,
          title: name,
          artistName: singer,
          id: id,
          mediaId: mediaId,
          ...rest
        }) => ({
          musicSrc,
          cover,
          name,
          singer,
          id,
          mediaId,
          ...rest
        }));
        const newPlaylist = [...audioLists, ...newArrayOfObj]

        console.log('newPlaylist', newPlaylist)
  
        setAudioLists(newPlaylist);
      }
     
    }
    // console.log('after update props.playUpdatedAudioPlaylistMode', audioLists)

  }, [props.suggestionsWithGenre, props.playUpdatedAudioPlaylist, props.playUpdatedAudioPlaylistMode,props.suggestionsWithoutGenre]);

  useEffect(() => {
    console.log('called now 4')
    if (props.isUserLoggedIn && clipId) {
      setLoader(true);
      setRBTStatus('');
      if (window.localStorage.getItem('phone')) {
        props.getRBTStatus(clipId, encodeURIComponent(window.localStorage.getItem('phone'))).then((result) => {
          setLoader(false);
          setRBTStatus(result.responseList[0].status);
        });
      }
    }
  }, [clipId]);

  useEffect(() => {
    console.log('called now 5')
    if (props.isUserLoggedIn && clipId) {
      setRBTStatus('');
      if (window.localStorage.getItem('phone')) {
        props.getRBTStatus(clipId, encodeURIComponent(window.localStorage.getItem('phone'))).then((result) => {
          setRBTStatus(result.responseList[0].status);
          // console.log('RBTstatusAPI',result.responseList[0].status);
        });
      }
    }
  }, [props.isUserLoggedIn]);

  useEffect(() => {
    console.log('called now 6')
    setRBTStatus(props.RBTStatusResponse.status)
  }, [props.RBTStatusResponse]);

  const onDestroyed = (currentPlayId, audioLists, audioInfo) => {
    setAudioLists(null)
  }

  const onBeforeDestroy = (currentPlayId, audioLists, audioInfo) => {
    return new Promise((resolve, reject) => {
      // your custom validate
      if (true) {
        // if resolve, player destroyed
        resolve(setAudioLists(null))
      } else {
        // if reject, skip.
        reject()
      }
    })
  }

  const handleFavourite = () => {
    props.postFavouriteMusic(id).then((result) => {
      setOpen(true);
      setMsg(result.message);
      setMsgType("success");
    }).catch((error) => {
      setOpen(true);
      setMsg(error.errorMessage);
      setMsgType("error");
    });
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const showLoginNotification = (message, msgType) => {
    setOpen(true);
    setMsg(message);
    setMsgType(msgType);
  }
  const handleLyricToggle = () => {
    if (showLyrics === null) {
      setOpen(true);
      setMsg('Lyrics not available');
      setMsgType('error');
    }
    else {
      if (lyricToggle) {
        setLyricToggle(false);
      }
      else {
        setLyricToggle(true);
      }
    }
  }

  const resetLyricToggle = () => {
    setLyricToggle(false);
  }

  const handlebackMobilePlayer = () => {
    setLyricToggle(false)
  }


  const showShareMusic = () => {
    setShareModalOpen(true);
  }

  const handleCloseShare = () => {
    setShareModalOpen(false);
  }

  // const handleCloseLyric = () => {
  //   setLyricsModalOpen(false);
  // }

  const callFunctions = (id, genre) => {
    console.log('I am callFunctions', id, genre)
    if (genre === null || genre === "-1") {
      console.log('I am callFunctions inside -1', id, genre)
      props.clearSuggestionswithGenre()
      props.getSuggestionswithoutGenre(1, 100, 'regular', id);
    } else if (genre !== null && genre !== '') {
      console.log('I am callFunctions inside "', id, genre)
      props.clearSuggestionswithoutGenre()
      props.getSuggestionsWithGenre(1, 100, genre, id)
    }
  }

  const handleClickedPrevNext = (audioObject) => {
    // callFunctions(id,genre)
    console.log('pressed', audioObject)

    setClipId(audioObject.clipId);
    setPaid(audioObject.mode)
    setId(audioObject.id || audioObject.mediaId);
    setGenre(audioObject.genre);
    setShowLyrics(audioObject.lyrics)
  };

  useEffect(() => {
    console.log('called on update')
  }, [paid, clipId, id, lyricToggle, showLyrics])


  const ShareAudioModalBody = (
    <SocialShareModal
      toggle={setShareModalOpen}
      // title="abc"
      // separator="|"
      quote="def"
      hashTag="#jik"
      handleCloseShare={handleCloseShare}
      mediaType='Music'
      mediaId={id}
    />
  );

  const saveConsentLogsForWelcomeTone = (message) => {
    let acrOrMob = getAcrOrMob();
    if (acrOrMob) {
      const serviceUrl = window.location.href;
      props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_WELCOME_TONE_SERVICE, serviceUrl, message);
    }
  }

  const handleModalOpen = () => {
    saveConsentLogsForWelcomeTone(EN.FIRST_CONSENT_APPROVAL);
    setOpenModal(true)
    setOpenCallerTune(true)
  }

  const handleCallerTuneNonLogin = () => {
    setOpenLogin(true);
  }

  const handleModalClose = () => {
    setOpenModal(false)
    setOpenCallerTune(false)
  }

  const checkRBTStatus = () => {
    if (window.localStorage.getItem('phone')) {
      props.getRBTStatus(clipId, encodeURIComponent(window.localStorage.getItem('phone'))).then((result) => {
        if (result.responseList[0].status === 'normal' || result.responseList[0].status === 'inactive') {
          handleModalOpen();
        }
      });
    }
  }

  const closeLoginModal = () => {
    setOpenLogin(false);
    checkRBTStatus();
  }

  const updatedId = (id) => {
    console.log('got id', id)
    setId(id);
  }

  useEffect(() => {
    console.log('got 2 id', id)
    setShowWelcomeTone(true);
  }, [id]);

  const getAcrOrMob = () => {
    let acrOrMob = '';
    if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
      acrOrMob = window.localStorage.getItem('phone');
    } else if (window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) {
      acrOrMob = window.sessionStorage.getItem('he_acr_value');
    }
    return acrOrMob;
  }

  const saveConsentLogsForArtistPortal = (message) => {
    let acrOrMob = getAcrOrMob();
    if (acrOrMob) {
      const serviceUrl = window.location.href;
      props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, message);
    }
  }

  return (
    <>
      {audioLists &&
        <div>
          <ReactMusicPlayer
            PlayMode='orderLoop'
            paid={paid}
            mobile={mobile}
            onBeforeDestroy={onBeforeDestroy}
            onDestroyed={onDestroyed}
            showDestroy
            autoPlay={false}
            mode="full"
            // quietUpdate
            clearPriorAudioLists
            audioLists={audioLists}
            clipId={clipId}
            mediaId={id}
            genre={genre}
            handlePostFavouriteMusic={handleFavourite}
            RBTStatus={RBTStatus}
            showLoginNotification={showLoginNotification}
            isUserLoggedIn={props.isUserLoggedIn}
            handleClickedPrevNext={handleClickedPrevNext}
            showShareMusic={showShareMusic}
            lyricToggle={lyricToggle}
            handleLyricToggle={handleLyricToggle}
            resetLyricToggle={resetLyricToggle}
            handleModalOpen={handleModalOpen}
            handleCallerTuneNonLogin={handleCallerTuneNonLogin}
            // showLyricsMusic={showLyricsMusic}
            serviceOffers={props.serviceOffers}
            updatedId={updatedId}
            saveConsentLogsForArtistPortal={saveConsentLogsForArtistPortal}
            checkRBTStatus={checkRBTStatus}
            showLyrics={showLyrics}
            handlebackMobilePlayer={handlebackMobilePlayer}
            saveConsentLogsForWelcomeTone={saveConsentLogsForWelcomeTone}
            showWelcomeTone={showWelcomeTone}
            musicLoader={props.musicLoader}
          />
          <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleClose} />
          <div>
            <Modal
              open={shareModalOpen}
              onClose={() => setShareModalOpen(false)}
              BackdropProps={{
                style: {
                  backgroundColor: '#707070',
                  opacity: '80%',
                  boxShadow: 'none',
                },
              }}
            >
              {ShareAudioModalBody}
            </Modal>
          </div>
        </div>
      }

      {openCallerTune && RBTStatus === 'normal' && (
        <div>
          <CallerTune
            open={openModal}
            // thumbnail={cover}
            // title={this.renderAudioTitle()}
            clipId={clipId}
            handleClose={handleModalClose}
            setShowWelcomeTone={setShowWelcomeTone}
          />
        </div>
      )}



      {openCallerTune && RBTStatus === 'inactive' && (
        <div>
          <Dialog
            style={{ zIndex: 99999, position: 'absolute' }}
            open={true}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{ background: '#000000de' }} id="alert-dialog-title"></DialogTitle>
            <DialogContent style={{ background: '#000000de' }}>
              <DialogContentText style={{ fontFamily: 'SegoeUI !important', color: 'white' }} id="alert-dialog-description">
                This tune is still active in your callertune library till next billing date,
                Please try to set this tune on or after next billing date
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ background: '#000000de' }}>
              <Button onClick={handleModalClose} className={classes.buttonThemeColor} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <LoginModal openLogin={openLogin} setOpenLogin={setOpenLogin} closeLoginModal={closeLoginModal} showLoginNotification={showLoginNotification} />
    </>
  );
};
const mapDispatchToProps = dispatch => bindActionCreators({
  getSuggestionswithoutGenre,
  getSuggestionsWithGenre,
  clearSuggestionswithGenre,
  clearSuggestionswithoutGenre,
  postFavouriteMusic,
  getRBTStatus,
  saveconsentLog
}, dispatch);

const mapStateToProps = state => {
  return {
    audioObject: state.ui.playSelectedAudio,
    playUpdatedAudioPlaylist: state.ui.playSelectedAudioPlaylist,
    playUpdatedAudioPlaylistMode: state.ui.playSelectedAudioPlaylistMode,
    RBTStatusResponse: state.rbt.getRBTStatus,
    isUserLoggedIn: state.oauth.isUserLoggedIn,
    suggestionsWithGenre: state.suggestion.suggestionsWithGenre,
    suggestionsWithoutGenre: state.suggestion.suggestionsWithoutGenre,
    serviceOffers: state.payment.serviceOffers,

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MusicPlayer));
