// import search from "./search";

/* eslint-disable */
export const VIDEO_LIST_REQUESTED = 'video/VIDEO_LIST_REQUESTED';
export const VIDEO_LIST_SUCCESS = 'video/VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_FAILURE = 'video/VIDEO_LIST_FAILURE';
export const VIDEO_LIST_EMPTY = 'video/VIDEO_LIST_EMPTY';

export const UPLOAD_MEDIA_REQUESTED = 'video/UPLOAD_MEDIA_REQUESTED';
export const UPLOAD_MEDIA_SUCCESS = 'video/UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_FAILURE = 'video/UPLOAD_MEDIA_FAILURE';


export const DELETE_UPLOADED_MEDIA_REQUESTED = 'video/DELETE_UPLOADED_MEDIA_REQUESTED';
export const DELETE_UPLOADED_MEDIA_SUCCESS = 'video/DELETE_UPLOADED_MEDIA_SUCCESS';
export const DELETE_UPLOADED_MEDIA_FAILURE = 'video/DELETE_UPLOADED_MEDIA_FAILURE';

export const SET_ORDER_OF_MEDIA_REQUESTED = 'video/SET_ORDER_OF_MEDIA_REQUESTED';
export const SET_ORDER_OF_MEDIA_SUCCESS = 'video/SET_ORDER_OF_MEDIA_SUCCESS';
export const SET_ORDER_OF_MEDIA_FAILURE = 'video/SET_ORDER_OF_MEDIA_FAILURE';

export const CHANGE_MEDIA_STATUS_REQUESTED = 'video/CHANGE_MEDIA_STATUS_REQUESTED';
export const CHANGE_MEDIA_STATUS_SUCCESS = 'video/CHANGE_MEDIA_STATUS_SUCCESS';
export const CHANGE_MEDIA_STATUS_FAILURE = 'video/CHANGE_MEDIA_STATUS_FAILURE';

export const GET_CATEGORY_LIST_REQUESTED = "video/GET_CATEGORY_LIST_REQUESTED";
export const GET_CATEGORY_LIST_SUCCESS = "video/GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILURE = "video/GET_CATEGORY_LIST_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  videoDataList: [],
  videoListCount: 0,
  videoCategoryList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VIDEO_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case VIDEO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        videoDataList: [...state.videoDataList, ...action.result.responseList],
        videoListCount: action.result.totalCount
      };
    }
    case VIDEO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case VIDEO_LIST_EMPTY: {
      console.log("%cempty", 'color:red');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        videoDataList: [],
      };
    }
    case UPLOAD_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPLOAD_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      }
    }
    case UPLOAD_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }

    case DELETE_UPLOADED_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_UPLOADED_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      }
    }
    case DELETE_UPLOADED_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case SET_ORDER_OF_MEDIA_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case SET_ORDER_OF_MEDIA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      }
    }
    case SET_ORDER_OF_MEDIA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case CHANGE_MEDIA_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CHANGE_MEDIA_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      }
    }
    case CHANGE_MEDIA_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case GET_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        videoCategoryList: action.result
      };
    }
    case GET_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const getVideoList = (pageNo, pageSize, searchKey, filterBy,category='all') => {
  let filter = 'live';
  if(filterBy !== undefined) {
    filter = filterBy;
  }
  return {
    types: [VIDEO_LIST_REQUESTED, VIDEO_LIST_SUCCESS, VIDEO_LIST_FAILURE],
    promise: (client) => client.get(`getVideoList?pageNo=${pageNo}&pageSize=${pageSize}&search=${searchKey}&filterBy=${filter}&category=${encodeURIComponent(category)}`, {
    })
  };
};

export const emptyVideoList = () => {
  console.log("%cempty", 'color:red');
  return {
    type: VIDEO_LIST_EMPTY
  };
};

export const uploadMediaFile = (data) => {
  return {
    types: [UPLOAD_MEDIA_REQUESTED, UPLOAD_MEDIA_SUCCESS, UPLOAD_MEDIA_FAILURE],
    promise: (client) => client.post('uploadMediaFile', {
      data,
    })
  };
};

export const deleteUploadedMedia = (id, type) => {
  return {
    types: [DELETE_UPLOADED_MEDIA_REQUESTED, DELETE_UPLOADED_MEDIA_SUCCESS, DELETE_UPLOADED_MEDIA_FAILURE],
    promise: (client) => client.del(`deleteUploadedMedia?mediaId=${id}&mediaType=${type}`, {
    })
  };
};

export const setOrderOfMedia = (id, type, order) => {
  return {
    types: [SET_ORDER_OF_MEDIA_REQUESTED, SET_ORDER_OF_MEDIA_SUCCESS, SET_ORDER_OF_MEDIA_FAILURE],
    promise: (client) => client.post(`setOrderOfMedia?mediaId=${id}&mediaType=${type}&order=${order}`, {
    })
  };
};

export const changeMediaStatus = (type, id, status) => {
  return {
    types: [CHANGE_MEDIA_STATUS_REQUESTED, CHANGE_MEDIA_STATUS_SUCCESS, CHANGE_MEDIA_STATUS_FAILURE],
    promise: (client) => client.post(`changeMediaStatus?mediaId=${id}&mediaType=${type}&status=${status}`, {
    })
  };
}

export const getCategoryList = (status) => {
  return {
    types: [GET_CATEGORY_LIST_REQUESTED, GET_CATEGORY_LIST_SUCCESS, GET_CATEGORY_LIST_FAILURE],
    promise: (client) => client.get(`categoryList?status=${status}`, {
    })
  };
};
