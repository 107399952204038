/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Grid, Card } from '@material-ui/core';
import { getSuggestionswithoutGenre, getSuggestionsWithGenre } from '../../../reducers/suggestion';
import { getMusicDetails } from '../../../reducers/portalContentDetails';
import { handleSelectedAudio} from '../../../reducers/ui';
import { updateHistoryList } from '../../../reducers/history';
import { ellipsis, getContentPath } from '../../../helpers/utils';
import PremiumIcon from '@assets/images/PremiumIcon.svg';


const useStyles = makeStyles((theme) => ({
    header: {
        color: theme.palette.titleClr,
        opacity:'80%',
        fontSize: '20px',
        marginBottom: 20,
    },
    subtitle: {
        color: theme.palette.titleClr,
        opacity:'60%',
        fontSize: '12px',
        marginBottom:'8px'

    },
    card: {
        width: '100%',
        overflowX: 'scroll',
        backgroundColor: 'transparent',
        color: theme.palette.titleClr,
        display: 'flex',
        gap: '5px',
        '&::-webkit-scrollbar': {
            width: 0,
        },
        boxShadow:'none',
    },
    

}));

const PlayerSuggestions = (props) => {
    const classes = useStyles();
    const scrollSuggestion = useRef();
    const [musicPlayerOpen, setMusicPlayerOpen] = useState(false);
    const pscrolled = useRef();

    const isAdminPage = window.location.pathname.includes('/celebrityadmin') ? true : false;
    const isProductAdminPage = window.location.pathname.includes('/productadmin') ? true : false;

    useEffect(() => {
        console.log("genresuggestions", props.genreSuggestions);
        console.log("suggestionsmediaId", props.mediaIdSuggestions);

        // if (props.genreSuggestions === null) {
        //     props.getSuggestionswithoutGenre(1, 100, 'regular', props.mediaIdSuggestions);
        // }
        // if (props.genreSuggestions !== null && props.genreSuggestions !== '') {
        //     props.getSuggestionsWithGenre(1, 100, props.genreSuggestions, props.mediaIdSuggestions)
        // }
    }, [props.genreSuggestions, props.mediaIdSuggestions])

    useEffect(() => {

        // scrollSuggestion.current.addEventListener('scroll', function (e) {
        //     console.log('scrollSuggestion.current', scrollSuggestion.current)
        //     e.preventDefault();
        // })
        // return () => scrollSuggestion.current.removeEventListener('scroll', function (e) {
        //     e.preventDefault();
        // })

        pscrolled.current.addEventListener('wheel',function(e){
            console.log('pscrolled')
            e.preventDefault();
          },false)
      
          return ()=> pscrolled.current.removeEventListener('wheel',function(e){
            e.preventDefault();
          },false)

    }, [])

    const handleSuggestion = (audio) => {
        if (audio.hasOwnProperty('duration')) {
            props.getMusicDetails(audio.id || audio.mediaId, 'regular').then((response) => {
                props.handleSelectedAudio(response.responseList[0]);
            });
            if(!isAdminPage && !isProductAdminPage) {
                if (audio.mediaId) {
                    props.updateHistoryList({
                        mediaId: audio.mediaId,
                        title: audio.title,
                        description: audio.description,
                        thumbnail: audio.thumbnail,
                        mediaType: 'Music',
                    });
                }
                if (audio.id) {
                    props.updateHistoryList({
                        mediaId: audio.id,
                        title: audio.title,
                        description: audio.description,
                        thumbnail: audio.thumbnail,
                        mediaType: 'Music',
                    });
                }
            }
        }
    };

    return (
        <Grid >
            <div ref={pscrolled} >
                <h2 align='left' className={classes.header}><span className='semibold'>Suggestion</span></h2>
            </div>
            <Grid spacing={1} className='list' onClick={handleSuggestion}>
                <Card className={classes.card} >
                    {props.genreSuggestions === null &&
                        props.suggestionsWithoutGenre.map((result, index) => (
                        <div style={{ fontSize:12, fontFamily:'SegoeUI'}}>
                            <div style={{position:'relative', height: '100px',width:'100px', minWidth: '100px', maxWidth: '100px', borderRadius: '15%',cursor:'pointer' }}>
                           {result.mode ==='paid' &&  (props.serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') && <div style={{ position:'absolute',bottom: '4%', left: '2%',  zIndex: 1}}>
                                    <img style={{ height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />  
                                </div>}
                                <img style={{ width: '100%', height: '100%', borderRadius: '15px' }} src={getContentPath(result.thumbnail)} alt="img" onClick={() =>handleSuggestion(result)} />
                            </div>
                            <div>{ellipsis(result.title,15)}</div>
                        </div>))
                        }
                    {props.genreSuggestions !== null && props.genreSuggestions !== '' &&
                        props.suggestionsWithGenre.map((result, index) => (
                            <div style={{ fontSize:12, fontFamily:'SegoeUI'}}>
                                <div style={{ position:'relative', height: '100px',width:'100px', minWidth: '100px', maxWidth: '100px', borderRadius: '15%',cursor:'pointer'  }}>
                                    {result.mode ==='paid' && (props.serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') && <div style={{ position:'absolute',bottom: '4%', left: '2%',  zIndex: 1}}>
                                        <img style={{ height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />  
                                    </div>}
                                    <img style={{ width: '100%', height: '100%', borderRadius: '15px' }} src={getContentPath(result.thumbnail)} alt="img" onClick={() =>handleSuggestion(result)} />
                                </div>
                                <div>{ ellipsis(result.title,15)}</div>
                            </div>
                        ))}
                </Card>
            </Grid>
            {/* {musicPlayerOpen && <MusicPlayer />} */}
        </Grid>
    );
};
const mapStateToProps = (state) => {
    return {
        suggestionsWithoutGenre: state.suggestion.suggestionsWithoutGenre,
        suggestionsWithGenre: state.suggestion.suggestionsWithGenre,
        serviceOffers: state.payment.serviceOffers,
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSuggestionswithoutGenre,
    getSuggestionsWithGenre,
    getMusicDetails,
    handleSelectedAudio,
    updateHistoryList
},
    dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerSuggestions));
