//  This page redirects to the Main URL Dashboard
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core';
import { APP_CONFIG } from '@constants';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { getContentPath } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  MainbackGround: {
    position: 'fixed',
    margin: 'auto',
    overflowY: 'scroll',
    backgroundColor: 'black',
    width: '100%',
    minWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  maindiv: {
    position: 'absolute',
    top: '43%',
    width: '100vw',
    [theme.breakpoints.up('1024')]: {
      width: '500px',
    },
  },
  backImg: {
    backgroundRepeat: 'no-repeat',
    color: theme.palette.titleClr,
    height: '100vh',
    [theme.breakpoints.down('1024')]: {
      width: '412px',
    },
    width: '500px',
    backgroundSize: 'cover',
  },
  firstText: {
    fontSize: '32px',
    color: theme.palette.selectedTxtClr,
    display: 'flex',
    justifyContent: 'center',
  },
  wlcmText: {
    fontSize: '22px',
    margin: '30px 0px 0px 20px',
  },
  ButtonStyle: {
    maxWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  enterBtn: {
    margin: '40px 0 0 20px',
    borderRadius: '50px',
    background: theme.palette.selectedTxtClr,
    color: theme.palette.titleClr,
    height: '50px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.selectedTxtClr,
      color: theme.palette.titleClr,
    },
    fontSize: '16px',
  },
  enjoyText: {
    margin: '0px 0px 0px 20px',
  },
}));

function AdsEnter() {
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  let advImage;
  if (APP_CONFIG.artistDetails && params.get('campaignType') === '1') {
    advImage = APP_CONFIG.artistDetails.properties.campaignType1Img;
  } else if (params.get('campaignType') === '2') {
    advImage = APP_CONFIG.artistDetails.properties.campaignType2Img;
  }

  const content = {
    firstText: 'celebrityconnect.co.za',
    wlcmText: 'Welcome',
    enjoyText: 'Enjoy all my exclusive updates now!',
  };

  const RedirectToHomePage = () => {
    history.push('/homepage');
  };

  const calls = (w, d, s, l, i) => {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || []; w[l].push({
      'gtm.start':

        new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];

    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
  };

  useEffect(() => {
    calls(window, document, 'script', 'dataLayer', 'GTM-KSN4S23');
  }, []);

  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KSN4S23"
          title="load"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <div className={classes.MainbackGround}>
        <div className={classes.backImg} style={{ backgroundImage: `url("${getContentPath(advImage)}")` }}>
          <div className={classes.maindiv}>
            <div className={classes.firstText}><span className="bold">{content.firstText}</span></div>
            <div className={classes.wlcmText}><span className="bold">{content.wlcmText}</span></div>
            <div className={classes.enjoyText}>{content.enjoyText}</div>
            <div className={classes.ButtonStyle}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.enterBtn}
                onClick={RedirectToHomePage}
              >
                <span className="bold">ENTER NOW</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdsEnter)
);
