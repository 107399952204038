/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Grid, useMediaQuery, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Masonry from './Masonry';
import MasonryResponsive from './MasonryResponsive';
import { ellipsis, getContentPath } from '../../helpers/utils';
import {
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
  renderFeedbackPanel,
  handleSelectedVideo,
  renderSelectedCallerTunePanel,
  renderCommentPanel,
} from '../../reducers/ui';
import { getVideoList, emptyVideoList, uploadMediaFile, setOrderOfMedia, changeMediaStatus, getCategoryList } from '../../reducers/video';
import { getMusicGenreList } from '../../reducers/music';
import { getTrendingMediaList } from '../../reducers/trending';
import { getLikeVideoList, postLikeApiList } from '../../reducers/like';
import { getAllMusicAlbum } from '../../reducers/album';
import usePagination from '../../hooks/usePagination';
import { getCommentListVideo } from '../../reducers/comment';
import chatIconList from '@assets/images/chatIconList.svg';
import UploadModal from '@containers/Modals/UploadModal';
import SetOrderModal from '@containers/Modals/SetOrderModal';
import ReactSelect from '@formElements/ReactSelect';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import CustomPopper from '../common/CustomPopper/CustomPopper';
import { EN } from '@localization';
import NotificationMessage from '../NotificationMessage';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import videoPlayerIcon from '@assets/images/VideoPlayerIcon.svg';
import AddIcon from '@material-ui/icons/Add';
import ApproveIcon from '@material-ui/icons/Done';
import RejectIcon from '@material-ui/icons/Close';
import ScrollTab from '../common/ScrollTab/ScrollTab';

const FILTER_LIST = [{ label: 'All', value: 'all' },
                     { label: 'Pending', value: 'inactive' },
                     { label: 'Rejected', value: 'rejected' },
                    { label: 'Approved', value: 'approved' },
                    { label: 'Live', value: 'live' },
                     { label: 'Suspended', value: 'suspended' }];

const useStyles = makeStyles((theme) => ({
  masonry: {
    paddingRight: '50px',
    marginBottom: '70px',
    marginRight: (window.location.pathname.includes('/celebrityadmin') || window.location.pathname.includes('/productadmin')) ? '10px' : '0',
    [theme.breakpoints.between('1025', '5000')]: {
      marginLeft: '60px',
    },
    background: 'black',
  },
  cardScroll: {
    overflowY: 'scroll',
    height: '100vh',
    scrollBehavior: 'smooth',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  img: {
    borderRadius: '10px',
    cursor: 'pointer',
    objectFit: 'contain',
    width: '100%'
  },
  videoFooter: {
    display: 'flex',
    fontFamily: 'Arial',
    color: theme.palette.titleClr,
    justifyContent: 'space-between',
    marginTop: '15px',
    marginBottom: '10px',
    [theme.breakpoints.down('1024')]: {
      marginTop: '2px',
      marginBottom: '0px',
    },
  },
  trending: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    paddingTop: '60px'
  },
  uploadGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '30px',
    marginRight: 'calc(100vw - 95.5vw)',
    [theme.breakpoints.down('1750')]: {
      marginRight: 'calc(100vw - 95vw)',
    },
    [theme.breakpoints.down('1550')]: {
      marginRight: 'calc(100vw - 94vw)',
    },
    [theme.breakpoints.down('1400')]: {
      marginRight: 'calc(100vw - 93vw)',
    },
    [theme.breakpoints.down('1300')]: {
      marginRight: 'calc(100vw - 92vw)',
    },
  },
  upload: {
    marginBottom: '30px',
    textTransform: 'capitalize',
    borderRadius: '50px',
    height: '43px',
    backgroundColor: 'rgb(0, 210, 241)',
    [theme.breakpoints.between('0', '1024')]: {
      color: theme.palette.selectedTxtClr,
      fontSize: '0.8em',
      height: 30,
    },
    [theme.breakpoints.between('1025', '5000')]: {
      background: `linear-gradient(126deg, ${theme.palette.selectedTxtClr}, #181D44)`,
      width: '8em',
      color: '#ffffff',
      fontSize: '1.125rem',
    },
  },
  topLeft: {
    position: 'absolute',
    top: '5%',
    left: '5%',
    color: 'black',
    fontSize: '1rem',
    backgroundColor: theme.palette.titleClr,
    fontWeight: '1000',
    borderRadius: '30px',
    padding: '5px 14px',
  },
  topRightSuccess: {
    position: 'absolute',
    top: '5%',
    right: '5%',
    color: '#079107'
  },
  topRightError: {
    position: 'absolute',
    top: '5%',
    right: '5%',
    color: '#AD0028'
  },
  topRightInfo: {
    position: 'absolute',
    top: '5%',
    right: '5%',
    color: theme.palette.titleClr
  },
  starIconForVideo: {
    position: 'absolute',
    bottom: '25%',
    left: '2%',
    zIndex: '1',
    [theme.breakpoints.between('320', '1024')]: {
      bottom: '22%',
    },
  },
  loaderbackdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  videoPlayerIcon: {
    position: 'absolute',
    bottom: '27%',
    cursor: 'pointer',
    right: '4%',
    opacity: '0.5',
    [theme.breakpoints.down('1024')]: {
      bottom: '25%',
    },

  },
  videoPlayerIconImg: {
    [theme.breakpoints.down('1024')]: {
      height: '40px',
      width: '40px',
      paddingBottom: '10px'
    },
  },
  titleAlignmentForSuggest: {
    padding: '15px 20px 10px 20px'
  },
  titleAlignmentForVideo: {
    padding: '15px 20px 10px 20px'
  },
  loaderStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '77vw',
    height: '50vh',
    alignItems: 'center',
    [theme.breakpoints.down('1024')]: {
      width: '90vw',
      height: '30vh',
    },
  },
  circleLoader: {
    color: theme.palette.selectedTxtClr,
  },
  errorText:{
    textAlign:'center !important',
    marginTop:20,
    color: 'red',
    [theme.breakpoints.down('1024')]: {
      fontSize:'1.8125rem',
    },
    [theme.breakpoints.up('1024')]: {
      fontSize:'2.8125rem',
      marginBottom:200
    },
  }
}));

const Video = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const mobileView = useMediaQuery('(max-width:1024px)');
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [genreList, setGenreList] = useState();
  const [albumList, setAlbumList] = useState();
  const [categoryList, setCategoryList] = useState();

  const mediaType = 2;
  const [videoList, setVideoList] = useState([]);
  const [trendingVideoList, setTrendingVideoList] = useState([]);
  const [likedVideoList, setLikedVideoList] = useState([]);

  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [mediaDetails, setMediaDetails] = useState();
  const [moreOptionOpen, setMoreOptionOpen] = useState(false);

  const [tElement, nextPage, setPage] = usePagination({});
  const firstTimeRender = useRef(true);

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMsgType, setNotificationMsgType] = useState('');
  const [notificationMsg, setNotificationMsg] = useState('');

  const isAdminPage = window.location.pathname.includes('/celebrityadmin') ? true : false;
  const isProductAdminPage = window.location.pathname.includes('/productadmin') ? true : false;

  const [filterBy, setFilterBy] = (isAdminPage || isProductAdminPage) ? useState(FILTER_LIST[0].value) : useState(FILTER_LIST[4].value);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const Suggest = window.location.pathname === '/videoLibrary';
  const videoPage = window.location.pathname === '/video';
  const [videoCat,setVideoCat] = useState('all')
  
  useEffect(() => {
    window.scrollTo(0,0);
    setLoading(false)
    const searchKey = document.getElementById('outlined-search')
      ? document.getElementById('outlined-search').value
      : '';
    if (isAdminPage) {
      props.getMusicGenreList('live', true);
      props.getAllMusicAlbum('live');
      props.getCategoryList('live');
    } else {
      props.getMusicGenreList('live');
    }
    props.emptyVideoList();
    if (props.isUserLoggedIn && !isAdminPage && !isProductAdminPage) {
      props.getLikeVideoList('Video');
    }
    props.getVideoList(1, 20, searchKey, filterBy, videoCat).then(() => {
      setLoading(true)
    });
    return () => props.emptyVideoList();
  }, [videoCat]);

  useEffect(() => {
    if (props.isUserLoggedIn && !isAdminPage && !isProductAdminPage) {
      props.getLikeVideoList('Video');
    }
  }, [props.isUserLoggedIn]);

  useEffect(() => {
    const likeVideoIds = props.LikeVideo.reduce((prev, curr) => {
      return [...prev, curr.mediaId]
    }, [])
    setLikedVideoList(likeVideoIds);
    setTrendingVideoList(props.trendingVideoList);
    setVideoList(props.videoDataList);
  }, [props.LikeVideo, props.trendingVideoList, props.videoDataList]);

  useEffect(() => {
    const searchKey = document.getElementById('outlined-search')
      ? document.getElementById('outlined-search').value
      : '';
    if (nextPage > 1 && (nextPage - 1)* 20 <= props.videoListCount) props.getVideoList(nextPage, 20, searchKey, filterBy, videoCat);
  }, [nextPage, props.videoListCount]);

  useEffect(() => {
    let list = [];
    props.musicGenreListFromAPI.map((item) => {
      list = [...list, { 'label': item.title }];
    });
    console.log("genrelist", list);
    setGenreList(list);

  }, [props.musicGenreListFromAPI]);

  useEffect(() => {
    let list = [];
    console.log("props.videoCategoryList", props.videoCategoryList);
    props.videoCategoryList.map((item) => {
      list = [...list, { 'label': item.title }];
    });
    console.log("categoryList", list);
    setCategoryList(list);
  }, [props.videoCategoryList]);

  useEffect(() => {
    let list = [];
    props.allMusicAlbum.length > 0 && props.allMusicAlbum.map((item) => {
      list = [...list, { 'value': item.id + '-' + item.albumName, 'label': item.albumName }];
    });
    setAlbumList(list);

  }, [props.allMusicAlbum]);

  useEffect(() => {
    if (firstTimeRender.current) {
      firstTimeRender.current = false;
    } else {
      successUpload();
    }
  }, [filterBy]);

  const handleVideoPlayer = (data) => {
    props.handleSelectedVideo(data);
    if (isAdminPage) {
      history.push(`/celebrityadmin/videoplayer?videoId=${data.id}`);
    } else if (isProductAdminPage) {
      history.push(`/productadmin/videoplayer?videoId=${data.id}`);
    } else {
      history.push(`/videoplayer?videoId=${data.id}`);
    }
  };

  const successUpload = (data) => {
    console.log('data success', data);
    if (data) {
      setNotificationMsg(data.message);
      setNotificationMsgType('success');
      setNotificationOpen(true);
    }
    props.emptyVideoList();
    setPage(1);
    const searchKey = document.getElementById('outlined-search')
      ? document.getElementById('outlined-search').value
      : '';
    props.getVideoList(1, 20, searchKey, filterBy, videoCat);
  }

  const failureUpload = (error) => {
    console.log('error failure', error);
    setNotificationMsg(error.errorMessage);
    setNotificationMsgType('error');
    setNotificationOpen(true);
  }

  const uploadMediaFile = (formData) => {
    setUploadModalOpen(false);
    setLoader(true);
    props.uploadMediaFile(formData).then((data) => {
      setLoader(false);
      successUpload(data);
    }).catch((error) => {
      setLoader(false);
      failureUpload(error)
    });
  }

  const uploadModalBody = (
    <UploadModal
      isEdit={false}
      setUploadModalOpen={setUploadModalOpen}
      mediaType={mediaType}
      uploadMediaFile={uploadMediaFile}
      genreList={genreList}
      albumList={albumList}
      categoryList={categoryList}
    />
  );

  const onChangeOfFilter = (value) => {
    console.log("onChangeOfFilter", value);
    setFilterBy(value);
  }

  const setMediaDetailsandOpenPopper = (value) => {
    console.log('media', value);
    setMediaDetails(value);
    setMoreOptionOpen(true);
  };

  const openOrderModal = () => {
    setOrderModalOpen(true);
  };

  const setOrderOfMedia = (value) => {
    console.log("order media", value);
    setOrderModalOpen(false);
    setLoader(true);
    props.setOrderOfMedia(mediaDetails.id, 'Video', value).then((data) => {
      setLoader(false);
      successUpload(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  };

  const setOrderModalBody = (
    <SetOrderModal setOrderModalOpen={setOrderModalOpen} setOrderOfMedia={setOrderOfMedia} mediaDetails={mediaDetails} />
  );

  const handleCloseAway = () => {
    setMoreOptionOpen(false)
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMoreOptionOpen(false)
    }
  };

  const handleApproveMedia = () => {
    console.log("approve", mediaDetails.id);
    setLoader(true);
    handleCloseAway();
    props.changeMediaStatus('Video', mediaDetails.id, 1).then((data) => {
      setLoader(false);
      successUpload(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  };

  const handleRejectMedia = () => {
    console.log("reject", mediaDetails.id);
    setLoader(true);
    handleCloseAway();
    props.changeMediaStatus('Video', mediaDetails.id, 0).then((data) => {
      setLoader(false);
      successUpload(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  };

  const passingData = [
    {
      value: 'Set Order',
      icon: AddIcon,
      handleClick: openOrderModal
    }
  ];

  const passingDataForProductAdmin = [
    {
      value: 'Approve Video',
      icon: ApproveIcon,
      handleClick: handleApproveMedia
    },
    {
      value: 'Reject Video',
      icon: RejectIcon,
      handleClick: handleRejectMedia
    }
  ];

  const getMenu = () => {
    if (isAdminPage) {
      return passingData;
    } else if (isProductAdminPage) {
        if(mediaDetails.status === "live" || mediaDetails.status === "approved") {
          return passingData;
        } else {
          return passingDataForProductAdmin;
        }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationOpen(false);
  };

  const handleLikeDisLike = (data, action) => {
    handleVideoPlayer(data);
    if (props.isUserLoggedIn && !isAdminPage && !isProductAdminPage) {
      props.postLikeApiList({
        mediaType: 'Video',
        mediaId: data.id,
        action: action,
      });
    }
  }
  const handleVideoCardIcon = (data) => {
    console.log("testing")
    props.handleSelectedVideo(data);
    if (isAdminPage) {
      history.push(`/celebrityadmin/videoplayer?videoId=${data.id}`);
    } else if (isProductAdminPage) {
      history.push(`/productadmin/videoplayer?videoId=${data.id}`);
    } else {
      history.push(`/videoplayer?videoId=${data.id}`);
    }
  };

  const handleTab = (item, index)=>{
    console.log('Clicked Video Genre',item, loading);
    setVideoCat(item.value);
    setPage(1);
    // const searchKey = document.getElementById('outlined-search')
    // ? document.getElementById('outlined-search').value
    // : '';
    // props.getVideoList(1, 20, searchKey, filterBy, item.value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: mobileView ? 'column' : 'row',
        background: 'black',
        marginTop: !mobileView && !isAdminPage && !isProductAdminPage && '30px',
      }}
    >
      <Grid item md={12} sm={12} xs={12} className={cn(classes.masonry, Suggest && classes.titleAlignmentForSuggest, (!Suggest && mobileView)&& classes.titleAlignmentForVideo)} style={{
        marginTop: Suggest ? '0px' : '0px', position:'relative'
      }}>
        {mobileView && window.location.pathname === '/video' && <div style={{ color: '#dbced2', fontSize: '24px', marginBottom: '10px', marginTop: Suggest ? '0px' : '0px' }}><span className='semibold'>Latest Video</span></div>}
        {mobileView && window.location.pathname !== '/video' && <div style={{ color: '#dbced2', fontSize: '26px', marginBottom: '10px', marginTop: Suggest ? '0px' : '0px' }}><span className='bold'>Suggestion</span></div>}
        {!mobileView && (
          <>
            <div>
              <Grid item className={classes.uploadGrid} >
                {(isAdminPage || isProductAdminPage) && (
                  <div className="col-xs-2 col-sm-2 col-md-2">
                    <ReactSelect
                      id="filterBy"
                      options={FILTER_LIST}
                      displayLabel={false}
                      value={filterBy}
                      onChange={onChangeOfFilter}
                      isNone={false}
                      disabled={false}
                      required={true}
                    />
                  </div>
                )}
                {(isAdminPage || isProductAdminPage) && (
                  <Button variant="contained"
                    className={classes.upload}
                    onClick={() => setUploadModalOpen(true)}
                  >{EN.UPLOAD}
                  </Button>
                )}
              </Grid>
            </div>
            <div>
              <Modal
                open={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
                BackdropProps={{
                  style: {
                    backgroundColor: '#707070',
                    opacity: '80%',
                    boxShadow: 'none',
                  },
                }}
              >
                {uploadModalBody}
              </Modal>
            </div>
          </>
        )}
      { !(isAdminPage || isProductAdminPage) && videoPage && <ScrollTab clicked={handleTab} />}
        <MasonryResponsive
          columnsCountBreakPoints={{ 350: 2, 750: 3, 1366: 4, 1847: 5 }}
        >
          <Masonry gutter={20} style={isProductAdminPage ? { marginTop: '30px' } : ''}>
          {loading ? 
            videoList.map((data, index) => {
              if (videoList.length === index + 1) {
                return (
                  <div ref={tElement} className={classes.card} key={index}>
                    {data.mode === 'paid' && (props.serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') && (
                      <div className={classes.starIconForVideo}>
                        <img style={{ height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />  
                      </div>
                    )}

                    <div className={classes.videoPlayerIcon} onClick={() => handleVideoCardIcon(data)}>
                      <img src={videoPlayerIcon} alt={data.title} className={classes.videoPlayerIconImg} />
                    </div>
                    <img
                      src={getContentPath(data.thumbnail)}
                      alt="img"
                      title={data.title}
                      className={classes.img}
                      onClick={() => handleVideoPlayer(data)}
                    />
                    {(isAdminPage || isProductAdminPage) && data.order > 0 && (
                      <div className={classes.topLeft}>{data.order}</div>
                    )}
                    {(isAdminPage || isProductAdminPage) && (data.status === 'live' || data.status === 'approved') && (
                      <CheckCircleIcon className={classes.topRightSuccess} />
                    )}
                    {(isAdminPage || isProductAdminPage) && data.transcodingStatus === 'FAILED' && (
                      <Tooltip title="Error in uploading video">
                        <ErrorIcon className={classes.topRightError} />
                      </Tooltip>
                    )}
                    {(isAdminPage || isProductAdminPage) && data.status === 'suspended' && (
                      <Tooltip title="Video expired">
                        <ErrorIcon className={classes.topRightError} />
                      </Tooltip>
                    )}
                    {(isAdminPage || isProductAdminPage) && (data.transcodingStatus === 'PENDING' || data.transcodingStatus === 'RUNNING') && (
                      <Tooltip title="Transcoding of video is in-progress">
                        <ErrorIcon className={classes.topRightInfo} />
                      </Tooltip>
                    )}
                    <div className={classes.videoFooter}>
                      <div style={{ fontSize: mobileView ? '0.75rem' : '0.75rem',display:'flex',alignItems:'center' }}>
                      {mobileView ? ellipsis(data.title, 8): ellipsis(data.title, 20)}
                      </div>
                      <div style={{ display: 'flex', gap: '15px' }}>
                        {/* <div style={{ fontSize: mobileView ? '0.75rem' : '0.8125rem', display: 'flex', alignSelf: 'center' }}>
                          {likedVideoList.includes(data.id) ? (
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={() => {
                              handleLikeDisLike(data, 'Like');
                            }}>
                              <FavoriteIcon
                                className={classes.mobileSize}
                                style={{
                                  color: '#ef485c',
                                  paddingRight: '5px',
                                  cursor: 'pointer',
                                  fontSize: mobileView ? 20 : 20,
                                }}

                              />
                              {data.numLikes}
                            </div>
                          ) : (
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={() => {
                              handleLikeDisLike(data, 'Undo-like');
                            }}>
                              <FavoriteBorderIcon
                                className={classes.mobileSize}
                                style={{
                                  color: '#ffffff',
                                  paddingRight: '5px',
                                  cursor: 'pointer',
                                  fontSize: mobileView ? 20 : 20,
                                }}

                              />
                              {data.numLikes}
                            </div>
                          )}
                        </div> */}
                        {/* <div
                          onClick={() => {
                            handleVideoPlayer(data);
                            if (mobileView) props.renderCommentPanel(true);
                          }}
                          style={{ fontSize: mobileView ? '0.75rem' : '0.6875rem', display: 'flex', alignSelf: 'center',cursor:'pointer' }}
                        >
                          <img
                            src={chatIconList}
                            className={classes.mobileSize}
                            style={{
                              color: 'white',
                              paddingRight: '5px',
                              paddingBottom: mobileView ? '2px' : '0px',
                              cursor: 'pointer',
                              width: mobileView ? 18 : 20,
                            }}
                          />
                          {data.numComments}
                        </div> */}
                        {(((isAdminPage || isProductAdminPage) && (data.status === 'live' || data.status === 'approved')) || (isProductAdminPage && data.status === 'inactive' && data.transcodingStatus === 'SUCCEEDED')) && (
                          <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <MoreVertIcon onClick={() => setMediaDetailsandOpenPopper(data)} />
                            {mediaDetails && data.id === mediaDetails.id && (
                              <div style={{ position: 'absolute', right: 120, zIndex: 9 }}>
                                <CustomPopper popperOpen={moreOptionOpen} handlePopperListKeyDown={handleListKeyDown} menu={getMenu()} handleCloseAway={handleCloseAway} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={classes.card} key={index}>
                    {data.mode === 'paid' && (props.serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') && (
                      <div className={classes.starIconForVideo}>
                        <img style={{ height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />  
                      </div>
                    )}
                    <div className={classes.videoPlayerIcon} onClick={() => handleVideoCardIcon(data)}>
                      <img src={videoPlayerIcon} alt={data.title} className={classes.videoPlayerIconImg} />
                    </div>
                    <img
                      src={getContentPath(data.thumbnail)}
                      alt="img"
                      title={data.title}
                      className={classes.img}
                      onClick={() => handleVideoPlayer(data)}
                    />
                    {(isAdminPage || isProductAdminPage)  && data.order > 0 && (
                      <div className={classes.topLeft}>{data.order}</div>
                    )}
                    {(isAdminPage || isProductAdminPage) && (data.status === 'live' || data.status === 'approved') && (
                      <CheckCircleIcon className={classes.topRightSuccess} />
                    )}
                    {(isAdminPage || isProductAdminPage) && data.transcodingStatus === 'FAILED' && (
                      <Tooltip title="Error in uploading video">
                        <ErrorIcon className={classes.topRightError} />
                      </Tooltip>
                    )}
                     {(isAdminPage || isProductAdminPage) && data.status === 'suspended' && (
                      <Tooltip title="Video expired">
                        <ErrorIcon className={classes.topRightError} />
                      </Tooltip>
                    )}
                    {(isAdminPage || isProductAdminPage) && (data.transcodingStatus === 'PENDING' || data.transcodingStatus === 'RUNNING') && (
                      <Tooltip title="Transcoding of video is in-progress">
                        <ErrorIcon className={classes.topRightInfo} />
                      </Tooltip>
                    )}
                    <div className={classes.videoFooter}>
                      <div style={{ fontSize: mobileView ? '0.75rem' : '0.75rem',display:'flex',alignItems:'center' }}>
                      {mobileView ? ellipsis(data.title, 8): ellipsis(data.title, 20)}
                      </div>
                      <div style={{ display: 'flex', gap: '15px' }}>
                        {/* <div style={{ fontSize: mobileView ? '0.75rem' : '0.8125rem', display: 'flex' }}>
                          {likedVideoList.includes(data.id) ? (
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={() => {
                              handleLikeDisLike(data, 'Undo-like');
                            }}>
                              <FavoriteIcon
                                className={classes.mobileSize}
                                style={{
                                  color: '#ef485c',
                                  paddingRight: '5px',
                                  cursor: 'pointer',
                                  fontSize: mobileView ? 20 : 20,
                                }}

                              />
                              {data.numLikes}
                            </div>
                          ) : (
                            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={() => {
                              handleLikeDisLike(data, 'Like');
                            }}>
                              <FavoriteBorderIcon
                                className={classes.mobileSize}
                                style={{
                                  color: '#ffffff',
                                  paddingRight: '5px',
                                  cursor: 'pointer',
                                  fontSize: mobileView ? 20 : 20,
                                }}
                              />
                              {data.numLikes}
                            </div>
                          )}

                        </div> */}
                        {/* <div
                          onClick={() => {
                            handleVideoPlayer(data);
                            if (mobileView) props.renderCommentPanel(true);
                          }}
                          style={{ fontSize: mobileView ? '0.75rem' : '0.8125rem',cursor:'pointer',display:!mobileView && 'flex',alignItems:!mobileView && 'center' }}
                        >
                          <img
                            src={chatIconList}
                            className={classes.mobileSize}
                            style={{
                              color: 'white',
                              paddingRight: '5px',
                              paddingBottom: mobileView ? '2px' : '0px',
                              cursor: 'pointer',
                              width: mobileView ? 18 : 20,
                            }}
                          />
                          {data.numComments}
                        </div> */}
                        {(((isAdminPage || isProductAdminPage) && (data.status === 'live' || data.status === 'approved')) || (isProductAdminPage && data.status === 'inactive' && data.transcodingStatus === 'SUCCEEDED')) && (
                          <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <MoreVertIcon onClick={() => setMediaDetailsandOpenPopper(data)} />
                            {mediaDetails && data.id === mediaDetails.id && (
                              <div style={{ position: 'absolute', right: 120, zIndex: 9 }}>
                                <CustomPopper popperOpen={moreOptionOpen} handlePopperListKeyDown={handleListKeyDown} menu={getMenu()} handleCloseAway={handleCloseAway} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            }) :
              <div className={classes.loaderStyle}>
                <CircularProgress className={classes.circleLoader} />
              </div>
            }
          </Masonry>
          <Backdrop className={classes.loaderbackdrop} open={loader}>
            <CircularProgress className={classes.circleLoader} />
          </Backdrop>
          <div>
            <Modal
              open={orderModalOpen}
              onClose={() => setOrderModalOpen(false)}
              BackdropProps={{
                style: {
                  backgroundColor: '#707070',
                  opacity: '80%',
                  boxShadow: 'none',
                },
              }}
            >
              {setOrderModalBody}
            </Modal>
          </div>
          <div className='w-100 mt-20per'>
            {videoList.length === 0 && loading && (
              <div className={classes.errorText}>{EN.NO_MEDIA}</div>
            )}
          </div>
        </MasonryResponsive>
      </Grid>
      <NotificationMessage open={notificationOpen} msgType={notificationMsgType} msg={notificationMsg} handleClose={handleClose} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    videoDataList: state.video.videoDataList,
    loaded: state.video.loaded,
    loading: state.video.loading,
    videoListCount: state.video.videoListCount,
    trendingVideoList: state.trending.trendingVideoList,
    LikeVideo: state.like.LikeVideo,
    musicGenreListFromAPI: state.music.musicGenreList,
    isUserLoggedIn: state.oauth.isUserLoggedIn,
    allMusicAlbum: state.album.allMusicAlbum,
    videoCategoryList: state.video.videoCategoryList,
    serviceOffers: state.payment.serviceOffers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCommentListVideo,
      renderEditPanel,
      renderInputPanel,
      renderProfilePanel,
      renderFeedbackPanel,
      handleSelectedVideo,
      getVideoList,
      emptyVideoList,
      getTrendingMediaList,
      getLikeVideoList,
      uploadMediaFile,
      getCommentListVideo,
      renderSelectedCallerTunePanel,
      postLikeApiList,
      renderCommentPanel,
      getMusicGenreList,
      setOrderOfMedia,
      changeMediaStatus,
      getAllMusicAlbum,
      getCategoryList
    },
    dispatch
  );
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video));
