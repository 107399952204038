// import search from "./search";

/* eslint-disable */
export const LIVE_STREAM_LIST_REQUESTED = 'livestream/LIVE_STREAM_LIST_REQUESTED';
export const LIVE_STREAM_LIST_SUCCESS = 'livestream/LIVE_STREAM_LIST_SUCCESS';
export const LIVE_STREAM_LIST_FAILURE = 'livestream/LIVE_STREAM_LIST_FAILURE';
export const LIVE_STREAM_LIST_EMPTY = 'livestream/LIVE_STREAM_LIST_EMPTY';

export const DASHBOARD_LIVE_STREAM_LIST_REQUESTED = 'livestream/DASHBOARD_LIVE_STREAM_LIST_REQUESTED';
export const DASHBOARD_LIVE_STREAM_LIST_SUCCESS = 'livestream/DASHBOARD_LIVE_STREAM_LIST_SUCCESS';
export const DASHBOARD_LIVE_STREAM_LIST_FAILURE = 'livestream/DASHBOARD_LIVE_STREAM_LIST_FAILURE';
export const DASHBOARD_LIVE_STREAM_LIST_EMPTY = 'livestream/DASHBOARD_LIVE_STREAM_LIST_EMPTY';

export const LIVE_EVENT_DETAILS_REQUESTED = 'livestream/LIVE_EVENT_DETAILS_REQUESTED';
export const LIVE_EVENT_DETAILS_SUCCESS = 'livestream/LIVE_EVENT_DETAILS_SUCCESS';
export const LIVE_EVENT_DETAILS_FAILURE = 'livestream/LIVE_EVENT_DETAILS_FAILURE';

export const ADD_LIVE_STREAM_REQUESTED = 'livestream/ADD_LIVE_STREAM_REQUESTED';
export const ADD_LIVE_STREAM_SUCCESS = 'livestream/ADD_LIVE_STREAM_SUCCESS';
export const ADD_LIVE_STREAM_FAILURE = 'livestream/ADD_LIVE_STREAM_FAILURE';

export const DELETE_LIVE_STREAM_REQUESTED = 'livestream/DELETE_LIVE_STREAM_REQUESTED';
export const DELETE_LIVE_STREAM_SUCCESS = 'livestream/DELETE_LIVE_STREAM_SUCCESS';
export const DELETE_LIVE_STREAM_FAILURE = 'livestream/DELETE_LIVE_STREAM_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  liveStreamDataList: [],
  liveStreamListCount: 0,
  dashboardLiveStreamList: [],
  dashboardLiveStreamCount: 0,
  liveEventDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIVE_STREAM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LIVE_STREAM_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        liveStreamDataList: [...state.liveStreamDataList, ...action.result.responseList],
        liveStreamListCount: action.result.totalCount
      };
    }
    case LIVE_STREAM_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case LIVE_STREAM_LIST_EMPTY: {
      console.log("%cempty", 'color:red');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        liveStreamDataList: [],
      };
    }
    case ADD_LIVE_STREAM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ADD_LIVE_STREAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      }
    }
    case ADD_LIVE_STREAM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }

    case DASHBOARD_LIVE_STREAM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DASHBOARD_LIVE_STREAM_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        dashboardLiveStreamList: action.result.responseList,
        dashboardLiveStreamListCount: action.result.totalCount
      };
    }
    case DASHBOARD_LIVE_STREAM_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }

    case LIVE_EVENT_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LIVE_EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        liveEventDetails: action.result.responseList,
      };
    }
    case LIVE_EVENT_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }

    case DELETE_LIVE_STREAM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_LIVE_STREAM_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      }
    }
    case DELETE_LIVE_STREAM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const getLiveStreamList = (pageNo, pageSize, searchKey) => {
  return {
    types: [LIVE_STREAM_LIST_REQUESTED, LIVE_STREAM_LIST_SUCCESS, LIVE_STREAM_LIST_FAILURE],
    promise: (client) => client.get(`getLiveStreamList?pageNo=${pageNo}&pageSize=${pageSize}&search=${searchKey}`, {
    })
  };
};

export const emptyLiveStreamList = () => {
  console.log("%cempty", 'color:red');
  return {
    type: LIVE_STREAM_LIST_EMPTY
  };
};


export const addLiveStream = (data) => {
  return {
    types: [ADD_LIVE_STREAM_REQUESTED, ADD_LIVE_STREAM_SUCCESS, ADD_LIVE_STREAM_FAILURE],
    promise: (client) => client.post('addLiveStream', {
      data,
    })
  };
};

export const liveStreamEventsListForDashboard = () => {
  return {
    types: [DASHBOARD_LIVE_STREAM_LIST_REQUESTED, DASHBOARD_LIVE_STREAM_LIST_SUCCESS, DASHBOARD_LIVE_STREAM_LIST_FAILURE],
    promise: (client) => client.get(`liveStreamEventsListForDashboard`, {
    })
  };
};

export const liveEventDetails = (videoId) => {
  return {
    types: [LIVE_EVENT_DETAILS_REQUESTED, LIVE_EVENT_DETAILS_SUCCESS, LIVE_EVENT_DETAILS_FAILURE],
    promise: (client) => client.get(`liveEventDetails?eventId=${videoId}`, {
    })
  };
};

export const deleteLiveStream = (id) => {
  return {
    types: [DELETE_LIVE_STREAM_REQUESTED, DELETE_LIVE_STREAM_SUCCESS, DELETE_LIVE_STREAM_FAILURE],
    promise: (client) => client.del(`deleteLiveStream?eventId=${id}`, {
    })
  };
};