/* eslint-disable */
export const GET_ALL_MUSICALBUM_REQUESTED = 'allMusicAlbum/GET_ALL_MUSICALBUM_REQUESTED';
export const GET_ALL_MUSICALBUM_SUCCESS = 'allMusicAlbum/GET_ALL_MUSICALBUM_SUCCESS';
export const GET_ALL_MUSICALBUM_FAILURE = 'allMusicAlbum/GET_ALL_MUSICALBUM_FAILURE';

export const GET_SPECIFIC_MUSICALBUM_REQUESTED = 'specificMusicAlbum/GET_SPECIFIC_MUSICALBUM_REQUESTED';
export const GET_SPECIFIC_MUSICALBUM_SUCCESS = 'specificMusicAlbum/GET_SPECIFIC_MUSICALBUM_SUCCESS';
export const GET_SPECIFIC_MUSICALBUM_FAILURE = 'specificMusicAlbum/GET_SPECIFIC_MUSICALBUM_FAILURE';

export const CREATE_ALBUM_REQUESTED = 'allMusicAlbum/CREATE_ALBUM_REQUESTED';
export const CREATE_ALBUM_SUCCESS = 'allMusicAlbum/CREATE_ALBUM_SUCCESS';
export const CREATE_ALBUM_FAILURE = 'allMusicAlbum/CREATE_ALBUM_FAILURE';

export const GET_ITEMS_OF_ALBUM_REQUESTED = 'allMusicAlbum/GET_ITEMS_OF_ALBUM_REQUESTED';
export const GET_ITEMS_OF_ALBUM_SUCCESS = 'allMusicAlbum/GET_ITEMS_OF_ALBUM_SUCCESS';
export const GET_ITEMS_OF_ALBUM_FAILURE = 'allMusicAlbum/GET_ITEMS_OF_ALBUM_FAILURE';

export const SET_ALBUM_MUSIC_LIST = 'allMusicAlbum/SET_ALBUM_MUSIC_LIST';


const initialState = {
    loading: false,
    loaded: false,
    error: false,
    allMusicAlbum: [],
    specificMusicAlbum: [],
    setAlbumMusicList:[]
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_MUSICALBUM_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_ALL_MUSICALBUM_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          allMusicAlbum: action.result.responseList
        };
      }
      case GET_ALL_MUSICALBUM_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }      
      case GET_SPECIFIC_MUSICALBUM_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_SPECIFIC_MUSICALBUM_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          specificMusicAlbum: action.result.responseList
        };
      }
      case GET_SPECIFIC_MUSICALBUM_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case CREATE_ALBUM_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case CREATE_ALBUM_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        }
      }
      case CREATE_ALBUM_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case SET_ALBUM_MUSIC_LIST: {
        return {
          ...state,
          setAlbumMusicList: action.payload
        };
      }
      case GET_ITEMS_OF_ALBUM_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_ITEMS_OF_ALBUM_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case GET_ITEMS_OF_ALBUM_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      default:
        return state;
    }
  };

  export const getAllMusicAlbum = (status, pageNo, pageSize) => {
    let pageValues = '';
    if(!pageNo && !pageSize) {
      pageValues = '';
    } else {
      pageValues = `&pageNo=${pageNo}&pageSize=${pageSize}`;
    }
    return {
      types: [GET_ALL_MUSICALBUM_REQUESTED, GET_ALL_MUSICALBUM_SUCCESS, GET_ALL_MUSICALBUM_FAILURE],
      promise: (client) => client.get(`getAllMusicAlbum?status=${status}${pageValues}`, {
      })
    };
  };

  export const getSpecificMusicAlbum = (status, pageNo, pageSize) => {
    return {
      types: [GET_SPECIFIC_MUSICALBUM_REQUESTED, GET_SPECIFIC_MUSICALBUM_SUCCESS, GET_SPECIFIC_MUSICALBUM_FAILURE],
      promise: (client) => client.get(`getSpecificMusicAlbum?status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const createAlbum = (data) => {
    return {
      types: [CREATE_ALBUM_REQUESTED, CREATE_ALBUM_SUCCESS, CREATE_ALBUM_FAILURE],
      promise: (client) => client.post('createAlbum', {
        data,
      })
    };
  }

  export const setAlbumMusicList = (payload) => {
    return {
      type: SET_ALBUM_MUSIC_LIST,
      payload
    };
  }
  
  export const getItemsOfAlbum = (albumId) => {
    return {
      types: [GET_ITEMS_OF_ALBUM_REQUESTED, GET_ITEMS_OF_ALBUM_SUCCESS, GET_ITEMS_OF_ALBUM_FAILURE],
      promise: (client) => client.get(`getItemsOfAlbum?albumId=${albumId}`, {
      })
    };
  };