/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Grid, useMediaQuery } from '@material-ui/core';
import ProductCard from '../Dashboard/productCard';
import { makeStyles } from '@material-ui/core/styles';
import { renderGenrePanel } from '../../reducers/ui';
import cn from 'classnames';

import {
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
  renderFeedbackPanel,
  handleSelectedVideo,
  renderSelectedCallerTunePanel
} from '../../reducers/ui';
import { getMusicList, getMusicMostViewed, getMusicByGenre, getMusicGenreList, emptyLatestMusicList, getGenreCategoryList } from '../../reducers/music';
import { getAllMusicAlbum } from '../../reducers/album';
import { getHistoryPlayed, emptyHistoryMusicPlayedList } from '../../reducers/history';

import MusicPlayer from '../MusicPlayer/MusicPlayer';
import { getTrendingMediaList } from '../../reducers/trending';


const useStyles = makeStyles((theme) => ({
  recently: {
    [theme.breakpoints.between('0', '1024')]: {
      paddingLeft: '20px',
      paddingTop: '0px',
      paddingRight: '0px',
    },
    height: 'auto',
    background: 'black',
  },
  searchDesk: {
    paddingTop: '20px',
    paddingLeft: '60px',
    paddingRight: '40px',
  },
  Short: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '40px',
    height: 'auto',
    background: 'black',
    paddingTop: '0px',
  },
  scroll: {
    maxWidth: 'calc(100vw - 425px)!important',
    [theme.breakpoints.between('0', '1024')]: {
      maxWidth: 'calc(100vw/1)!important',
      // gap: 5,
    },
    [theme.breakpoints.between('1024', '1848')]: {
      maxWidth: 'calc(100vw - 220px)!important',
    },
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexWrap: 'nowrap',
    overflowX: 'auto',
    gap: 20,
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },

  mobileSize: {
    [theme.breakpoints.between('0', '1024')]: {
      margin: '0px 15px 60px 15px',
    },
    marginTop: '35px'
  },

  TopDiv: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    backgroundColor: 'black',
    [theme.breakpoints.up('1024')]: {
      height: '100%',
      marginLeft: '60px',
      marginBottom: '30px'
      // marginRight: '60px',
      // marginTop: '35px',
    },
    [theme.breakpoints.down('1024')]: {
      marginTop: '0px',
    },

  },
  hits: {
    height: 'auto',
    background: 'black',
    [theme.breakpoints.between('0', '1024')]: {
      paddingLeft: '20px',
      paddingRight: '0px',
    },
  },
}));

const Music = (props) => {
  const [musicPlayerOpen, setMusicPlayerOpen] = useState(false);
  const [mostViewedList, setMostViewedList] = useState([]);
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0.5,
        left: 0,
        behavior: 'smooth'
      });
    }, 750);
    props.emptyLatestMusicList();
    props.emptyHistoryMusicPlayedList();
    props.getMusicList(1, 6, 'regular', '');
    // props.getTrendingMediaList();
    props.getMusicMostViewed(1, 6).then((data) => {
      setMostViewedList(data.responseList);
    });
    props.getMusicGenreList('live');
    props.getAllMusicAlbum('live');
    if (props.isUserLoggedIn) {
      props.getHistoryPlayed(1, 6);
    }
    props.getGenreCategoryList();
  }, []);

  useEffect(() => {

  }, [musicPlayerOpen, props.historyDetails]);

  const handleExpand = (title) => {
    if (title === 'Most Viewed') {
      props.history.push('/music/mostViewedMusic');
    }
    else if (title === 'Latest') {
      props.history.push('/music/latestMusic');
    }
    else if (title === 'Recently played') {
      props.history.push('/music/recentlyPlayed');
    }
    else if (title === 'Latest Albums') {
      props.history.push('/music/albumList');
    }
    else if (title !== 'Genre') {
      props.renderGenrePanel(title);
      window.localStorage.setItem('GenreTitle', title);
      handleMusicByGenre(title);
    }
  };

  const handleCardRecentListClick = () => {
    setMusicPlayerOpen(true);
  };

  const handleAlbumCardRecentListClick = () => {
    props.history.push('/music/musicByAlbum');
  };

  const handleMusicByGenre = (title) => {
    if (title === 'Genre') {
      window.localStorage.setItem('GenreTitle', '');
    }
    props.history.push('/music/musicByGenre');
  };

  const cardTitle = (title, subnote) => {
    return (
      <div style={{ paddingRight: !mobileView && '15px' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: mobileView ? '0px' : '10px' }}>
          <p
            style={{
              color: '#dbced2',
              fontSize: mobileView ? '1.5rem' : '1.75rem',
              paddingBottom: '0px',
              fontFamily: 'SegoeUI',
            }}
          >
            {title.charAt(0).toUpperCase() + title.slice(1)}
          </p>
          {(title !== 'Genre' && title !== 'Album') && (
            <p
              style={{
                color: '#6e6e6e',
                opacity: '80%',
                fontSize: mobileView ? '0.75rem' : '0.875rem',
                textAlign: 'end',
                fontFamily: 'SegoeUI',
                cursor: 'pointer',
                alignSelf: 'center',
                marginRight: !mobileView && '50px'
              }}
              onClick={() => handleExpand(title)}
            >
              {title === 'Recently played' ? (props.historyDetails.length > 0 ? `See More >` : '') : `See More >`}
            </p>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={classes.TopDiv}>
        <div className={classes.mobileSize} style={{ marginBottom: musicPlayerOpen && 100 }}>
          {props.historyDetails && props.historyDetails.length > 0 &&
            <Grid className={cn(!mobileView && 'mb-20')}>
              <Grid>{cardTitle('Recently played')}</Grid>
              <Grid
                className={classes.scroll}
                style={{ fontFamily: 'SegoeUI' }}
              >
                <ProductCard products={props.historyDetails} handleCardRecentListClick={handleCardRecentListClick} />
              </Grid>
            </Grid>
          }
          <Grid className={cn(!mobileView && 'mb-20')}>
            <Grid>{cardTitle('Most Viewed')}</Grid>
            <Grid
              className={classes.scroll}
              style={{ fontFamily: 'SegoeUI' }}
            >
              <ProductCard products={mostViewedList} handleCardRecentListClick={handleCardRecentListClick} />
            </Grid>
          </Grid>

          <Grid className={cn(!mobileView && 'mb-20')}>
            <Grid>{cardTitle('Latest')}</Grid>
            <Grid
              className={classes.scroll}
              style={{ fontFamily: 'SegoeUI' }}
            >
              <ProductCard products={props.musicLatest} handleCardRecentListClick={handleCardRecentListClick} />
            </Grid>
          </Grid>

          <Grid className={cn(!mobileView && 'mb-20')}>
            <Grid>{cardTitle('Latest Albums')}</Grid>
            <Grid
              className={classes.scroll}
              style={{ fontFamily: 'SegoeUI', paddingBottom: mobileView ? 7 : 13 }}
            >
              <ProductCard products={props.allMusicAlbum} handleCardRecentListClick={() => handleAlbumCardRecentListClick()} />
            </Grid>
          </Grid>

          {props.musicGenreCategoryList.responseList && props.musicGenreCategoryList.responseList.map((genreData, index) => {
            return Object.entries(genreData).map((categoryData) => {
              const lastMusicDataCard = Object.entries(genreData).slice(Object.entries(genreData).length - 1)[0];
              return (
                <Grid id="genreSection" className={cn(!mobileView ? 'mb-30' : lastMusicDataCard[0] === categoryData[0] && 'mb-60')} style={{ paddingBottom: !mobileView && categoryData[0] === 'Gospel' ? '0px' : '0px', paddingTop: !mobileView && categoryData[0] === 'Gospel' ? '10px' : '0px' }}>
                  <Grid>{cardTitle(categoryData[0])}</Grid>
                  <Grid className={classes.scroll} style={{ fontFamily: 'SegoeUI' }}>
                    <ProductCard products={categoryData[1]} handleCardRecentListClick={handleCardRecentListClick} />
                  </Grid>
                </Grid>
              )
            })
          })}
          {props.musicGenreListAPI && props.musicGenreListAPI.length > 0 &&
            <Grid className={cn(mobileView && 'mb-60')}>
              <Grid>{cardTitle('Genre')}</Grid>
              <Grid className={classes.scroll} style={{ fontFamily: 'SegoeUI' }} onClick={() => handleMusicByGenre('Genre')}>
                <ProductCard products={props.musicGenreListAPI} handleCardRecentListClick={handleCardRecentListClick} />
              </Grid>
            </Grid>
          }
        </div>
      </div>
      {musicPlayerOpen && <MusicPlayer />}
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      renderEditPanel,
      renderInputPanel,
      renderProfilePanel,
      renderFeedbackPanel,
      getMusicList,
      getTrendingMediaList,
      getMusicMostViewed,
      getMusicByGenre,
      getMusicGenreList,
      getAllMusicAlbum,
      getHistoryPlayed,
      emptyLatestMusicList,
      emptyHistoryMusicPlayedList,
      renderSelectedCallerTunePanel,
      emptyLatestMusicList,
      getGenreCategoryList,
      renderGenrePanel
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    audioObject: state.ui.playSelectedAudio,
    musicMostViewedAPI: state.music.musicMostViewed,
    musicByGenreAPI: state.music.musicByGenre,
    musicGenreListAPI: state.music.musicGenreList,
    historyDetails: state.history.historyDetails,
    musicLatest: state.music.musicDataList,
    trendingVideoList: state.trending.trendingVideoList,
    musicGenreCategoryList: state.music.genreCategoryList,
    isUserLoggedIn: state.oauth.isUserLoggedIn,
    allMusicAlbum: state.album.allMusicAlbum,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Music));
