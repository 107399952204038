/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, useMediaQuery, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { generateOtp, verifyOtp, oAuth, googleLogin } from '../../reducers/otp';
import OtpInput from 'react-otp-input';
import cn from 'classnames';
// import TextField from '@material-ui/core/TextField';
import Link from "@material-ui/core/Link";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    btns: {
        width: '100%',
        gap: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        [theme.breakpoints.between('1024', '1410')]: {
            marginTop: '10vh',
        },
        marginTop: '10vh',
        marginBottom: '2vh',
        [theme.breakpoints.down('1024')]: {
            marginTop: '10vh',
            marginBottom: '2vh'
        },
    },
    payBtn: {
        backgroundColor: theme.palette.selectedTxtClr,
        '&:hover': {
            backgroundColor: theme.palette.selectedTxtClr,
        },
        color: theme.palette.titleClr,
        width: '100%',
        height: '50px',
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cancelBtn: {
        // borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#959595',
        cursor: 'pointer',
        backgroundColor: '#0000',
        '&:hover': {
            backgroundColor: '#0000',
        },
        width: '100%',
        height: '50px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },
    },
    verifyBtn: {
        borderRadius: '15px',
        backgroundColor: theme.palette.selectedTxtClr,
        '&:hover': {
            backgroundColor: theme.palette.selectedTxtClr,
        },
        color: theme.palette.titleClr,
        textTransform: 'none',
        width: '120px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1rem'
        },
    },
    margin: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '5px'
    },
    textFieldNumber: {
        color: theme.palette.titleClr,
        maxWidth: '1000px',
        width: '80%',
        marginBottom: 15,
        [`& fieldset`]: {
            borderRadius: '0px 50px 50px 0px',
        },
        fontSize: '1.1rem',
        // [`& focus`]: {
        //   color: 'red',
        // },
        backgroundColor: '#444343',
        // opacity: '52%',
        borderRadius: '0px 50px 50px 0px',
        // border:'0px solid'
        // marginTop: '35%',
        height: '56px',
        [theme.breakpoints.up('1024')]: {
            height: '58.38px',
        },
        paddingLeft: '15px',
        border: '0px',
        // fontWeight: 900
    },
    mainHead: {
        color: theme.palette.titleClr,
        opacity: '71%',
        fontSize: '1rem',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        [theme.breakpoints.between('1024', '1410')]: {
            marginBottom: '20px'
        },
        marginBottom: '30px',
        [theme.breakpoints.up('1024')]: {
            fontSize: '2rem',
        },
    },
    countryCode: {
        color: theme.palette.titleClr,
        fontSize: '0.875rem',
        width: '20%',
        background: '#646464',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px 0px 0px 50px',
        height: '56px',
        [theme.breakpoints.up('1024')]: {
            height: '58.38px',
        },
        opacity: '100%'
    },
    OTPLibrary: {
        columnGap: '10px'
    },
    loaderbackdrop: {
        zIndex: 10000,
    },
    circleLoader: {
        color: theme.palette.selectedTxtClr,
    },
    linkStyle: {
        color: theme.palette.selectedTxtClr,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.selectedTxtClr,
        },
    },
    resendLinkStyle: {
        color: theme.palette.selectedTxtClr,
        cursor: 'pointer',
        fontSize: '0.875rem'
    }
}));

const MobileOtp = (props) => {
    const classes = useStyles();
    const mobileView = useMediaQuery('(max-width:1024px)');
    const [phoneNo, setPhoneNo] = useState(true);

    useEffect(() => {
        if (props.isOtpScreen) {
            setPhoneNo(false);
        } else {
            setPhoneNo(true);
        }
    }, [props.isOtpScreen]);

    const handleOTP = () => {
        props.handleGetOPT();
    }

    const handleKeypressLoginNum = (e) => {
        if (e.key === 'Enter') {
            handleOTP();
        }
    }

    const handleKeypressLoginOtp = (e) => {
        if (e.key === 'Enter') {
            props.verifyClick();
        }
    }

    return (
        <>
            <div className={classes.margin}>
                <div className={classes.mainHead}><span className='bold'>{props.mainHead}</span></div>
                {phoneNo && (<>
                    <div style={{ padding: '25px' }}>
                        <InputLabel htmlFor="input-with-icon-adornment" style={{ color: '#fff', fontSize: mobileView ? '0.9rem' : '0.825rem', marginBottom: '10px' }}>Enter your Vodacom mobile number to receive an OTP to proceed</InputLabel>
                        <div style={{ display: 'flex' }}>
                            <div className={classes.countryCode}>+27</div>
                            <input
                                type="tel"
                                className={classes.textFieldNumber}
                                placeholder="Phone Number"
                                onChange={props.setPhoneNumber}
                                validation="required"
                                onKeyPress={event => handleKeypressLoginNum(event)}
                                value={props.targetValue}
                            />
                            {/* <TextField
                                className={classes.textFieldNumber}
                                // fullWidth
                                disableUnderline
                                id="outlined-search"
                                type="number"
                                variant="outlined"
                                placeholder="Phone Number"
                                maxLength="10"
                                onChange={props.setPhoneNumber}
                                validation="required"
                                onKeyPress={event => handleKeypressLoginNum(event)}
                            /> */}
                        </div>
                    </div>
                    <div className={classes.btns}>
                        <div style={{ marginBottom: '2vh', display: 'flex', justifyContent: 'center' }} >
                            <Link underline="always" className={cn(classes.linkStyle)} onClick={props.handlTnC}>Terms & Conditions</Link>
                        </div>
                        <div
                            className={classes.payBtn}
                            variant="contained"
                            color="secondary"
                            onClick={handleOTP}
                        // onClick={props.checkOTPstatus}
                        >
                            <span className='semibold'>NEXT</span>
                        </div>
                        <div
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={props.handleCloseFn}
                        >
                            <span className='semibold'>NO THANKS</span>
                        </div>
                    </div>

                </>)}

                {!phoneNo && (<>
                    <div style={{ padding: '25px 0px 25px', margin: mobileView ? '0px 10px 0px 10px' : '0px 0px 0px 20px' }} onKeyPress={event => handleKeypressLoginOtp(event)}
                    >
                        <InputLabel htmlFor="input-with-icon-adornment" style={{ color: '#fff', fontSize: mobileView ? '0.9rem' : '0.825', marginBottom: '10px' }}>ENTER OTP</InputLabel>
                        <InputLabel htmlFor="input-with-icon-adornment" style={{ color: '#fff', fontSize: mobileView ? '0.9rem' : '0.825', marginBottom: '10px' }}>We have sent an OTP to your mobile number, please enter below</InputLabel>
                            <OtpInput
                                otpType="number"
                                value={props.otp}
                                htmlAttrs={{
                                    autocomplete: 'off',
                                    inputmode: 'numeric'
                                }}
                                onChange={props.setOtpNo}
                                numInputs={props.otpLength}
                                separator={<span>--</span>}
                                isInputSecure={true}
                                isInputNum={true}
                                inputStyle={{
                                    width: mobileView ? "3rem" : "3.5rem",
                                    height: mobileView ? "3rem" : "3.5rem",
                                    margin: "0rem",
                                    fontSize: "2rem",
                                    borderRadius: 8,
                                    // backgroundColor: '#FFFFFF',
                                    // opacity: '21%',
                                    backgroundColor: '#383838',
                                    borderColor: '#383838',
                                    color: '#fff',
                                    marginBottom: '20px'
                                }}
                            />

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.resendLinkStyle} onClick={props.handleGetOPT}>Resend OTP</div>
                        </div>
                    </div>
                    <div className={classes.btns}>
                        <div style={{ marginBottom: '2vh', display: 'flex', justifyContent: 'center' }}>
                            <Link underline="always" className={cn(classes.linkStyle)} onClick={props.handlTnC}>Terms & Conditions</Link>
                        </div>
                        <div
                            className={classes.payBtn}
                            variant="contained"
                            color="secondary"
                            onClick={props.verifyClick}
                        >
                            <span className='semibold'>LOGIN</span>
                        </div>
                        <div
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={props.handleCloseFn}
                        >
                            <span className='semibold'>NO THANKS</span>
                        </div>
                    </div></>)}

                <Backdrop className={classes.loaderbackdrop} open={props.loader}>
                    <CircularProgress className={classes.circleLoader} />
                </Backdrop>

            </div>
        </>
    );
};
const mapStateToProps = state => {
    return {
        userDetails: state.otp.userData,
        rbtCallertunePayment: state.rbt.rbtCallertunePayment,
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    generateOtp,
    verifyOtp,
    oAuth,
    googleLogin,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileOtp));
