/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import profilePic from '@assets/images/default-profile-pic.jpg';
import { getContentPath } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    // background: theme.palette.titleClr,
    height: '10em',
    width: '10em',
    margin: 'auto',
    boxShadow: ` ${theme.palette.selectedTxtClr} 0px 2px 15px 0px`,

  },
  apply: {
    display: 'flex',
    background: '#242424',
    color: theme.palette.titleClr,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      background: '#242424',
      paddingTop: '30px',
    },
  },
}));

const ProfileHeader = (props) => {
  const classes = useStyles();
  const [profileImg, setProfileImg] = useState(props.thumbPath);

  useEffect(() => {
    setProfileImg(props.thumbPath ? props.thumbPath : profilePic);
  }, []);
  // console.log('profileImg', profileImg)
  return (
    <>
      <div className={classes.apply}>
        {console.log('console it', props.thumbPath)}
        <Avatar alt="Remy Sharp" src={`${props.thumbPath ? getContentPath(props.thumbPath) : profilePic}`} className={classes.large} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.otp.userData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // updateField: (form) => dispatch(reset(form)),
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileHeader));
