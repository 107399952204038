/* eslint-disable */
import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { handleSelectedAudio } from '../../reducers/ui';
import { withRouter, useHistory } from 'react-router';
import { getMusicDetails } from '../../reducers/portalContentDetails';
import { useState } from 'react';
import MusicPlayer from '../MusicPlayer/MusicPlayer';
import { Typography, useMediaQuery } from '@material-ui/core';
import './MusicDetailPage.scss';
import NotificationMessage from '../NotificationMessage';
import { EN } from '@localization';
import { getContentPath } from '../../helpers/utils';

export const MusicDetailPage = props => {
  const params = new URLSearchParams(window.location.search);
  let musicId = params.get('musicId');
  const [mobile, setMobile] = useState(window.innerWidth);
  // const mobile = useMediaQuery('(max-width:960px)');
  const [musicPlayerOpen, setMusicPlayerOpen] = useState(false)
  const [music, setMusic] = useState({});
  const [openNoti, setOpenNoti] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');

  const handleCloseForNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNoti(false);
  };

  useEffect(() => {
    console.log(musicId, 'musicId');
    props.getMusicDetails(musicId, 'regular').then((data) => {
      console.log(data.responseList, 'data.responseList');
      if (data.message === "1 mediaId(s) were invalid") {
        setOpenNoti(true);
        setMsg(EN.NO_MEDIA_FOUND);
        setMsgType('error');
        setTimeout(() => {
          props.history.push('/homepage');
        }, 2000);
      } else {
        setMusic(data.responseList);
        setMusicPlayerOpen(true);
      }
    }).catch((error) => {
      setOpenNoti(true);
      setMsg(EN.NO_MEDIA_FOUND);
      setMsgType('error');
      setTimeout(() => {
        props.history.push('/homepage');
      }, 2000);
    });
  }, [musicId]);

  useEffect(() => {
    const {
      contentPath: musicSrc,
      thumbnail: cover,
      title: name,
      artistName: singer,
      id: id,
      mediaId: mediaId,
      ...rest
    } = { ...music[0] };
    console.log(music, 'newMusic musicId props.audioObject');
    const newMusic = Object.assign({}, {
      musicSrc,
      cover,
      name,
      singer,
      id,
      mediaId,
      ...rest
    });
    console.log(musicId, props.audioObject, newMusic, 'newMusic musicId props.audioObject');
    props.handleSelectedAudio(newMusic)
  }, [music]);

  useEffect(() => {
    setMobile(window.innerWidth)
  }, [mobile])
  
  return (
    <>
    {console.log('check---', mobile)}
      {mobile > 1024 && 
        <div style={{ color: 'white' }}>
          <div className='mainBox'>
            <div className='imageBox'>
              <img src={getContentPath(music[0]?.thumbnail)} className='image' height='300px' width='300px' ></img>
            </div>
            <div className='MusicDetailsSection'>
              <Typography variant="h4" component="h2" style={{ color: '#e1e1e1', margin: '10px', fontSize: '2rem' }}>
                {music[0]?.title}
              </Typography>
              <Typography
                style={{
                  color: '#bbbbbb',
                  margin: '10px',
                  fontSize: '1.5625rem',
                  marginBottom: '50px',
                }}
              >
                {music[0]?.description}
              </Typography>
              <Typography variant="h3" component="h2" style={{ color: '#e1e1e1', margin: '10px', fontSize: '1rem' }}>
                {music[0]?.duration && (
                  'Duration : ' + music[0].duration
                )}
              </Typography>
              <Typography variant="h3" component="h2" style={{ color: '#e1e1e1', margin: '10px', fontSize: '1rem' }}>
                {music[0]?.genre && (
                  'Genre : ' + music[0].genre
                )}
              </Typography>
              <Typography variant="h3" component="h2" style={{ color: '#e1e1e1', margin: '10px', fontSize: '1rem' }}>
                {music[0]?.language && (
                  'Language : ' + music[0].language
                )}
              </Typography>
              <Typography variant="h3" component="h2" style={{ color: '#e1e1e1', margin: '10px', fontSize: '1rem' }}>
                {music[0]?.mode && (
                  'Mode : ' + music[0].mode
                )}
              </Typography>
              <Typography variant="h3" component="h2" style={{ color: '#e1e1e1', margin: '10px', fontSize: '1rem' }}>
                {music[0]?.tags && (
                  'Hash Tags : ' + music[0].tags
                )}
              </Typography>
            </div>
          </div>
          <NotificationMessage open={openNoti} msgType={msgType} msg={msg} handleClose={handleCloseForNotify} />
        </div>
      }
      {musicPlayerOpen && <MusicPlayer />}
    </>
  );
};

const mapStateToProps = state => ({
  audioObject: state.ui.playSelectedAudio,

});

const mapDispatchToProps = {
  handleSelectedAudio,
  getMusicDetails
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MusicDetailPage));
