import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import Modal from '@material-ui/core/Modal';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import {
  generateOtp, verifyOtp, oAuth, getUserDetails
} from '@reducers/otp';
import { updateIsUserLoggedInStatus, getOtpLength } from '@reducers/oauth';
import { getPaymentServiceOffers } from '@reducers/payment';
import MobileOtp from '@components/common/MobileOtp';
// import * as CryptoJS from 'crypto-js';
// import { APP_CONFIG } from '@constants';
import { JSEncrypt } from 'jsencrypt';
import { APP_CONFIG } from '@constants';
import { getUtagDataForLogin } from '../../../helpers/utils';
import { trackSearchPath } from '../../../reducers/ui';
// import { includes } from 'lodash';

const useStyles = makeStyles((theme) => ({
  top: {
    margin: 'auto',
    marginTop: '3%',
    width: '27%',
    background: '#00000099',
    borderRadius: '20px',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backdropFilter: 'blur(50px)',
    filter: 'drop-shadow(6px 6px 20px #000000)',
    [theme.breakpoints.between('1024', '1599')]: {
      // backdropFilter: 'blur(100px)',
      height: 'auto',
      minHeight: '50vh',
      width: '35%',
      // background: '#00000099',
    },
    [theme.breakpoints.up('1600')]: {
      height: 'auto',
      minHeight: '50vh',
      width: '27%',
      // background: '#00000099',
    },
    '@media (min-height: 720px) and (max-height: 900px)': {
      marginTop: '3%',
    },
    '@media (min-height: 901px) and (max-height: 1080px)': {
      marginTop: '9%',
    },
  },
  mobiletop: {
    margin: 'auto',
    marginTop: '25%',
    width: '95%',
    background: '#00000099',
    borderRadius: '20px',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backdropFilter: 'blur(50px)',
    filter: 'drop-shadow(6px 6px 20px #000000)',
  },
}));

const LoginModal = (props) => {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');
  const [openModal, setOpenModal] = useState(true);
  const [otp, setOtpNo] = useState('');
  const [phoneNo, setPhoneNo] = useState(0);
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [targetValue, setTargetValue] = useState('');
  const [loader, setLoader] = useState(false);
  // const [premiumUser, setPremiumUser] = useState(false);

  useEffect(() => {
    props.getOtpLength();
  }, []);

  const handleLoginClose = () => {
    setTargetValue('');
    setPhoneNo('');
    setIsOtpScreen(false);
    props.setOpenLogin(false);
    if (props.setIsWelcomeToneClicked) {
      props.setIsWelcomeToneClicked(false);
    }
    if (window.location.href.includes('/post/detail?postId=')) {
      props.history.goBack();
    }
  };

  const setPhoneNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if ((e.target.value === '' || e.target.value.match(re)) && e.target.value.length <= 10) {
      setTargetValue(e.target.value);
      setPhoneNo(e.target.value);
    }
  };

  // const getencryptedString = (data) => {
  //   const keyHex = CryptoJS.enc.Utf8.parse(APP_CONFIG.AES_KEY); //
  //   const ivHex = CryptoJS.enc.Utf8.parse(APP_CONFIG.AES_IV);
  //   const messageHex = CryptoJS.enc.Utf8.parse(data);
  //   const encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
  //     iv: ivHex,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   });

  //   return encrypted.toString();// base64 result
  //   // return encrypted.ciphertext.toString(); // binary result
  // };

  const handleGetOPT = () => {
    const encrypt = new JSEncrypt();

    // Copied from https://github.com/travist/jsencrypt
    const publicKey = APP_CONFIG.PUBLIC_KEY;

    encrypt.setPublicKey(publicKey);

    // Perform our encryption based on our public key - only private key can read it!
    // encrypted = encrypt.encrypt("Hello world!");

    setLoader(true);
    setOtpNo('');
    const data = {
      mobileNumber: encrypt.encrypt(phoneNo)
    };
    console.log('data', data);

    props
      .generateOtp(data)
      .then((successData) => {
        setLoader(false);
        console.log('execute', successData);
        setIsOtpScreen(true);
      })
      .catch((error) => {
        setLoader(false);
        props.showLoginNotification(error.errorMessage, 'error');
        console.log('execute', error);
      });
  };

  const getOauthToken = (userData) => {
    const encrypt = new JSEncrypt();

    // Copied from https://github.com/travist/jsencrypt
    const publicKey = APP_CONFIG.PUBLIC_KEY;

    encrypt.setPublicKey(publicKey);
    const encryptedMobileNo = encrypt.encrypt(userData);
    const oAuthData = {
      username: encryptedMobileNo,
      password: '',
    };
    props
      .oAuth(oAuthData)
      .then((data) => {
        console.log('authData', data);
        window.localStorage.setItem('access_token', data.access_token);
        window.localStorage.setItem('allowedServices', data.allowedServices);
        props.getUserDetails().then((userDetails) => {
          getUtagDataForLogin(userDetails.responseList[0]?.userSurrogateId);
          window.localStorage.setItem('userDetails', userDetails.responseList[0].userSurrogateId);
          window.localStorage.setItem('acrValue', userDetails.responseList[0].acr);
          window.localStorage.setItem('phone', userDetails.responseList[0].mobileNumber ? userDetails.responseList[0].mobileNumber : '');
          window.localStorage.setItem('countryCode', userDetails.responseList[0].mobileNumberCountryCode ? userDetails.responseList[0].mobileNumberCountryCode : '');
          window.localStorage.setItem('name', userDetails.responseList[0].name ? userDetails.responseList[0].name : '');
          window.localStorage.setItem('email', userDetails.responseList[0].email ? userDetails.responseList[0].email : '');
          window.localStorage.setItem('dob', userDetails.responseList[0].dob ? userDetails.responseList[0].dob : '');
          props.updateIsUserLoggedInStatus();
          // if (props.callPaymentService) {
          //   props.getPaymentServiceOffers(window.localStorage.getItem('acrValue'), '', window.location.href).then((resp) => {
          //     props.closeLoginModal(resp?.responseList[0], true);
          //   });
          // } else {
          props.getPaymentServiceOffers(window.localStorage.getItem('acrValue'), '', window.location.href).then((resp) => {
            props.closeLoginModal(resp?.responseList[0], true);
          });
          // props.closeLoginModal('', true);
          // }
          setLoader(false);
        }).catch(() => {
          setLoader(false);
        });
      })
      .catch((error) => {
        setLoader(false);
        console.log('erroraccess', error);
      });
  };

  const verifyClick = () => {
    if (otp.length !== props.otpCount.otpLength) {
      props.showLoginNotification(`Please provide ${props.otpCount.otpLength} digit OTP`, 'error');
      setOtpNo('');
    } else {
      const encrypt = new JSEncrypt();

      // Copied from https://github.com/travist/jsencrypt
      const publicKey = APP_CONFIG.PUBLIC_KEY;
      encrypt.setPublicKey(publicKey);
      setLoader(true);
      const data = {
        mobileNumber: encrypt.encrypt(phoneNo),
        otp: Number(otp),
      };
      // setOpenModal(false);
      props.verifyOtp(data).then((successData) => {
        if (successData) {
          props.showLoginNotification(successData.message, 'success');
          setOpenModal(false);
        }
        getOauthToken(phoneNo);
      }).catch((error) => {
        setOpenModal(true);
        props.showLoginNotification(error.errorMessage, 'error');
        setOtpNo('');
        console.log('errrr', error);
        setLoader(false);
      });
    }
  };

  const handlTnC = () => {
    if (!window.location.pathname.includes('/termsAndCondition')) {
      props.trackSearchPath(window.location.pathname + window.location.search);
    }
    props.history.push('/termsAndCondition');
    props.setOpenLogin(false);
    setTargetValue('');
    setPhoneNo('');
    setIsOtpScreen(false);
  };

  return (
    <>
      {props.openLogin && (
        <>
          <Modal
            open={openModal}
            onClose={handleLoginClose}
            BackdropProps={{
              style: {
                backdropFilter: 'blur(7px)',
                // backgroundColor: '#707070',
                // opacity: '60%',
                boxShadow: 'none',
              },
            }}
          >
            <div className={mobileView ? classes.mobiletop : classes.top}>
              <MobileOtp
                setPhoneNumber={setPhoneNumber}
                targetValue={targetValue}
                handleGetOPT={() => handleGetOPT()}
                setOtpNo={setOtpNo}
                verifyClick={verifyClick}
                // checkOTPstatus={checkOTPstatus}
                handleCloseFn={handleLoginClose}
                otp={otp}
                phoneNo={phoneNo}
                mainHead="Login"
                isOtpScreen={isOtpScreen}
                setOpenLogin={props.setOpenLogin}
                handlTnC={handlTnC}
                loader={loader}
                otpLength={props.otpCount.otpLength}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: state.oauth.isUserLoggedIn,
    serviceOffers: state.payment.serviceOffers,
    otpCount: state.oauth.otpCount
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    generateOtp,
    verifyOtp,
    oAuth,
    getUserDetails,
    updateIsUserLoggedInStatus,
    trackSearchPath,
    getPaymentServiceOffers,
    getOtpLength
  },
  dispatch
);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginModal)
);
