/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import { TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import { renderPlaylistPanel } from '../../reducers/ui';
import {
 postCreateVideoPlaylist,
 postExistingVideoPlaylist,
 postExistingMusicPlaylist,
 getAllVideoPlaylist,
 getAllMusicPlaylist,
 postCreateMusicPlaylist,
} from '../../reducers/playlist';
import { Divider, withStyles } from '@material-ui/core';
import NotificationMessage from '../NotificationMessage';
 
const StyleTextField = withStyles((theme) => ({
 root: {
   marginBottom: 15,
   [`& fieldset`]: {
     // borderRadius: 8,
   },
   width: '98%',
   margin: 'auto',
   marginLeft: '1%',
   backgroundColor: '#363636',
   [theme.breakpoints.down('1024')]: {
     background: '#3e3e3e',
   },
   height: '80px',
   color: '#ffffff',
   fontSize: '0.1em',
   fontFamily: 'SegeoUI',
   zIndex: 1,
   [`& ::-webkit-input-placeholder`]: {
     opacity: '1 !important',
     paddingLeft: '20px',
     color: '#ababab',
   },
   [`& ::-moz-placeholder`]: {
     opacity: '1 !important',
   },
 },
 '@global': {
   // You should target [class*="MuiButton-root"] instead if you nest themes.
   '@media (min-width: 1024px)': {
     '.MuiInputBase-input': {
       fontSize: '1.5rem',
       zIndex: 10,
       color: theme.palette.titleClr,
       fontFamily: 'SegeoUI',
       lineHeight: 30,
       // backgroundColor: '#3e3e3e',
     },
   },
 },
}))(TextField);
 
const useStyles = makeStyles((theme) => ({
 top: {
   background: theme.palette.bgThemeColor,
   margin: '15px',
   borderRadius: '10px',
   height: '50vh',
   width: '50vw',
 },
 header: {
   display: 'flex',
   alignItems: 'center',
   flexDirection: 'column',
   [theme.breakpoints.down('sm')]: {
     background: '#242424',
   },
   [theme.breakpoints.up('sm')]: {
     background: theme.palette.bgThemeColor,
   },
 },
 img_title: {
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'flex-start',
   paddingLeft: '30px',
   fontSize: '1.9375rem',
   color: '#B9B9B9',
 },
 PlayListPopup: {
   // position: 'fixed',
   bottom: 0,
   width: '100%',
   height: '55vh',
   borderRadius: '20px 20px 0px 0px',
   alignItems: 'center',
   // background: '#363636',
   zIndex: 1,
   '& > svg': {
     margin: theme.spacing(2),
   },
 },
 title: {
   fontSize: '1.25rem',
   color: '#ffffff',
   margin: '10px',
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'space-between',
   cursor: 'pointer'
 },
 iconStyle: {
   borderRadius: '50%',
   height: '50px',
   width: '50px',
   background: '#616161',
   padding: '10px',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
 },
 menuList: {
   height: '80px',
   backgroundColor: '#3e3e3e',
   color: '#d6d6d6',
   fontSize: '0.75rem',
   lineHeight: '50px',
   paddingLeft: '40px',
   display: 'flex',
   alignItems: 'center',
   marginBottom: '10px',
   width: '98%',
   margin: 'auto',
 },
 playlistData:{
  '@media (max-height: 898px)': {
    height: '25vh'
  },

  '@media (min-height: 899px)': {
    height: '35vh'
  },
  overflowY: 'scroll',
  

 }
}));
 
const SaveVideoDesk = (props) => {
 const classes = useStyles();
 const [savePlaylistDesk, setSavePlaylistDesk] = useState('');
 const [createPlaylist, setCreatePlaylist] = useState(true);
 const [open, setOpen] = useState(false);
 const [msg, setMsg] = useState('');
 const [msgType, setMsgType] = useState('');
 const [targetValue, setTargetValue] = useState('');

 useEffect(() => {
   if (props.audio) {
     console.log('audio');
     console.log(props.audioId);
     props.getAllMusicPlaylist(1, 20);
   }
   if (props.video) {
     console.log('video');
     console.log(props.videoId);
     props.getAllVideoPlaylist(1, 20);
   }
 }, []);
 
 const handleCloseDesk = () => {
   if (props.audio) props.handleCloseModal();
 
   setCreatePlaylist(true);
   props.renderPlaylistPanel(!props.showPlaylistPanel);
 };
 
 const handleCreatePlaylistDesk = () => {
   setCreatePlaylist(false);
 };
 
 const handleSavePlaylistDesk = (videoId) => {
   if (props.audioId) {
     props.postCreateMusicPlaylist({ playlistName: savePlaylistDesk, mediaId: videoId }).then((result) => {
       setOpen(true);
       setMsg(result.message);
       setMsgType("success");
     }).catch((error) => {
       setOpen(true);
       setMsg(error.errorMessage);
       setMsgType("error");
     });
   }
   if (props.videoId) {
     props.postCreateVideoPlaylist({ playlistName: savePlaylistDesk, mediaId: videoId }).then((result) => {
       setOpen(true);
       setMsg(result.message);
       setMsgType("success");
     }).catch((error) => {
       setOpen(true);
       setMsg(error.errorMessage);
       setMsgType("error");
     });
   }
   setTimeout(() => {
     props.renderPlaylistPanel(!props.showPlaylistPanel);
     props.handleCloseModal();
   }, 3000);
 
 };
 
 const handleExistingPlaylist = (IdtoAdded, id) => {
   if (props.videoId) {
     props.postExistingVideoPlaylist({ playlistId: id, mediaId: IdtoAdded }).then((result) => {
       setOpen(true);
       setMsg(result.message);
       setMsgType("success");
     }).catch((error) => {
       setOpen(true);
       setMsg(error.errorMessage);
       setMsgType("error");
     });
     setTimeout(() => {
       props.renderPlaylistPanel(!props.showPlaylistPanel);
     }, 3000);
   }
   if (props.audioId) {
     props.postExistingMusicPlaylist({ playlistId: id, mediaId: IdtoAdded }).then((result) => {
       setOpen(true);
       setMsg(result.message);
       setMsgType("success");
     }).catch((error) => {
       setOpen(true);
       setMsg(error.errorMessage);
       setMsgType("error");
     });
     setTimeout(() => {
       props.handleCloseModal();
       props.renderPlaylistPanel(!props.showPlaylistPanel);
     }, 3000);
   }
 };

 const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};
 const handlePlaylistTitle = (e) => {
  const re = /^[0-9a-zA-Z ]*$/;
  if ((e.target.value.match(re))) {
      setTargetValue(e.target.value);
      setSavePlaylistDesk(e.target.value);

      console.log('search', e.target.value.trim());
  }
 }
 return (
   <>
     <Grid className={classes.PlayListPopup}>
       <div style={{ paddingLeft: '1.125rem', paddingRight: '1.125rem' }}>
         <div
           style={{
             display: 'flex',
             justifyContent: 'space-between',
             fontSize: '2rem',
             color: '#ababab',
             marginLeft: '20px',
             fontWeight: 900,
             marginTop: '30px',
           }}
         >
           <p>Add To Playlist</p>
           <CloseIcon style={{ fontSize: '40px', cursor: 'pointer' }} onClick={handleCloseDesk} />
         </div>
       </div>
       {createPlaylist ? (
         <>
           <div className={classes.menuList}>
             <div className={classes.iconStyle}>
               <AddIcon style={{ fontSize: '20px', color: '#d6d6d6' }} />
             </div>
             <div style={{ marginLeft: '40px', fontSize: '1.5rem' }} onClick={handleCreatePlaylistDesk}>
               Create New Playlist
             </div>
           </div>
         </>
       ) : (
         <StyleTextField
           fullWidth
           disableUnderline
           id="outlined-search"
           type="text"
           variant="outlined"
           placeholder="Add your Playlist Name....."
           autoFocus
           value={targetValue}
           autoComplete="off"
           onChange={handlePlaylistTitle}
           InputProps={{
             style: { color: '#ffffff', height: '100px' },
             endAdornment: (
               <InputAdornment position="end">
                 <div className={classes.apply}>
                   <CheckIcon
                     style={{ backgroundColor: '#363636', color: '#ababab', fontSize: '30px', cursor: 'pointer' }}
                     onClick={() => {
                       if (props.videoId) handleSavePlaylistDesk(props.videoId);
                       if (props.audioId) handleSavePlaylistDesk(props.audioId);
                     }}
                   />
                 </div>
               </InputAdornment>
             ),
           }}
         />
       )}
       <div className={classes.playlistData}>
         {props.video &&
           props.allVideoPlaylist.map((PlaylistDeskData) => {
             return (
               <>
                 <Typography
                   variant="h5"
                   component="h2"
                   className={classes.title}
                   onClick={() => {
                     if (props.videoId) handleExistingPlaylist(props.videoId, PlaylistDeskData.id);
                   }}
                 >
                   <div className={classes.img_title}>{PlaylistDeskData.playlistName}</div>
                 </Typography>
                 <Divider style={{ backgroundColor: '#505050', margin: 'auto' }} />
               </>
             );
           })}
 
         {props.audio &&
           props.allMusicPlaylist.map((PlaylistDeskData) => {
             return (
               <>
                 <Typography
                   variant="h5"
                   component="h2"
                   className={classes.title}
                   onClick={() => {
                     if (props.audioId) handleExistingPlaylist(props.audioId, PlaylistDeskData.id);
                   }}
                 >
                   <div className={classes.img_title}>{PlaylistDeskData.playlistName}</div>
                 </Typography>
                 <Divider style={{ backgroundColor: '#505050', margin: 'auto' }} />
               </>
             );
           })}
       </div>
       <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleClose}/>
     </Grid>
   </>
 );
};
const mapStateToProps = (state) => {
 return {
   showPlaylistPanel: state.ui.showPlaylistPanel,
   allVideoPlaylist: state.playlist.allVideoPlaylist,
   allMusicPlaylist: state.playlist.allMusicPlaylist,
 };
};
const mapDispatchToProps = (dispatch) =>
 bindActionCreators(
   {
     renderPlaylistPanel,
     postCreateVideoPlaylist,
     postExistingVideoPlaylist,
     postExistingMusicPlaylist,
     getAllVideoPlaylist,
     getAllMusicPlaylist,
     postCreateMusicPlaylist,
   },
   dispatch
 );
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveVideoDesk));
