import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '@components/common/Logo';
import styles from './header.module.css';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className={styles.logo} />
      <Link className="navbar-brand" to="/">
        <Logo text="One dine" />
      </Link>
    </nav>
  );
};

export default Header;
