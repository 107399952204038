import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateSubscriptionStatus } from '../../reducers/payment';

const AicUnsubscribe = (props) => {
  const params = new URLSearchParams(window.location.search);

  const acrValue = window.sessionStorage.getItem('is_he_user') === 'true' ? window.sessionStorage.getItem('he_acr_value') : window.localStorage.getItem('acrValue');
  if (params.get('SUCCESS')) {
    const data = {
      msisdn: acrValue,
      subId: params.get('umrid'),
      status: 'inactive',
      action: 'unsubscribe'
    };
    props.updateSubscriptionStatus(data).then(() => {
      window.location.href = window.localStorage.getItem('redirectionUrl');
    });
  } else {
    window.location.href = window.localStorage.getItem('redirectionUrl');
  }

  return (
    <div />
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSubscriptionStatus
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(AicUnsubscribe));
