/* eslint-disable */

import { makeStyles } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';
const useStyles = makeStyles(theme => ({
    subTitle: {
        fontSize: '20px',
        fontWeight: 800,
        textDecoration: 'underline',
        [theme.breakpoints.down('1024')]: {
            fontSize: '16px',
        },
        marginTop: '20px',
        marginBottom: '10px',
    },
    mainTitle: {
        fontSize: '30px',
        fontWeight: 900,
        textAlign: 'center',
        [theme.breakpoints.down('1024')]: {
            fontSize: '26px',
        },
    },
    boldValues: {
        fontWeight: 900,
    },

    voucherTitle: {
        fontSize: '30px',
        fontWeight: 900,
        textDecoration: 'underline',
        [theme.breakpoints.down('1024')]: {
            fontSize: '16px',
        },
        textAlign: 'center',
    },
    subTitleColor: {
        color: 'red'
    }
}));

const BraggaTC = () => {
    const classes = useStyles();
    const privacyPolicyUrl = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.privacyPolicyUrl;

    return (
        <>
            <div className={classes.voucherTitle}>
                BRAGGAWORLD “BOSS BABE” HAMPER COMPETITION
            </div>
            <ol>
                <li>
                    <div className={classes.subTitle}>Introduction:</div>
                    <ol>
                        <li>
                            Vodacom (Pty) Ltd (
                            <span className={classes.boldValues}> “Vodacom” </span>) and
                            OnMobile Global SA (Pty) Ltd (
                            <span className={classes.boldValues}> “Partner” </span>) are
                            running a Competition where participants stand a chance of winning a
                            Boss Babe hamper to the value of R5 500.00 (five thousand five hundred Rand) 
                            by subscribing to BraggaWorld on the Celebrity Connect service (<span className={classes.boldValues}>“Service”</span>) 
                            for only R3.00 (three Rand) per day,  finding the competition post on Facebook or Instagram
                            and posting a comment with why they deserve to win the Competition (
                            <span className={classes.boldValues}> “Social Media Comment” </span>
                            ) (<span className={classes.boldValues}> the “Competition” </span>).
                        </li>
                    </ol>
                    <div
                        className={classes.mainTitle}
                        style={{ marginTop: '30px', textDecoration: 'underline' }}
                    >
                        IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS
                    </div>

                    <div className={classes.subTitle}>
                        PLEASE READ THESE TERMS AND CONDITIONS AND THE &nbsp;
                        <a
                            target="_blank"
                            style={{ textDecoration: 'underline', color: '#b9b9b9' }}
                            href={privacyPolicyUrl}
                        >
                            PRIVACY POLICY
                        </a>
                        &nbsp; CAREFULLY BEFORE PARTICIPATING IN THIS COMPETITION. BY
                        ENTERING THE COMEPTITION, YOU ARE AGREEING TO BE BOUND BY THESE
                        TERMS AND CONDITIONS. IF YOU DO NOT AGREE, PLEASE DO NOT ENTER THE
                        COMPETITION OR SUBMIT ANY INFORMATION IN THIS REGARD. WE RESERVE THE
                        RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS AND CONDITIONS
                        FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS AND CONDITIONS ON
                        THE VODACOM WEBSITE. ANY SUCH UPDATES SHALL APPLY TO YOU AND YOUR
                        ENTRY INTO THE COMPETITION AND YOU SHALL BE DEEMED TO HAVE ACCEPTED
                        SUCH CHANGES.
                    </div>

                    <div className={cn(classes.subTitle, classes.subTitleColor)}>
                        ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE
                        FOLLOWING TERMS AND CONDITIONS:
                    </div>
                </li>
                <li>
                    <div className={classes.subTitle}>Duration:</div>
                    <ol>
                        <li>
                            This Competition shall commence on 00h01 on 1 November 2024 to 23h59
                            on 30 November 2024 (the
                            <span className={classes.boldValues}> “End Date” </span>) (
                            <span className={classes.boldValues}>
                                {' '}
                                “the Competition period”{' '}
                            </span>
                            ).
                        </li>
                        <li className="mt-20">
                            The duration of the Competition may be extended or curtailed at
                            the discretion of Vodacom.
                        </li>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>Prizes:</div>
                    <ol>
                        <li>Participants to the Competition stand a chance to win:</li>
                        <ol>
                            <li className="mt-20">
                                a Boss Babe hamper which includes the following:

                                <ol>
                                    <li className="mt-20">
                                        Era by DJ Zinhle merchandise;
                                    </li>
                                    <li className="mt-20">
                                        Black Rose Gin;
                                    </li>
                                    <li className="mt-20">
                                        Nadia Nakai’s “Naked Without” makeup set; and
                                    </li>
                                    <li className="mt-20">
                                        a Chateau Gateaux voucher.
                                    </li>
                                </ol>
                            </li>
                            <li className="mt-20">
                                The total value of the Prize is R5 500.00 (five thousand five hundred Rand) (<span className={classes.boldValues}> “the Prize” </span>).
                            </li>
                        </ol>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>Eligibility:</div>
                    <ol>
                        <li>
                            Only entries received during the Competition period will qualify
                            to enter the Competition.
                        </li>
                        <li className="mt-20">
                            The following requirements must be complied with during the
                            Competition period in order to qualify for entry into the
                            Competition:
                            <ol>
                                <li className="mt-20">be a natural person;</li>
                                <li className="mt-20">be at least 18 years and older;</li>
                                <li className="mt-20">
                                    be a Vodacom prepaid, hybrid or post-paid subscriber during
                                    the Competition Period;
                                </li>
                                <li className="mt-20">
                                    reside in South Africa and be in possession of a valid South
                                    African identify document or South African permanent residence
                                    permit or a South African temporary residence permit valid for
                                    a period exceeding 3(three) months which permits shall be
                                    valid as at the End Date;
                                </li>
                                <li className="mt-20">
                                    use a SIM card that has been RICA registered; and
                                </li>
                                <li className="mt-20">
                                    subscribe to the Service for only R3.00 (three Rand) per day, R15.00 (fifteen Rand)
                                    per week or R30.00 (thirty Rand) per month by:
                                </li>
                                <ol>
                                    <li className="mt-20">
                                        dialling USSD: *117*55#; or
                                    </li>

                                    <li className="mt-20">
                                        visiting web: &nbsp;
                                        <a
                                            target="_blank"
                                            style={{
                                                textDecoration: 'underline',
                                                color: '#b9b9b9',
                                                overflowWrap: 'break-word',
                                            }}
                                            href="https://braggaworld.celebrityconnect.co.za/"
                                        >
                                            https://braggaworld.celebrityconnect.co.za/
                                        </a>
                                        &nbsp;
                                    </li>
                                </ol>
                                <li className="mt-20">
                                    find the competition post on Facebook or Instagram and post a Social Media Comment.
                                </li>
                            </ol>
                            <div className="mt-20">
                                Once or if all the requirements of paragraphs 4.2.1 to 4.2.7 are
                                met, entry into the Competition will be automatic.
                            </div>
                        </li>
                        <li className="mt-20">
                            Employees, directors and agents of Vodacom and the Partner (where
                            applicable) all participating stores, their immediate families,
                            life partners, business partners and associates and any person
                            directly involved with the sponsoring, devising, production,
                            management or marketing of this Competition are not eligible to
                            enter this Competition.
                        </li>
                        <li className="mt-20">
                            In the event that the registered user of the SIM Card or Vodacom
                            account (i.e, the participant in the Competition) is not the
                            person that is responsible for payment of the Vodacom account/
                            charges on the Vodacom account, then the Prize will be awarded to
                            the person that is responsible for payment of the Vodacom account.
                        </li>
                        <li className="mt-20">
                            Each subscription and Social Media Comment and qualifies for 1 (one)
                            entry into the Competition.
                        </li>
                        <li className="mt-20">
                            Standard data costs apply as per the user’s Service Provider and
                            existing price plan for data usage. Vodacom shall not be liable
                            for any costs incurred by the winners for claiming any Prizes,
                            where applicable.
                        </li>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>Prize draw:</div>
                    <ol>
                        <li>
                            Winners will be randomly selected on 1 December 2024 from all eligible
                            entries who meet all the requirements set out in clause 4 above.
                        </li>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>Winner Validation:</div>
                    <ol>
                        <li>
                            All the information provided or related to this Competition shall
                            be managed, captured and approved by Vodacom and/or the Partner
                            (where applicable).
                        </li>
                        <li className="mt-20">
                            The winner will be notified via direct message on social media and
                            published on the BraggaWorld portal. Where necessary, Vodacom and/or
                            the Partner (where applicable) will use reasonable efforts to contact
                            the winners telephonically on the MSISDN used by the participant to subscribe
                            to the Service (<span className={classes.boldValues}>"Registered MSISDN"</span>).
                        </li>
                        <li className="mt-20">
                            The claim for any Prize will be subject to security and validation, and Vodacom
                            reserves the right to withhold any Prize until it is satisfied that the claim by
                            a particular winner is valid.  The selected winners must be able to identify
                            themselves, in a manner determined by Vodacom, as the qualifying participants to
                            the Competition and are required to comply with the validation procedure, determined
                            by Vodacom, to claim any Prizes, failing which such winner will forfeit the Prize and
                            the Prize will be awarded to the next selected qualifying participant.
                        </li>
                        <li className="mt-20">
                            Where necessary, Vodacom and/or the Partner (where applicable) shall attempt to contact
                            the winners for a period of 05 (five) working days after their names have been drawn and
                            verified as winners. The winners will be contacted during normal working hours, between
                            09:00 to 16:00. Winners will be advised of arrangements in respect of Prizes at the time
                            of contacting the selected winners. Should a participant not be available on the Registered
                            MSISDN during the timeframe stipulated above or rejects, forfeits or declines acceptance of
                            the Prize, that person's right to the Prize will be deemed to have been waived and the Prize
                            will be forfeited. Vodacom reserves the right to then award the Prize to the next randomly
                            drawn participant. The winners will be contacted within 7 (seven) working days after the End Date.
                        </li>
                        <li className="mt-20">
                            In the event that Vodacom requires certain documentation to be submitted by a particular winner and
                            same has not been received at the agreed cut-off date communicated to the winner, or no effort is
                            made on the part of any of the winners to make special arrangements to meet the deadline set by
                            Vodacom, such Prize will be forfeited. Vodacom then reserves the right to award the Prize to the
                            finalist that is next in line.
                        </li>
                        <li className="mt-20">
                            Vodacom and/or the Partner (where applicable) shall request the winners’ consent
                            in writing to their image and/or likeness being used and published by Vodacom
                            in connection with the Competition for a period of 12 (twelve) months after
                            they have been announced as winners. The winners may decline the use of their
                            image and/or likeness by Vodacom.
                        </li>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>Prize Delivery:</div>
                    <ol>
                        <li>
                            All Prizes will be sent with a courier service to the winners’ physical
                            addresses only.  No Prizes will be delivered to a postal address. The winner
                            will be required to provide proof of their physical address.
                        </li>
                        <li className="mt-20">
                            If a winner cannot accept a Prize for any reason whatsoever, the
                            Prize will be awarded to the next selected participant.
                        </li>
                        <li className="mt-20">
                            All risks and ownership of the Prizes shall pass to the winners on
                            transfer/delivery thereof and hence all of Vodacom’s obligations regarding the
                            Competition as well as in regard to the Prizes shall terminate.
                        </li>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>Personal information:</div>
                    <ol>
                        <li>
                            User data, including personal information collected via the
                            Competition will not be used for any other purpose than for
                            execution of the Competition and will be processed in line with
                            the Vodacom’s &nbsp;
                            <a
                                target="_blank"
                                style={{ textDecoration: 'underline', color: '#b9b9b9' }}
                                href="https://www.vodacom.co.za/vodacom/privacy-policy/terms?icmp=Home/Footer/PrivacyPolicy#eb0f6ca5-accordion-title--1053848991-1053848991"
                            >
                                Privacy Statement{' '}
                            </a>
                            &nbsp;which is incorporated into these terms and conditions. The
                            Privacy Statement sets out how amongst other things Vodacom
                            collects, use and shares subscribers personal information and how
                            it protects subscriber’s privacy when processing their
                            information.
                        </li>
                        <li className="mt-20">
                            By continuing, a subscriber confirms that the Vodacom Privacy
                            Statement has been read and understood. The latest Privacy
                            Statement can be found on the Vodacom website at &nbsp;
                            <a
                                target="_blank"
                                style={{
                                    textDecoration: 'underline',
                                    color: '#b9b9b9',
                                    overflowWrap: 'break-word',
                                }}
                                href="https://www.vodacom.co.za/vodacom/privacy-
                    policy/terms?icmp=Home/Footer/PrivacyPolicy"
                            >
                                https://www.vodacom.co.za/vodacom/privacy-
                                policy/terms?icmp=Home/Footer/PrivacyPolicy
                            </a>
                            &nbsp;.
                        </li>
                    </ol>
                </li>
                <li>
                    <div className={classes.subTitle}>General:</div>
                    <ol>
                        <li>
                            Participants may obtain a copy of the competition rules on the
                            <a
                                target="_blank"
                                style={{
                                    textDecoration: 'underline',
                                    color: '#b9b9b9',
                                    overflowWrap: 'break-word',
                                }}
                                href="https://braggaworld.celebrityconnect.co.za/termsAndCondition"
                            >
                                https://braggaworld.celebrityconnect.co.za/termsAndCondition
                            </a>
                            &nbsp;.
                        </li>
                        <li className="mt-20">
                            Nothing in these Terms and Conditions is intended to, or must be
                            understood to, unlawfully restrict, limit or avoid any rights or
                            obligations, as the case may be, created for either the
                            participant or Vodacom in terms of the Consumer Protection Act, 68
                            of 2008 (<span className={classes.boldValues}> "CPA" </span>
                            ).
                        </li>
                        <li className="mt-20">
                            The Prize is not transferable and is not exchangeable for another
                            Prize or cash and cannot be sold.
                        </li>
                        <li className="mt-20">
                            The decision of Vodacom in respect of disputes arising out of this
                            Competition shall be dealt with by Vodacom in terms of these Terms
                            and Conditions. The decision of Vodacom in this regard shall be
                            final and binding on the Parties and no correspondence will be
                            entered into.
                        </li>
                        <li className="mt-20">
                            Winners are only entitled to win 1 (one) Prize each.
                        </li>
                        <li className="mt-20">
                            In the event of a dispute with regard to any aspect of the
                            Competition and/or the Terms and Conditions, Vodacom’s decision
                            will be final, and binding and no correspondence will be entered
                            into.
                        </li>
                        <li className="mt-20">
                            Vodacom and/or the Partner may refuse to award a Prize if entry
                            procedures or these Terms and Conditions have not been adhered to
                            or if it detects any irregularities or fraudulent practices.
                            Vodacom is entitled in its entire discretion to reject any
                            participant for any reason and will not be obliged to notify
                            participants that they have not been successful.
                        </li>
                        <li className="mt-20">
                            Vodacom and/or the Partner and/or any other person or party associated
                            with the Competition, their associated companies, agents, contractors
                            and sponsors and any of its personnel involved in the Competition, shall
                            not be liable whatsoever for any loss or damage incurred or suffered
                            (including but not limited to direct or indirect or consequential loss),
                            death or personal injury suffered or sustained arising from either the
                            participant entering the Competition or from it claiming a Prize.
                        </li>
                        <li className="mt-20">
                            Vodacom is not liable for any technical failures affecting participation
                            and / or Prize redemption process of the Competition.  In addition, neither
                            Vodacom nor its agents shall be responsible for any loss or misdirected entries,
                            including entries that were not received due to any failure of hardware,
                            software, or other computer or technical systems affecting participating in
                            and/or the Prize redemption process of the Competition.
                        </li>
                        <li className="mt-20">
                            Vodacom, its directors, employees, agents, and distributors, are not responsible
                            for any misrepresentation (whether written or verbal) in respect of any Prize
                            nor in respect of any warranties, guarantees or undertakings given by any person
                            other than Vodacom itself.
                        </li>
                        <li className="mt-20">
                            If Vodacom elects in its sole and absolute discretion or is required by law to
                            alter, vary or cancel any aspect of the Competition, it will have the right to
                            terminate the Competition being offered, with immediate effect and upon written
                            notice being published on the Vodacom website. In such event all participants
                            hereby waive any rights which they may have against Vodacom and its associated
                            companies, agents, contractors and/or sponsors.
                        </li>
                        <li className="mt-20">
                            All information relating to the Competition which is published on any marketing
                            material will form part of these Terms and Conditions. In the event of any
                            conflict between such marketing material and these Terms and Conditions,
                            these Terms and Conditions shall prevail.
                        </li>
                        <li className="mt-20">
                            The Competition is also subject to, and must be read in conjunction with,
                            Vodacom’s existing terms and conditions applicable to its website and mobile
                            site respectively., which terms and conditions are available on the Vodacom
                            website.
                        </li>
                    </ol>
                </li>
            </ol>
        </>
    );
};
export default BraggaTC;
