/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ProfileHeader from '../ProfileHeader';
import EditIcon from '@material-ui/icons/Edit';
import {
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
} from '../../reducers/ui';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  align: {
    color: theme.palette.selectedTxtClr,
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  table: {
    margin: 'auto',
    marginTop: '20px',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '20px',
  },
  tablebody:{
    '& .MuiTableCell-body':{
      color: theme.palette.selectedTxtClr,
    },
  }
}));
const TableCellHead = styled(TableCell)((theme) => ({
  borderBottom: '2px solid rgb(37 123 238 / 10%)',
  padding: '8px',
}));
const TableCellBody = styled(TableCell)(() => ({
  borderBottom: '2px solid rgb(37 123 238 / 10%)',
  padding: '8px',
}));

const ProfileDetails = props => {
  const [profileImg, setProfileImg] = useState(props.thumbPath);

  const RedirectToEdit = () => {
    props.renderInputPanel(true);
    props.renderEditPanel(false);
    props.renderProfilePanel(false);
  };
  const classes = useStyles();
  
  return (
    <div style={{ backgroundColor: '#242424', height: '100vh' }}>
      <ProfileHeader thumbPath={profileImg}/>
      <div className={classes.align} onClick={RedirectToEdit}>
        <EditIcon 
        style={{fontSize:'20px'}}
         />
        <b>Edit</b>
      </div>

      <TableContainer>
        <div className={classes.table}>
          <Table aria-label="simple table">
            <TableBody className={classes.tablebody}>
              {props.userDetails &&
                props.userDetails.length > 0 &&
                props.userDetails.map(user => {
                  return (
                    <>
                      <TableRow>
                        <TableCellHead>Username- </TableCellHead>
                        <TableCellBody align="left">
                          {user.name ? user.name : ''}
                        </TableCellBody>
                      </TableRow>
                      <TableRow>
                        <TableCellHead>Birthday- </TableCellHead>
                        <TableCellBody align="left">
                          {user.dob ? moment(user.dob).format('DD-MM-YYYY') : ''}
                        </TableCellBody>
                      </TableRow>
                      <TableRow style={{display:'none'}}>
                        <TableCellHead>Phone- </TableCellHead>
                        <TableCellBody align="left">
                          {user.mobileNumber ? user.mobileNumber : ''}
                        </TableCellBody>
                      </TableRow>
                      <TableRow>
                        <TableCellHead>Email- </TableCellHead>
                        <TableCellBody align="left">
                          {user.email ? user.email : ''}
                        </TableCellBody>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userDetails: state.otp.userData,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      renderEditPanel,
      renderInputPanel,
      renderProfilePanel,
      // updateField: (form) => dispatch(reset(form)),
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDetails)
);
