/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { EN } from '@localization';
import {
  styled,
  makeStyles
} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  selectFormControl: {
    backgroundColor: '#2b2b2b',
    borderRadius: '25px',
    width: '100%',
    height: '50px'
  },
  reactSelect: {
    height: '50px !important',
    marginLeft: '20px'
  },
  label: {
    color: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    fontFfamily: 'Arial',
    fontSize: '1.25rem',
    marginLeft: '10px'
  },
  errorLabelMainSpan:{
    marginTop: '2%',
    paddingLeft: '1%'
  },
  errorLabelSpan: {
    color: '#EE255C',
    fontSize: '0.8rem',
    fontFamily: 'SegoeUISemiBold !important',
  }
}));

const CssSelect = styled(Select)({
  '& .MuiSelect-icon': {
    color: '#ffffff',
    right: '2%'
  },
  '& .MuiInputBase-input': {
    lineHeight: '2 !important'
  },
});

export default function SelectVariants(props) {
  const classes = useStyles();

  const {
    label,
    required,
    id,
    value,
    disabled,
    isNone,
    options,
    displayLabel,
    onChange,
    errorMsg,
    displayErrorMsg,
  } = props;

  const [errorLabel, setErrorLabel] = useState(errorMsg);
  const [displayError, setDisplayError] = useState(displayErrorMsg);

  useEffect(() => {
    setErrorLabel(errorMsg);
  }, [errorMsg]);



  useEffect(() => {
    setDisplayError(displayErrorMsg);
  }, [displayErrorMsg]);

  const handleChange = (event) => {
    if(event.target.value === -1 && required) {
      setErrorLabel(EN.PLEASE_SELECT_GENRE);
      setDisplayError(true);
    } else {
      setErrorLabel("");
      setDisplayError(false);
    }
    onChange(event.target.value);
  };



  return (
    <div>
      {displayLabel && (
        <InputLabel className={classes.label}>
          <span className="text-capitalize">{label}</span>
          {required ? EN.REQUIRED : ''}
        </InputLabel>)}
      <FormControl variant="standard" id='dropDownSelect' className={classes.selectFormControl}>
        <CssSelect
          labelId={id}
          id="reactSelect"
          value={value}
          onChange={handleChange}
          label={label}
          className={classes.reactSelect}
          disabled={disabled}
        >
          {isNone && (
            <MenuItem
              key="none"
              value={-1}
              className={classes.menuItem}
            >
             None
            </MenuItem>
          )}
          {options.map((item) => (
            <MenuItem
              key={item.value ? item.value : item.label}
              value={item.value ? item.value : item.label}
              className={classes.menuItem}
            >
              {item.label}
            </MenuItem>
          ))}
        </CssSelect>
        {displayError && (
          <span className="col-xs-12 col-sm-12 col-md-9" key={displayError}>
              <span className={classes.errorLabelSpan}>
                {errorLabel}
              </span>
          </span>)}
      </FormControl>
    </div>
  );
}
