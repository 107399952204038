/* eslint-disable */
import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import styles from './dashboard.module.scss';
import { handleSelectedAudio, handleSelectedAudioPlayListMode,  handleSelectedAudioPlayList, renderGenrePanel, handleAlbumNameAndId } from '../../reducers/ui';
import { updateHistoryList } from '../../reducers/history';
import { getMusicDetails } from '../../reducers/portalContentDetails';
import { setOrderOfMedia, changeMediaStatus, uploadMediaFile, deleteUploadedMedia } from '../../reducers/video';
import { getMusicGenreList } from '../../reducers/music';
import { getAllMusicAlbum, setAlbumMusicList } from '../../reducers/album';
import SetOrderModal from '@containers/Modals/SetOrderModal';
import UploadModal from '@containers/Modals/UploadModal';
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomPopper from '../common/CustomPopper/CustomPopper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationMessage from '../NotificationMessage';
import ConfirmationModal from '../../containers/Modals/ConfirmationModal';
import { EN } from '@localization';
import cn from 'classnames';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ApproveIcon from '@material-ui/icons/Done';
import RejectIcon from '@material-ui/icons/Close';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { getContentPath } from '../../helpers/utils';

const StyleTypographyTitle = withStyles({
  root: {
    fontSize: '1.375rem',
    fontFamily: 'SegoeUI',
    color: '#d0d0d0',
    textAlign: 'left',
    '@media (max-width: 1024px)': {
      fontSize: '0.75rem',
    },
  },
})(Typography);

const StyleTypographyContent = withStyles({
  root: {
    fontSize: '1.125rem',
    fontFamily: 'SegoeUI',
    color: '#9e9d9e',
    textAlign: 'left',
    '@media (max-width: 1024px)': {
      fontSize: '0.625rem',
    },
  },
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    borderRadius: '16px',
    background: theme.palette.bgThemeColor,
  },
  dashboardCardWidth: {
    borderRadius: '16px',
    background: theme.palette.bgThemeColor,
  },
  mainContainer: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  topLeft: {
    position: 'absolute',
    top: '5%',
    left: '5%',
    color: 'black',
    fontSize: '1rem',
    backgroundColor: theme.palette.titleClr,
    fontWeight: '1000',
    borderRadius: '30px',
    padding: '5px 14px',
  },
  topRight: {
    position: 'absolute',
    top: '10%',
    right: '15%',
    color: '#079107'
  },
  topRightError: {
    position: 'absolute',
    top: '10%',
    right: '15%',
    color: '#AD0028'
  },
  popperDiv: {
    position: 'absolute',
    right: 150,
    bottom: 100,
    zIndex: 9
  },
  popperDivForLive: {
    position: 'absolute',
    right: 150,
    bottom: 150,
    zIndex: 9
  },
  popperDivForProductAdmin: {
    position: 'absolute',
    right: 190,
    bottom: 90,
    zIndex: 9
  },
  loaderbackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent'
  },
  circleLoader: {
    color: theme.palette.selectedTxtClr,
  }
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const [ellipsisChar, setEllipsisChar] = useState('');
  const [audioitem, setAudio] = useState({});

  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const mediaType = 1; // Music

  const [mediaDetails, setMediaDetails] = useState();
  const [moreOptionOpen, setMoreOptionOpen] = useState(false);
  const [genreList, setGenreList] = useState();
  const [albumList, setAlbumList] = useState();


  const isAdminPage = window.location.pathname.includes('/celebrityadmin') ? true : false;
  const isProductAdminPage = window.location.pathname.includes('/productadmin') ? true : false;
  const isMusicPage = window.location.pathname.split('/')[2] && window.location.pathname.split('/')[2];
  const isMusicListPage = window.location.pathname === '/music' ? true : false;
  const isDashboardPage = window.location.pathname.includes('/homepage') ? true : false;
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMsgType, setNotificationMsgType] = useState('');
  const [notificationMsg, setNotificationMsg] = useState('');
  const isSearchPage = window.location.pathname.includes('/search');

  const [loader, setLoader] = useState(false);


  useEffect(() => {
    if (window.innerWidth > 1600) {
      setEllipsisChar(20);
    } else if (window.innerWidth > 1900) {
      setEllipsisChar(40);
    } else {
      setEllipsisChar(20);
    }
    if (isAdminPage) {
      props.getMusicGenreList('live', true);
      props.getAllMusicAlbum('live');
    } else {
      // props.getMusicGenreList('live');
    }
  }, []);

  useEffect(() => {
    let list = [];
    props.musicGenreListFromAPI.length > 0 && props.musicGenreListFromAPI.map((item) => {
      list = [...list, { 'label': item.title }];
    });
    setGenreList(list);

  }, [props.musicGenreListFromAPI]);

  useEffect(() => {
    let list = [];
    props.allMusicAlbum.length > 0 && props.allMusicAlbum.map((item) => {
      list = [...list, { 'value': item.id + '-' + item.albumName , 'label': item.albumName }];
    });
    setAlbumList(list);

  }, [props.allMusicAlbum]);


  const ellipsis = (fileName, length) => {
    if (fileName && fileName.includes('_')) {
      const filteredFileName = fileName.replace(/_/g, ' ');
      return filteredFileName && filteredFileName.length > length ? (
        <span title={filteredFileName}>{filteredFileName.slice(0, length).concat('...')}</span>
      ) : (
        <span>{filteredFileName}</span>
      );
    }
    return fileName && fileName.length > length ? (
      <span title={fileName}>{fileName.slice(0, length).concat('...')}</span>
    ) : (
      <span>{fileName}</span>
    );
  };

  const handleAudioPlayer = useCallback((audio) => {
    console.log('audioitem 13',audio,window.location.pathname,audio.hasOwnProperty('albumDescription'),audio.hasOwnProperty('duration') );
    props.handleSelectedAudioPlayListMode('suggestions');
    props.handleCardRecentListClick();
    
    if(audio.hasOwnProperty('albumDescription')){
      if(audio.hasOwnProperty('albumDescription') ){
      // props.history.push('/music/musicByAlbum');
      props.handleSelectedAudioPlayListMode('notSuggestions');
      console.log('audio.albumName',audio.albumName)
      props.handleAlbumNameAndId(audio.albumName, audio.id)
      console.log('audioitem 2',audio.music);
      props.setAlbumMusicList(audio.music)
      // props.handleSelectedAudio(audio.music);
    }else{
      console.log('reached',props.musicByAlbumList)
      props.handleSelectedAudioPlayListMode('notSuggestions');
      props.handleSelectedAudio(audio.music);
    }
  }else{
      console.log('audioitem 12',audio.music);
      if (audio.hasOwnProperty('duration')) {
        props.getMusicDetails(audio.id || audio.mediaId, 'regular').then((response) => {
          console.log('response.responseList[0]',response.responseList[0])
          props.handleSelectedAudio(response.responseList[0]);
        });
        if (!isAdminPage && !isProductAdminPage) {
          if (audio.mediaId) {
            props.updateHistoryList({
              mediaId: audio.mediaId,
              title: audio.title,
              description: audio.description,
              thumbnail: audio.thumbnail,
              mediaType: 'Music',
            });
          }
          if (audio.id) {
            props.updateHistoryList({
              mediaId: audio.id,
              title: audio.title,
              description: audio.description,
              thumbnail: audio.thumbnail,
              mediaType: 'Music',
            });
          }
        }
      } else {
        props.renderGenrePanel(audio.title);
      }
    }

   
  }, []);

  const setMediaDetailsandOpenPopper = (value) => {
    setMediaDetails(value);
    setMoreOptionOpen(true);
  };

  const openOrderModal = () => {
    setOrderModalOpen(true);
  };

  const setOrderOfMedia = (value) => {
    setOrderModalOpen(false);
    setLoader(true);
    props.setOrderOfMedia(mediaDetails.id, 'Music', value).then((data) => {
      setLoader(false);
      props.getMusicList(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  };

  const setOrderModalBody = (
    <SetOrderModal setOrderModalOpen={setOrderModalOpen} setOrderOfMedia={setOrderOfMedia} mediaDetails={mediaDetails} />
  );

  const handleCloseAway = () => {
    setMoreOptionOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMoreOptionOpen(false)
    }
  };

  const handleApproveMedia = () => {
    handleCloseAway();
    setLoader(true);
    props.changeMediaStatus('Music', mediaDetails.id, 1).then((data) => {
      setLoader(false);
      props.getMusicList(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  };

  const handleRejectMedia = () => {
    handleCloseAway();
    setLoader(true);
    props.changeMediaStatus('Music', mediaDetails.id, 0).then((data) => {
      setLoader(false);
      props.getMusicList(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  };

  const deleteMusic = () => {
    const id = mediaDetails.id;
    setDeleteModalOpen(false);
    setLoader(true);
    props.deleteUploadedMedia(id, "Music").then((data) => {
      setLoader(false);
      setNotificationMsg(data.message);
      setNotificationMsgType('success');
      setNotificationOpen(true);
      props.getMusicList(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    })
  };

  const getMenu = (status) => {
    if (isAdminPage) {
      if (status === "live" || status === "approved") {
        return passingDataForLive;
      } else {
        return passingData;
      }
    } else if (isProductAdminPage) {
      if(status === "all" || status === "inactive") {
        return passingDataForProductAdmin;
      } else {
        return passingDataForProductAdminForLive;
      }
    }
  };

  const editMusicDetails = (formData) => {
    setEditModalOpen(false);
    setLoader(true);
    props.uploadMediaFile(formData).then((data) => {
      setLoader(false);
      setNotificationMsg(data.message);
      setNotificationMsgType('success');
      setNotificationOpen(true);
      props.getMusicList(data);
    }).catch((error) => {
      setLoader(false);
      setNotificationMsg(error.errorMessage);
      setNotificationMsgType('error');
      setNotificationOpen(true);
    });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationOpen(false);
  };

  const EditMusicModalBody = (
    <UploadModal
      isEdit={true}
      setUploadModalOpen={setEditModalOpen}
      mediaType={mediaType}
      editMediaDetails={editMusicDetails}
      modalHeading={EN.MUSIC_EDIT}
      initialValues={mediaDetails}
      genreList={genreList}
      albumList={albumList}
    />
  );

  const DeleteVideoModalBody = (
    <ConfirmationModal
      deleteItem={deleteMusic}
      deleteMsg={EN.MUSIC_DELETE_CONFIRMATION}
      setDeleteModalOpen={setDeleteModalOpen}
      heading={EN.DELETE}
    />
  );

  const passingData = [
    {
      value: 'Edit',
      icon: EditSharpIcon,
      handleClick: setEditModalOpen
    },
    // {
    //   value: 'Delete',
    //   icon: DeleteSharpIcon,
    //   handleClick: setDeleteModalOpen
    // }
  ];

  const passingDataForLive = [
    {
      value: 'Edit',
      icon: EditSharpIcon,
      handleClick: setEditModalOpen
    },
    // {
    //   value: 'Delete',
    //   icon: DeleteSharpIcon,
    //   handleClick: setDeleteModalOpen
    // },
    {
      value: 'Set Order',
      icon: AddIcon,
      handleClick: openOrderModal
    }
  ]

  const passingDataForProductAdmin = [
    {
      value: 'Approve Music',
      icon: ApproveIcon,
      handleClick: handleApproveMedia
    },
    {
      value: 'Reject Music',
      icon: RejectIcon,
      handleClick: handleRejectMedia
    },
    {
      value: 'Edit',
      icon: EditSharpIcon,
      handleClick: setEditModalOpen
    },
    {
      value: 'Delete',
      icon: DeleteSharpIcon,
      handleClick: setDeleteModalOpen
    }
  ];

  const passingDataForProductAdminForLive = [
    {
      value: 'Edit',
      icon: EditSharpIcon,
      handleClick: setEditModalOpen
    },
    {
      value: 'Delete',
      icon: DeleteSharpIcon,
      handleClick: setDeleteModalOpen
    },
    {
      value: 'Set Order',
      icon: AddIcon,
      handleClick: openOrderModal
    }
  ];

  return (
    <>
      {
        props.products &&
        props.products.length > 0 &&
        props.products.map((product) => {
          return (
            <>
              <Grid item className={cn((isMusicPage && !isSearchPage) && 'p-5px', (isDashboardPage || isSearchPage) && styles.dashboardAudioCardParentDiv)}>
                <Card className={isDashboardPage ? classes.dashboardCardWidth : classes.root} style={{ position:'relative', padding: ((window.location.pathname === '/homepage' || isSearchPage) && !props.mobileView) ? '0px 25px 0px 0px' : '' }}>
                  <div className={classes.container} onClick={() => handleAudioPlayer(product)}>
                    {product.mode === 'paid' && (props.serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') && (
                      <div className={styles.starIconForAudio}>
                        <img style={{ height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />  
                      </div>
                    )}
                    <CardMedia
                      style={{ backgroundSize: '100% 100%' }}
                      className={ (isDashboardPage && !props.mobileView) ? styles.dashboardAudioCardStyle : styles.expAudioCard}
                      image={getContentPath(product.thumbnail)}
                      title={product.title}
                    />
                    {(isAdminPage || isProductAdminPage)  && product.order > 0 && (
                      <div className={classes.topLeft}>{product.order}</div>
                    )}
                    {(isAdminPage || isProductAdminPage) && (product.status === 'live' || product.status === 'approved') && (
                      <CheckCircleIcon className={classes.topRight} />
                    )}
                    {(isAdminPage || isProductAdminPage) && product.status === 'suspended' && (
                      <Tooltip title="Music expired">
                        <ErrorIcon className={classes.topRightError} />
                      </Tooltip>
                    )}
                  </div>
                </Card>
                <CardContent style={{ padding: '2px' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ justifyContent: 'flex-start', width: '100%', cursor: 'pointer' }} onClick={() => handleAudioPlayer(product)}>
                        <StyleTypographyTitle variant="h6" component="h6">
                          {ellipsis(product.title||product.albumName, 13)}
                        </StyleTypographyTitle>
                      </div>
                      {(isAdminPage || isProductAdminPage) && (
                        <div style={{ position: 'relative', cursor: 'pointer', justifyContent: 'flex-end' }}>
                          <MoreVertIcon style={{ color: 'white' }} onClick={() => setMediaDetailsandOpenPopper(product)} />
                          {mediaDetails && product.id === mediaDetails.id && (
                            <div className={(isAdminPage && (product.status === 'live' || product.status === 'approved')) ? classes.popperDivForLive : (isAdminPage) ? classes.popperDiv : classes.popperDivForProductAdmin}>
                              <CustomPopper popperOpen={moreOptionOpen} handlePopperListKeyDown={handleListKeyDown} menu={getMenu(product.status)} handleCloseAway={handleCloseAway} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </CardContent>
              </Grid>
            </>
          );
        })
      }
      <div>
        <Modal
          open={orderModalOpen}
          onClose={() => setOrderModalOpen(false)}
          BackdropProps={{
            style: {
              backgroundColor: '#707070',
              opacity: '80%',
              boxShadow: 'none',
            },
          }}
        >
          {setOrderModalBody}
        </Modal>
        <Modal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          BackdropProps={{
            style: {
              backgroundColor: '#707070',
              opacity: '80%',
              boxShadow: 'none',
            },
          }}
        >
          {EditMusicModalBody}
        </Modal>
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          BackdropProps={{
            style: {
              backgroundColor: '#707070',
              opacity: '80%',
              boxShadow: 'none',
            },
          }}
        >
          {DeleteVideoModalBody}
        </Modal>
        <Backdrop className={classes.loaderbackdrop} open={loader}>
          <CircularProgress className={classes.circleLoader} />
        </Backdrop>
        <NotificationMessage open={notificationOpen} msgType={notificationMsgType} msg={notificationMsg} handleClose={handleClose} />
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleSelectedAudio,
      renderGenrePanel,
      updateHistoryList,
      getMusicDetails,
      setOrderOfMedia,
      changeMediaStatus,
      getMusicGenreList,
      uploadMediaFile,
      deleteUploadedMedia,
      handleSelectedAudioPlayListMode,
      handleSelectedAudioPlayList,
      getAllMusicAlbum,
      setAlbumMusicList,
      handleAlbumNameAndId
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    musicGenreListFromAPI: state.music.musicGenreList,
    allMusicAlbum: state.album.allMusicAlbum, 
    playUpdatedAudioPlaylist: state.ui.playSelectedAudioPlaylist,
    musicByAlbumList: state.album.setAlbumMusicList,
    serviceOffers: state.payment.serviceOffers,
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCard));
