/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
// import PostComment from './PostComment';
// import GetComment from './GetComment';
import { Typography, useMediaQuery, makeStyles, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { renderFavouritePanel } from '../../reducers/ui';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { getAllVideoPlaylist, getAllMusicPlaylist, postCreateMusicPlaylist, postExistingMusicPlaylist } from '../../reducers/playlist';

import { Divider, withStyles } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { postCreateVideoPlaylist, postExistingVideoPlaylist } from '../../reducers/playlist';
import NotificationMessage from '../NotificationMessage';

const StyleTextField = withStyles((theme) => ({
  root: {
    maxWidth: '1023px',
    marginBottom: 15,
    [`& fieldset`]: {
      borderRadius: 8,
    },
    backgroundColor: '#363636',
    [theme.breakpoints.down('1024')]: {
      background: '#3e3e3e',
    },
    color: '#ffffff',
    fontSize: '0.1em',
    borderRadius: 8,
    fontFamily: 'SegeoUI',
    zIndex: 1,
    [`& ::-webkit-input-placeholder`]: {
      opacity: '1 !important',
      paddingLeft: '20px',
    },
    [`& ::-moz-placeholder`]: {
      opacity: '1 !important',
    },
  },
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '@media (max-width: 1024px)': {
      '.MuiInputBase-input': {
        fontSize: '0.75em',
        zIndex: 10,
        color: theme.palette.titleClr,
        fontFamily: 'SegeoUI',
        lineHeight: 30,
        // backgroundColor: '#3e3e3e',
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  PlayListPopup: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '38vh',
    borderRadius: '20px 20px 0px 0px',
    alignItems: 'center',
    background: '#363636',
    zIndex: 999,
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
  SavePopup: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '24vh',
    borderRadius: '20px 20px 0px 0px',
    alignItems: 'center',
    background: '#363636',
    zIndex: 1,
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
  large: {
    background: theme.palette.titleClr,
    height: '40px',
    width: '40px',
    margin: 'auto',
  },
  img_title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: '30px',
    fontSize: '1.125rem',
    color: '#d6d6d6',
  },
  title: {
    [theme.breakpoints.down('1024')]: {
      fontSize: '0.8125rem',
      color: '#ffffff',
    },
    fontSize: '1.25rem',
    color: '#ffffff',
    margin: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  apply: {
    display: 'flex',
    background: '#3e3e3e',
    color: theme.palette.titleClr,
    width: '100%',
    background: '#363636',
  },

  menuList: {
    height: '50px',
    backgroundColor: '#3e3e3e',
    color: '#d6d6d6',
    fontSize: '0.75rem',
    lineHeight: '50px',
    paddingLeft: '40px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  iconStyle: {
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    background: '#616161',
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconColor: {
    color: '#FFFFFF50 !important'
  }
}));

const SaveMusicMob = (props) => {
  const classes = useStyles();
  const [saveTo, setSaveTo] = useState(true);
  const [createPlaylist, setCreatePlaylist] = useState(true);
  const [savePlaylist, setSavePlaylist] = useState('');
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [targetValue, setTargetValue] = useState('');

  const handleClose = () => {
    props.handleShowMusicPlaylist();
    setTargetValue('');
    setSavePlaylist('');
  };

  useEffect(() => {
    props.getAllMusicPlaylist(1, 20);
  }, []);

  useEffect(() => {
    console.log('props.allMusicPlaylist', props.allMusicPlaylist);
  }, [props.allMusicPlaylist]);

  const handleToPlaylist = () => {
    setSaveTo(false);
  };
  const handleCreatePlaylist = () => {
    setCreatePlaylist(false);
  };

  const handleSavePlaylist = (audioId) => {
    props.postCreateMusicPlaylist({ playlistName: savePlaylist, mediaId: audioId }).then((result) => {
      setOpen(true);
      setMsg(result.message);
      setMsgType("success");
    }).catch((error) => {
      setOpen(true);
      setMsg(error.errorMessage);
      setMsgType("error");
    });
    setTimeout(() => {
      props.handleShowMusicPlaylist()
      setSaveTo(true);
      setCreatePlaylist(true);
    }, 3000);
  };

  const handleExistingPlaylistMob = (audioId, title) => {
    props.postExistingMusicPlaylist({ playlistId: title, mediaId: audioId }).then((result) => {
      setOpen(true);
      setMsg(result.message);
      setMsgType("success");
    }).catch((error) => {
      setOpen(true);
      setMsg(error.errorMessage);
      setMsgType("error");
    });
    setTimeout(() => {
      props.handleShowMusicPlaylist()
      setSaveTo(true);
      setCreatePlaylist(true);
    }, 3000);
  };

  const handleCloseForNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleplaylistTitle = (e) => {
    const re = /^[0-9a-zA-Z ]*$/;
    if ((e.target.value.match(re))) {
      // var searchKey = e.target.value.trim();
      setTargetValue(e.target.value);
      setSavePlaylist(e.target.value);

      console.log('search', e.target.value.trim());
    }
  }

  return (
    <>
      <Grid className={classes.PlayListPopup}>
        <div style={{ paddingLeft: '1.125rem', paddingRight: '1.125rem', marginTop: 25 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '1.125rem',
              color: '#ababab',
              marginLeft: '20px',
              fontWeight: 900,
            }}
          >
            <p>Add To Playlist</p>
            <CloseIcon className={classes.iconColor} onClick={handleClose} />
          </div>
        </div>
        {createPlaylist ? (
          <>
            <div className={classes.menuList}>
              <div className={classes.iconStyle}>
                <AddIcon style={{ fontSize: '20px', color: '#d6d6d6' }} />
              </div>
              <div style={{ marginLeft: '40px', fontSize: '0.75rem' }} onClick={handleCreatePlaylist}>
                Create New Playlist
              </div>
            </div>
          </>
        ) : (
          <StyleTextField
            disableUnderline
            id="outlined-search"
            type="text"
            variant="outlined"
            placeholder="Add your Playlist Name....."
            autoFocus
            autoComplete="off"
            onChange={handleplaylistTitle}
            value={targetValue}
            style={{ width: '100vw' }}
            InputProps={{
              style: { color: '#ffffff', height: '50px' },
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.apply}>
                    <CheckIcon
                      style={{ backgroundColor: '#3e3e3e', color: '#ababab' }}
                      onClick={() => handleSavePlaylist(props.audioId)}
                    />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        )}
        <div style={{ overflowY: 'scroll', height: '170px' }}>
          {props.allMusicPlaylist.map((PlaylistMobData) => {
            return (
              <>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.title}
                  onClick={() => handleExistingPlaylistMob(props.audioId, PlaylistMobData.id)}
                >
                  <div className={classes.img_title}>{PlaylistMobData.playlistName}</div>
                </Typography>
                <Divider style={{ backgroundColor: '#505050', margin: 'auto' }} />
              </>
            );
          })}
        </div>
        <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleCloseForNotification} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allMusicPlaylist: state.playlist.allMusicPlaylist,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllVideoPlaylist,
      getAllMusicPlaylist,
      postCreateMusicPlaylist,
      postExistingMusicPlaylist,
    },
    dispatch
  );
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveMusicMob));
