import { useState, useEffect } from 'react';

const useDetectBtn = (
  observedRef,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  }
) => {
  const [visible, setVisible] = useState(true);
  const callback = ([entry]) => {
    setVisible(entry.isIntersecting);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (observedRef.current) observer.observe(observedRef.current);
    return () => {
      if (observedRef.current) observer.unobserve(observedRef.current);
    };
  }, [observedRef, options]);

  return visible;
};

export default useDetectBtn;
