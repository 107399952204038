/* eslint-disable */
import cls from 'classnames';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PlayModeTip from './PlayModeTip';
import lyricIcon from '@assets/images/lyrics.svg';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
const prefix = 'react-jinke-music-player-mobile';
import cn from 'classnames';
// import { ImStarHalf as HalfStarIcon } from '@react-icons/all-files/im/ImStarHalf'
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { ellipsis, getContentPath, hmsToSeconds, secondsToHms, saveLocationUrl, getSubscription } from '../../../helpers/utils'
import PlayerSuggestions from '../PlayerSuggestions/PlayerSuggestions.jsx';
import { useState } from 'react';
import { useEffect } from 'react';
import CallerTune from '../../Music/CallerTune';
import CustomPopper from '../../common/CustomPopper/CustomPopper';
import { renderFavouritePanel } from '../../../reducers/ui';
import { postFavouriteMusic } from '../../../reducers/favourite';
import PremiumOverlay from '../../../components/common/PremiumOverlay';
import SaveMusicMob from '../../saveVideo/SaveMusicMob';
import SocialShareModalmob from '../../../containers/Modals/SocialShareModal/SocialShareModalmob';
import AddIcon from '@material-ui/icons/Add';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import {
  ShufflePlayIcon,
} from '../additional/Icon';
import LoginModal from '@containers/Modals/LoginModal';
import PremiumPayment from '@components/common/PremiumPayment';
import NotificationMessage from '../../NotificationMessage';
import { APP_CONFIG } from '@constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EN } from '@localization';
import SVGWrapper from "react-svg-wrapper";

export const CallerTuneBtn = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '.8em',
    width: '60%',
    margin: 'auto',
    borderRadius: '25px',
    lineHeight: 1.5,
    backgroundColor: '#6b6a666b',
    border: `1px solid ${theme.palette.selectedTxtClr}`,
    color: theme.palette.selectedTxtClr,
    fontWeight: 600,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: theme.palette.selectedTxtClr,
      color: 'white',
      borderColor: theme.palette.selectedTxtClr,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.selectedTxtClr,
      color: 'white',
      borderColor: theme.palette.selectedTxtClr,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  circleLoader: {
    color: theme.palette.selectedTxtClr,
    marginLeft: '45%',
    marginBottom: '15px'
  },
  circleLoader2: {
    color: theme.palette.selectedTxtClr,
    marginLeft: '45%',
    marginBottom: '25%',
    marginTop: '65%'
  },
  playbtn: {
    height: 49,
    width: 49,
    background: theme.palette.selectedTxtClr,
    borderRadius: '50%',
    position: 'relative',
    top: '-12px'

  },
  musicBtn: {
    '& .group svg': {
      color: theme.palette.selectedTxtClr,
    }
  },
  mobileCoverImage: {
    outlineColor: `${theme.palette.selectedTxtClr}60`,
  },
  lyricStyle: {
    cursor: "pointer",
    '& .lyricStyle': {
      fill: theme.palette.selectedTxtClr,
      stroke: theme.palette.selectedTxtClr,
    }
  },
  buttonThemeColor: {
    backgroundColor: theme.palette.selectedTxtClr,
    color: theme.palette.titleClr,
    '&:hover': {
      background: theme.palette.selectedTxtClr,
      color: theme.palette.titleClr,
    },
  }

}));

const PlayerMobile = ({
  name,
  cover,
  singer,
  playing,
  duration,
  currentTime,
  loading,
  themeSwitch,
  progressBar,
  openAudioListsPanel,
  audioPrevPlay,
  audioNextPlay,
  playMode,
  onClose,
  playModeTipVisible,
  currentPlayModeName,
  extendsContent,
  onPlay,
  glassBg,
  onCoverClick,
  autoHiddenCover,
  icon,
  locale,
  toggleMode,
  togglePlayMode,
  renderAudioTitle,
  shouldShowPlayIcon,
  isResetCoverRotate,
  progressHandle,
  paid,
  mobile,
  clipId,
  audioId,
  mediaId,
  genre,
  RBTStatus,
  onPause,
  showLoginNotification,
  isUserLoggedIn,
  lyricToggle,
  handleLyricToggle,
  disableSeekbar,
  serviceOffers,
  saveConsentLogsForArtistPortal,
  checkRBTStatus,
  showLyrics,
  saveConsentLogsForWelcomeTone,
  musicLoader,
  setErrorMsg,
  setErrorHeading,
  setOpenError
}, props) => {
  const [currentPlayTime, setCurrentPlayTime] = useState(currentTime);
  const [open, setOpen] = useState(true);
  const [throughLogin, setThroughLogin] = useState(false)
  const [recvAudioId, setAudioId] = useState(audioId);

  const [openCallerTune, setOpenCallerTune] = useState(false);
  const [moreOptionOpen, setMoreOptionOpen] = useState(false);
  const [showMusicPlaylist, setShowMusicPlaylist] = useState(false);
  const [mediaId2, setMediaId2] = useState('');
  const notScroll = useRef();
  const scrolled = useRef();
  const anchorRef = useRef();

  const [socialShareMob, setSocialShareMob] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const history = useHistory();

  const [disablePlayer, setDisablePlayer] = useState(false);
  const [premiumOverlay, setPremiumOverlay] = useState(false);

  const [isWelcomeToneClicked, setIsWelcomeToneClicked] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openPremiumPayment, setOpenPremiumPayment] = useState(false);

  const [showWelcomeTone, setShowWelcomeTone] = useState(true);
  const classes = useStyles();
  const mediaFreeTime = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).properties.mediaFreeTime;

  let premiumImage;
  if (APP_CONFIG.artistDetails) {
    premiumImage = APP_CONFIG.artistDetails.properties.premiumSubscribeImg;
  }

  const handleShowMusicPlaylist = (props) => {
    setShowMusicPlaylist(false)
  };

  const handleToPlaylist = (props) => {
    setShowMusicPlaylist(true)
    setSocialShareMob(false);
    setMoreOptionOpen(false)
  };

  const handlePostFavourite = (props) => {
    console.log(anchorRef.current)
    anchorRef.current(mediaId2)
    setMoreOptionOpen(false)
    setShowMusicPlaylist(false)
    setSocialShareMob(false);
  }

  const handleShare = () => {
    setSocialShareMob(true);
    setMoreOptionOpen(false);
    setShowMusicPlaylist(false)
  }

  const handleShuffleIcon = () => {
    togglePlayMode()
    setMoreOptionOpen(false);
  }

  const handleCloseShare = () => {
    setSocialShareMob(false);
    setMoreOptionOpen(false);
  }

  const passingData = [
    {
      value: 'Add to Playlist',
      icon: AddIcon,
      handleClick: handleToPlaylist,
    },
    {
      value: 'Favourite',
      icon: AddIcon,
      handleClick: handlePostFavourite
    },
    {
      value: 'Share',
      icon: ShareOutlinedIcon,
      handleClick: handleShare
    },
    {
      value: 'Shuffle',
      icon: ShufflePlayIcon,
      handleClick: handleShuffleIcon
    }
  ]

  useEffect(() => {
    setMediaId2(mediaId)
    setCurrentPlayTime(hmsToSeconds(currentTime))
  }, [duration, currentPlayTime, currentTime,])

  useEffect(() => {
    if (currentPlayTime >= mediaFreeTime && paid === 'paid') {
      if ((window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) ||
        (isUserLoggedIn && !window.localStorage.getItem('allowedServices').includes('celebrityAdmin') && !window.localStorage.getItem('allowedServices').includes('productAdmin'))) {
        if (!serviceOffers?.responseList[0]?.status) {
          console.log("in playermobile !status");
          onPause();
          if (throughLogin) {
            handleSubscriptionProcess();
            setThroughLogin(false)
          }
          setPremiumOverlay(true);
          setDisablePlayer(true);
          disableSeekbar(true);
        } else {
          console.log("in playermobile status");
          switch (serviceOffers?.responseList[0]?.status) {
            case "1" || 1: //Active
              // Do nothing
              setPremiumOverlay(false);
              setDisablePlayer(false);
              disableSeekbar(false);
              break;
            case "4" || 4: //Suspended
              onPause();
              setPremiumOverlay(true);
              setDisablePlayer(true);
              disableSeekbar(true);
              setErrorMsg(EN.SUSPENDED_MSG);
              setErrorHeading(EN.ERROR);
              setOpenError(true);
              break;
            case "5" || 5: // Grace Period
              setPremiumOverlay(false);
              setDisablePlayer(false);
              disableSeekbar(false);
              if (currentPlayTime > 5 && currentPlayTime < 7)
                showLoginNotification(EN.GRACE_PERIOD_MSG, 'warning');
              break;
            default:
              onPause();
              setPremiumOverlay(true);
              setDisablePlayer(true);
              disableSeekbar(true);
              break;
          }
        }
      } else if (!isUserLoggedIn) {
        console.log("in playermobile !isUserLoggedIn");
        onPause();
        setPremiumOverlay(true);
        setDisablePlayer(true);
        disableSeekbar(true);
      } else {
        console.log("in playermobile admin");
        setPremiumOverlay(false);
        setDisablePlayer(false);
        disableSeekbar(false);
      }
    } else {
      setPremiumOverlay(false);
      setDisablePlayer(false);
      disableSeekbar(false);
    }
  }, [duration, currentPlayTime, currentTime, serviceOffers]);

  useEffect(() => {
    console.log("genreplaylermobile", genre);
    console.log("suggestionsWithGenre", props);
    setShowWelcomeTone(true);
  }, [mediaId2])

  const handleValue = (value) => {
    setCurrentPlayTime(secondsToHms(value))
  }

  const handleClose = () => {
    setOpen(false);
    setOpenCallerTune(false);
  }

  const handleCallerTune = () => {
    saveConsentLogsForWelcomeTone(EN.FIRST_CONSENT_APPROVAL);
    setOpen(true);
    setOpenCallerTune(true);
  }

  const handleCallerTuneNonLogin = () => {
    setIsWelcomeToneClicked(true);
    setOpenLogin(true);
  }

  const handleCloseAway = () => {
    setMoreOptionOpen(false)
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMoreOptionOpen(false)
    }
  }

  const closeLoginModal = () => {
    setOpenLogin(false);
    setThroughLogin(true)
    if (isWelcomeToneClicked) {
      setIsWelcomeToneClicked(false);
      checkRBTStatus();
    }
  }

  const handleCloseForNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNoti(true);
  };

  const showLoginNotificationMusic = (message, msgType) => {
    setOpenNoti(true);
    setMsg(message);
    setMsgType(msgType);
  }

  const handleBack = () => {
    history.push('/homepage');
  }

  const handleClosePayment = () => {
    setOpenPremiumPayment(false);
    setOpenPaymentModal(false);
  }

  const handleSubscriptionProcess = () => {
    saveLocationUrl(window.location.href)
    getSubscription();
  }

  const redirectToPremiumPayment = () => {
    setOpenPremiumPayment(false);
    if ((window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) || isUserLoggedIn) {
      // log consent
      // saveConsentLogsForArtistPortal(EN.FIRST_CONSENT_APPROVAL);
      // setOpenPremiumPayment(true);
      // setOpenPaymentModal(true);
      if (!props.serviceOffers?.responseList[0]?.status) {
        handleSubscriptionProcess();
      }
    } else {
      setOpenLogin(true);
    }
  }

  const handleClosePremiumSubscribe = () => {
    console.log('nthg here ');
  }

  const handleChoosePremiumSubscribe = () => {
    // setPremiumSubscribe(false)
    if (isUserLoggedIn) {
      //log consent
      saveConsentLogsForArtistPortal(EN.FIRST_CONSENT_APPROVAL);
    }
  }
  const mediaDetails = {
    mediaId: mediaId,
    mediaTitle: renderAudioTitle(),
    mediaType: "music"
  }
  return (
    <>
      <div className={cls(prefix, { 'default-bg': !glassBg, 'glass-bg': glassBg })}>
        {musicLoader ? <CircularProgress className={classes.circleLoader2} /> :
          <>
            <div className={`${prefix}-background`} style={{ background: `url(${cover})`, backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
            <div ref={notScroll}
              style={{ flexBasis: '100%' }}
            >
              <PlayModeTip
                prefix={prefix}
                visible={playModeTipVisible}
                title={playMode}
                text={currentPlayModeName}
              />
              <div className={`${prefix}-header group`}>
                {toggleMode && (<>
                  <div className={`${prefix}-header-left`} onClick={window.location.pathname === '/musicdetail' ? handleBack : onClose}>
                    {icon.backarrow} <span style={{ position: 'relative', left: -8, fontSize: '12px' }}>Back</span>
                  </div>
                </>
                )}
              </div>
              <div className={cls('safariCircle')} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                {(!autoHiddenCover || (autoHiddenCover && cover)) && (
                  <div
                    className={cn(`${prefix}-cover text-center`, classes.mobileCoverImage)}
                    onClick={() => onCoverClick()}
                  >
                    {premiumOverlay && <div className={cls('mobile_premiumOverlayImage')} >
                      <PremiumOverlay
                        showLoginNotification={showLoginNotification}
                        isUserLoggedIn={isUserLoggedIn}
                        serviceOffers={serviceOffers}
                        saveConsentLogsForArtistPortal={saveConsentLogsForArtistPortal}
                        setOpenError={setOpenError}
                        setErrorMsg={setErrorMsg}
                        setErrorHeading={setErrorHeading}
                        mediaDetails={mediaDetails}
                      /></div>}
                    <img
                      src={getContentPath(cover)}
                      alt="cover"
                      className={cls('cover', {
                        'img-rotate-pause': !playing || !cover,
                        'img-rotate-reset': isResetCoverRotate,
                      })}
                    />
                  </div>
                )}
              </div>

              <div className={`${prefix}-seekbar`}>
                {progressBar}
                <div className={`${prefix}-seekbar-time`}>
                  <span>{currentTime}</span>
                  <span>{duration}</span>
                </div>
              </div>

              <div className={`${prefix}-title group`}>
                <span className="lyric" style={{ height: 27, width: 27 }}>
                  {showLyrics !== null &&
                    <SVGWrapper
                      className={classes.lyricStyle}
                      src={lyricIcon}
                      alt='lyrics'
                      onClick={handleLyricToggle}
                    />
                  }
                </span>
                <span className={`${prefix}-song-name`}>
                  {paid === 'paid' && (serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') && <img style={{ height: '1.8rem', width: '1.8rem' }} src={PremiumIcon} alt='PremiumIcon' />}
                  <h1 className="recev" style={{ fontSize: 26 }}>{ellipsis(renderAudioTitle(), 20)} </h1>
                  <span style={{ fontSize: 14, color: '#9E9E9E' }}>{singer}</span>
                </span>
                <div style={{ position: 'relative' }}>
                  <MoreHorizIcon onClick={() => isUserLoggedIn ? setMoreOptionOpen(true) : setOpenLogin(true)} style={{ fontSize: '27px', background: '#68625b', borderRadius: '50%', padding: '10%', cursor: 'pointer' }} />
                  {isUserLoggedIn && (
                    <div style={{ position: 'absolute', right: 95, zIndex: 9 }} >
                      <CustomPopper popperOpen={moreOptionOpen} popperRef={anchorRef} mediaId={mediaId2} handlePopperListKeyDown={handleListKeyDown} menu={passingData} handleCloseAway={handleCloseAway} />
                    </div>
                  )}
                </div>
              </div>

              <div className={cn(`${prefix}-toggle text-center group`, classes.musicBtn)}>
                <div className='gott'>
                  <span
                    className="group prev-audio"
                    title={locale.previousTrackText}
                    onClick={audioPrevPlay}
                    style={{ paddingLeft: 10 }}
                  >
                    {icon.prev}
                  </span>
                  {loading ? (
                    <span className="group loading-icon">{icon.loading}</span>
                  ) : (
                    <div className={classes.playbtn} >
                      <span
                        className="group play-btn"
                        title={
                          shouldShowPlayIcon
                            ? locale.clickToPlayText
                            : locale.clickToPauseText
                        }
                        onClick={() => { disablePlayer ? redirectToPremiumPayment() : onPlay() }}
                        style={{ pointerEvents: disablePlayer && 'none' }}
                      >
                        {shouldShowPlayIcon ? icon.mobileplay : icon.mobilepause}
                      </span>
                    </div>

                  )}
                  <span
                    className="group next-audio"
                    title={locale.nextTrackText}
                    onClick={audioNextPlay}
                    style={{ paddingRight: 10 }}
                  >
                    {icon.next}
                  </span>
                </div>
              </div>

              {/* {clipId !== null && RBTStatus === 'active' && <p style={{ textAlign: 'center' }}>Welcome Tone is already present</p>}
          {clipId !== null && RBTStatus === 'normal' && isUserLoggedIn && <CallerTuneBtn style={{ cursor: 'pointer', fontFamily: 'SegoeUIBold', color: '', marginLeft: '25%', marginBottom: '15px' }} onClick={handleCallerTune}> <span className='semibold'>Welcome Tone</span></CallerTuneBtn>}
          {clipId !== null && RBTStatus === 'inactive' && isUserLoggedIn && <CallerTuneBtn style={{ cursor: 'pointer', fontFamily: 'SegoeUIBold', color: '', marginLeft: '25%', marginBottom: '15px' }} onClick={handleCallerTune}> <span className='semibold'>Welcome Tone</span></CallerTuneBtn>} */}
              {/* {clipId!== null && RBTStatus === 'active' && <p>Caller tune is already present</p>} */}

              {clipId !== null && !isUserLoggedIn && <CallerTuneBtn style={{ fontSize: '0.875rem', cursor: 'pointer', color: '', marginLeft: '20%', marginBottom: '15px', lineHeight: '14px' }} onClick={handleCallerTuneNonLogin}> <span className='bold'>Set as Welcome Tone</span></CallerTuneBtn>}
              {clipId !== null && RBTStatus === 'active' && isUserLoggedIn && <p style={{ textAlign: 'center' }}>Welcome Tone is already present</p>}
              {showWelcomeTone && clipId !== null && RBTStatus === 'normal' && isUserLoggedIn && <CallerTuneBtn style={{ fontSize: '0.875rem', cursor: 'pointer', color: '', marginLeft: '20%', marginBottom: '15px', lineHeight: '14px' }} onClick={handleCallerTune}> <span className='bold'>Set as Welcome Tone</span></CallerTuneBtn>}
              {showWelcomeTone && clipId !== null && RBTStatus === 'inactive' && isUserLoggedIn && <CallerTuneBtn style={{ fontSize: '0.875rem', cursor: 'pointer', color: '', marginLeft: '20%', marginBottom: '15px', lineHeight: '14px' }} onClick={handleCallerTune}> <span className='bold'>Set as Welcome Tone</span></CallerTuneBtn>}
              {clipId !== null && RBTStatus === '' && isUserLoggedIn && <CircularProgress className={classes.circleLoader} />}


              {openCallerTune && RBTStatus === 'normal' && (
                <div>
                  <CallerTune
                    open={open}
                    thumbnail={cover}
                    title={renderAudioTitle()}
                    clipId={clipId}
                    handleClose={handleClose}
                    setShowWelcomeTone={setShowWelcomeTone}
                  />
                </div>
              )}
              {openCallerTune && RBTStatus === 'inactive' && (
                <div>
                  <Dialog
                    open={true}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle style={{ background: '#000000de' }} id="alert-dialog-title"></DialogTitle>
                    <DialogContent style={{ background: '#000000de' }}>
                      <DialogContentText style={{ fontFamily: 'SegoeUI !important', color: 'white' }} id="alert-dialog-description">
                        This tune is still active in your callertune library till next billing date,
                        Please try to set this tune on or after next billing date
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ background: '#000000de' }}>
                      <Button onClick={handleClose} className={classes.buttonThemeColor} autoFocus>
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}

              {/* <div className={`${prefix}-operation group`}>
            <ul className="items">
              {[playMode, icon.download, icon.reload, icon.lyric]
                .filter(isValidElement)
                .map((item) => (
                  <li className="item" key={item.props.className}>
                    {item}
                  </li>
                ))}
              {extendsContent}
              <li className="item" onClick={openAudioListsPanel}>
                {icon.playLists}
              </li>
            </ul>
          </div> */}
            </div>
          </>}
        {lyricToggle ?
          <div style={{ whiteSpace: 'pre-line', textAlign: 'center', color: '#DBDBDB', fontSize: '0.875rem', height: 320, overflowY: 'scroll' }}>
            {/* Hi! My name is John <br />
           I’m a creative geek from San Francisco, CA. I <br />
           enjoy creating eye candy solutions for web and mobile apps. <br />
           Contact me at enjoy creating eye candy solutions <br /> */}
            {showLyrics}
          </div> :
          <div ref={scrolled} >
            <PlayerSuggestions mediaIdSuggestions={mediaId} genreSuggestions={genre} />
          </div>
        }

      </div>
      {openPaymentModal && <PremiumPayment
        open={openPremiumPayment}
        thumbnail={getContentPath(premiumImage)}
        handleClose={handleClosePayment}
        loginRedirect={redirectToPremiumPayment}
        forSubscribe={false}
        first={false}
        closePremiumSubscribe={handleClosePremiumSubscribe}
        handlePremiumSubscribe={handleChoosePremiumSubscribe}
        showLoginNotification={showLoginNotificationMusic}
        isRedirect={false}
        setOpenError={setOpenError}
        setErrorMsg={setErrorMsg}
        setErrorHeading={setErrorHeading}
        mediaDetails={mediaDetails}
      />}
      {showMusicPlaylist && <SaveMusicMob handleShowMusicPlaylist={handleShowMusicPlaylist} audioId={mediaId2} />}
      {socialShareMob && <SocialShareModalmob handleCloseShare={handleCloseShare} mediaType='Music' mediaId={mediaId2} />}
      <NotificationMessage open={openNoti} msgType={msgType} msg={msg} handleClose={handleCloseForNotify} />
      <LoginModal openLogin={openLogin} setOpenLogin={setOpenLogin} closeLoginModal={closeLoginModal} showLoginNotification={showLoginNotificationMusic} setIsWelcomeToneClicked={setIsWelcomeToneClicked} />
    </>
  );
}

PlayerMobile.defaultProps = {
  icon: {},
  renderAudioTitle: () => { },
};

const mapStateToProps = state => {
  return {
    audioObject: state.ui.playSelectedAudio,
    AddToPhotosIcon: state.ui.showFavouritePanel,
    suggestionsWithGenre: state.suggestion.suggestionsWithGenre,
    serviceOffers: state.payment.serviceOffers,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    renderFavouritePanel,
    postFavouriteMusic,
    // postCreateVideoPlaylist,
    // postExistingVideoPlaylist,
  },
  dispatch
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlayerMobile)
);

// export default memo(PlayerMobile);
