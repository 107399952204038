/* eslint-disable */
export const GET_LIKEID_REQUESTED = 'LikeId/GET_LIKEID_REQUESTED';
export const GET_LIKEID_SUCCESS = 'LikeId/GET_LIKEID_SUCCESS';
export const GET_LIKEID_FAILURE = 'LikeId/GET_LIKEID_FAILURE';
export const GET_LIKEVIDEO_REQUESTED = 'LikeVideo/GET_LIKEVIDEO_REQUESTED';
export const GET_LIKEVIDEO_SUCCESS = 'LikeVideo/GET_LIKEVIDEO_SUCCESS';
export const GET_LIKEVIDEO_FAILURE = 'LikeVideo/GET_LIKEVIDEO_FAILURE';
export const POST_LIKEAPI_REQUESTED = 'LikeApi/POST_LIKEAPI_REQUESTED';
export const POST_LIKEAPI_SUCCESS = 'LikeApi/POST_LIKEAPI_SUCCESS';
export const POST_LIKEAPI_FAILURE = 'LikeApi/POST_LIKEAPI_FAILURE';

const initialState = {
    loading: false,
    loaded: false,
    error: false,
    LikeStatus: '',
    LikeVideo: [],
};

export default (state = initialState, action) => {
    console.log('action.requests', action.result);
    switch (action.type) {
        case GET_LIKEID_REQUESTED: {
            return {
                ...state,
                loading: true,
                loaded: false
            };
        }
        case GET_LIKEID_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
                LikeStatus: action.result.responseList
            };
        }
        case GET_LIKEID_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                loaded: false
            };
        }
        case GET_LIKEVIDEO_REQUESTED: {
            return {
                ...state,
                loading: true,
                loaded: false
            };
        }
        case GET_LIKEVIDEO_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
                LikeVideo: action.result.responseList
            };
        }
        case GET_LIKEVIDEO_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                loaded: false
            };
        }
        case POST_LIKEAPI_REQUESTED: {
            return {
                ...state,
                loading: true,
                loaded: false
            };
        }
        case POST_LIKEAPI_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                loaded: true,
            };
        }
        case POST_LIKEAPI_FAILURE: {
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true
            };
        }
        default:
            return state;
    }
};
// for case 1
export const getLikeIdStatus = (mediaType,mediaId) => {
    return {
        types: [GET_LIKEID_REQUESTED, GET_LIKEID_SUCCESS, GET_LIKEID_FAILURE],
        promise: (client) => client.get(`getLikeIdStatus/${mediaType}?mediaId=${mediaId}`)
    };
};

// for case 2
export const getLikeVideoList = (mediaType) => {
    return {
        types: [GET_LIKEVIDEO_REQUESTED, GET_LIKEVIDEO_SUCCESS, GET_LIKEVIDEO_FAILURE],
        promise: (client) => client.get(`getLikeVideoList/${mediaType}`)
    };
};

export const postLikeApiList = (formData) => {
    return {
        types: [POST_LIKEAPI_REQUESTED, POST_LIKEAPI_SUCCESS, POST_LIKEAPI_FAILURE],
        promise: (client) => client.post('postLikeApilist', {
            data: formData,
        })
    };
};

