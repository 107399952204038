/* eslint-disable */
export const RENDER_PROFILE_PANEL = 'ui/RENDER_PROFILE_PANEL';
export const RENDER_EDIT_PANEL = 'ui/RENDER_EDIT_PANEL';
export const RENDER_INPUT_PANEL = 'ui/RENDER_INPUT_PANEL';
export const RENDER_FEEDBACK_PANEL = 'ui/RENDER_FEEBACK_PANEL';
export const WINDOW_WIDTH = 'ui/WINDOW_WIDTH';
export const PLAY_SELECTED_VIDEO = 'ui/PLAY_SELECTED_VIDEO';
export const PLAY_SELECTED_AUDIO = 'ui/PLAY_SELECTED_AUDIO';
export const PLAY_SELECTED_AUDIOPLAYLIST = 'ui/PLAY_SELECTED_AUDIOPLAYLIST';
export const PLAY_SELECTED_AUDIOPLAYLISTMODE = 'ui/PLAY_SELECTED_AUDIOPLAYLISTMODE';
export const HANDLE_ALBUM_NAME_AND_ID = 'ui/HANDLE_ALBUM_NAME_AND_ID';
export const RENDER_COMMENT_PANEL = 'ui/RENDER_COMMENT_PANEL';
export const RENDER_FAVOURITE_PANEL = 'ui/RENDER_FAVOURITE_PANEL';
export const RENDER_PLAYLIST_PANEL = 'ui/RENDER_PLAYLIST_PANEL';
export const TRACK_POST_COMMENT = 'ui/TRACK_POST_COMMENT';
export const GENRETYPE = 'ui/GENRETYPE';
export const PARENT_COMMENTID = 'ui/PARENT_COMMENTID';
export const RENDER_SELECTED_CALLERTUNE_PANEL = 'ui/RENDER_SELECTED_CALLERTUNE_PANEL';
export const RENDER_CIRCULARPROGRESS_PANEL = 'ui/RENDER_CIRCULARPROGRESS_PANEL';
export const RENDER_SUBSCRIPTION_PANEL = 'ui/RENDER_SUBSCRIPTION_PANEL';
export const RENDER_SHARE_PANEL = 'ui/RENDER_SHARE_PANEL';
export const SEARCH_LOACTION = 'ui/SEARCH_LOACTION';

const initialState = {
  showInputPanel: false,
  showEditPanel: false,
  showProfilePanel: true,
  showFeedbackPanel: false,
  windowWidth: window.innerWidth,
  showCommentPanel: false,
  showFavouritePanel: false,
  showPlaylistPanel: false,
  statusPostComment: '',
  //  playSelectedVideo: {},
  playSelectedVideo: '',
  genreTitle: 'hip-hop',
  parentCommentId: null,
  showSelectedCallertunePanel: false,
  showCircularProgress: false,
  showSubscriptionPanel: false,
  playSelectedAudioPlaylist: [],
  playSelectedAudioPlaylistMode: 'suggestions',
  showSharePanel: false,
  handleAlbumName:'',
  handleAlbumId:'',
  searchLocation:''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case RENDER_PROFILE_PANEL: {
      return {
        ...state,
        showProfilePanel: action.payload
      };
    }
    case RENDER_EDIT_PANEL: {
      return {
        ...state,
        showEditPanel: action.payload
      };
    }
    case RENDER_INPUT_PANEL: {
      return {
        ...state,
        showInputPanel: action.payload
      };
    }
    case RENDER_FEEDBACK_PANEL: {
      return {
        ...state,
        showFeedbackPanel: action.payload
      };
    }
    case WINDOW_WIDTH: {
      return {
        ...state,
        windowWidth: action.payload
      };
    }
    case PLAY_SELECTED_VIDEO: {
      return {
        ...state,
        playSelectedVideo: action.payload
      };
    }
    case PLAY_SELECTED_AUDIO: {
      return {
        ...state,
        playSelectedAudio: action.payload
      };
    }
    case PLAY_SELECTED_AUDIOPLAYLIST: {
      console.log('ction.payload', action.payload);
      return {
        ...state,
        playSelectedAudioPlaylist: action.payload
      };
    }
    case PLAY_SELECTED_AUDIOPLAYLISTMODE: {
      console.log('ction.payload', action.payload);
      return {
        ...state,
        playSelectedAudioPlaylistMode: action.payload
      };
    }
    case HANDLE_ALBUM_NAME_AND_ID: {
      console.log('ction.payload', action.payload);
      return {
        ...state,
        handleAlbumName: action.payload.name,
        handleAlbumId: action.payload.id
      };
    }
    
    case RENDER_COMMENT_PANEL: {
      return {
        ...state,
        showCommentPanel: action.payload
      };
    }
    case RENDER_FAVOURITE_PANEL: {
      return {
        ...state,
        showFavouritePanel: action.payload
      };
    }

    case RENDER_PLAYLIST_PANEL: {
      return {
        ...state,
        showPlaylistPanel: action.payload
      };
    }
    case TRACK_POST_COMMENT: {
      return {
        ...state,
        statusPostComment: action.payload
      };
    }
    case GENRETYPE: {
      return {
        ...state,
        genreTitle: action.payload
      };
    }
    case PARENT_COMMENTID: {
      return {
        ...state,
        parentCommentId: action.payload
      };
    }
    case RENDER_SELECTED_CALLERTUNE_PANEL: {
      return {
        ...state,
        showSelectedCallertunePanel: action.payload
      };
    }
    case RENDER_CIRCULARPROGRESS_PANEL: {
      return {
        ...state,
        showCircularProgress: action.payload
      };
    }

    case RENDER_SUBSCRIPTION_PANEL: {
      return {
        ...state,
        showSubscriptionPanel: action.payload
      };
    }
    case RENDER_SHARE_PANEL: {
      return {
        ...state,
        showSharePanel: action.payload
      };
    }
    case SEARCH_LOACTION: {
      return {
        ...state,
        searchLocation: action.payload
      };
    }
    default:
      return state;
  }
};
export const renderInputPanel = (payload) => {
  return {
    type: RENDER_INPUT_PANEL,
    payload
  };
};
export const renderEditPanel = (payload) => {
  return {
    type: RENDER_EDIT_PANEL,
    payload
  };
};
export const renderProfilePanel = (payload) => {
  return {
    type: RENDER_PROFILE_PANEL,
    payload
  };
};
export const renderFeedbackPanel = (payload) => {
  return {
    type: RENDER_FEEDBACK_PANEL,
    payload
  };
};
export const setWindowWidth = (payload) => {
  return {
    type: WINDOW_WIDTH,
    payload
  };
};
export const handleSelectedVideo = (payload) => {
  return {
    type: PLAY_SELECTED_VIDEO,
    payload
  };
};

export const handleSelectedAudio = (payload) => {
  return {
    type: PLAY_SELECTED_AUDIO,
    payload
  };
};

export const handleSelectedAudioPlayList = (payload) => {
  console.log('ction.payload', payload);
  return {
    type: PLAY_SELECTED_AUDIOPLAYLIST,
    payload
  };
};

export const handleSelectedAudioPlayListMode = (payload) => {
  console.log('ction.payload', payload);
  return {
    type: PLAY_SELECTED_AUDIOPLAYLISTMODE,
    payload
  };
};

export const handleAlbumNameAndId = (albumName, albumId) => {
  const payload = {
    name: albumName,
    id: albumId
  };
  console.log('ction.payload', payload);
  return {
    type: HANDLE_ALBUM_NAME_AND_ID,
    payload
  };
};

export const renderCommentPanel = (payload) => {
  return {
    type: RENDER_COMMENT_PANEL,
    payload
  };
};

export const renderFavouritePanel = (payload) => {
  return {
    type: RENDER_FAVOURITE_PANEL,
    payload
  };
};

export const renderPlaylistPanel = (payload) => {
  return {
    type: RENDER_PLAYLIST_PANEL,
    payload
  };
};

export const toTrackPostComment = (payload) => {
  return {
    type: TRACK_POST_COMMENT,
    payload
  };
};
export const renderGenrePanel = (payload) => {
  return {
    type: GENRETYPE,
    payload
  };
};
export const setParentCommentId = (payload) => {
  return {
    type: PARENT_COMMENTID,
    payload
  };
};
export const renderSelectedCallerTunePanel = (payload) => {
  return {
    type: RENDER_SELECTED_CALLERTUNE_PANEL,
    payload
  };
};
export const renderCircularProgress = (payload) => {
  return {
    type: RENDER_CIRCULARPROGRESS_PANEL,
    payload
  };
};
export const renderSubscriptionPanel = (payload) => {
  return {
    type: RENDER_SUBSCRIPTION_PANEL,
    payload
  };
};

export const renderSharePanel = (payload) => {
  return {
    type: RENDER_SHARE_PANEL,
    payload
  };
};

export const trackSearchPath = (payload) => {
  return {
    type: SEARCH_LOACTION,
    payload
  };
};