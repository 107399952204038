/* eslint-disable */
export const GETCOMMENT_REQUESTED = 'comment/GETCOMMENT_REQUESTED';
export const GETCOMMENT_SUCCESS = 'comment/GETCOMMENT_SUCCESS';
export const GETCOMMENT_FAILURE = 'comment/GETCOMMENT_FAILURE';

export const POSTCOMMENT_REQUESTED = 'comment/POSTCOMMENT_REQUESTED';
export const POSTCOMMENT_SUCCESS = 'comment/POSTCOMMENT_SUCCESS';
export const POSTCOMMENT_FAILURE = 'comment/POSTCOMMENT_FAILURE';

export const GETREPLY_REQUESTED = 'comment/GETREPLY_REQUESTED';
export const GETREPLY_SUCCESS = 'comment/GETREPLY_SUCCESS';
export const GETREPLY_FAILURE = 'comment/GETREPLY_FAILURE';

export const POSTREPLY_REQUESTED = 'comment/POSTREPLY_REQUESTED';
export const POSTREPLY_SUCCESS = 'comment/POSTREPLY_SUCCESS';
export const POSTREPLY_FAILURE = 'comment/POSTREPLY_FAILURE';

export const GETDROPDOWNCOMMENTLIST_REQUESTED = 'comment/GETDROPDOWNCOMMENTLIST_REQUESTED';
export const GETDROPDOWNCOMMENTLIST_SUCCESS = 'comment/GETDROPDOWNCOMMENTLIST_SUCCESS';
export const GETDROPDOWNCOMMENTLIST_FAILURE = 'comment/GETDROPDOWNCOMMENTLIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  commentList: [],
  replyList: [],
  dropDownDataList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GETCOMMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GETCOMMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        commentList: action.result.responseList
      };
    }
    case GETCOMMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case POSTCOMMENT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case POSTCOMMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case POSTCOMMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GETREPLY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GETREPLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        replyList: action.result.responseList
      };
    }
    case GETREPLY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case POSTREPLY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case POSTREPLY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case POSTREPLY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GETDROPDOWNCOMMENTLIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GETDROPDOWNCOMMENTLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        dropDownDataList: action.result.responseList
      };
    }
    case GETDROPDOWNCOMMENTLIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const getCommentListVideo = (mediaType, mediaId, pageNo, pageSize) => {
  return {
    types: [GETCOMMENT_REQUESTED, GETCOMMENT_SUCCESS, GETCOMMENT_FAILURE],
    promise: (client) => client.get(`getCommentListVideo/${mediaType}?mediaId=${mediaId}&pageNo=${pageNo}&pageSize=${pageSize}`, {
    })
  };
};

export const postComment = (formData) => {
  return {
    types: [POSTCOMMENT_REQUESTED, POSTCOMMENT_SUCCESS, POSTCOMMENT_FAILURE],
    promise: (client) => client.post('postComment', {
      data: formData,
    })
  };
};

export const getReplyList = (commentId, pageNo, pageSize) => {
  return {
    types: [GETREPLY_REQUESTED, GETREPLY_SUCCESS, GETREPLY_FAILURE],
    promise: (client) => client.get(`getReplyList?commentId=${commentId}&pageNo=${pageNo}&pageSize=${pageSize}`, {
    })
  };
};

export const postReply = (formData) => {
  return {
    types: [POSTREPLY_REQUESTED, POSTREPLY_SUCCESS, POSTREPLY_FAILURE],
    promise: (client) => client.post('postReply', {
      data: formData,
    })
  };
};

export const getDropDownCommentList = () => {
  return {
    types: [GETDROPDOWNCOMMENTLIST_REQUESTED, GETDROPDOWNCOMMENTLIST_SUCCESS, GETDROPDOWNCOMMENTLIST_FAILURE],
    promise: (client) => client.get(`getDropDownCommentList`, {
    })
  };
};