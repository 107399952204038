/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, useMediaQuery, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserDetails } from '../../reducers/otp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { generateOtp, verifyOtp, oAuth, googleLogin } from '../../reducers/otp';
import { postRBTcallerturnePayment } from '../../reducers/rbt';
import { saveconsentLog } from '../../reducers/payment';
import Modal from '@material-ui/core/Modal';
import { APP_CONFIG } from '@constants';
import MobileOtp from '../common/MobileOtp';
import { getContentPath, getUtagDataForLogin } from '../../helpers/utils';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { EN } from '@localization';

const useStyles = makeStyles((theme) => ({
    top: {
        margin: 'auto',
        marginTop: '3%',
        width: '95%',
        borderRadius: '10px',
        background: '#00000099',
        borderRadius: '20px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backdropFilter: 'blur(50px)',
        filter: 'drop-shadow(6px 6px 20px #000000)',
        [theme.breakpoints.up('1024')]: {
            height: 'auto',
            minHeight: '50vh',
            width: '35%',
        },
        '@media (min-height: 720px) and (max-height: 900px)': {
            marginTop: '3%',
        },
        '@media (min-height: 901px) and (max-height: 1080px)': {
            marginTop: '9%',
        },
    },
    mobiletop: {
        margin: 'auto',
        marginTop: '30%',
        width: '95%',
        borderRadius: '10px',
        background: '#00000099',
        borderRadius: '20px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backdropFilter: 'blur(50px)',
        filter: 'drop-shadow(6px 6px 20px #000000)',
    },
    btns: {
        width: '100%',
        gap: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        [theme.breakpoints.down('1410')]: {
            marginTop: '0vh',
        },
        marginTop: '5vh',
        marginBottom: '2vh',
    },
    payBtn: {
        borderRadius: '25px',
        backgroundColor: '#E1E1E1',
        '&:hover': {
            backgroundColor: '#E1E1E1',
        },
        color: '#000000',
        width: '80%',
        margin: 'auto',
        height: '50px',
        textTransform: 'capitalize',
        fontSize: '1.125rem',
        opacity: '100%'
    },
    cancelBtn: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        color: '#959595',
        width: '100%',
        margin: 'auto',
        height: '50px',
        textTransform: 'capitalize',
        fontSize: '1.125rem',
        opacity: '100%',
        cursor: 'pointer'
    },
    songImg: {
        height: '10vw',
        width: '24vw',
        [theme.breakpoints.down('1410')]: {
            marginTop: '30px',
        },
        [theme.breakpoints.down('1024')]: {
            height: '15vh',
            width: '75vw',
            borderRadius: '8px'
        },
        borderRadius: '8px',
        objectFit: 'cover'
    },
    firstData: {
        color: theme.palette.titleClr,
        opacity: '71%',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.25rem'
        },
        fontSize: '1.875rem',

        [theme.breakpoints.down('1410')]: {
            marginTop: '45px'
        },
        marginTop: '40px'

    },
    buttonThemeColor: {
        backgroundColor: theme.palette.selectedTxtClr,
        color: theme.palette.titleClr,
        '&:hover': {
            background: theme.palette.selectedTxtClr,
            color: theme.palette.titleClr,
        },
    },
    thankYou: {
        color: theme.palette.selectedTxtClr,
        fontSize: '1rem',
        fontWeight: 900,
        opacity: '100%'
    }
}));

const PaymentData = {

    FirstData: 'Get this Welcome Tone',
    SecondData: 'By pressing confirm, you agree to set this Welcome Tone for R5 per month.',
    thirdData: `Terms and Conditions apply`,
    // SecondData: 'By pressing confirm you agree to subscribe to celebrity connect premium at R3/Day. First day free',
    confirmationMsg: 'Your Welcome Tone has been set, you will receive a confirmation SMS shortly.'

}

const CallerTune = (props) => {
    const classes = useStyles();
    const [paymentDone, setPaymentDone] = useState(false);
    const [checkMobNum, setCheckMobNum] = useState('');
    const mobileView = useMediaQuery('(max-width:1024px)');
    const [phoneNo, setPhoneNo] = useState(0);
    const [otp, setOtpNo] = useState('');
    const [OTPCorrect, setOTPCorrect] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [APIMSG, setAPIMSG] = useState('');
    const [isOtpScreen, setIsOtpScreen] = useState(false);
    const [loginFlow, setLoginFlow] = useState(false)

    let welcomeToneImg;
    if (APP_CONFIG.artistDetails) {
        welcomeToneImg = APP_CONFIG.artistDetails.properties.welcomeToneImg;
    }

    useEffect(() => {
        props.getUserDetails().then((result) => {
            if (loginFlow) {
                getUtagDataForLogin(result.responseList[0]?.userSurrogateId);
            }
            // setCheckMobNum(result.responseList && result.responseList[0].mobileNumber);
            if (result.responseList[0].mobileNumber === null) {
                setCheckMobNum(false);
            }
            else {
                setCheckMobNum(true);
            }
        })
        setLoginFlow(false);
    }, []);

    const getAcrOrMob = () => {
        let acrOrMob = '';
        if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
            acrOrMob = window.localStorage.getItem('phone');
        } else if (window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) {
            acrOrMob = window.sessionStorage.getItem('he_acr_value');
        }
        return acrOrMob;
    }

    const saveConsentLogsForWelcomeTone = (message) => {
        let acrOrMob = getAcrOrMob();
        if (acrOrMob) {
            const serviceUrl = window.location.href;
            props.saveconsentLog(encodeURIComponent(acrOrMob), EN.CONSENT_LOG_WELCOME_TONE_SERVICE, serviceUrl, message);
        }
    }

    const rejectPayment = () => {
        saveConsentLogsForWelcomeTone(EN.SECOND_CONSENT_REJECTION);
        props.handleClose();
    }

    const checkPayment = () => {
        // setPaymentDone(true);
        saveConsentLogsForWelcomeTone(EN.SECOND_CONSENT_APPROVAL);
        if (window.localStorage.getItem('phone')) {
            props.postRBTcallerturnePayment(encodeURIComponent(window.localStorage.getItem('phone')), props.clipId).then((result) => {

                if (result.message.toUpperCase() === "SUCCESS") {
                    props.setShowWelcomeTone(false);
                    setPaymentDone(true);
                    setSuccessMsg(true);
                }
                else if (result.message.toUpperCase() === "INVALID_PREFIX") {
                    setAPIMSG(EN.INVALID_USER_ERROR_MSG_FOR_SET_TUNE);
                    setPaymentDone(true);
                    setSuccessMsg(false);
                } else if (result.message.toUpperCase().includes('SUSPENDED')) {
                    setAPIMSG(EN.SUSPENDED_USER_ERROR_MSG_FOR_SET_TUNE);
                    setPaymentDone(true);
                    setSuccessMsg(false);
                } else {
                    setAPIMSG(EN.SOMETHING_WENT_WRONG);
                    setPaymentDone(true);
                    setSuccessMsg(false);
                }
            })
        }
    };

    const getOauthToken = userData => {
        const oAuthData = {
            username: userData,
            password: '',
        };
        props
            .oAuth(oAuthData)
            .then(data => {
                console.log('authData', data);
                setLoginFlow(true)
                window.localStorage.setItem('access_token', data.access_token);
                window.localStorage.setItem('allowedServices', data.allowedServices);
            })
            .catch(error => {
                console.log('erroraccess');
            });
    };

    const setPhoneNumber = e => {
        setPhoneNo(e.target.value);
    };

    const handleGetOPT = () => {
        const data = {
            mobileNumber: Number(phoneNo),
        };
        props
            .generateOtp(data)
            .then(data => {
                // setVerifyFields(true);
                // setLoginFields(false);
            })
            .catch(error => {
                console.log('execute', error);
            });
    };

    const verifyClick = () => {
        const data = {
            mobileNumber: Number(phoneNo),
            otp: Number(otp),
        };
        props.verifyOtp(data).then(data => {
            getOauthToken(phoneNo);
            alert("OTP is correct");
            setOTPCorrect(true);
            setCheckMobNum(true);
            console.log('data', data);

        }).catch(error => {
            alert("Opps!! OTP is Incorrect");
            console.log('errrr', error);
        });
    };

    // const checkOTPstatus = () => {
    //     if (OTPCorrect) {
    //         setCheckMobNum(true);
    //     } else {
    //         alert("Opps!! OTP is Incorrect");
    //     }
    // }

    const handleClose = () => {
        setOpen(false);
        setOpenCallerTune(false);
    }

    const handleCloseFn = () => {
        setIsOtpScreen(false);
        props.handleClose();
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                BackdropProps={{
                    style: {
                        backdropFilter: 'blur(7px)',
                        // backgroundColor: '#707070',
                        // opacity: '60%',
                        boxShadow: 'none',
                    },
                }}
            >
                <div className={mobileView ? classes.mobiletop : classes.top}>
                    {checkMobNum === false &&
                        <MobileOtp
                            setPhoneNumber={setPhoneNumber}
                            handleGetOPT={() => handleGetOPT()}
                            setOtpNo={setOtpNo}
                            verifyClick={verifyClick}
                            // checkOTPstatus={checkOTPstatus}
                            handleCloseFn={handleCloseFn}
                            otp={otp}
                            phoneNo={phoneNo}
                            mainHead="WELCOME TONE PAYMENT"
                            isOtpScreen={isOtpScreen}
                        />}
                    {(!paymentDone && checkMobNum) && (<>
                        <div style={{ marginTop: mobileView ? '0px' : '65px' }}>
                            <img className={classes.songImg} src={getContentPath(welcomeToneImg)} alt="img" />
                        </div>
                        <div className={classes.firstData}>
                            <span className='bold'>{PaymentData.FirstData}</span>
                        </div>
                        <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '30px 25px 0px 25px' : '15px 60px 20px 60px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>
                            {PaymentData.SecondData}
                        </div>
                        <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '30px 25px 30px 25px' : '15px 60px 20px 60px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>
                            {PaymentData.thirdData}
                        </div>
                        <div className={classes.btns}>
                            <Button
                                className={classes.payBtn}
                                variant="contained"
                                color="secondary"
                                onClick={checkPayment}
                            >
                                <span className='bold'>CONFIRM</span>
                            </Button>
                            <div
                                className={classes.cancelBtn}
                                variant="contained"
                                onClick={rejectPayment}
                            >
                                <span className='bold'>NO THANKS</span>
                            </div>
                        </div>
                    </>)}

                    {(paymentDone && checkMobNum && successMsg) && (<>
                        <HighlightOffIcon style={{ fontSize: '40px', marginLeft: '90%', cursor: 'pointer', color: 'grey', marginTop: '25px', marginRight: '25px' }}
                            onClick={props.handleClose} />
                        <div>
                            <img className={classes.songImg} src={getContentPath(welcomeToneImg)} alt="img" />
                        </div>
                        <div style={{ color: '#dcdcdc', fontSize: '2.25rem', marginTop: '50px', opacity: '100%' }}><span className='bold'>Thank You</span></div>
                        {/* <div style={{ color: '#dcdcdc', fontSize: '0.875rem', opacity: '56%' }}>All Premium Content</div> */}
                        <div className={classes.thankYou} style={{ marginBottom: mobileView ? '3vh' : '4vh' }}><CheckCircleIcon style={{ marginRight: '10px' }} />You have subscribed successfully</div>
                        <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '0px 30px 45px' : '0px 50px 60px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>{PaymentData.confirmationMsg}</div>
                    </>)}
                    {(paymentDone && checkMobNum && !successMsg) && (<div>
                        <Dialog

                            open={true}
                            // onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle style={{ background: '#000000de' }} id="alert-dialog-title"></DialogTitle>
                            <DialogContent style={{ background: '#000000de' }}>
                                <DialogContentText style={{ fontFamily: 'SegoeUI !important', color: 'white' }} id="alert-dialog-description">
                                    {APIMSG}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ background: '#000000de' }}>
                                <Button onClick={props.handleClose} className={classes.buttonThemeColor} autoFocus>
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    )}
                </div>
            </Modal>
        </>
    );
};
const mapStateToProps = state => {
    return {
        userDetails: state.otp.userData,
        rbtCallertunePayment: state.rbt.rbtCallertunePayment,
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getUserDetails,
    generateOtp,
    verifyOtp,
    oAuth,
    googleLogin,
    postRBTcallerturnePayment,
    saveconsentLog,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CallerTune));


