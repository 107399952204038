/* eslint-disable */
export const MUSIC_LIST_REQUESTED = 'music/MUSIC_LIST_REQUESTED';
export const MUSIC_LIST_SUCCESS = 'music/MUSIC_LIST_SUCCESS';
export const MUSIC_LIST_FAILURE = 'music/MUSIC_LIST_FAILURE';

export const MUSICMOSTVIEWED_REQUESTED = 'music/MUSICMOSTVIEWED_REQUESTED';
export const MUSICMOSTVIEWED_SUCCESS = 'music/MUSICMOSTVIEWED_SUCCESS';
export const MUSICMOSTVIEWED_FAILURE = 'music/MUSICMOSTVIEWED_FAILURE';

export const MUSICBYGENRE_REQUESTED = 'music/MUSICBYGENRE_REQUESTED';
export const MUSICBYGENRE_SUCCESS = 'music/MUSICBYGENRE_SUCCESS';
export const MUSICBYGENRE_FAILURE = 'music/MUSICBYGENRE_FAILURE';

export const MUSICGENRELIST_REQUESTED = 'music/MUSICGENRELIST_REQUESTED';
export const MUSICGENRELIST_SUCCESS = 'music/MUSICGENRELIST_SUCCESS';
export const MUSICGENRELIST_FAILURE = 'music/MUSICGENRELIST_FAILURE';

export const GENRE_CATEGORY_LIST_REQUESTED = 'music/GENRE_CATEGORY_LIST_REQUESTED';
export const GENRE_CATEGORY_LIST_SUCCESS = 'music/GENRE_CATEGORY_LIST_SUCCESS';
export const GENRE_CATEGORY_LIST_FAILURE = 'music/GENRE_CATEGORY_LIST_FAILURE';

export const MUSICMOSTVIEWED_LIST_EMPTY = 'music/MUSICMOSTVIEWED_LIST_EMPTY';
export const LATEST_MUSIC_LIST_EMPTY = 'music/LATEST_MUSIC_LIST_EMPTY';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  musicDataList: [],
  musicMostViewed: [],
  musicGenreList: [],
  musicByGenre: [],
  genreCategoryList: [],
  musicDataListCount:0,
  musicMostViewedListCount:0,
  musicGenreListCount:0,
  musicByGenreListCount:0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MUSIC_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case MUSIC_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicDataList: [...state.musicDataList, ...action.result.responseList],
        musicDataListCount:action.result.totalCount
      };
    }
    case MUSIC_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case MUSICMOSTVIEWED_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case MUSICMOSTVIEWED_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicMostViewed: [...state.musicMostViewed, ...action.result.responseList],
        musicMostViewedListCount:action.result.totalCount

      };
    }
    case MUSICMOSTVIEWED_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case MUSICBYGENRE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case MUSICBYGENRE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicByGenre: action.result.responseList,
        musicByGenreListCount:action.result.totalCount

      };
    }
    case MUSICBYGENRE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case MUSICGENRELIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case MUSICGENRELIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicGenreList: action.result,
        musicGenreListCount:action.result.totalCount
      };
    }
    case MUSICGENRELIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case GENRE_CATEGORY_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GENRE_CATEGORY_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        genreCategoryList: action.result,
        // musicGenreListCount:action.result.totalCount
      };
    }
    case GENRE_CATEGORY_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case MUSICMOSTVIEWED_LIST_EMPTY: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicMostViewed: []
      };
    }
    case LATEST_MUSIC_LIST_EMPTY: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicDataList: []
      };
    }
    default:
      return state;
  }
};

export const getMusicList = (pageNo,pageSize,contentType,searchKey, filterBy) => {
  let filter = 'live';
  if(filterBy !== undefined) {
    filter = filterBy;
  }
  return {
    types: [MUSIC_LIST_REQUESTED, MUSIC_LIST_SUCCESS, MUSIC_LIST_FAILURE],
    promise: (client) => client.get(`getMusicList?pageNo=${pageNo}&pageSize=${pageSize}&contentType=${contentType}&search=${searchKey}&filterBy=${filter}`, {
    })
 };
};

export const getMusicMostViewed = (pageNo,pageSize) => {
return {
  types: [MUSICMOSTVIEWED_REQUESTED,MUSICMOSTVIEWED_SUCCESS, MUSICMOSTVIEWED_FAILURE],
  promise: (client) => client.get(`getMusicMostViewed?pageNo=${pageNo}&pageSize=${pageSize}`, {
  })
};
};

export const getMusicByGenre = (pageNo,pageSize,contentType,genre) => {
  return {
    types: [MUSICBYGENRE_REQUESTED, MUSICBYGENRE_SUCCESS, MUSICBYGENRE_FAILURE],
    promise: (client) => client.get(`getMusicByGenre?pageNo=${pageNo}&pageSize=${pageSize}&contentType=${contentType}&genre=${genre}`, {
    })
  };
};

export const getMusicGenreList = (status, isUploadPage) => {
  const thresholdValue = isUploadPage ? '' : 'below';
  return {
    types: [MUSICGENRELIST_REQUESTED, MUSICGENRELIST_SUCCESS, MUSICGENRELIST_FAILURE],
    promise: (client) => client.get(`getMusicGenreList?filterBy=live&contentType=regular&aboveOrBelow=${thresholdValue}`, {
    })
  };
};

export const getGenreCategoryList = (status) => {
  return {
    types: [GENRE_CATEGORY_LIST_REQUESTED, GENRE_CATEGORY_LIST_SUCCESS, GENRE_CATEGORY_LIST_FAILURE],
    promise: (client) => client.get(`getGenreCategoryList?filterBy=live&contentType=regular`, {
    })
  };
};

export const emptyMusicMostViewedList = () => {
  return {
    type: MUSICMOSTVIEWED_LIST_EMPTY
  };
};

export const emptyLatestMusicList = () => {
  return {
    type: LATEST_MUSIC_LIST_EMPTY
  };
};