/* eslint-disable */
import { Grid, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import image1 from '@assets/images/Image1.png';
import image4 from '@assets/images/Image4.png';
import { useHistory } from 'react-router-dom';

const Welcome = () => {
    const history = useHistory();
    const mobileView = useMediaQuery('(max-width:1024px)');

    useEffect(() => {
        console.log("calle")
        setTimeout(() => {
            (history.push('./homepage'))
        }, 2000);
    }, []);

    return (
        <Grid  style={{ height: '100vh', width: '100vw', backgroundColor: 'black' }}>
            <div style={{ paddingTop: '30vh', marginBottom: '43vh',display:'flex',justifyContent:'center',paddingLeft:mobileView? '0px':'10vw' }}>
                <img src={image4} />
            </div>
            <div style={{display:'flex',justifyContent:'center',paddingLeft:mobileView? '0px':'15vw'}}>
                <img src={image1} />
            </div>
        </Grid>
    )
}
export default Welcome;