/* eslint-disable */
import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CancelCircleIcon from '@material-ui/icons/Cancel';
import Modal from '@material-ui/core/Modal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    top: {
        margin: 'auto',
        marginTop: '3%',
        borderRadius: '10px',
        background: '#00000099',
        borderRadius: '20px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backdropFilter: 'blur(50px)',
        filter: 'drop-shadow(6px 6px 20px #000000)',
        [theme.breakpoints.between('1024','1599')]: {
            height: 'auto',
            minHeight: '50vh',
            width: '35%',
        },
        [theme.breakpoints.up('1600')]: {
            height: 'auto',
            minHeight: '50vh',
            width: '27%',
        },
        '@media (min-height: 720px) and (max-height: 900px)': {
            marginTop: '3%',
        },
        '@media (min-height: 901px) and (max-height: 1080px)': {
            marginTop: '9%',
        },
    },
    mobiletop: {
        margin: 'auto',
        marginTop: '25%',
        width: '95%',
        borderRadius: '10px',
        background: '#00000099',
        borderRadius: '20px',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backdropFilter: 'blur(50px)',
        filter: 'drop-shadow(6px 6px 20px #000000)',
    },
    errorBtn: {
        color: theme.palette.selectedTxtClr,
        fontSize: '1.5rem',
        fontWeight: 900,
        opacity: '100%'
    },
}));

const ErrorModal = (props) => {
    const classes = useStyles();
    const mobileView = useMediaQuery('(max-width:1024px)');

    return (
        <>
            <Modal
                open={props.openError}
                onClose={() => props.setOpenError(false)}
                BackdropProps={{
                    style: {
                        backdropFilter: 'blur(7px)',
                        boxShadow: 'none',
                    },
                }}
            >
                <div className={mobileView ? classes.mobiletop : classes.top}>
                <>
                        <HighlightOffIcon style={{ fontSize: '40px', marginLeft: '90%', cursor: 'pointer', color: 'grey', marginTop: '25px', marginRight: '25px' }}
                            onClick={() => props.setOpenError(false)} />
                        <div className={classes.errorBtn} style={{ marginBottom: mobileView ? '3vh' : '4vh'}}><CancelCircleIcon style={{ marginRight: '10px' }} />{props.errorHeading}</div>
                        <div style={{ color: '#fff', fontSize: mobileView ? '0.875rem' : '1.25rem', padding: mobileView ? '0px 30px 45px' : '0px 50px 60px', lineHeight: '27px', opacity: '100%', textAlign: 'center' }}>{props.errorMsg}</div>
                    </>
                </div>
            </Modal>
        </>
    );
};
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorModal));


