//  This page Functionality to login the user
import React, { useState, useEffect } from 'react';
import {
  Button,
  makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

// custom components
import { useHistory } from 'react-router-dom';
import { EN } from '@localization';
import Backdrop from '@material-ui/core/Backdrop';
import { APP_CONFIG } from '@constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorModal from '@containers/Modals/ErrorModal';
import NotificationMessage from '../NotificationMessage';
import { getContentPath, getUtagDataForSubscription } from '../../helpers/utils';
import { evinaAndCharge, saveconsentLog } from '../../reducers/payment';

const useStyles = makeStyles((theme) => ({

  MainbackGround: {
    position: 'fixed',
    overflowY: 'scroll',
    margin: 'auto',
    backgroundColor: 'black',
    width: '100%',
    minWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  maindiv: {
    position: 'absolute',
    top: '43%',
    width: '100vw',
    [theme.breakpoints.up('1024')]: {
      width: '500px',
    },
  },
  backImg: {
    backgroundRepeat: 'no-repeat',
    color: theme.palette.titleClr,
    height: '100vh',
    [theme.breakpoints.down('1024')]: {
      width: '412px',
    },
    width: '500px',
    backgroundSize: 'cover',
  },
  link: {
    fontSize: '32px',
    color: theme.palette.selectedTxtClr,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '28px',
    margin: '4vh 0px 0px 8px',
    lineHeight: '25px',
    display: 'flex',
    justifyContent: 'center'
  },
  ButtonStyle: {
    maxWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  confirmBtn: {
    margin: '3vh 0 0 12px',
    borderRadius: '50px',
    background: theme.palette.selectedTxtClr,
    color: theme.palette.titleClr,
    height: '50px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.selectedTxtClr,
      color: theme.palette.titleClr,
    },
    fontSize: '16px',
  },
  cancelBtn: {
    margin: '3vh 0 0 12px',
    borderRadius: '50px',
    background: 'grey',
    color: theme.palette.titleClr,
    height: '50px',
    textTransform: 'none',
    '&:hover': {
      background: 'grey',
      color: theme.palette.titleClr,
    },
    fontSize: '16px',
  },
  note: {
    fontSize: '18px',
    margin: '30px 5px 0px 12px',
    lineHeight: '22px',
  },
  loaderbackdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  circleLoader: {
    color: theme.palette.selectedTxtClr,
  }

}));

function AdsConfirm(props) {
  const content = {
    title: 'Confirm your subscription',
    link: 'celebrityconnect.co.za',
    note: window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).properties.advConfirmMsg
  };
  const classes = useStyles();
  const history = useHistory();
  const serviceUrl = window.location.href;
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorHeading, setErrorHeading] = useState('');
  const [openNoti, setOpenNoti] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const params = new URLSearchParams(window.location.search);
  const [loader, setLoader] = useState(false);

  let advImage;
  if (APP_CONFIG.artistDetails && params.get('campaignType') === '1') {
    advImage = APP_CONFIG.artistDetails.properties.campaignType1Img;
  } else if (params.get('campaignType') === '2') {
    advImage = APP_CONFIG.artistDetails.properties.campaignType2Img;
  }

  useEffect(() => {
    if (window.sessionStorage.getItem('is_he_user') === 'false' && !props.isUserLoggedIn) {
      if (params.get('campaignType')) {
        history.push(`./DMLandingPage?${window.location.href.split('?')[1]}`);
      } else {
        history.push('./DMLandingPage');
      }
    }
  });

  const calls = (w, d, s, l, i) => {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || []; w[l].push({
      'gtm.start':

        new Date().getTime(),
      event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];

    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`; f.parentNode.insertBefore(j, f);
  };

  useEffect(() => {
    calls(window, document, 'script', 'dataLayer', 'GTM-KSN4S23');
  }, []);

  const showNotification = (message, msgtype) => {
    setOpenNoti(true);
    setMsg(message);
    setMsgType(msgtype);
  };

  useEffect(() => {
    if (props.serviceOffers?.responseList[0]?.status) {
      switch (props.serviceOffers?.responseList[0]?.status) {
        case '1' || 1: // Active
          history.push('/homepage');
          break;
        case '4' || 4: // Suspended
          setErrorMsg(EN.SUSPENDED_MSG);
          setErrorHeading(EN.ERROR);
          setOpenError(true);
          break;
        case '5' || 5: // Grace Period
          showNotification(EN.GRACE_PERIOD_MSG, 'warning');
          break;
        default:
          showNotification(EN.CONTACT_CC_MSG, 'error');
          break;
      }
    }
  }, [props.serviceOffers]);

  const handleCloseForNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNoti(false);
  };

  const getAcrOrMob = () => {
    let acrOrMob = '';
    if (window.localStorage.getItem('phone') && window.localStorage.getItem('countryCode')) {
      acrOrMob = window.localStorage.getItem('phone');
    } else if (window.sessionStorage.getItem('is_he_user') === 'true' && window.sessionStorage.getItem('he_acr_value')) {
      acrOrMob = window.sessionStorage.getItem('he_acr_value');
    }
    return acrOrMob;
  };

  const mediaDetails = {
    mediaType: 'campaign',
  };

  const handleConfirm = () => {
    setLoader(true);
    const acrOrMob = getAcrOrMob();
    if (acrOrMob) {
      // log consent
      props.saveconsentLog(acrOrMob, EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, EN.SECOND_CONSENT_APPROVAL);
    }
    let longPackageId = '';
    let shortPackageId = '';
    if (props.serviceOffers?.responseList?.length === 1) {
      if (props.serviceOffers?.responseList[0]?.longPackageId) {
        longPackageId = props.serviceOffers?.responseList[0]?.longPackageId;
        shortPackageId = props.serviceOffers?.responseList[0]?.shortPackageId ? props.serviceOffers?.responseList[0]?.shortPackageId : '';
      } else if (props.serviceOffers?.responseList[0]?.status === '4') {
        setErrorMsg(EN.SUSPENDED_MSG);
        setErrorHeading(EN.ERROR);
        setOpenError(true);
        setLoader(false);
      } else if (props.serviceOffers?.responseList[0]?.status !== '1' && props.serviceOffers?.responseList[0]?.status !== '5') {
        setLoader(false);
        showNotification(EN.CONTACT_CC_MSG, 'error');
      }
    } else if (props.serviceOffers?.responseList?.length === 2) {
      if (props.serviceOffers?.responseList[0]?.promoCode === 'TRIAL') {
        if (props.serviceOffers?.responseList[0]?.longPackageId) {
          longPackageId = props.serviceOffers?.responseList[0]?.longPackageId;
          shortPackageId = props.serviceOffers?.responseList[0]?.shortPackageId ? props.serviceOffers?.responseList[0]?.shortPackageId : '';
        }
      } else if (props.serviceOffers?.responseList[1]?.promoCode === 'TRIAL') {
        if (props.serviceOffers?.responseList[1]?.longPackageId) {
          longPackageId = props.serviceOffers?.responseList[1]?.longPackageId;
          shortPackageId = props.serviceOffers?.responseList[1]?.shortPackageId ? props.serviceOffers?.responseList[1]?.shortPackageId : '';
        }
      }
    } else {
      setLoader(false);
      showNotification(EN.CONTACT_CC_MSG, 'error');
    }
    if (longPackageId !== '' && acrOrMob) {
      props.evinaAndCharge(encodeURIComponent(acrOrMob), longPackageId, shortPackageId, EN.DIGITAL_MARKETING, serviceUrl).then((data) => {
        setLoader(false);
        getUtagDataForSubscription(mediaDetails, data.message.replace(/[^0-9]/g, ''));
        if (params.get('campaignType')) {
          history.push(`./DMThankPage?${window.location.href.split('?')[1]}`);
        } else {
          history.push('./DMThankPage');
        }
      }).catch((error) => {
        setLoader(false);
        if (error.errorMessage.toUpperCase().includes('INSUFFICIENT_FUNDS')) {
          setErrorMsg(EN.INSUFFICIENT_BALANCE_MSG);
          setErrorHeading(EN.PAYMENT_FAILED);
          setOpenError(true);
        } else if (error.errorMessage.toUpperCase().includes('USER_INVALID')) {
          showNotification(EN.INVALID_USER_ERROR_MSG, 'error');
        } else {
          showNotification(EN.SOMETHING_WENT_WRONG, 'error');
        }
      });
    }
  };

  const handleCancel = () => {
    const acrOrMob = getAcrOrMob();
    props.saveconsentLog(acrOrMob, EN.CONSENT_LOG_ARTIST_PORTAL_SERVICE, serviceUrl, EN.SECOND_CONSENT_REJECTION);
    history.goBack();
  };

  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KSN4S23"
          title="load"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <div className={classes.MainbackGround}>
        <div className={classes.backImg} style={{ backgroundImage: `url("${getContentPath(advImage)}")` }}>
          <div className={classes.maindiv}>
            <div className={classes.link}><span className="bold">{content.link}</span></div>
            <div className={classes.title}><span className="bold">{content.title}</span></div>
            <div className={classes.note}>{content.note}</div>
            <div className={classes.ButtonStyle}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.confirmBtn}
                onClick={handleConfirm}
              >
                <span className="bold">CONFIRM</span>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.cancelBtn}
                onClick={handleCancel}
              >
                <span className="bold">CANCEL</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <NotificationMessage open={openNoti} msgType={msgType} msg={msg} handleClose={handleCloseForNotify} />
      <ErrorModal openError={openError} setOpenError={setOpenError} errorMsg={errorMsg} errorHeading={errorHeading} />
      <Backdrop className={classes.loaderbackdrop} open={loader}>
        <CircularProgress className={classes.circleLoader} />
      </Backdrop>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: state.oauth.isUserLoggedIn,
    serviceOffers: state.payment.serviceOffers,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    saveconsentLog,
    evinaAndCharge
  },
  dispatch
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdsConfirm));
