import React from 'react';
import { RiPlayCircleLine as PlayIcon } from '@react-icons/all-files/ri/RiPlayCircleLine';
// import { FiPauseCircle as PauseIcon } from '@react-icons/all-files/fi/FiPauseCircle';
import { FiPauseCircle as PauseIcon } from '@react-icons/all-files/fi/FiPauseCircle';

export { IoIosPlay as Mobileplay } from '@react-icons/all-files/io/IoIosPlay';
export { IoIosFastforward as MobileForward } from '@react-icons/all-files/io/IoIosFastforward';
export { IoIosPause as Mobilepause } from '@react-icons/all-files/io/IoIosPause';
export { FaBook as LyricIcon } from '@react-icons/all-files/fa/FaBook';
export { FaHeadphones as FaHeadphonesIcon } from '@react-icons/all-files/fa/FaHeadphones';
export { FaRegMinusSquare as FaMinusSquareOIcon } from '@react-icons/all-files/fa/FaRegMinusSquare';
export { FaSpinner as LoadIcon } from '@react-icons/all-files/fa/FaSpinner';
export { FaSyncAlt as ReloadIcon } from '@react-icons/all-files/fa/FaSyncAlt';
export { FiChevronsDown as ArrowDownIcon } from '@react-icons/all-files/fi/FiChevronsDown';
export { GoMute as VolumeMuteIcon } from '@react-icons/all-files/go/GoMute';
export { GoUnmute as VolumeUnmuteIcon } from '@react-icons/all-files/go/GoUnmute';
export { MdClose as CloseIcon } from '@react-icons/all-files/md/MdClose';
export { MdLibraryMusic as EmptyIcon } from '@react-icons/all-files/md/MdLibraryMusic';
export { MdRepeat as RepeatIcon } from '@react-icons/all-files/md/MdRepeat';
export { MdRepeatOne as LoopIcon } from '@react-icons/all-files/md/MdRepeatOne';
export { MdSkipPrevious as PrevAudioIcon } from '@react-icons/all-files/md/MdSkipPrevious';
export { MdViewHeadline as OrderPlayIcon } from '@react-icons/all-files/md/MdViewHeadline';
export { MdSkipNext as NextAudioIcon } from '@react-icons/all-files/md/MdSkipNext';
export { RiDeleteBinLine as DeleteIcon } from '@react-icons/all-files/ri/RiDeleteBinLine';
export { RiPlayList2Fill as PlayListsIcon } from '@react-icons/all-files/ri/RiPlayList2Fill';
export { TiArrowShuffle as ShufflePlayIcon } from '@react-icons/all-files/ti/TiArrowShuffle';
export { TiDownload as DownloadIcon } from '@react-icons/all-files/ti/TiDownload';
export { MdChevronLeft as ArrowBackIcon } from '@react-icons/all-files/md/MdChevronLeft';

export const AnimatePlayIcon = () => <PlayIcon fontSize="3.125rem" />;
export const AnimatePauseIcon = () => <PauseIcon fontSize="3.125rem" />;
