/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Card, CardContent, makeStyles, Button, useMediaQuery } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { secToDate } from '../../helpers/utils';
import { deleteRBTCallertune, getRBTcallerTuneList } from '../../reducers/rbt';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { renderCircularProgress } from '../../reducers/ui';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { withStyles } from '@material-ui/core/styles';
import NotificationMessage from '../NotificationMessage';
import { APP_CONFIG } from '@constants';
import Tooltip from '@material-ui/core/Tooltip';


const StyleSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.selectedTxtClr,
        '&$checked': {
            color: theme.palette.selectedTxtClr,
        },
        '&$checked + $track': {
            backgroundColor: '#fff',
        },
    },
    checked: {},
    track: {
        width: 25,
        height: 5,
        marginTop: 5,
    },
}))(Switch);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '100%',
        margin: '0 auto',
        borderRadius: 10,
        background: '#242424',
        marginBottom: '10px',
        [theme.breakpoints.up('sm')]: {
            minWidth: '60%',
        },
        height: '104px'
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
        background: '#242424',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: `linear-gradient(to right,#fff,${theme.palette.selectedTxtClr})`,
    },
    image: {
        width: 95,
        height: '90%',
        borderRadius: 15,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        objectFit: 'cover',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
            background: '#00000000',
        },
        [theme.breakpoints.up('sm')]: {
            background: '#00000000',
        }
    },
    welcomeText:{
        color: theme.palette.selectedTxtClr,
        fontSize: '1.125rem',
        padding: '0px 15px'
    },
    btns: {
        width: '95%',
        background: '#00000000',
        [theme.breakpoints.up('1024')]: {
            width: '100%',
        },
        gap: '5%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        marginTop: '5vh',
        padding: '20px',
    },
    sendBtn: {
        borderRadius: '15px',
        backgroundColor: theme.palette.selectedTxtClr,
        '&:hover': {
            backgroundColor: theme.palette.selectedTxtClr,
        },
        color: theme.palette.titleClr,
        textTransform: 'none',
        width: '150px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },
    },
    cancelBtn: {
        borderRadius: '15px',
        color: theme.palette.titleClr,
        backgroundColor: '#4a4a4a',
        '&:hover': {
            backgroundColor: '#4a4a4a',
        },
        textTransform: 'none',
        width: '150px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },

    },
    oneCardAlert: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#00000000',
        height: '110px'
    },
    yesBtn: {
        borderRadius: '15px',
        backgroundColor: theme.palette.selectedTxtClr,
        '&:hover': {
            backgroundColor: theme.palette.selectedTxtClr,
        },
        color: theme.palette.titleClr,
        textTransform: 'none',
        width: '150px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },
    },
    mainPopper: {
        [theme.breakpoints.down('1024')]: {
            marginLeft: '90px',
        },
        [theme.breakpoints.between('1024', '1370')]: {
            marginLeft: '96px',
        },
        [theme.breakpoints.between('1370', '1599')]: {
            marginLeft: '125px',
        },
        [theme.breakpoints.between('1600', '1950')]: {
            marginLeft: '175px',
        },
        [theme.breakpoints.up('1950')]: {
            marginLeft: '185px',
        },
    }
}));

const CardCallerTune = (props) => {
    const classes = useStyles();
    const [openPopper, setOpenPopper] = useState(false);
    const anchorRef = React.useRef(null);
    const mobileView = useMediaQuery('(max-width:1024px)');
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgType, setMsgType] = useState('');
    const [cardClipId, setCardClipId] = useState('');
    const profile_photo_base_url_activeTone = APP_CONFIG.profile_photo_base_url_activetone;

    const handleChange = () => {
        setOpenPopper((prevOpen) => !prevOpen);
    };

    useEffect(() => {

    }, [props.cardTune])

    const closePopper = () => {
        // event.preventDefault();
        setOpenPopper(false);
        console.log('closePopper', openPopper)
    }

    const prevOpen = React.useRef(openPopper);
    useEffect(() => {
        if (prevOpen.current === true && openPopper === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openPopper;
    }, []);

    const deleteCallertne = () => {
        props.renderCircularProgress(true);
        props.deleteRBTCallertune(cardClipId, props.msisdn).then((result) => {
            if(window.localStorage.getItem('phone')) {
                props.getRBTcallerTuneList(encodeURIComponent(window.localStorage.getItem('phone'))).then(() => {
                    props.renderCircularProgress(false);
                })
            }
            setOpen(true);
            setMsg(result.message);
            setMsgType("success");
        }).catch((error) => {
            setOpen(true);
            setMsg(error.errorMessage);
            setMsgType("error");
        });
        setTimeout(() => {
            setOpen(false);
        }, 3000);
    }
    const oneCardAlert = () => {
        setOpenPopper(false);
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const cardIdData = (clipId) => {
        setCardClipId(clipId);
    }

    return (
        <>

            {props.cardTune.map((tunedata) => {
                return (<Card className={classes.root} onClick={() => cardIdData(tunedata.id)}>
                    <div className={classes.details}>
                        <img id={tunedata.id} src={`${profile_photo_base_url_activeTone}?clipId=${tunedata.id}`} alt='TuneImg' className={classes.image} />
                        <CardContent>
                            <div style={{ fontSize: '0.9375rem' }}><b>{tunedata.name}</b></div>
                            <div style={{ fontSize: '0.75rem' }}>Set Up Date : {secToDate(tunedata.setTime)}</div>
                            <div style={{ fontSize: '0.75rem' }}>Expire Date : {secToDate(tunedata.endTime)}</div>
                        </CardContent>
                        <span className="moreoption" ref={anchorRef} aria-controls={openPopper ? 'menu-list-grow' : undefined} aria-haspopup="true">
                            {' '}
                            {tunedata.downloadStatus === "act_pending" ?
                                (<>
                                <Tooltip title="Pending"><NewReleasesIcon className="newRelease" /></Tooltip>
                                    </>) :
                                <StyleSwitch
                                    className={classes.switch}
                                    checked={tunedata.downloadStatus === "active" ? true : false}
                                    onChange={handleChange}
                                    // name="checkedA"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                        </span>
                        <Popper open={openPopper} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ position: 'absolute', right: mobileView ? '' : -100, zIndex: 9 }}>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        borderRadius: '15px',
                                        padding: '10px',
                                        width: '340px',
                                       
                                    }}
                                    className={classes.mainPopper}
                                >
                                    <Paper style={{ backgroundColor: '#3e3e3e', color: '#ababab' }}>
                                        <div style={{ width: mobileView ? '100%' : '320px' }}>
                                            <div className={classes.header}>
                                                <div className={classes.welcomeText}>
                                                    {props.cardTune.length > 1 ?
                                                        'Are you sure you want to cancel your Welcome Tone?' :
                                                        'Your Welcome Tone Service will get Suspended/Deactivated along with your last active tone'}
                                                </div>
                                                <div className={classes.btns}>
                                                    <Button
                                                        className={classes.cancelBtn}
                                                        variant="contained"
                                                        onClick={closePopper}
                                                    >
                                                        <b>Cancel</b>
                                                    </Button>
                                                    <Button
                                                        className={classes.sendBtn}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={deleteCallertne}
                                                    >
                                                        <b>Confirm</b>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </Card>)

            })}
            <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleClose} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    deleteRBTCallertune,
    getRBTcallerTuneList,
    renderCircularProgress,
},
    dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardCallerTune));
