/* eslint-disable */
export const GET_SUGGESTIONS_WITHOUTGENRE_REQUESTED = 'suggestionsWithoutGenre/GET_SUGGESTIONS_WITHOUTGENRE_REQUESTED';
export const GET_SUGGESTIONS_WITHOUTGENRE_SUCCESS = 'suggestionsWithoutGenre/GET_SUGGESTIONS_WITHOUTGENRE_SUCCESS';
export const GET_SUGGESTIONS_WITHOUTGENRE_FAILURE = 'suggestionsWithoutGenre/GET_SUGGESTIONS_WITHOUTGENRE_FAILURE';

export const GET_SUGGESTIONS_WITHGENRE_REQUESTED = 'suggestionsWithGenre/GET_SUGGESTIONS_WITHGENRE_REQUESTED';
export const GET_SUGGESTIONS_WITHGENRE_SUCCESS = 'suggestionsWithGenre/GET_SUGGESTIONS_WITHGENRE_SUCCESS';
export const GET_SUGGESTIONS_WITHGENRE_FAILURE = 'suggestionsWithGenre/GET_SUGGESTIONS_WITHGENRE_FAILURE';

export const SUGGESTIONS_WITHOUT_GENRE_EMPTY = 'suggestionsWithGenre/SUGGESTIONS_WITHOUT_GENRE_EMPTY';
export const SUGGESTIONS_WITH_GENRE_EMPTY = 'suggestionsWithoutGenre/SUGGESTIONS_WITH_GENRE_EMPTY';

const initialState = {
    loading: false,
    loaded: false,
    error: false,
    suggestionsWithoutGenre: [],
    suggestionsWithGenre: [],
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_SUGGESTIONS_WITHOUTGENRE_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_SUGGESTIONS_WITHOUTGENRE_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          suggestionsWithoutGenre: action.result.responseList
        };
      }
      case GET_SUGGESTIONS_WITHOUTGENRE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }

      case GET_SUGGESTIONS_WITHGENRE_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_SUGGESTIONS_WITHGENRE_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          suggestionsWithGenre: action.result.responseList
        };
      }
      case GET_SUGGESTIONS_WITHGENRE_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case SUGGESTIONS_WITHOUT_GENRE_EMPTY: {
        console.log('clearSuggestionswithoutGenre');
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          suggestionsWithoutGenre: []
        };
      }
      case SUGGESTIONS_WITH_GENRE_EMPTY: {
        console.log('clearSuggestionswithGenre');
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          suggestionsWithGenre: []
        };
      }
      default:
        return state;
    }
  };

  export const getSuggestionswithoutGenre = (pageNo,pageSize,contentType,mediaId) => {
    return {
      types: [GET_SUGGESTIONS_WITHOUTGENRE_REQUESTED, GET_SUGGESTIONS_WITHOUTGENRE_SUCCESS, GET_SUGGESTIONS_WITHOUTGENRE_FAILURE],
      promise: (client) => client.get(`getSuggestionswithoutGenre?pageNo=${pageNo}&pageSize=${pageSize}&contentType=${contentType}&mediaId=${mediaId}`, {
      })
    };
  };

  export const getSuggestionsWithGenre = (pageNo,pageSize,genre,mediaId) => {
    return {
      types: [GET_SUGGESTIONS_WITHGENRE_REQUESTED, GET_SUGGESTIONS_WITHGENRE_SUCCESS, GET_SUGGESTIONS_WITHGENRE_FAILURE],
      promise: (client) => client.get(`getSuggestionsWithGenre?pageNo=${pageNo}&pageSize=${pageSize}&genre=${genre}&mediaId=${mediaId}`, {
      })
    };
  };

  export const clearSuggestionswithoutGenre = () => {
    return {
      type: SUGGESTIONS_WITHOUT_GENRE_EMPTY
    };
  };

  export const clearSuggestionswithGenre = () => {
    return {
      type: SUGGESTIONS_WITH_GENRE_EMPTY
    };
  };