/* eslint-disable */
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Container, makeStyles, useMediaQuery, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerClass: {
    '& svg': {
      fill: '#FFFFFF',
    },
  }
}))

const NotificationMessage = (props) => {
  const [state, setState] = React.useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:1024px)');
  const isAdminLogin = window.location.pathname === '/admin/login' ? true : false;

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <Snackbar className={classes.containerClass} anchorOrigin={{ vertical, horizontal }} open={props.open} autoHideDuration={5000} onClose={props.handleClose}

      style={{ color: '#FFFFFF !important', marginBottom: mobileView ? '80px' : '8px', bottom: isAdminLogin ? '250px' : '' }}

    >
      <Alert onClose={props.handleClose} severity={props.msgType} sx={{ width: '100%', color: '#FFFFFF !important' }}>
        {props.msg}
      </Alert>
    </Snackbar>
  );
};

export default NotificationMessage;

// import React from 'react';
// import Button from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
// import { makeStyles } from '@material-ui/core/styles';

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

// export default function CustomizedSnackbars() {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);

//   const handleClick = () => {
//     setOpen(true);
//   };

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }

//     setOpen(false);
//   };

//   return (
//     <div className={classes.root}>
//       <Button variant="outlined" onClick={handleClick}>
//         Open success snackbar
//       </Button>
//       <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="success">
//           This is a success message!
//         </Alert>
//       </Snackbar>
//       <Alert severity="error">This is an error message!</Alert>
//       <Alert severity="warning">This is a warning message!</Alert>
//       <Alert severity="info">This is an information message!</Alert>
//       <Alert severity="success">This is a success message!</Alert>
//     </div>
//   );
// }
