import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {
  styled,
  makeStyles
} from '@material-ui/core/styles';
import { EN } from '@localization';

const useStyles = makeStyles(() => ({
  textFormControl: {
    backgroundColor: '#2b2b2b',
    width: '100%',
    height: 'calc(1.5em + 1.5rem)',
    borderRadius: '50px'
  },
  label: {
    color: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    fontSize: '1.25rem',
    marginLeft: '10px'
  }
}));

const CssTextField = styled(TextField)({
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  // '& .MuiInput-underline.Mui-error:after': {
  //   borderBottom: 'none'
  // },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none'
  },
  '& .MuiInput-root': {
    marginTop: '1%',
    marginLeft: '2%'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    marginTop: '2%',
    fontSize: '0.8rem',
    marginLeft: '1%'
  }
});

export default function textField(props) {
  const classes = useStyles();
  const {
    label,
    required,
    id,
    defaultValue,
    disabled,
    type,
    variant,
    size,
    multiline,
    maxRows,
    placeholder,
    displayLabel,
    autoComplete,
    // color,
    errorLabel
  } = props;

  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);

  const validate = (e) => {
    if (required) {
      if (e.target.value === '' || e.target.value === undefined) {
        setError(true);
        setHelperText(errorLabel);
      } else {
        setError(props.error);
        setHelperText(props.helperText);
      }
    }
  };

  const handleChange = (e) => {
    props.onChange(e.target.value);
    validate(e);
  };

  return (
    <div>
      {displayLabel && (
        <InputLabel className={classes.label}>
          <span className="text-capitalize">{label}</span>
          {required ? EN.REQUIRED : ''}
        </InputLabel>)}
      <FormControl variant="standard" className={classes.textFormControl}>
        <CssTextField
          required={required}
          id={id}
          // label={label}
          defaultValue={defaultValue}
          disabled={disabled}
          type={type} // text, password,number,search
          helperText={helperText}
          variant={variant} // standard, filled, outlined
          size={size} // small, normal
          error={error}
          multiline={multiline}
          maxRows={maxRows}
          placeholder={placeholder}
          hiddenLabel={!displayLabel}
          onChange={handleChange}
          autoComplete={autoComplete}
          // color={color} // secondary, success, warning
          onBlur={validate}
        />
      </FormControl>
    </div>
  );
}
