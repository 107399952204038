/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import logo from '../../assets/images/Mobile.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MotherPortalDashboard from '../MotherPortalDashboard';
import { getContentPath } from '../../helpers/utils';
import { getArtistsList } from '../../reducers/profile';
import FooterComponent from '../FooterComponent';

const useStyles = makeStyles((theme) => ({
  containerDiv: {
    padding: '20px',
  },
  fixedHeader: {
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1000',
    background: '#000'
  },
  imgDiv: {
    marginBottom: '1%',
    marginTop: '1%',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.2%',
      marginTop: '3%',
    },
  },
  separatorDiv: {
    background: '#D9D9D933',
    height: '1px',
    // marginBottom: '2.2%',
    // [theme.breakpoints.down('md')]: {
    //   marginBottom: '5%',
    // },
  },
  loaderbackdrop: {
    zIndex: 10000,
  },
  circleLoader: {
    color: theme.palette.selectedTxtClr,
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
}));

const MotherPortalHeader = (props) => {
  const classes = useStyles();
  const [apiData, setApiData] = useState([]);
  // const [loader, setLoader] = useState(true);

  useEffect(() => {
    props.getArtistsList().then((data) => setApiData(data));
  }, []);

  return (
    <>
      {/* {(loader && apiData.length === 0) 
        && <Backdrop className={classes.loaderbackdrop} open={loader}>
          <CircularProgress className={classes.circleLoader} />
        </Backdrop>} */}
      {(props.artistData.motherPortalLogoForWeb !== undefined && JSON.parse(window.sessionStorage.getItem('artistDetails'))) ? (
        <><div className={classes.containerDiv}>
          <div className={classes.fixedHeader}>
            <div className={classes.imgDiv}>
              <img src={getContentPath(apiData.motherPortalLogoForWeb)} alt="logo" height={48} width={134} />
            </div>
            <div className={classes.separatorDiv} />
          </div>
          <MotherPortalDashboard apiData={apiData} />
          {/* <FooterComponent /> */}
        </div>
        <FooterComponent />
        </>)
        : (<div style={{ height: '100vh' }}><CircularProgress className={classes.circleLoader} /></div>)}
    </>
  );
};

const mapStateToProps = state => {
  return {
    artistData: state.profile.artistsList
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getArtistsList
  },
  dispatch
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MotherPortalHeader)
);
