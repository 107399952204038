import React, { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {
  styled,
  makeStyles
} from '@material-ui/core/styles';
import { EN } from '@localization';

const useStyles = makeStyles(() => ({
  textFormControl: {
    backgroundColor: '#2b2b2b',
    width: '100%',
    borderRadius: '25px'
  },
  label: {
    color: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    fontSize: '1.25rem',
    marginLeft: '2%'
  },
  textAreaTag: {
    margin: '2%',
    border: 'none',
    fontSize: '1.125rem',
    outline: 'none',
    backgroundColor: '#2b2b2b',
    color: '#ffffff'
  },
  errorLabelSpan: {
    color: '#f44336',
    fontSize: '0.8rem',
    fontFamily: 'SegoeUISemiBold !important',
    marginLeft: '10px'
  }
}));

const CssTextArea = styled(TextareaAutosize)({
});

export default function textArea(props) {
  const classes = useStyles();
  const {
    label,
    required,
    id,
    defaultValue,
    maxRows,
    minRows,
    placeholder,
    displayLabel,
    onChange,
    errorLabel
  } = props;

  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);

  const validate = (e) => {
    if (required) {
      if (e.target.value === '' || e.target.value === undefined) {
        setError(true);
        setHelperText(errorLabel);
      } else {
        setError(props.error);
        setHelperText(props.helperText);
      }
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value);
    validate(e);
  };

  return (
    <div>
      {displayLabel && (
        <InputLabel className={classes.label}>
          <span className="text-capitalize">{label}</span>
          {required ? EN.REQUIRED : ''}
        </InputLabel>)}
      <FormControl variant="standard" className={classes.textFormControl}>
        <CssTextArea
          aria-label={label}
          id={id}
          required={required}
          placeholder={placeholder}
          minRows={minRows}
          maxRows={maxRows}
          defaultValue={defaultValue}
          className={classes.textAreaTag}
          onChange={handleChange}
          onBlur={validate}
        />
      </FormControl>
      {error && (
        <span className="col-xs-12 col-sm-12 col-md-9 pl-0" key={error}>
          <span className={classes.errorLabelSpan}>
            {helperText}
          </span>
        </span>)}
    </div>

  );
}
