/* eslint-disable */
export const PROFILEPIC_REQUESTED = 'profilePic/PROFILEPIC_REQUESTED';
export const PROFILEPIC_SUCCESS = 'profilePic/PROFILEPIC_SUCCESS';
export const PROFILEPIC_FAILURE = 'profilePic/PROFILEPIC_FAILURE';

const initialState = {
    loading: false,
    loaded: false,
    error: false
  };

export default (state = initialState, action) => {
    switch (action.type) {
      case PROFILEPIC_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case PROFILEPIC_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case PROFILEPIC_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      default:
        return state;
    }
  };
  
  export const profilePicUpload = (formData) => {
    return {
      types: [PROFILEPIC_REQUESTED, PROFILEPIC_SUCCESS, PROFILEPIC_FAILURE],
      promise: (client) => client.post('profilePicUpload', {
        data: formData,
      })
    };
  };
  
  
  