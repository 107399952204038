/* eslint-disable */
export const POST_EXISTING_MUSICPLAYLIST_REQUESTED = 'existingMusicPlaylist/POST_EXISTING_MUSICPLAYLIST_REQUESTED';
export const POST_EXISTING_MUSICPLAYLIST_SUCCESS = 'existingMusicPlaylist/POST_EXISTING_MUSICPLAYLIST_SUCCESS';
export const POST_EXISTING_MUSICPLAYLIST_FAILURE = 'existingMusicPlaylist/POST_EXISTING_MUSICPLAYLIST_FAILURE';

export const POST_CREATE_MUSICPLAYLIST_REQUESTED = 'createMusicPlaylist/POST_CREATE_MUSICPLAYLIST_REQUESTED';
export const POST_CREATE_MUSICPLAYLIST_SUCCESS = 'createMusicPlaylist/POST_CREATE_MUSICPLAYLIST_SUCCESS';
export const POST_CREATE_MUSICPLAYLIST_FAILURE = 'createMusicPlaylist/POST_CREATE_MUSICPLAYLIST_FAILURE';

export const POST_EXISTING_VIDEO_PLAYLIST_REQUESTED = 'existingVideo_Playlist/POST_EXISTING_VIDEO_PLAYLIST_REQUESTED';
export const POST_EXISTING_VIDEO_PLAYLIST_SUCCESS = 'existingVideo_Playlist/POST_EXISTING_VIDEO_PLAYLIST_SUCCESS';
export const POST_EXISTING_VIDEO_PLAYLIST_FAILURE = 'existingVideo_Playlist/POST_EXISTING_VIDEO_PLAYLIST_FAILURE';

export const POST_CREATE_VIDEO_PLAYLIST_REQUESTED = 'createVideo_Playlist/POST_CREATE_VIDEO_PLAYLIST_REQUESTED';
export const POST_CREATE_VIDEO_PLAYLIST_SUCCESS = 'createVideo_Playlist/POST_CREATE_VIDEO_PLAYLIST_SUCCESS';
export const POST_CREATE_VIDEO_PLAYLIST_FAILURE = 'createVideo_Playlist/POST_CREATE_VIDEO_PLAYLIST_FAILURE';

export const GET_ALL_MUSICPLAYLIST_REQUESTED = 'allMusicPlaylist/GET_ALL_MUSICPLAYLIST_REQUESTED';
export const GET_ALL_MUSICPLAYLIST_SUCCESS = 'allMusicPlaylist/GET_ALL_MUSICPLAYLIST_SUCCESS';
export const GET_ALL_MUSICPLAYLIST_FAILURE = 'allMusicPlaylist/GET_ALL_MUSICPLAYLIST_FAILURE';

export const GET_SPECIFIC_MUSICPLAYLIST_REQUESTED = 'specificMusicPlaylist/GET_SPECIFIC_MUSICPLAYLIST_REQUESTED';
export const GET_SPECIFIC_MUSICPLAYLIST_SUCCESS = 'specificMusicPlaylist/GET_SPECIFIC_MUSICPLAYLIST_SUCCESS';
export const GET_SPECIFIC_MUSICPLAYLIST_FAILURE = 'specificMusicPlaylist/GET_SPECIFIC_MUSICPLAYLIST_FAILURE';

export const GET_ALL_VIDEOPLAYLIST_REQUESTED = 'allVideoPlaylist/GET_ALL_VIDEOPLAYLIST_REQUESTED';
export const GET_ALL_VIDEOPLAYLIST_SUCCESS = 'allVideoPlaylist/GET_ALL_VIDEOPLAYLIST_SUCCESS';
export const GET_ALL_VIDEOPLAYLIST_FAILURE = 'allVideoPlaylist/GET_ALL_VIDEOPLAYLIST_FAILURE';

export const GET_SPECIFIC_VIDEOPLAYLIST_REQUESTED = 'specificVideoPlaylist/GET_SPECIFIC_VIDEOPLAYLIST_REQUESTED';
export const GET_SPECIFIC_VIDEOPLAYLIST_SUCCESS = 'specificVideoPlaylist/GET_SPECIFIC_VIDEOPLAYLIST_SUCCESS';
export const GET_SPECIFIC_VIDEOPLAYLIST_FAILURE = 'specificVideoPlaylist/GET_SPECIFIC_VIDEOPLAYLIST_FAILURE';

export const DELETE_PLAYLIST_REQUESTED = 'deletePlaylist/DELETE_PLAYLIST_REQUESTED';
export const DELETE_PLAYLIST_SUCCESS = 'deletePlaylist/DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAILURE = 'deletePlaylist/DELETE_PLAYLIST_FAILURE';

export const GET_ITEMS_OF_PLAYLIST_REQUESTED = 'playlist/GET_ITEMS_OF_PLAYLIST_REQUESTED';
export const GET_ITEMS_OF_PLAYLIST_SUCCESS = 'playlist/GET_ITEMS_OF_PLAYLIST_SUCCESS';
export const GET_ITEMS_OF_PLAYLIST_FAILURE = 'playlist/GET_ITEMS_OF_PLAYLIST_FAILURE';


const initialState = {
    loading: false,
    loaded: false,
    error: false,
    allMusicPlaylist: [],
    allVideoPlaylist: [],
    specificMusicPlaylist: [],
    specificVideoPlaylist: [],
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_MUSICPLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_ALL_MUSICPLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          allMusicPlaylist: action.result.responseList
        };
      }
      case GET_ALL_MUSICPLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }

      case GET_ALL_VIDEOPLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_ALL_VIDEOPLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          allVideoPlaylist: action.result.responseList
        };
      }
      case GET_ALL_VIDEOPLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }

      case GET_SPECIFIC_MUSICPLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_SPECIFIC_MUSICPLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          specificMusicPlaylist: action.result.responseList
        };
      }
      case GET_SPECIFIC_MUSICPLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case POST_EXISTING_VIDEO_PLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_EXISTING_VIDEO_PLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case POST_EXISTING_VIDEO_PLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case POST_CREATE_VIDEO_PLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_CREATE_VIDEO_PLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case POST_CREATE_VIDEO_PLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case GET_SPECIFIC_VIDEOPLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_SPECIFIC_VIDEOPLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          specificVideoPlaylist: action.result.responseList
        };
      }
      case GET_SPECIFIC_VIDEOPLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }

      case POST_EXISTING_MUSICPLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_EXISTING_MUSICPLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case POST_EXISTING_MUSICPLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case POST_CREATE_MUSICPLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_CREATE_MUSICPLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case POST_CREATE_MUSICPLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }

      case DELETE_PLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case DELETE_PLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case DELETE_PLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case GET_ITEMS_OF_PLAYLIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_ITEMS_OF_PLAYLIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case GET_ITEMS_OF_PLAYLIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      default:
        return state;
    }
  };

  export const postExistingMusicPlaylist = (formData) => {
    return {
      types: [POST_EXISTING_MUSICPLAYLIST_REQUESTED, POST_EXISTING_MUSICPLAYLIST_SUCCESS, POST_EXISTING_MUSICPLAYLIST_FAILURE],
      promise: (client) => client.post('postExistingMusicPlaylist', {
        data: formData,
      })
    };
  };

  export const postCreateMusicPlaylist = (formData) => {
    return {
      types: [POST_CREATE_MUSICPLAYLIST_REQUESTED, POST_CREATE_MUSICPLAYLIST_SUCCESS, POST_CREATE_MUSICPLAYLIST_FAILURE],
      promise: (client) => client.post('postCreateMusicPlaylist', {
        data: formData,
      })
    };
  };

  export const postExistingVideoPlaylist = (formData) => {
    return {
      types: [POST_EXISTING_VIDEO_PLAYLIST_REQUESTED, POST_EXISTING_VIDEO_PLAYLIST_SUCCESS, POST_EXISTING_VIDEO_PLAYLIST_FAILURE],
      promise: (client) => client.post('postExistingVideoPlaylist', {
        data: formData,
      })
    };
  };

  export const postCreateVideoPlaylist = (formData) => {
    return {
      types: [POST_CREATE_VIDEO_PLAYLIST_REQUESTED, POST_CREATE_VIDEO_PLAYLIST_SUCCESS, POST_CREATE_VIDEO_PLAYLIST_FAILURE],
      promise: (client) => client.post('postCreateVideoPlaylist', {
        data: formData,
      })
    };
  };

  export const getAllMusicPlaylist = (pageNo,pageSize) => {
    return {
      types: [GET_ALL_MUSICPLAYLIST_REQUESTED, GET_ALL_MUSICPLAYLIST_SUCCESS, GET_ALL_MUSICPLAYLIST_FAILURE],
      promise: (client) => client.get(`getAllMusicPlaylist?pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const getSpecificMusicPlaylist = (pageNo,pageSize) => {
    return {
      types: [GET_SPECIFIC_MUSICPLAYLIST_REQUESTED, GET_SPECIFIC_MUSICPLAYLIST_SUCCESS, GET_SPECIFIC_MUSICPLAYLIST_FAILURE],
      promise: (client) => client.get(`getSpecificMusicPlaylist?pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const getAllVideoPlaylist = (pageNo,pageSize) => {
    return {
      types: [GET_ALL_VIDEOPLAYLIST_REQUESTED, GET_ALL_VIDEOPLAYLIST_SUCCESS, GET_ALL_VIDEOPLAYLIST_FAILURE],
      promise: (client) => client.get(`getAllVideoPlaylist?pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const getSpecificVideoPlaylist = (pageNo,pageSize) => {
    return {
      types: [GET_SPECIFIC_VIDEOPLAYLIST_REQUESTED, GET_SPECIFIC_VIDEOPLAYLIST_SUCCESS, GET_SPECIFIC_VIDEOPLAYLIST_FAILURE],
      promise: (client) => client.get(`getSpecificVideoPlaylist?pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const deletePlaylist = (formData) => {
    return {
      types: [DELETE_PLAYLIST_REQUESTED, DELETE_PLAYLIST_SUCCESS, DELETE_PLAYLIST_FAILURE],
      promise: (client) => client.del('deletePlaylist', {
        data: formData,
      })
    };
  };

  export const getItemsOfPlaylist = (playlistId) => {
    return {
      types: [GET_ITEMS_OF_PLAYLIST_REQUESTED, GET_ITEMS_OF_PLAYLIST_SUCCESS, GET_ITEMS_OF_PLAYLIST_FAILURE],
      promise: (client) => client.get(`getItemsOfPlaylist?playlistId=${playlistId}`, {
      })
    };
  };
  