import React from 'react';
import HeaderComponent from '@components/common/header';

const Header = () => {
  return (
    <HeaderComponent />
  );
};

export default Header;
