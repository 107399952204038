/* eslint-disable */
export const POST_FAVOURITE_MUSIC_REQUESTED = 'favouriteMusic/POST_FAVOURITE_MUSIC_REQUESTED';
export const POST_FAVOURITE_MUSIC_SUCCESS = 'favouriteMusic/POST_FAVOURITE_MUSIC_SUCCESS';
export const POST_FAVOURITE_MUSIC_FAILURE = 'favouriteMusic/POST_FAVOURITE_MUSIC_FAILURE';

export const POST_FAVOURITE_VIDEO_REQUESTED = 'favouriteVideo/POST_FAVOURITE_VIDEO_REQUESTED';
export const POST_FAVOURITE_VIDEO_SUCCESS = 'favouriteVideo/POST_FAVOURITE_VIDEO_SUCCESS';
export const POST_FAVOURITE_VIDEO_FAILURE = 'favouriteVideo/POST_FAVOURITE_VIDEO_FAILURE';

export const GET_FAVOURITE_MUSIC_REQUESTED = 'favouriteMusic/GET_FAVOURITE_MUSIC_REQUESTED';
export const GET_FAVOURITE_MUSIC_SUCCESS = 'favouriteMusic/GET_FAVOURITE_MUSIC_SUCCESS';
export const GET_FAVOURITE_MUSIC_FAILURE = 'favouriteMusic/GET_FAVOURITE_MUSIC_FAILURE';

export const GET_FAVOURITE_VIDEO_REQUESTED = 'favouriteVideo/GET_FAVOURITE_VIDEO_REQUESTED';
export const GET_FAVOURITE_VIDEO_SUCCESS = 'favouriteVideo/GET_FAVOURITE_VIDEO_SUCCESS';
export const GET_FAVOURITE_VIDEO_FAILURE = 'favouriteVideo/GET_FAVOURITE_VIDEO_FAILURE';

export const DELETE_FAVOURITE_REQUESTED = 'deleteFavourite/DELETE_FAVOURITE_REQUESTED';
export const DELETE_FAVOURITE_SUCCESS = 'deleteFavourite/DELETE_FAVOURITE_SUCCESS';
export const DELETE_FAVOURITE_FAILURE = 'deleteFavourite/DELETE_FAVOURITE_FAILURE';

const initialState = {
    loading: false,
    loaded: false,
    error: false,
    favouriteMusic: [],
    favouriteVideo: [],
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case POST_FAVOURITE_MUSIC_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_FAVOURITE_MUSIC_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case POST_FAVOURITE_MUSIC_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case POST_FAVOURITE_VIDEO_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_FAVOURITE_VIDEO_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case POST_FAVOURITE_VIDEO_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case GET_FAVOURITE_MUSIC_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_FAVOURITE_MUSIC_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          favouriteMusic: action.result.responseList
        };
      }
      case GET_FAVOURITE_MUSIC_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }

      case GET_FAVOURITE_VIDEO_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_FAVOURITE_VIDEO_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          favouriteVideo: action.result.responseList
        };
      }
      case GET_FAVOURITE_VIDEO_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      }
      case DELETE_FAVOURITE_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case DELETE_FAVOURITE_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case DELETE_FAVOURITE_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      default:
        return state;
    }
  };

  export const postFavouriteMusic = (mediaId) => {
    return {
      types: [POST_FAVOURITE_MUSIC_REQUESTED, POST_FAVOURITE_MUSIC_SUCCESS, POST_FAVOURITE_MUSIC_FAILURE],
      promise: (client) => client.post(`postFavouriteMusic?mediaId=${mediaId}`, {
      })
    };
  };

  export const postFavouriteVideo = (mediaId) => {
    return {
      types: [POST_FAVOURITE_VIDEO_REQUESTED, POST_FAVOURITE_VIDEO_SUCCESS, POST_FAVOURITE_VIDEO_FAILURE],
      promise: (client) => client.post(`postFavouriteVideo?mediaId=${mediaId}`, {
      })
    };
  };
  
  export const getFavouriteMusic = (pageNo,pageSize) => {
    return {
      types: [GET_FAVOURITE_MUSIC_REQUESTED, GET_FAVOURITE_MUSIC_SUCCESS, GET_FAVOURITE_MUSIC_FAILURE],
      promise: (client) => client.get(`getFavouriteMusic?pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const getFavouriteVideo = (pageNo,pageSize) => {
    return {
      types: [GET_FAVOURITE_VIDEO_REQUESTED, GET_FAVOURITE_VIDEO_SUCCESS, GET_FAVOURITE_VIDEO_FAILURE],
      promise: (client) => client.get(`getFavouriteVideo?pageNo=${pageNo}&pageSize=${pageSize}`, {
      })
    };
  };

  export const deleteFavourite = (mediaId) => {
    return {
      types: [DELETE_FAVOURITE_REQUESTED, DELETE_FAVOURITE_SUCCESS, DELETE_FAVOURITE_FAILURE],
      promise: (client) => client.del(`deleteFavourite?mediaId=${mediaId}`, {
      })
    };
  };
  
