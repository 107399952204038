/* eslint-disable */
export const TRENDING_VIDEO_LIST_REQUESTED = 'trendingvideo/TRENDING_VIDEO_LIST_REQUESTED';
export const TRENDING_VIDEO_LIST_SUCCESS = 'trendingvideo/TRENDING_VIDEO_LIST_SUCCESS';
export const TRENDING_VIDEO_LIST_FAILURE = 'trendingvideo/TRENDING_VIDEO_LIST_FAILURE';

export const GET_NOTIFICATION_LIST_REQUESTED = 'recommendedVideo/GET_NOTIFICATION_LIST_REQUESTED';
export const GET_NOTIFICATION_LIST_SUCCESS = 'recommendedVideo/GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAILURE = 'recommendedVideo/GET_NOTIFICATION_LIST_FAILURE';

export const RECOMMENDED_VIDEO_LIST_REQUESTED = 'recommendedVideo/RECOMMENDED_VIDEO_LIST_REQUESTED';
export const RECOMMENDED_VIDEO_LIST_SUCCESS = 'recommendedVideo/RECOMMENDED_VIDEO_LIST_SUCCESS';
export const RECOMMENDED_VIDEO_LIST_FAILURE = 'recommendedVideo/RECOMMENDED_VIDEO_LIST_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  trendingVideoList: [],
  recommendedVideoList: [],
  notificationList: []  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRENDING_VIDEO_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TRENDING_VIDEO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        trendingVideoList: action.result.responseList
      };
    }
    case TRENDING_VIDEO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case RECOMMENDED_VIDEO_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case RECOMMENDED_VIDEO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        recommendedVideoList: action.result.responseList
      };
    }
    case RECOMMENDED_VIDEO_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    case GET_NOTIFICATION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_NOTIFICATION_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        notificationList: action.result.responseList
      };
    }
    case GET_NOTIFICATION_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const getTrendingMediaList = () => {
  return {
    types: [TRENDING_VIDEO_LIST_REQUESTED, TRENDING_VIDEO_LIST_SUCCESS, TRENDING_VIDEO_LIST_FAILURE],
    promise: (client) => client.get('getTrendingMediaList', {
    })
 };
};

export const getRecommendedVideoList = (mediaId) => {
return {
  types: [RECOMMENDED_VIDEO_LIST_REQUESTED, RECOMMENDED_VIDEO_LIST_SUCCESS, RECOMMENDED_VIDEO_LIST_FAILURE],
  promise: (client) => client.get(`getRecommendedVideoList?mediaId=${mediaId}`, {
  })
};
};

export const getNotificationList = (pageNo, pageSize) => {
  return {
    types: [GET_NOTIFICATION_LIST_REQUESTED, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_FAILURE],
    promise: (client) => client.get(`getNotificationList?pageNo=${pageNo}&pageSize=${pageSize}`, {
    })
  };
};

export const getMediaDetails = (mediaType, mediaId) => {
  return {
    types: [GET_NOTIFICATION_LIST_REQUESTED, GET_NOTIFICATION_LIST_SUCCESS, GET_NOTIFICATION_LIST_FAILURE],
    promise: (client) => client.get(`getMediaDetails?mediaType=${mediaType}&mediaId=${mediaId}`, {
    })
  };
};
