export const GET_OTP_REQUESTED = 'otp/OTP_REQUESTED';
export const GET_OTP_SUCCESS = 'otp/OTP_SUCCESS';
export const GET_OTP_FAILURE = 'otp/OTP_FAILURE';

export const VERIFY_OTP_REQUESTED = 'otp/VERIFY_OTP_REQUESTED';
export const VERIFY_OTP_SUCCESS = 'otp/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'otp/VERIFY_OTP_FAILURE';

export const GET_USER_REQUESTED = 'REGISTER/GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'REGISTER/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'REGISTER/GET_USER_FAILURE';

export const OAUTH_REQUESTED = 'oauth/OAUTH_REQUESTED';
export const OAUTH_SUCCESS = 'oauth/OAUTH_SUCCESS';
export const OAUTH_FAILURE = 'oauth/OAUTH_FAILURE';

export const GOOGLE_LOGIN_REQUESTED = 'otp/GOOGLE_LOGIN_REQUESTED';
export const GOOGLE_LOGIN_SUCCESS = 'otp/GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'otp/GOOGLE_LOGIN_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OTP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_OTP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case VERIFY_OTP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case VERIFY_OTP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userData: action.result.responseList
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case OAUTH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OAUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result
      };
    }
    case OAUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GOOGLE_LOGIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GOOGLE_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GOOGLE_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const generateOtp = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [GET_OTP_REQUESTED, GET_OTP_SUCCESS, GET_OTP_FAILURE],
    promise: (client) => client.post('generateOtp', {
      data,
      headers
    })
  };
};

export const verifyOtp = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [VERIFY_OTP_REQUESTED, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE],
    promise: (client) => client.post('verifyOtp', {
      data,
      headers
    })
  };
};

export const getUserDetails = () => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: (client) => client.get('getUserDetails')
  };
};

export const oAuth = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [OAUTH_REQUESTED, OAUTH_SUCCESS, OAUTH_FAILURE],
    promise: (client) => client.post('oAuth', {
      data,
      headers
    })
  };
};

export const googleLogin = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [GOOGLE_LOGIN_REQUESTED, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAILURE],
    promise: (client) => client.post('googleLogin', {
      data,
      headers
    })
  };
};
