/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import {
  makeStyles
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { EN } from '@localization';
import { ERROR_LABEL } from '@constants';
import {getContentPath} from '../../../../helpers/utils.js'

const useStyles = makeStyles(() => ({
  label: {
    color: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    fontSize: '1.25rem'
  },
  fileUploadFormControl: {
    width: '100%'
  },
  uploadBtn: {
    marginTop: '10px',
    marginLeft: '15px'
  },
  fileNameSpan: {
    color: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    fontSize: '1.25rem',
    marginLeft: '10px'
  },
  errorLabelSpan: {
    color: '#EE255C',
    fontSize: '0.8rem',
    fontFamily: 'SegoeUISemiBold !important'
  },
  imgStyle: {
    width: '200px',
    height: '150px',
    marginLeft: '10px',
    marginRight: '10px'
  }
}));

export default function FileUpload(props) {
  const classes = useStyles();
  const {
    label,
    id,
    required,
    displayLabel,
    type,
    multiple,
    fileTypes,
    buttonName,
    onChange,
    errorMsg,
    displayErrorMsg,
    resetErrorDataOfFile,
    displayImg,
    fileLimit
  } = props;

  const [file, setFile] = useState();
  const [errorLabel, setErrorLabel] = useState(errorMsg);
  const [displayError, setDisplayError] = useState(displayErrorMsg);
  const [defaultFile, setDefaultFile] = useState(props.defaultFile);
  const [images, setImages] = useState([]);
  const firstTimeRender = useRef(true);

  useEffect(() => {
    setErrorLabel(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    setDisplayError(displayErrorMsg);
  }, [displayErrorMsg]);

  useEffect(() => {
    setDisplayError(false);
    setErrorLabel('');
  }, [id]);

  const handleChange = ({ target }) => {
    console.log("handleChange");
    if(target.files.length > fileLimit) {
      setFile();
      onChange();
      setImages();
      setDisplayError(true);
      setErrorLabel(ERROR_LABEL.MAX_FILE_NUMBER_EXCEEDED);
    } else {
      let isAllFilesValid = true;
    if (target.files.length > 0) {
      let selectedFiles = [];
      Array.from(target.files).forEach((selectedItem) => {
        const objectUrl = URL.createObjectURL(selectedItem);
        selectedFiles = [...selectedFiles, objectUrl];
        let isValid = false;
        fileTypes.map((item) => {
          if (selectedItem.type === item) {
            isValid = true;
          }
          return isValid;
        });
        if (!isValid) {
          isAllFilesValid = false;
        }
      });
      setImages(selectedFiles);
    } else {
      if (required) {
        isAllFilesValid = false;
      }
    }

    if (!isAllFilesValid) {
      setDisplayError(true);
      if (label === EN.MUSIC) {
        setErrorLabel(ERROR_LABEL.SELECT_VALID_FILE_TYPE);
      } else if (label === EN.VIDEO) {
        setErrorLabel(ERROR_LABEL.SELECT_VALID_FILE_TYPE);
      } else {
        setErrorLabel(ERROR_LABEL.SELECT_VALID_FILE_TYPE);
      }
      setFile();
      onChange();
    } else {
      setDefaultFile();
      setFile(target.files);
      onChange(target.files);
      setDisplayError(false);
      setErrorLabel('');
      if (resetErrorDataOfFile) {
        resetErrorDataOfFile();
      }
    }
    }
  };

  const deleteFile = () => {
    console.log("deleteFile");
    setFile();
    onChange();
  };

  useEffect(() => {
    if(firstTimeRender.current) {
      firstTimeRender.current = false;
    } else {
      deleteFile();
    }
  }, [JSON.stringify(fileTypes)]);

  const displayFileName = () => {
    let fileName = '';
    if (file && file[0]) {
      fileName = file[0].name;
      if (file[0].name.length > 10) {
        const name = ' ...';
        fileName = file[0].name.slice(0, 20) + name;
      }
    }
    return fileName;
  };

  const displayDefaultFileName = () => {
    const fileNameArray = Array.isArray(defaultFile) ? defaultFile[0].split('/') : defaultFile.split('/');
    let fileName = fileNameArray[fileNameArray.length - 1];
    if (fileName.length > 10) {
      const name = ' ...';
      fileName = fileName.slice(0, 20) + name;
    }
    return fileName;
  };

  return (
    <div>
      {displayLabel && (
        <InputLabel className={classes.label}>
          <span className="text-capitalize ml-10">{label}</span>
          {required ? EN.REQUIRED : ''}
        </InputLabel>)}
      <FormControl variant="standard" className={classes.fileUploadFormControl}>
        <label htmlFor={id} className="col-xs-12 col-sm-12 col-md-3 pl-0">
          <input
          type={type}
          accept={fileTypes}
          multiple={multiple}
          label={id}
          id={id}
          onChange={handleChange}
          className={classes.uploadBtn}
          />
        </label>
        {(!displayImg && file && file[0]) ? ( // selected files names
          <span className="col-xs-12 col-sm-12 col-md-9 pl-0">
            <span className={classes.fileNameSpan}>
              {displayFileName()}
            </span>
          </span>
        ) : !displayImg && defaultFile && ( // default file names
          <span className="col-xs-12 col-sm-12 col-md-9 pl-0">
            <span className={classes.fileNameSpan}>
              {displayDefaultFileName()}
            </span>
          </span>
        )}
        {displayImg && defaultFile && defaultFile.length > 0 && ( //default files
          <div>
            {defaultFile.map((item,index) => (
              <>
                <img src={getContentPath(item)} key={item} className={classes.imgStyle} alt="" />
              </>
            ))}
          </div>
        )}
        {displayImg && images && images.length > 0 && ( // selected files
          <div>
            {images.map((item, index) => (
              <>
                <img src={getContentPath(item)} key={item} className={classes.imgStyle} alt="" />
              </>
            ))}
          </div>
        )}
        {displayError && (
          <span className="col-xs-12 col-sm-12 col-md-9 pl-0" key={displayError}>
            <span className={classes.errorLabelSpan}>
              {errorLabel}
            </span>
          </span>)}
      </FormControl>
    </div>
  );
}
