import {
  // CardMedia,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  withStyles,
  // useMediaQuery,
} from '@material-ui/core';
import React, { useState } from 'react';
import FeedbackIcon from '@material-ui/icons/Feedback';
// import HistoryIcon from '@material-ui/icons/History';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import HelpIcon from '@material-ui/icons/Help';
import { bindActionCreators } from 'redux';
import SVGWrapper from 'react-svg-wrapper';
// import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import logoutIcon from '@assets/images/logoutIcon.svg';
import { updateIsUserLoggedInStatus } from '@reducers/oauth';
import {
  renderProfilePanel,
  renderFeedbackPanel,
  renderInputPanel,
  renderEditPanel,
  renderSelectedCallerTunePanel,
  trackSearchPath
} from '../../reducers/ui';
// import LogoutButton from '../common/LogoutButton/LogoutButton';
import NotificationMessage from '../NotificationMessage';

const StyledListItemText = withStyles({
  primary: {
    '@media (min-width: 1025px)': {
      fontWeight: '500',
      color: '#b9b9b9',
      fontSize: '1.6875em',
      fontFamily: 'Arial',

    },
    '@media (max-width: 1024px)': {
      fontWeight: '500',
      color: '#b9b9b9',
      fontSize: '0.875em',
      fontFamily: 'Arial',
    },
  },
})(ListItemText);

const useStyles = makeStyles((theme) => ({
  links: {
    color: '#CFCFCF',
    marginTop: '5%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
      paddingLeft: '10%',
      fontSize: '60px',
    },
    [theme.breakpoints.up('md')]: {
      background: theme.palette.bgThemeColor,
      marginRight: '6%',
    },
  },
  linksStyle: {
    [theme.breakpoints.down('md')]: {
      color: '#CFCFCF',
    },
  },
  iconStyle: {
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.selectedTxtClr,
      width: '1.3em',
      height: '1.3em',
    },
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.selectedTxtClr,
    },
  },
  logoutIconStyle: {
    '& path': {
      [theme.breakpoints.up('sm')]: {
        fill: theme.palette.selectedTxtClr,
        width: '1.3em',
        height: '1.3em',
      },
      [theme.breakpoints.down('sm')]: {
        fill: theme.palette.selectedTxtClr,
      }
    }
  },
  button: {
    color: '#EE255C',
    background: '#4e4e4e',
    borderRadius: 15,
    width: '70%',
    fontWeight: 'bold',
    margin: '30px auto',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  chevronRightIcon: {
    color: theme.palette.selectedTxtClr,
  }
}));

const ProfileLinks = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const privacyPolicyUrl = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.privacyPolicyUrl;

  // const mobile = useMediaQuery('(max-width:1024px)');

  const toFeedbackPage = () => {
    props.renderFeedbackPanel(true);
    props.renderProfilePanel(false);
    props.renderInputPanel(false);
    props.renderEditPanel(false);
    props.renderSelectedCallerTunePanel(false);
  };

  const handleLogout = () => {
    setOpen(true);
    setMsg('You have successfully logged out!');
    setMsgType('success');
    setTimeout(() => {
      window.localStorage.clear();
      props.updateIsUserLoggedInStatus();
      props.history.push('/');
      window.location.reload();
    }, 1000);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleTermsAndCondition = () => {
    if (!window.location.pathname.includes('/termsAndCondition')) {
      props.trackSearchPath(window.location.pathname);
    }
    props.history.push('/termsAndCondition');
  };
  return (
    <>
      <div className={classes.links}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem
            className={classes.linksStyle}
            button
            onClick={handleTermsAndCondition}
          >
            <ListItemIcon>
              <CheckCircleOutlineIcon className={classes.iconStyle} />
            </ListItemIcon>
            <StyledListItemText primary="Terms and Conditions" />
            <ChevronRightIcon className={classes.chevronRightIcon} />
          </ListItem>
          {/* {!mobile && (
            <>
              <Divider style={{ backgroundColor: '#3c3c3c' }} />
              <ListItem className={classes.linksStyle} button>
                <ListItemIcon>
                  <HistoryIcon className={classes.iconStyle} />
                </ListItemIcon>
                <StyledListItemText primary="Transaction" />
                <ChevronRightIcon style={{ color: '#EE255C' }} />
              </ListItem>
            </>
          )} */}
          {/* <Divider style={{ backgroundColor: '#3c3c3c' }} />
          <ListItem className={classes.linksStyle} button>
            <ListItemIcon>
              <HelpIcon className={classes.iconStyle} />
            </ListItemIcon>
            <StyledListItemText primary="FAQ" />
            <ChevronRightIcon style={{ color: '#EE255C' }} />
          </ListItem> */}
          <Divider style={{ backgroundColor: '#3c3c3c' }} />
          <a style={{ textDecoration: 'none' }} href={privacyPolicyUrl} rel="noreferrer" target="_blank">
            <ListItem
              className={classes.linksStyle}
              button
            >
              <ListItemIcon>
                <CheckCircleOutlineIcon className={classes.iconStyle} />
              </ListItemIcon>
              <StyledListItemText primary="Privacy Policy" />
              <ChevronRightIcon className={classes.chevronRightIcon} />
            </ListItem>
          </a>
          <Divider style={{ backgroundColor: '#3c3c3c' }} />
          <ListItem
            className={classes.linksStyle}
            button
            onClick={toFeedbackPage}
          >
            <ListItemIcon>
              <FeedbackIcon className={classes.iconStyle} />
            </ListItemIcon>
            <StyledListItemText primary="Feedback" />
            <ChevronRightIcon className={classes.chevronRightIcon} />
          </ListItem>
          <Divider style={{ backgroundColor: '#3c3c3c' }} />
          <ListItem
            className={classes.linksStyle}
            button
            onClick={handleLogout}
          >
            <ListItemIcon>
              <SVGWrapper src={logoutIcon} className={classes.logoutIconStyle} />
            </ListItemIcon>
            <StyledListItemText primary="Logout" />
            <ChevronRightIcon className={classes.chevronRightIcon} />
          </ListItem>
        </List>
        <Divider style={{ backgroundColor: '#3c3c3c' }} />
      </div>
      <div
        style={{
          padding: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {/* <LogoutButton clickLogout={handleLogout} /> */}
      </div>
      <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleClose} />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    renderFeedbackPanel,
    renderProfilePanel,
    renderInputPanel,
    renderEditPanel,
    renderSelectedCallerTunePanel,
    updateIsUserLoggedInStatus,
    trackSearchPath
    // updateField: (form) => dispatch(reset(form)),
  },
  dispatch
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileLinks)
);
