import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactSelect from '@formElements/ReactSelect';
import { EN } from '@localization';
import { reduxForm } from 'redux-form';
import Textfield from '@formElements/TextField';

const SET_ORDER_OPTIONS = [{ label: '1' }, { label: '2' }, { label: '3' }, { label: '4' }, { label: '5' }];

const useStyles = makeStyles((theme) => ({
  top: {
    background: theme.palette.bgThemeColor,
    margin: '15px',
    borderRadius: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      background: '#242424',
    },
    [theme.breakpoints.up('sm')]: {
      background: theme.palette.bgThemeColor,
    }
  },
  btns: {
    width: '100%',
    gap: '5%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
  },
  setOrderLabel: {
    color: '#b5b5b5',
    fontFamily: 'Arial',
    fontSize: '2.3125rem',
    textTransform: 'uppercase'
  },
  setOrderBtn: {
    borderRadius: '15px',
    backgroundColor: 'rgb(0, 210, 241)',
    color: theme.palette.titleClr,
    width: '150px',
    textTransform: 'capitalize',
    fontWeight: '300',
    [theme.breakpoints.between('0', '1024')]: {
      color: theme.palette.selectedTxtClr,
      fontSize: '0.8em',
      height: 30,
    },
    [theme.breakpoints.between('1025', '5000')]: {
      background: `linear-gradient(126deg, ${theme.palette.selectedTxtClr}, #181D44)`,
      width: '8em',
      color: '#ffffff',
      fontSize: '1.125rem',
    },
  },
  cancelBtn: {
    borderRadius: '15px',
    color: theme.palette.titleClr,
    backgroundColor: '#4a4a4a',
    width: '150px',
    textTransform: 'capitalize',
    fontWeight: '300',
    [theme.breakpoints.down('1024')]: {
      fontSize: '1.125rem'
    },
  },
  modalTopDiv: {
    maxHeight: 'calc(100vh - 210px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  paper: {
    margin: 'auto',
    marginTop: '2%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    background: theme.palette.bgThemeColor,
    border: `2px solid ${theme.palette.bgThemeColor}`,
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SetOrderModal = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState(-1);
  const [errorMsg, setErrorMsg] = useState('');
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false);

  const onChangeOfOrder = (e) => {
    console.log('order value: ', e);
    if (e > 0) {
      setErrorMsg('');
      setDisplayErrorMsg(false);
    } else {
      setErrorMsg(EN.PLEASE_SELECT_ORDER);
      setDisplayErrorMsg(true);
    }
    setOrder(e);
  };

  const handleSubmit = () => {
    if (order > 0) {
      setErrorMsg('');
      setDisplayErrorMsg(false);
      props.setOrderOfMedia(order);
    } else {
      setErrorMsg(EN.PLEASE_SELECT_ORDER);
      setDisplayErrorMsg(true);
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <div className={classes.top}>
          <div className={classes.header}>
            <div className={classes.setOrderLabel}>
              <p><b>{props.isEdit ? props.modalHeading : EN.SETORDER}</b></p>
            </div>
          </div>

          <div className={classes.modalTopDiv}>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <Textfield
                    id="title"
                    type="text"
                    label={EN.TITLE}
                    disabled
                    displayLabel
                    defaultValue={props.mediaDetails ? props.mediaDetails.title : ''}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <ReactSelect
                    id="setOrder"
                    label={EN.SETORDER}
                    placeholder={EN.SETORDER}
                    options={SET_ORDER_OPTIONS}
                    displayLabel
                    value={order}
                    onChange={onChangeOfOrder}
                    isNone
                    disabled={false}
                    required
                    errorMsg={errorMsg}
                    displayErrorMsg={displayErrorMsg}
                  />
                </div>
              </div>
              <div className={classes.btns}>
                <Button
                  className={classes.cancelBtn}
                  variant="contained"
                  onClick={() => props.setOrderModalOpen(false)}
                >
                  <b>{EN.CANCEL}</b>
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  className={classes.setOrderBtn}
                  variant="contained"
                  color="secondary"
                >
                  <b>{EN.SETORDER}</b>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'SetOrderModal', // a unique identifier for this form
})(SetOrderModal);
