/* eslint-disable */
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { bindActionCreators } from 'redux';
import { Box, Avatar, Badge, makeStyles, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import PremiumIcon from '@assets/images/PremiumIcon.svg';
import {
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
  renderFeedbackPanel,
  renderSelectedCallerTunePanel,
  renderSubscriptionPanel,
} from '../../reducers/ui';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 10,
    background: '#6B6B6B',
    color: '#FABFCF',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8em',
      width: '1.5em',
      height: '1.5em',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1em',
      width: '1.8em',
      height: '1.8em',
    },
  },
}))(Badge);

const useStyles = makeStyles(theme => ({
  align: {
    width: '100%',
    [theme.breakpoints.up('1024')]: {
      display: 'flex',
      height:'30vh'
    },
    [theme.breakpoints.down('1024')]: {
      height:'15vh',
      marginTop:'30px',

    },
    justifyContent: 'center',
    flexDirection: 'row',
    height: '15vh',
    [theme.breakpoints.up('sm')]: {
      background: '#242424',
    },
    [theme.breakpoints.down('sm')]: {
      color: '#b9b9b9',
    },
  },
  options: {
    margin: 'auto',
    color: theme.palette.selectedTxtClr,
    background: theme.palette.bgThemeColor,
    boxShadow: `${theme.palette.selectedTxtClr} 0px 2px 15px 0px`,
    [theme.breakpoints.down('sm')]: {
      boxShadow: `${theme.palette.selectedTxtClr} 0px 2px 15px 0px`,
      height: '2.5em',
      width: '2.5em',
    },
    [theme.breakpoints.up('sm')]: {
      height: '4.5em',
      width: '4.5em',
      background: theme.palette.bgThemeColor,
    },
  },
  desktop: {
    flexGrow: 1,
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    textAlign: 'center',
    borderRadius: '15px',
    margin: '0 5px',
    '&:hover': {
      background: 'linear-gradient(110deg,#fff 50%,#FABFCF 50%)',
      border: '2px solid #FABFCF',
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: '#EE255C 0px 0px 15px 0px',
    },
  },
  iconStyle: {
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.selectedTxtClr,
      width: '1.8em',
      height: '1.8em',
    },
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.selectedTxtClr,
      width: '1.3em',
      height: '1.3em',
    },
  },
  optionStyle: {
    [theme.breakpoints.up('1024')]: {
      textAlign:'center',
      marginTop: '10px',
      fontWeight: 'bold',
      fontFamily: 'SegoeUI',
      fontSize:'1rem'
    },
    [theme.breakpoints.down('1024')]: {
      textAlign:'center',
      marginTop: '10px',
      fontWeight: 'bold',
      fontFamily: 'SegoeUI',
      fontSize:'0.625rem'
    },
  }
}));

const ProfileOptions = props => {
  const classes = useStyles();
  const handleProfile = () => {
    props.renderEditPanel(true);
    props.renderInputPanel(false);
    props.renderProfilePanel(false);
    props.renderFeedbackPanel(false);
    props.renderSelectedCallerTunePanel(false);
    props.renderSubscriptionPanel(false);
  };

  const toCallerTune = () => {
    props.renderFeedbackPanel(false);
    props.renderProfilePanel(false);
    props.renderInputPanel(false);
    props.renderEditPanel(false);
    props.renderSelectedCallerTunePanel(true);
    props.renderSubscriptionPanel(false);
  };

  const handleSubscription = () => {
    props.renderFeedbackPanel(false);
    props.renderProfilePanel(false);
    props.renderInputPanel(false);
    props.renderEditPanel(false);
    props.renderSelectedCallerTunePanel(false);
    props.renderSubscriptionPanel(true);
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '5%',
          color: '#fff',
        }}
      >
        <div className={classes.align}>
          <Box
            sx={{ alignSelf: 'flex-start', cursor: 'pointer' }}
            onClick={handleProfile}
          >
            <Avatar className={classes.options}>
              <PersonRoundedIcon className={classes.iconStyle} />
            </Avatar>
           <div className={classes.optionStyle}>MY PROFILE</div>
          </Box>
        </div>
        <div className={classes.align}>
          <Box sx={{ margin: 'auto', cursor: 'pointer'  }} onClick={toCallerTune}>
            <Avatar className={classes.options}>
              <QueueMusicIcon className={classes.iconStyle} />
            </Avatar>
           <div className={classes.optionStyle}>WELCOME TONE</div>
          </Box>
        </div>
        {/* <Box className={classes.align}>
          <Box sx={{ textAlign: 'center' }}>
            <StyledBadge badgeContent={56} color="secondary">
              <Avatar className={classes.options}>
                <NotificationsIcon className={classes.iconStyle} />
              </Avatar>
            </StyledBadge>
            <div className={classes.optionStyle}>NOTIFICATION</div>
          </Box>
        </Box> */}
        <div className={classes.align}>
          <Box
            sx={{ alignSelf: 'flex-start', cursor: 'pointer' }}
            onClick={handleSubscription}
          >
            <Avatar className={classes.options}>
              <img className={classes.iconStyle} src={PremiumIcon} alt='PremiumIcon' />  
            </Avatar>
           <div className={classes.optionStyle}>SUBSCRIPTION</div>
          </Box>
        </div>
        
      </Box>
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      renderEditPanel,
      renderInputPanel,
      renderProfilePanel,
      renderFeedbackPanel,
      renderSelectedCallerTunePanel,
      renderSubscriptionPanel
      // updateField: (form) => dispatch(reset(form)),
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(ProfileOptions));
