import React from 'react';
import styles from './Logo.module.css';

const Logo = ({ text }) => {
  return (
    <span className={styles.logo}>
      {text}
    </span>
  );
};

export default Logo;
