/* eslint-disable */
import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import lyricIcon from '@assets/images/lyrics.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { postFavouriteMusic } from '../../../reducers/favourite';
import NotificationMessage from '../../NotificationMessage';
import SVGWrapper from "react-svg-wrapper";


const useStyle = makeStyles((theme) => ({
  addIconstyle: {
    borderRadius: '50%',
    background: '#616161',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width:1024px)': {
      fontSize: '20px',
      height: '20px',
      width: '20px',
      marginRight: '15px',
    },
    '@media (max-width:1024px)': {
      fontSize: '12px',
      height: '16px',
      width: '16px',
      marginRight: '10px',
    },
  },
  lyricStyle: {
    cursor: "pointer",
    marginRight: '10px',
    height: '20px',
    '& .lyricStyle': {
      fill: theme.palette.menuClr,
      stroke: "#d6d6d6",
      // stroke: theme.palette.menuClr,
    }
  },
  listItem: {
    '@media (min-width:1024px)': {
      padding: '5px 10px !important',
      margin: '0 !important',
      color: '#ababab',
    },
    '@media (max-width:1024px)': {
      padding: '5px !important',
      margin: '0 !important',
      fontSize: '12px',
      background: '#5d5652',
      // background: '#896c6c26',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(11.5px)',
      // -webkit-backdrop-filter: 'blur(11.5px)',
      color: theme.palette.titleClr,
      minHeight: '40px'
    },
  },
}));

function CustomPopper(props) {
  const classes = useStyle();
  const mobile = useMediaQuery('(max-width:1024px)');
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');

  useEffect(() => {
    if (mobile && props.popperRef) props.popperRef.current = handleCall;
  }, [mobile]);

  const handleCall = (mediaId) => {
    props.postFavouriteMusic(mediaId).then((result) => {
      setOpen(true);
      setMsg(result.message);
      setMsgType("success");
    }).catch((error) => {
      setOpen(true);
      setMsg(error.errorMessage);
      setMsgType("error");
    });
    setTimeout(() => {
      setOpen(false);
    }, 300000);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Popper open={props.popperOpen} role={undefined} transition disablePortal style={{ position: 'absolute' }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper style={{ backgroundColor: '#3e3e3e' }}>
              <ClickAwayListener onClickAway={props.handleCloseAway}>
                <MenuList
                  className={classes.listItem}
                  autoFocusItem={props.popperOpen}
                  id="menu-list-grow"
                  onKeyDown={props.handlePopperListKeyDown}
                >
                  {props.menu.slice(0, props.musicPlayer === true ? (props.showLyrics ? props.menu.length : props.menu.length - 1) : props.menu.length).map((item) => (
                    <>
                      <MenuItem className={classes.listItem} onClick={item.handleClick}>
                        {item.icon && (
                          <div>
                            {item.value === 'Lyrics' && props.showLyrics &&
                              <SVGWrapper
                                className={classes.lyricStyle}
                                src={lyricIcon}
                                alt='lyrics'
                              />}

                            {item.value !== 'Lyrics' && <item.icon className={classes.addIconstyle} style={{ fontSize: '15px', color: '#d6d6d6' }} />}
                          </div>
                        )}
                        {item.value === 'Lyrics' && props.showLyrics && (item.value)}
                        {item.value !== 'Lyrics' && (item.value)}

                      </MenuItem>
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleClose} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postFavouriteMusic,
    },
    dispatch
  );
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomPopper));
 // export default CustomPopper;
