import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'react-router-redux';
import { createTheme, ThemeProvider } from '@material-ui/core';

import store from './store';
import Routes from './routes';
import './app.css';
import './normalize.css';

import * as serviceWorker from './serviceWorkerRegister';

import './include/bootstrap';

const target = document.querySelector('#root');

const theme = createTheme({
  palette: {
    menuClr: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.menuClr,
    bgThemeColor: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.bgThemeColor,
    titleClr: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.titleClr,
    selectedTxtClr: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.selectedTxtClr,
    leftmenuSelectedColor: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.leftmenuSelectedColor,
    leftmenuHoverColor: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.leftmenuHoverColor,
    mobileViewBioFont: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.mobileViewBioFont,
    middleViewBioFont: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.middleViewBioFont,
    largeViewBioFont: JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.largeViewBioFont
  },
});

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <div>
        <Routes />
      </div>
    </ThemeProvider>
  </Provider>,
  target
);

serviceWorker.register();
