/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { trackSearchPath } from '../../reducers/ui';
import DrtumiTC from './artist/DrtumiTC';
import DjtiraTC from './artist/DjtiraTC';
import KhuliLamiez from './artist/KhuliLamiez';
import GigiverseTC from './artist/GigiverseTC';
import BlxckieTC from './artist/BlxckieTC';
import BraggaTC from './artist/BraggaTC';

const useStyles = makeStyles((theme) => ({
    iconStyle: {
        color: theme.palette.selectedTxtClr,
        width: '1.5em',
        height: '1.5em',
        marginRight: '10px',
        [theme.breakpoints.down('1024')]: {
            width: '1em',
            height: '1em',
            color: theme.palette.selectedTxtClr
        },
    },
    main: {
        paddingLeft: '50px',
        paddingRight: '50px',
        color: '#b9b9b9',
        [theme.breakpoints.down('1024')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
            backgroundColor: '#242424'
        },
    },
    title: {
        fontSize: '44px',
        fontWeight: 900,
        [theme.breakpoints.down('1024')]: {
            fontSize: '26px',
        },
    },
    arrow: {
        fontSize: '45px',
        color: theme.palette.selectedTxtClr,
        cursor: 'pointer',
        alignSelf: 'center',
        [theme.breakpoints.down('1024')]: {
            fontSize: '25px',
            color: theme.palette.selectedTxtClr
        },
        marginRight: '10px',
    },
    ArrowNIcon: {
        display: 'flex',
        paddingTop: '20px',
        paddingBottom: '30px',
        alignItems: 'center'
    },
    subTitle: {
        fontSize: '20px',
        fontWeight: 800,
        textDecoration: 'underline',
        [theme.breakpoints.down('1024')]: {
            fontSize: '16px',
        },
        marginTop: '20px',
        marginBottom: '10px',
    },
    mainTitle: {
        fontSize: '30px',
        fontWeight: 900,
        textAlign: 'center',
        [theme.breakpoints.down('1024')]: {
            fontSize: '26px',
        },
    },
    boldValues: {
        fontWeight: 900
    },
    voucherTitle: {
        fontSize: '30px',
        fontWeight: 900,
        textDecoration: 'underline',
        [theme.breakpoints.down('1024')]: {
            fontSize: '16px',
        },
        textAlign: 'center',
    },


}));

const TermsAndCondition = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const mobileView = useMediaQuery('(max-width:1024px)');
    const privacyPolicyUrl = window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails'))?.properties?.privacyPolicyUrl;

    const RedirectToProfile = () => {
        if (mobileView) {
            history.goBack();
        } else {
            history.goBack();
            // console.log('searchPath','back');
            // history.push(props.searchLocation);
        }
    }
    return (
        <>
            <div className={classes.main}>
                <div className={classes.ArrowNIcon}>
                    <ArrowBackIcon className={classes.arrow}
                        onClick={RedirectToProfile}
                    />
                    <div className={classes.title}>Terms And Conditions</div>
                </div>
                <ol>
                    <li>
                        These terms and conditions govern the relationship between You (“
                        <span className={classes.boldValues}>You</span>
                        ” or “
                        <span className={classes.boldValues}>Your</span>
                        ”) and Vodacom and its affiliates (hereinafter “
                        <span className={classes.boldValues}>Vodacom</span>
                        ” or “
                        <span className={classes.boldValues}>Us</span>
                        ” or “
                        <span className={classes.boldValues}>We</span>”
                        ) and OnMobile Global SA Pty (“
                        <span className={classes.boldValues}>Partner</span>
                        ”) regarding Your use of the Celebrity Connect Service and related features (the “
                        <span className={classes.boldValues}>Service</span>
                        ”) (“
                        <span className={classes.boldValues}>Terms and Conditions</span>
                        ”). Use of the Service is also governed by the &nbsp;
                        <a target="_blank" style={{ textDecoration: 'underline', color: '#b9b9b9' }} href={privacyPolicyUrl}>Privacy Policy</a>
                        &nbsp;which is incorporated herein by reference.
                    </li>
                </ol>
                <div className={classes.mainTitle}>
                    IMPORTANT PROVISIONS/ ACCEPTANCE OF THESE TERMS AND CONDITIONS
                </div>
                <ol start={2}>
                    <li style={{ marginTop: 15 }}>
                        <span className={classes.boldValues} style={{ textDecoration: 'underline' }}>Please read these Terms and Conditions and the </span>
                        <a target="_blank" style={{ textDecoration: 'underline', color: '#b9b9b9' }} href={privacyPolicyUrl}>Privacy Policy</a>
                        <span className={classes.boldValues} style={{ textDecoration: 'underline' }}> carefully before using the Service or submitting
                            information through the Service. By using the Service or submitting information through
                            the Service, You are agreeing to these Terms and Conditions. If You do not agree,
                            please do not use this Service or submit any information through the Service.
                            We reserve the right, at any time, to modify and update these Terms and Conditions
                            from time to time by posting such updated Terms and Conditions on the Vodacom website.
                            Any such updates shall apply to You and Your use of the Service and by continuing
                            to use the Service, You shall be deemed to have accepted such changes.
                        </span>
                    </li>
                    <div className={classes.subTitle}>
                        General:
                    </div>
                    <li>
                        As a user of the Service, You will have the option to subscribe on a once off basis or
                        recurring basis, with either scenario making You a Paying User (“
                        <span className={classes.boldValues}>Subscribed</span>
                        ”) and doing so accordingly binds You to these Terms and Conditions. As a subscribed user,
                        You will be asked to submit personal information to us. We are committed to ensuring responsible
                        practices in the collection, use and disclosure of Your personal information in accordance
                        with applicable law and the&nbsp;
                        <a target="_blank" style={{ textDecoration: 'underline', color: '#b9b9b9' }} href={privacyPolicyUrl}>Privacy Policy</a>
                        &nbsp;incorporated by reference into and form a binding part of these Terms and Conditions.
                    </li>
                    <li className='mt-20'>
                        By being Subscribed, You agree to the following additional rules upon successful initial
                        registration for the Service:
                    </li>
                    <div className={classes.subTitle}>
                        Free Trial Day:
                    </div>
                    <li>
                        You will be eligible to access all the content on the Service free of charge for 1 (one) day
                        only (the “
                        <span className={classes.boldValues}>Free Trial Day</span>
                        ”). For the avoidance of doubt, You will not be eligible for another
                        Free Trial Day if You cancel Your subscription and then re-register at a later stage.
                    </li>
                    <div className={classes.subTitle}>
                        Subscription Fees:
                    </div>
                    <li>
                        After the Free Trial Day, You will be charged a subscription fee of ZAR R3,00 (three Rand
                        per day) (incl. VAT) or ZAR15, 00 (fifteen Rand) per week or R30,00 (thirty Rand) per
                        month (incl. VAT) (the “
                        <span className={classes.boldValues}>Fee</span>
                        ”) for each day that You remain subscribed for the Service.
                    </li>
                    <li className='mt-20'>
                        If You are a pre-paid or top-up user:
                        <ol>
                            <li>
                                the Fee will be deducted from Your available daily airtime balance. You acknowledge that
                                You must have enough airtime available to pay the Fee in order to continue to use the
                                Service;
                            </li>
                            <li>
                                the Fees will be set out in Your monthly statement for payment. In the event that You do
                                not pay the Fee set out in Your monthly statement, Your subscription to the Service may
                                be suspended;
                            </li>
                            <li>
                                before the expiry of the Free Trial Day, You may cancel Your subscription to the Service
                                to avoid being charged the Fee.
                            </li>
                            <li>
                                only one subscription is permitted per mobile number. Vodacom and non-Vodacom (where
                                they are able to do so) users within the Republic of South Africa may subscribe.
                            </li>
                        </ol>
                    </li>
                    <li className='mt-20'>
                        If You are a contract user, the Fee will be set out in Your monthly statement. If You do not
                        pay Your monthly Fee, Your subscription may be suspended by Vodacom in its discretion.
                    </li>
                    <div className={classes.subTitle}>
                        Micro billing:
                    </div>
                    <li>
                        If we are unable to bill the full value of the subscription You have chosen for the Service,
                        we may implement micro-billing. Micro billing occurs when You have subscribed to Service
                        and You do not have sufficient funds in Your account (i.e., Your airtime balance) to pay for
                        the subscription that You have chosen. Micro-billing will allow Vodacom to charge a
                        discounted subscription or any reduced amount as we may determine from time to time
                        dependent on the available balance until we can charge You the full amount. If we are
                        successful in billing an amount, in part of in full for the end of the applicable period, that
                        billing record will stand as the completed billing for that particular period (day, week, or
                        month, depending on the option chosen). Billing for the next day or period will revert back
                        to attempting to bill the full daily Fee or stated price. Billing does not occur in arrears.
                        Micro-billing shall be implemented in Vodacom’s sole and absolute discretion.
                        You must have enough airtime available for payment of the subscription You have chosen
                        for the Service to keep Your subscription in an active state. You acknowledge that in the
                        event we are unable to bill any valid value for Your subscription of the Service that You
                        have chosen, we will continue to attempt to bill and enable the subscription for a maximum
                        period of 90 (ninety) consecutive days after the last successful bill collected. Should we
                        be unable to collect any funds for a period of 90(ninety) consecutive days, You will be
                        automatically unsubscribed from the Service. There is no micro-billing for a weekly or
                        monthly subscription.
                    </li>
                    <li className='mt-20'>
                        You acknowledge that the Service has certain content and features which are locked and
                        unavailable for access unless You become Subscribed.
                    </li>
                    <li className='mt-20'>
                        You give us consent to provide You with various messages about / based on Your
                        interaction with the Service. These messages will be delivered to You in the form of text
                        messages sent to Your mobile device using the mobile number identifying You as a user
                        of the Service.
                    </li>
                    <li className='mt-20'>
                        These messages include, but are not limited to:
                        <ol>
                            <li>
                                periodic messages to encourage, or remind You to browse or access the Service;
                            </li>
                            <li>
                                messages inviting You to share, voluntarily, data about Yourself or Your preferences;
                            </li>
                            <li>
                                messages inviting You to try alternate versions of the Service based on Vodacom’s
                                knowledge of the device You are using to access the Service;
                            </li>
                            <li>
                                confirmation messages relating to Your subscribing for, or cancellation of, the Service;
                            </li>
                            <li>
                                messages inviting You to rate the Service’s quality and Your opinion of Your
                                experience of the Service as measured by the metric scale provided;
                            </li>
                            <li>
                                messages with Service content, including, but not limited to, confirmation of weekly
                                results/draws/ picks, and;
                            </li>
                            <li>
                                messages relating to the earning, notification, and servicing of rewards.
                            </li>
                        </ol>
                    </li>
                    <li className='mt-20'>
                        You may opt out of the messages above except for the system messages which are
                        mandatory in order for us to correctly administrate Your use of the Service. To opt out of
                        the messages, You are advised to make use of the functionality on the Service settings to
                        cancel messages, or alternatively, to send an opt-out message request to SMS STOP To
                        30881 or dial *135*181#.
                    </li>
                    <li className='mt-20'>
                        You will not be charged for interactions that You will have with the Service via SMS. Data
                        charges may apply for accessing the Service on website.
                    </li>
                    <li className='mt-20'>
                        You accept that the Service’s default language is English.
                    </li>
                    <li className='mt-20'>
                        By subscribing, You agree that You will be opted into receiving the messages mentioned
                        above, which You may cancel at any time via the Service menu.
                    </li>
                    <li className='mt-20'>
                        You acknowledge that Your subscription information will be retained for use to allow You
                        access to future iterations and new features of the Service, and that You will be engaged
                        to continue using the Service as applicable for other functionality intended to be covered
                        by the Service.
                    </li>
                    <li className='mt-20'>
                        You acknowledge that any subscriptions in error will be billed. For Your protection, we ask
                        You to confirm all purchases twice.
                    </li>
                    <div className={classes.subTitle}>
                        Rewards and Prizes (if applicable):
                    </div>
                    <li>
                        If applicable and to be eligible for rewards from the Service, You acknowledge that You
                        need to have an active subscription for the Service.
                    </li>
                    <li className='mt-20'>
                        You acknowledge, understand, and agree that for Your subscription to the Service does
                        not guarantee any prizes or rewards from the Service. We shall not have any liability for
                        such loss or damage in the course of using the Service.
                    </li>
                    <li className='mt-20'>
                        Where applicable, prizes may not be substituted or transferred. All taxes associated with
                        the receipt or use of any prize is the sole responsibility of the winner. If any legal authority
                        contests the awarding of a prize, we may, in our sole discretion, determine whether or not
                        to award such a prize.
                    </li>
                    <li className='mt-20'>
                        Rewards can only be earned if You are a Vodacom customer. Any non-Vodacom
                        customers will be unable to earn rewards due to their being active on a different network.
                    </li>
                    <li className='mt-20'>
                        Only the qualifying Vodacom mobile number that is subscribed to the Service, can earn rewards.
                    </li>
                    <li className='mt-20'>
                        In the event that You terminate Your Vodacom contract, You accept that You forfeit the
                        use of any unused rewards by that point as well as the earning of future rewards from that point.
                    </li>
                    <div className={classes.subTitle}>
                        Subscription Rules:
                    </div>
                    <li className='mt-20'>
                        The Service provides subscribers with regularly updated content from South Africa’s top
                        artists and their environment. Subscribers get guaranteed exclusive access to content in
                        the form of text-based stories, pictures and photos, videos and audio files and social media
                        feeds. Such content and stories may include (but not be limited to) personal facts, lifestyle
                        insights, backstage experience sharing, interviews, artistic collaborations, or new
                        endorsement initiatives The Service is based on daily, weekly, or monthly subscription
                        offering '24 hours access eligibility'. The Service will be accessible via mobile friendly web
                        portal, as well as the Android mobile application.
                    </li>
                    <li className='mt-20'>
                        Customers can subscribe to the Service by:
                        <ol>
                            <li>
                                Customers can subscribe to the Service by:
                            </li>
                            <li>
                                Visiting the Website; <a target="_blank" style={{ textDecoration: 'underline', color: '#b9b9b9' }} href="https://celebrityconnect.co.za">celebrityconnect.co.za</a>
                            </li>
                            <li>
                                Other promotional means for e.g OBD the User will receive an automated voice call
                                and should follow the prompts to subscribe to the Service.
                            </li>
                        </ol>
                    </li>
                    <li className='mt-20'>
                        The Service will be available to You as part of a subscription service valid for either 1
                        (one), 7 (seven) or 30 (thirty) days (“
                        <span className={classes.boldValues}>subscription period</span>
                        ”). If You are electing to use the
                        Service on a month-to-month in which instance You will be able to cancel the Service at
                        any time before the expiry of the current month of use. To deactivate the recurring bundle,
                        You need to visit the Celebrity Connect web portal.
                    </li>
                    <li className='mt-20'>
                        Any existing in-bundle usage and any other loaded data in Your account will not be
                        consumed until the Service is depleted or expires. You can check Your bundle balance for
                        the Service by dialing *135#, and choosing option 1 – balances or via My Vodacom App.
                    </li>
                    <li className='mt-20'>
                        The subscription process (and terms and conditions) required for the Service shall at all
                        times be applicable and shall constitute an agreement between You and the. Vodacom
                        shall not be liable for any unavailability, faults glitches, bugs, or technical issues with the
                        Partner’s application and/or URL. Furthermore, Vodacom shall not be held liable for any
                        damages or loss howsoever arising that You may suffer due to use of the Partner’s
                        application and/or URL.
                    </li>
                    <li className='mt-20'>
                        No data pro-ration will occur.
                    </li>
                    <li className='mt-20'>
                        If You use the applications outside of the Service, You will be charged as per Your existing
                        tariff plan. If You follow a link outside the Partner’s application and/or URL, normal data
                        rates will apply as per Your existing tariff plan.
                    </li>
                    <li className='mt-20'>
                        Your data bundle depletion will occur for any banner advertising or in-stream advertising
                        that may be present within the Service.
                    </li>
                    <li className='mt-20'>
                        The Partner’s applications and/or URL may contain third party advertisements, content,
                        materials, and/or links to third party services and / or websites that are not owned or
                        controlled by Vodacom. Vodacom assumes no responsibility for, the content, privacy
                        policies or practices of any third-party websites or applications. In addition, Vodacom does
                        not and cannot censor or edit the content of any third-party website. When You visit third
                        party websites, Vodacom encourages You to read the terms and conditions and privacy
                        policies of the other websites. By using the Service, You expressly release Vodacom from
                        all liability arising from Your use of any third-party website or as a result of any third-party
                        services.
                    </li>
                    <li className='mt-20'>
                        You cannot use the Services when You are roaming. Whilst roaming the use of the
                        Partner’s application and/or URL will be charged at Your existing tariff plan.
                    </li>
                    <li className='mt-20'>
                        If You do a price plan migration, whilst using You have an existing active Service, such
                        Service shall be carried over to the new price plan.
                    </li>
                    <li className='mt-20'>
                        If You do a price plan upgrade, You will be able to add the Service to Your upgraded price plan.
                    </li>
                    <li className='mt-20'>
                        Vodacom reserves the right to terminate or change the Service at any time and for
                        whatsoever reason provided the on-going subscription period for the Service will not be affected
                    </li>
                    <div className={classes.subTitle}>
                        Your Obligations and Entitlements:
                    </div>
                    <div>
                        Vodacom and/or its appointed Partner reserves the right to determine what conduct it
                        considers to be in violation of the rules of use or otherwise outside the intent or spirit of
                        these Terms and Conditions or the Service itself. We reserve the right to act as a result,
                        which may include, if relevant, suspending the Service, terminating Your registration, and
                        prohibiting You from using the Service. Said suspension, termination and prohibition may
                        occur with or without notice to You, and as a result may involve the loss of any benefits,
                        privileges or earned items associated with Your use of the Service, and we are under no
                        obligation to compensate You for any such losses or results.
                    </div>
                    <div className={classes.subTitle}>
                        How to Unsubscribe?
                    </div>
                    <li>
                        You are entitled to unsubscribe from the Service at any time and for any reason by
                        following the “cancel” prompts available within the Service menu, or by:
                        <ol>
                            <li>
                                Using Self Service Channels by dialing *135*997#;
                            </li>
                            <li>
                                {window.sessionStorage.getItem('artistDetails') && JSON.parse(window.sessionStorage.getItem('artistDetails')).properties.termsMsg}
                            </li>
                            <li>
                                Calling Vodacom Customer Care: 082 135
                            </li>
                        </ol>
                    </li>
                    <li className='mt-20'>
                        When cancelling/ unsubscribing from the Service, You accept that Your old profile and
                        preferences will be retained for a 6 (six) month period and for no longer than 5 years (only
                        if necessary), in order to facilitate a convenient experience should You choose to re-
                        subscribe at a later stage within the 6 (six) month period.
                    </li>
                    <div className={classes.subTitle}>
                        Limitation of Liability
                    </div>
                    <li>
                        You agree that Your use of the Service shall be at Your sole risk. To the fullest extent
                        permitted by law, we and our officers, directors, employees, and agents disclaim all
                        warranties, express or implied, in connection with the services and Your use thereof. We
                        make no warranties or representations about the accuracy or completeness of the
                        Service’s content or the content of any websites or services linked to these services and
                        assume no liability or responsibility for any (i) errors, mistakes, or inaccuracies of content;
                        (ii) personal injury or property damage of any nature whatsoever resulting from Your
                        access to and use of the Service; (iii) any unauthorized access to or use of our servers
                        and/or any and all personal information and/or financial information stored therein; (iv) any
                        interruption or cessation of transmission to or from the Service; (v) any bugs, viruses, trojan
                        horses or the like which may be transmitted to or through the Service by any third party;
                        or (vi) any errors or omissions in any content or for any loss or damage of any kind incurred
                        as a result of the use of any content posted, emailed, transmitted, or otherwise made
                        available via the Service. We do not warrant, endorse, guarantee, or assume responsibility
                        for any product or Service advertised or offered by a third party through the Service or any
                        hyperlinked website or featured in any banner or other advertising, and we will not be a
                        party to or in any way be responsible for monitoring any transaction between You and
                        third-party providers of products or services.
                    </li>
                    <li className='mt-20'>
                        To the extent permitted by applicable law, in no event shall we or our subsidiaries and
                        affiliates, and their respective officers, directors, owners, agents, employees,
                        representatives and licensors be liable for any special, incidental, indirect, consequential,
                        punitive or exemplary losses or damages whatsoever or for loss of profits (including,
                        without limitation, damages for loss of revenue, loss of data, failure to realize expected
                        savings, interruption of activities, or any other pecuniary or economic loss) and whether
                        arising from breach of contract, damages (including negligence), strict liability or otherwise,
                        arising out of the use of or inability to use the Service or its content, or any product or
                        service described or provided through the Service.
                    </li>
                    <li className='mt-20'>
                        Save to the extent permitted by law, You agree to indemnify, defend and hold harmless
                        Vodacom from and against any and all claims, demands, actions, liability, losses, costs
                        and expenses (including legal fees and expenses) arising from or related to: (i) any use or
                        alleged use of the Service by any other person, whether or not authorized by You; (ii) Your
                        breach of these Terms and Conditions, including any breach of Your representations and
                        warranties herein; and (iii) Your violation of any law or (iv) Your use of or inability to use
                        the Service, the contents, any hyperlinked website, and any of the products and services
                        made available on the Service thereof.
                    </li>
                    <li className='mt-20'>
                        For support or any queries please contact us at <a href="mailto:celebrityconnect_feedback@onmobile.com" style={{ textDecoration: 'underline', color: '#b9b9b9' }}>celebrityconnect_feedback@onmobile.com</a>
                    </li>
                </ol>
                {/* {window.location.hostname.includes('localhost') && <BraggaTC />} */}
                {window.location.hostname.includes('braggaworld') && <BraggaTC />}
                {(window.location.hostname.includes('drtumi') && <DrtumiTC />)}
                {window.location.hostname.includes('djtira') && <DjtiraTC />}
                {(window.location.hostname.includes('gigiverse') || window.location.hostname.includes('artist')) && <GigiverseTC />}
                {window.location.hostname.includes('blxckie') && <BlxckieTC />}
                {(window.location.hostname.includes('khuliandlamiez') || window.location.hostname.includes('khulilamiez')) && <KhuliLamiez />}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            trackSearchPath,
        },
        dispatch
    );

const mapStateToProps = state => {
    return {
        searchLocation: state.ui.searchLocation,
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition));
