export default {
  // This is ENGLISH language specific constant file
  REQUIRED: ' *',
  LOGIN: 'Sign In',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONFIRM_PASSWORD: 'Confirm Password',
  MODAL_CANCEL: 'Cancel',
  LATEST_MEDIA: 'Latest Media',
  TITLE: 'Title',
  SONG_NAME: 'Music / Video name',
  DESCRIPTION: 'Description',
  SONG_DESCRIPTION: 'Music / Video Description',
  TAGS: 'Tags',
  INTERNAL_TAGS: 'Internal Tags',
  TAGS_EX: '#genre #latest #trending',
  INTERNAL_TAGS_EX: 'ssss_test ssss_test1',
  MEDIA_TYPE: 'Media Type',
  MODE: 'Mode',
  MUSIC: 'Music',
  VIDEO: 'Video',
  IMAGE: 'Image',
  UPLOAD: 'Upload',
  CREATE_ALBUM: 'Create Album',
  ADD_ALBUM: 'Add Album',
  THUMBNAIL: 'Thumbnail',
  POST: 'Post',
  POST_TITLE: 'Post title',
  SUB_NOTE: 'Sub Note',
  SELECT: 'Select',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  DELETE: 'Delete',
  VIDEO_EDIT: 'Edit Video',
  UPDATE: 'Update',
  GENRE: 'Genre',
  RELEASED_AT: 'Released At',
  EXPIRY_AT: 'Expiry At',
  LYRICS: 'Lyrics',
  SONG_LYRICS: 'Lyrics',
  CLIPID: 'Clip Id',
  PLEASE_SELECT_GENRE: 'Please select a genre',
  MUSIC_EDIT: 'Edit Music',
  NO_MEDIA: 'No Media Found',
  NO_POST: 'No Post Found',
  SETORDER: 'Set Order',
  PLEASE_SELECT_ORDER: 'Please select order',
  ARTIST_BIO: 'Artist Bio',
  LANDING_PAGE_TEXT: 'Landing Page Text',
  BANNER_IMAGE: 'Banner Image',
  LANDING_PAGE_IMAGES: 'Landing Page Images',
  LANDING_PAGE_IMAGES_FOR_MOB: 'Landing Page Images For Mobile',
  DETAILS: 'Details',
  SAVE: 'Save',
  PROFILE_IMAGE: 'Profile Image',
  LATEST_POST: 'Latest Post',
  MUSIC_DELETE_CONFIRMATION: 'Are you sure you want to delete this music ?',
  VIDEO_DELETE_CONFIRMATION: 'Are you sure you want to delete this video ?',
  POST_DELETE_CONFIRMATION: 'Are you sure you want to delete this post ?',
  TWITTER_HANDLE: 'Twitter account',
  FB_HANDLE: 'Facebook account',
  SHARE: 'Share',
  COPY: 'Copy',
  ALBUM_NAME: 'Album Name',
  ALBUM: 'Album',
  PAYMENT_FAILED: 'Payment Failed',
  ERROR: 'Error',
  SUSPENDED_MSG: 'Your premium service is suspended. Please recharge your Vodacom account to access the service.',
  INSUFFICIENT_BALANCE_MSG: 'Your payment has failed due to insufficient funds. Please recharge your account and try again.',
  GRACE_PERIOD_MSG: 'Your premium service is in grace period. Please recharge your Vodacom account to have hassle free service.',
  CONTACT_CC_MSG: 'Please contact Vodacom customer care to access the service.',
  INVALID_USER_ERROR_MSG: 'Please use your valid Vodacom number to re login again to continue premium content access.',
  INVALID_USER_ERROR_MSG_FOR_SET_TUNE: 'Please use Vodacom number to set yor favourite artist music as welcome tune.',
  SUSPENDED_USER_ERROR_MSG_FOR_SET_TUNE: 'Please recharge your Vodacom account to access the service.',
  CONSENT_LOG_ARTIST_PORTAL_SERVICE: 'Artist Portal',
  CONSENT_LOG_WELCOME_TONE_SERVICE: 'Welcome Tone',
  FIRST_CONSENT_APPROVAL: 'First consent approval',
  FIRST_CONSENT_REJECTION: 'First consent rejection',
  SECOND_CONSENT_APPROVAL: 'Second consent approval',
  SECOND_CONSENT_REJECTION: 'Second consent rejection',
  SOMETHING_WENT_WRONG: 'Oops! Something went wrong',
  NO_MEDIA_FOUND: 'No media found!!',
  DIGITAL_MARKETING: 'digital marketing',
  ADD_LIVE_STREAM: 'Add Live Stream',
  EVENT_ID: 'Event Id',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  INSTAGRAM_HANDLE: 'Instagram account',
  LIVE_STREAM_EDIT: 'Edit Live Stream',
  EVENT_URL: 'Event Url',
  COMING_SOON: 'COMING SOON',
  PRIZE: 'Prize',
  RESULTS_OUT_MSG: 'RESULTS WILL BE OUT SOON',
  WINNERS_LIST_TITLE: 'Winners List',
  NO_ACTIVE_EVENTS: 'New Competition coming soon',
  NO_UPCOMING_EVENTS: 'No Upcoming Events',
  NO_COMPETITION: 'No Competition Found',
  ENTER_CONTEST_TEXT: 'Enter the contest to win',
  INSTRUCTION: 'How to Win',
  ENTER_NOW: 'ENTER NOW',
  WIN_WITH: 'Win With',
  FREE_TRAIL_TEXT: '15 days free Premium',
  COMPETITION_ENDED: 'Competition Ended',
  COMPETITION_TITLE: 'Competition Title',
  MAIN_HEADER: 'Main Header',
  MAIN_DESCRIPTION: 'Main Description',
  SUB_DESCRIPTION: 'Sub Description',
  COMPETITION_MAIN_IMG: 'Competition Main Image',
  COMPETITION_SEC_IMG: 'Competition Secondary Image',
  PRIZE_IMG: 'Prize Image',
  PRIZE_DESC: 'Prize Description',
  INSTRUCTIONS: 'Instructions',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  COMPETITION_DELETE_CONFIRMATION: 'Are you sure you want to delete this competition ?',
  COMPETITION_END_CONFIRMATION: 'Are you sure you want to end this competition ?',
  NO_PARTICIPANTS: 'No Participants Found',
  NO_WINNERS: 'No Winners Found',
  ALL_PARTICIPANTS_STATUS: 'all',
  WINNER_STATUS: 'winner',
  DOWNLOAD_PARTICIPANTS: 'Download Participants',
  PREV: 'Prev',
  NEXT: 'Next',
  PARTICIPANTS: 'Participants',
  WINNERS: 'Winners',
  UPLOAD_WINNERS: 'Upload/Reset Winners',
  DOWNLOAD_WINNERS: 'Download Winners',
  END_COMPETITION: 'End',
  CREATE: 'Create',
  FILTER: 'Filter',
  FROM: 'From',
  TO: 'To',
  APPLY: 'Apply',
  CLEAR: 'Clear',
  WINNERS_LIST: 'Winners List',
  ANNOUNCE_WINNERS_LIST: 'Yet To Announce Winners!',
  CATEGORY: 'Category',
  NO_EVENTS: 'No Past Events',
  TOTAL_PARTICIPANTS: 'Total Participants',
  TOTAL_WINNERS: 'Total Winners',
  SUBSCRIPTION_STATUS_UPDATE: 'Subscription status update is in-progress for participants!',
  TRANCODING_FAILED: 'Video transcoding failed. Try uploading again!',
};
