/* eslint-disable */
export const FEEDBACK_REQUESTED = 'feedback/FEEDBACK_REQUESTED';
export const FEEDBACK_SUCCESS = 'feedback/FEEDBACK_SUCCESS';
export const FEEDBACK_FAILURE = 'feedback/FEEDBACK_FAILURE';

const initialState = {
    loading: false,
    loaded: false,
    error: false
  };

export default (state = initialState, action) => {
    switch (action.type) {
      case FEEDBACK_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case FEEDBACK_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case FEEDBACK_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      default:
        return state;
    }
  };
  
  export const feedbackData = (formData) => {
    return {
      types: [FEEDBACK_REQUESTED, FEEDBACK_SUCCESS, FEEDBACK_FAILURE],
      promise: (client) => client.post('sendfeedback', {
        data: formData,
      })
    };
  };
  
  
  