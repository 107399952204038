/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import ProfileHeader from '../ProfileHeader';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateUserDetails } from '../../reducers/profile';
import {
  renderEditPanel,
  renderInputPanel,
  renderProfilePanel,
} from '../../reducers/ui';
import { profilePicUpload } from '../../reducers/profilePic';
import { Grid, Paper, Input } from '@material-ui/core';
import { reduxForm, Field, isDirty } from 'redux-form';
import TextField from '@formElements/TextField';
import cn from 'classnames';
import { includes } from 'lodash';
import NotificationMessage from '../../components/NotificationMessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import moment from 'moment';

const alphabets = [
  'a', 'b', 'c', 'd', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
  'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'F', 'G', 'H', 'I', 'J',
  'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '`', '!', '@', '#', '$',
  '%', '^', '&', '*', '(', ')', '-', '_', '+', '+', ',', '.', '/', '?', ':', ';', '', '{', '}', '[', ']'
];

const useStyles = makeStyles((theme) => ({
  align: {
    color: theme.palette.selectedTxtClr,
    textDecorationLine: 'underline',
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  table: {
    margin: 'auto',
    marginBottom: '10vh',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '20px',
  },
  ButtonStyle: {
    margin: 'auto',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '8vh',
    padding: '20px',
  },
  saveBtn: {
    borderRadius: '50px',
    background: theme.palette.selectedTxtClr,
    color: theme.palette.titleClr,
    height: '50px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.selectedTxtClr,
      color: theme.palette.titleClr
    },
  },
  cancelBtn: {
    borderRadius: '50px',
    marginBottom: '15px',
    height: '50px',
    backgroundColor: 'grey',
    color: theme.palette.titleClr,
    textTransform: 'none',
    '&:hover': {
      background: 'grey',
      color: theme.palette.titleClr
    },
  },
  DesktopBtn: {
    margin: 'auto',
    maxWidth: '500px',
    gap: '5px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '15vh',
    padding: '20px',
  },
  fieldStyle: {
    color: theme.palette.selectedTxtClr,
    padding: '2px',
    fontSize: '0.875rem',
    backgroundColor: 'transparent',
    textAlign: 'end',
    border: '0',
  },
  valueStyle: {
    color: theme.palette.selectedTxtClr,
    background: 'transparent',
    border: '0',
    width: '90%'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  alignEnd: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '10%',
    [theme.breakpoints.up('1024')]: {
      paddingLeft: '15%',
    },
  },
  loaderbackdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  circleLoader: {
    color: theme.palette.selectedTxtClr,
  }
}));
const EditProfile = props => {
  const classes = useStyles();
  const desktops = useMediaQuery('(max-width:600px)');
  const [desktop, setDesktop] = useState('');
  const [thumbnailPath, setThumbnailpath] = useState(props.thumbPath);
  const [thumbnailForApiCall, setThumbnailForApiCall] = useState('');
  const [isThumbnailPresent, setThumbnailStatus] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const { handleSubmit } = props;
  const [openNoti, setOpenNoti] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [loader, setLoader] = useState(false);
  const [targetValue, setTargetValue] = useState('');

  useEffect(() => {
    setDesktop(desktops);
  }, []);

  useEffect(() => {
  }, [window.localStorage.getItem('name'), window.localStorage.getItem('email'), window.localStorage.getItem('dob'), window.localStorage.getItem('phone')]);

  const history = useHistory();
  const RedirectToProfile = () => {
    props.renderInputPanel(false);
    props.renderEditPanel(true);
    props.renderProfilePanel(false);
  };
  const save = 'save';
  const cancel = 'cancel';

  const submit = values => {
    setLoader(true);
    saveThumbnailData(values);
  }

  const saveThumbnailData = (values) => {
    if (props.isDirty && !isThumbnailPresent) {
      saveUserData(values);
    } else if ((!props.isDirty && isThumbnailPresent) || (props.isDirty && isThumbnailPresent)) {
      const formData = new FormData();
      formData.append('file', thumbnailForApiCall);
      props.profilePicUpload(formData).then((data) => {
        if (props.isDirty) {
          saveUserData(values);
        } else {
          setOpenNoti(true);
          setMsg(data.message);
          setMsgType("success");
          setTimeout(() => {
            setLoader(false);
            props.renderInputPanel(false);
            props.renderEditPanel(false);
            props.renderProfilePanel(true);
            setOpenNoti(false);
          }, 1000);
        }
      }).catch((error) => {
        setLoader(false);
        setOpenNoti(true);
        setMsg(error.errorMessage);
        setMsgType("error");
      });
    }
  }

  const saveUserData = (userDetails) => {
    const jsonData = {
      name: userDetails.name,
      // mobileNumber: userDetails.mobileNumber,
      email: userDetails.email,
      dob: userDetails.dob,
    };
    props.updateUserDetails(jsonData).then((data) => {
      setOpenNoti(true);
      setMsg(data.message);
      setMsgType("success");
      setTimeout(() => {
        setLoader(false);
        props.renderInputPanel(false);
        props.renderEditPanel(false);
        props.renderProfilePanel(true);
        setOpenNoti(false);
      }, 1000);
    }).catch((error) => {
      setLoader(false);
      setOpenNoti(true);
      setMsg(error.errorMessage);
      setMsgType("error");
    });
  }

  const UploadUserThumbnail = (e) => {
    setThumbnailStatus(true);
    setThumbnailpath(URL.createObjectURL(e.target.files[0]));
    setThumbnailForApiCall(e.target.files[0]);
  }

  const removeThumbnailImg = () => {
    setThumbnailStatus(true);
    setThumbnailpath('');
    setThumbnailForApiCall('');
  }

  const handleClick = () => {
    () => { hiddenFileInput.click() };
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNoti(false);
  };

  const handleProfile = (profileType, e) => {
    if(profileType === 'profileName'){
      const re = /^[0-9a-zA-Z ]*$/;
      if ((e.target.value.match(re))) {
        setTargetValue(e.target.value);
      }
      else{
        e.preventDefault();
      }
    }
    else{
      const re = /^[0-9a-zA-Z @.]*$/;
      if ((e.target.value.match(re))) {
        setTargetValue(e.target.value);
      }
      else{
        e.preventDefault();
      }
    }
    
  }

  return (
    <>
      <div style={{ backgroundColor: '#242424', height: '100vh' }}>
        <ProfileHeader thumbPath={thumbnailPath} />
        <Typography className='d-flex'>
          <label htmlFor="thumbnail" className={classes.align} onClick={handleClick}>Choose Pic</label>
          <input id="thumbnail" type="file" accept=".jpg, .jpeg, .png" ref={hiddenFileInput} style={{ display: 'none' }} onChange={UploadUserThumbnail} />
          {/* <Link href="#" onClick={UploadUserThumbnail} className={classes.align}>
        Choose Pic
      </Link> */}
          <label className={classes.align} onClick={removeThumbnailImg}>Remove Pic</label>
        </Typography>
        <form onSubmit={handleSubmit(submit)} initialValues={props.initialValues} autoComplete="off">
          {/* <Grid> */}
          <Grid container className="mt-30">
            <Grid container item xs={12}>
              <Grid item xs={4} className={classes.alignEnd}>
                <label className={classes.fieldStyle}>Username -</label>
              </Grid>
              <Grid item xs={8} className={classes.alignCenter}>
                <Field name="name" component="input" type="text" value={targetValue} onChange={(e) => handleProfile('profileName', e)} className={classes.valueStyle} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={4} className={classes.alignEnd}>
                <label className={classes.fieldStyle}>Birthday -</label>
              </Grid>
              <Grid item xs={8} className={classes.alignCenter}>
                <Field name="dob" component="input" type="date" max={moment().format("YYYY-MM-DD")} className={cn(classes.valueStyle, 'nml-30')} />
              </Grid>
            </Grid>
            <Grid container item xs={12}  style={{display: 'none'}} >
              <Grid item xs={4} className={classes.alignEnd}>
                <label className={classes.fieldStyle}>Phone -</label>
              </Grid>
              <Grid item xs={8} className={classes.alignCenter}>
                <Field
                  name="mobileNumber"
                  component="input"
                  type="number"
                  disabled={true}
                  onKeyDown={(e) => {
                    if (includes(alphabets, e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className={classes.valueStyle}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} >
              <Grid item xs={4} className={classes.alignEnd}>
                <label className={classes.fieldStyle}>Email -</label>
              </Grid>
              <Grid item xs={8} className={classes.alignCenter}>
                <Field
                  className={classes.valueStyle}
                  name="email"
                  component="input"
                  type="text"
                  value={targetValue} onChange={(e) => handleProfile('profileEmail', e)}
                />
              </Grid>
            </Grid>
          </Grid>
          {desktops ? (
            <div className={classes.ButtonStyle}>
              <Button
                className={classes.cancelBtn}
                variant="contained"
                fullWidth
                onClick={RedirectToProfile}
                type="cancel"
              >
                <b>{cancel.charAt(0).toUpperCase() + cancel.slice(1)}</b>
              </Button>
              <Button
                className={classes.saveBtn}
                fullWidth
                variant="contained"
                // color="secondary"
                // onClick={saveDetails}
                type="submit"
              >
                <b>{save.charAt(0).toUpperCase() + save.slice(1)}</b>
              </Button>
            </div>
          ) : (
            <div className={classes.DesktopBtn}>
              <Button
                className={classes.cancelBtn}
                variant="contained"
                fullWidth
                onClick={RedirectToProfile}
                type="cancel"
              >
                <b>{cancel.charAt(0).toUpperCase() + cancel.slice(1)}</b>
              </Button>
              <Button
                className={classes.saveBtn}
                fullWidth
                variant="contained"
                color="secondary"
                // onClick={saveDetails}
                type="submit"
              >
                <b>{save.charAt(0).toUpperCase() + save.slice(1)}</b>
              </Button>
            </div>
          )}
          {/* </Grid> */}
        </form>
        <NotificationMessage open={openNoti} msgType={msgType} msg={msg} handleClose={handleClose} />
      </div>
      <Backdrop className={classes.loaderbackdrop} open={loader}>
        <CircularProgress className={classes.circleLoader} />
      </Backdrop>
    </>
  );
};
const mapStateToProps = state => {
  return {
    userDetails: state.otp.userData,
    isDirty: isDirty('EditProfileForm')(state),
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserDetails,
      renderEditPanel,
      renderInputPanel,
      renderProfilePanel,
      profilePicUpload
      // updateField: (form) => dispatch(reset(form)),
    },
    dispatch
  );
const EditProfileForm = reduxForm({
  // a unique name for the form
  form: 'EditProfileForm',
  enableReinitialize: true,
})(EditProfile);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditProfileForm)
);
