/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Avatar } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { renderEditPanel, renderInputPanel, renderProfilePanel, renderFeedbackPanel, renderSelectedCallerTunePanel, renderCircularProgress } from '../../reducers/ui';
import { feedbackData } from '../../reducers/feedback';
import CardCallerTune from './CardCallerTune';
import { getRBTcallerTuneList } from '../../reducers/rbt';
import CircularProgress from '@material-ui/core/CircularProgress';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import { EN } from '@localization';

const useStyles = makeStyles((theme) => ({
    top: {
        background: theme.palette.bgThemeColor,
        // margin: '15px',
        borderRadius: '10px',
        [theme.breakpoints.up('1024')]: {
            marginTop: '20px'
        },
        position: 'relative'
    },
    header: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            background: '#242424',
        },
        [theme.breakpoints.up('sm')]: {
            background: theme.palette.bgThemeColor,
        },
        // height:'auto'
    },
    img: {
        clipPath: 'circle(80%)',
        shapeOutside: 'circle(80%)',
        height: '50%',
        width: '50%',
        [theme.breakpoints.down('1024')]: {
            height: '90%',
            width: '35%',
            // boxShadow: '#EE255C 0px 2px 15px 0px',
        },
        // borderRadius:'5px'
    },
    ActiveText: {
        color: theme.palette.selectedTxtClr,
        fontSize: '1.25rem',
        marginBottom: '30px',
        marginTop: '10px'
    },
    CardCaller: {
        width: '90%',
        maxHeight: '500px',
        overflowY: 'scroll',
        [theme.breakpoints.up('1024')]: {
            width: '25vw',
            maxHeight: '280px',
            minHeight: '250px'
        },
    },
    premium: {
        marginBottom: '30px',
        width: '100%',
        marginTop: '20px',
        [theme.breakpoints.down('1024')]: {
            width: '90%'
        },
    },
    options: {
        margin: 'auto',
        color: theme.palette.selectedTxtClr,
        background: '#414141',
        [theme.breakpoints.down('sm')]: {
            height: '5.5em',
            width: '5.5em',
        },
        [theme.breakpoints.up('sm')]: {
            height: '5.5em',
            width: '5.5em',
            background: '#414141',
        },
    },
    iconStyle: {
        [theme.breakpoints.up('sm')]: {
            color: theme.palette.selectedTxtClr,
            width: '1.8em',
            height: '1.8em',
        },
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.selectedTxtClr,
            width: '1.3em',
            height: '1.3em',
        },
    },
    circleLoader: {
        color: theme.palette.selectedTxtClr,
    }
}));

const SelectedCallerTune = (props) => {
    const classes = useStyles();

    useEffect(() => {
        props.renderCircularProgress(true);
        if(window.localStorage.getItem('phone')) {
            props.getRBTcallerTuneList(encodeURIComponent(window.localStorage.getItem('phone'))).then(() => {
                props.renderCircularProgress(false);
            })
        }
        // console.log('props.showCircularProgress', props.showCircularProgress);
    }, [])
    console.log('props.rbtCallertuneList', props.rbtCallertuneList)
    return (
        <>
            <div className={classes.top}>
                <div className={classes.header}>
                    {/* <img src={QueueMusic} alt='callertune' className={classes.img} /> */}
                    <div>
                        <Box sx={{ margin: 'auto', cursor: 'pointer' }}>
                            <Avatar className={classes.options}>
                                <QueueMusicIcon className={classes.iconStyle} />
                            </Avatar>
                        </Box>
                    </div>
                    <div className={classes.ActiveText}><b>Active Welcome Tone</b></div>
                    {props.showCircularProgress ?
                        <CircularProgress className={classes.circleLoader} /> :
                        (<div className={classes.CardCaller}>
                            {props.rbtCallerTuneMessage && props.rbtCallerTuneMessage.toUpperCase() === 'SUSPENDED' ? (
                                <div style={{ color: '#fff', fontSize: '1.125rem', textAlign: 'center' }}> {EN.SUSPENDED_USER_ERROR_MSG_FOR_SET_TUNE} </div> 
                            ) : (
                                <>
                                    {props.rbtCallertuneList.length === 0 ?
                                        <div style={{ color: '#fff', fontSize: '1.125rem', textAlign: 'center' }}> You are yet to set a welcome tune. Please visit music section to set one </div> 
                                    :
                                        <CardCallerTune cardTune={props.rbtCallertuneList} msisdn={window.localStorage.getItem('phone')} />
                                    }
                                </>
                            )}
                        </div>)
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        rbtCallertuneList: state.rbt.rbtCallertuneList,
        rbtCallerTuneMessage: state.rbt.rbtCallerTuneMessage,
        showCircularProgress: state.ui.showCircularProgress,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    renderEditPanel,
    renderInputPanel,
    renderProfilePanel,
    renderFeedbackPanel,
    feedbackData,
    renderSelectedCallerTunePanel,
    getRBTcallerTuneList,
    renderCircularProgress,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectedCallerTune));

