export const PROGRESS_BAR_SLIDER_OPTIONS = {
  min: 0,
  step: 1,
};

export const VOLUME_BAR_SLIDER_OPTIONS = {
  min: 0,
  max: 1,
  step: 0.01,
};
