/* eslint-disable */

import superagent from 'superagent';
import { APIConfig } from '../constants';
import IsJsonString from '@helpers/TypeCheckers';
// import axios from "axios";
// import { userInfo } from '@utility';

const methods = ['get', 'post', 'put', 'patch', 'del'];

const ENDPOINTS = APIConfig.endpoints;
const PORTS = APIConfig.ports;
// let HOSTNAME = APIConfig.hostname;


function formatUrl(path, bfrLogin) {
  let HOSTNAME = APIConfig.hostname;
  let apiPath = path;
  let apiPathArray = [];

  if (apiPath.indexOf('?') != -1) {
    apiPathArray = apiPath.split('?');
    apiPath = apiPathArray[0];
    apiPathArray.shift();
  }

  let mappedEndpoint = ENDPOINTS[apiPath];

  if (apiPath.indexOf('/') !== -1) {
    mappedEndpoint = '';
    let splitPathArray = apiPath.split('/');
    mappedEndpoint += ENDPOINTS[splitPathArray[0]] + '/';
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join('/');
  }

  // if (mappedEndpoint.startsWith('PortalUser')) {
  //   HOSTNAME = HOSTNAME + PORTS['profile'];
  // }
  // if (mappedEndpoint.startsWith('PortalContent')) {
  //   HOSTNAME = HOSTNAME + PORTS['content'];
  // }
  // if (mappedEndpoint.startsWith('PortalPayment')) {
  //   HOSTNAME = HOSTNAME + PORTS['payment'];
  // }
  // if (mappedEndpoint.startsWith('PortalNotification')) {
  //   HOSTNAME = HOSTNAME + PORTS['notification'];
  // }
  let adjustedPath;

  if (mappedEndpoint.startsWith('PortalUser') || mappedEndpoint.startsWith('PortalContent')) {
    if(!bfrLogin) {
      adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' +  mappedEndpoint : HOSTNAME + mappedEndpoint;
    } else {
      adjustedPath = mappedEndpoint[0] !== '/' ? bfrLogin + '/' +  mappedEndpoint : bfrLogin + mappedEndpoint;
    }
  } else if (mappedEndpoint.startsWith('PortalAuth')) {
    adjustedPath = mappedEndpoint[0] !== '/' ? '/before/' + mappedEndpoint : mappedEndpoint;
  } else if (mappedEndpoint.startsWith('PortalNotification') || mappedEndpoint.startsWith('PortalPayment')) {
    adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint;
  }
    
  if (apiPathArray)
    adjustedPath = adjustedPath + '?' + apiPathArray;
    console.log('adjusted Path', adjustedPath);
  return adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = (path, { params, data, headers, files, fields } = {}) => new Promise((resolve, reject) => {
        let request = '';
        const accessToken = window.localStorage.getItem('access_token');
        // const basicAccessToken = window.localStorage.getItem('basic_access_token');
        if ((!accessToken || accessToken === null || accessToken === 'null') && 
        //  (!basicAccessToken || basicAccessToken === null || basicAccessToken === 'null') && 
         (path !== 'generateOtp' && path !== 'verifyOtp' && !path.includes('getUserDetails') 
          && !path.includes('getArtistInfo'))) {
          // request = superagent[method](formatUrl(path, '/before'));
          request = superagent[method](formatUrl(path, ''));
        } else {
          request = superagent[method](formatUrl(path, ''));
        }

        console.log("request is ", request);
        if (path.indexOf('fakeapi') !== -1) {
          let fakePath = path;
          let splitPathArray = fakePath.split('/');
          splitPathArray.shift();
          let constructedURL = splitPathArray.join('/');
          request = superagent[method](`http://localhost:3004/${constructedURL}`).withCredentials();
        }
        if (path.indexOf('profilePicUpload') == -1 && path.indexOf('uploadMediaFile') == -1 &&
             path.indexOf('postData') == -1 && path.indexOf('uploadArtistDetails') == -1 && 
             path.indexOf('createAlbum') == -1 && path.indexOf('addLiveStream') == -1 && 
             path.indexOf('uploadCompetition') == -1 && path.indexOf('uploadCompetitionWinners') == -1) {
          request.set({ 'Content-Type': 'application/json;charset=UTF-8' });
        }
        console.log("path", path);
        if(window.sessionStorage.getItem('artistDetails')) {
          request.set({ "X-Artist-Identifier":  JSON.parse(window.sessionStorage.getItem('artistDetails')).artistIdentifier});
        }

        if (accessToken && accessToken !== null && accessToken !== 'null') {
          request.set('Authorization', `Bearer ${accessToken}`);
        } else {
          const basic_access_token = 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAtMjU2In0.RDLIkLAX9zRRSUsmYhZvf7bUoZ4IG0uvt38BGq4kZxbsNQ7zQnwa7qGJNwaqFS-YQeOo_x-3v3IyBPBrSO2sJlbaWJjMuGfKgBifoViUBNgKCjI2q0s989SSFfTyipjXQyWLKcRH53YYE2-rRHDqB8GuaQ8UEw9M_PoI1_LYyOyL7ktWj06L65H3itsA2HpGS9ZEsZpqLhWA_gxy8b-cjlVIEKHlRS9YyLaXgM8ZIAj6nWWbz92Lbupf_1qoTiC8dIGFXfpOneE9quXISOWBwpiLpjjfqoxrsbbrpbTmqs-gOP89WwgUJCbTWVNXBHu72rUvGMzi8ShH5fj8YjOvfw.Je2-gSsKTHXcNmjv.FQ-UE16r044MvJe-8QH7Q_S2XkHgemGD45SLvUrP5Fo3tVlh5iYYC5rhbX7lqUDoTHAjG1F77a09Hp6B3ejo1gg7YgaPCtYZqbCKJJGshdtoMneP7nQH1ZmPTjNp7dCQKC3F28hk_5pne0v_Sbfi6HPKPPMO8EVwlotB9I7qvTK9doK9T6nSV4foGfbRGSqv6IGuSSF3hZ_mJdr1vHoroychw_R8wD9FHHq7EW3pOqgmlPQecKAy9ToofssKQnnNOMR3QXlt-WemBHmarb_hyHpE40zxf43HJKm-o7gG9cK7Ffy0Nbr_JoZqWNG0bp8rW-vW28cDcYe6Duyk4V3p-e0embq9IJmO8HBtp12LtZQUlAZfsniV63FB1SJodfgy56B3fCw0MZ5BEFNOWvPh6xqki9hkccQDZWEjGslQtAbAs-DII4Ymug6viau9En7zgcJaomz_HfDiypYg-Yhqu77UesIPN7-wGBF9m2lx4yhAkWogh1ffeI9okf9qi50dC-uqmk5aTT8yj5YuqhRb19UhZeZeTulku6bJ223kkPId3MOQuM-2YWAUQKJ6cQLSYs_ZC1SpgK1ZcMFOe6dIF_Ohr85ZgK3-7fZ7gclqDyuTb3FQj6SB9ELKpC0eTK6yjGqyMz54mQ5xXtmzsyutFZs8Lr-xJ4FL8JcSqnzWWpINp4m05kQLWFBm6qS2nu-iY_8eb1TiQ3xoQwvX2MpEdAIvb8Z_fdgHHW1coNWIMol3AUtnwel-pBXtWzJsQfFYVLu9x_-JBc6qaG48OOl_0ixmHfDiSWoFu8aj_p0QyAnr-QMJcTEegFXRBgdumZi9YNOc8pTNaLYgHOsq3qjJtd8jiW7Q7qAmGmn8EK3NSphDFyupvOBgrh2bEkEMsKhMK5I6mwz8p_tIPu7cZRJ3NJeeZtX19TkUu8OXVwlHh0tskBtu4xNAkdR8rfRgNKjBemylO00zA0eHFmPnzYr4Bt10VwFUAInJ4egSXkDU1z6CfcLlnQQ0sdZTgYk7hw.H3N7f58dF-8lkuYNe6PM5w';
          request.set('Authorization', `Bearer ${basic_access_token}`);
        }
        // else if (basicAccessToken && basicAccessToken !== null && basicAccessToken !== 'null') {
        //     request.set('Authorization', `Bearer ${basicAccessToken}`);
        // } 

        if (params) {
          request.query(params);
          console.log('params', params)
        }

        if (headers) {
          request.set(headers);
        }

        if (files) {
          files.forEach(file => request.attach(file.key, file.value));
        }

        if (fields) {
          fields.forEach(item => request.field(item.key, item.value));
        }

        if (data) {
          request.send(data);
        }
        request.end((err, response) => {
          console.log('eorrrr', err, response)
          if (err) {
            // console.log('error', err.Error.error)
            // if ((response === 'undefined' && window.location.pathname !== '/') || (err.error === 'unauthorized' && window.location.pathname !== '/') || (err.error === 'invalid_token' || window.location.pathname !== '/')) {
            //   // console.log('err', err.Error.error)
            //   let HOSTNAME = APIConfig.hostname;
            //   window.location.href = `${HOSTNAME}/`
            // }
            
            if (response && response.body != '') {
              //   // debugger
              if (IsJsonString(response.body)) {
                reject(JSON.parse(response.body));
              } else {
                reject(response.body)
              }
            }
          } else if (response && response.body != '' && response.body !== null) {
            console.log('response', response)
            const decodedResponse = response.body && typeof response.body === 'string' ? decodeURIComponent(response.body.replace(/\+/g, '%20')) : '';
            //below condition is true if the response is in url-encoded form, usually happens when it comes from node js
            if (decodedResponse !== '' && IsJsonString(decodedResponse)) {
              resolve(JSON.parse(decodedResponse));
            } else {
              resolve(response.body);
            }
          } else if (response && response.text != '') {
            console.log('response', response)
            const decodedResponse = response.text && typeof response.text === 'string' ? decodeURIComponent(response.text.replace(/\+/g, '%20')) : '';
            //below condition is true if the response is in url-encoded form, usually happens when it comes from node js
            if (decodedResponse !== '' && IsJsonString(decodedResponse)) {
              resolve(JSON.parse(decodedResponse));
            } else {
              resolve(response.text);
            }
          }
        });
      });
    });
  }
  empty() { }
}