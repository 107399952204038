export const GET_MUSIC_REQUESTED = 'music/GET_MUSIC_REQUESTED';
export const GET_MUSIC_SUCCESS = 'music/GET_MUSIC_SUCCESS';
export const GET_MUSIC_FAILURE = 'music/GET_MUSIC_FAILURE';

export const GET_VIDEO_REQUESTED = 'video/GET_VIDEO_REQUESTED';
export const GET_VIDEO_SUCCESS = 'video/GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAILURE = 'video/GET_VIDEO_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  musicDetails: {},
  videoDetails: {},
};

export default (state = initialState, action) => {
  console.log('action.requests');
  console.log(action.result);
  switch (action.type) {
    case GET_MUSIC_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_MUSIC_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        musicDetails: action.result.responseList[0]
      };
    }
    case GET_MUSIC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_VIDEO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        videoDetails: action.result.responseList[0]
      };
    }
    case GET_VIDEO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const getMusicDetails = (musicId, contentType) => {
  return {
    types: [GET_MUSIC_REQUESTED, GET_MUSIC_SUCCESS, GET_MUSIC_FAILURE],
    promise: (client) => client.get(`getMusicDetails?musicId=${musicId}&contentType=${contentType}`)
  };
};

export const getVideoDetails = (videoId) => {
  return {
    types: [GET_VIDEO_REQUESTED, GET_VIDEO_SUCCESS, GET_VIDEO_FAILURE],
    promise: (client) => client.get(`getVideoDetails?videoId=${videoId}`)
  };
};
