/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PremiumPayment from './PremiumPayment';
import LoginModal from '@containers/Modals/LoginModal';
import { useEffect } from 'react';
import cn from 'classnames';
import { APP_CONFIG } from '@constants';
import { EN } from '@localization';
import { useMediaQuery } from '@material-ui/core';
import { getContentPath, getSubscription, saveLocationUrl } from '../../helpers/utils';

const useStyles = makeStyles(theme => ({
    mainDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        boxSizing: 'content-box',
        padding: 'inherit',
        borderRadius: '40%',

    },
    leftDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#00000091',
        cursor: 'pointer',
        // opacity: 0.3

    },
    leftFirstdiv: {
        color: theme.palette.selectedTxtClr,
        fontSize: '0.663rem',
        paddingRight: '0px',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.5rem',
            // paddingRight: '30px',
        },
        fontWeight: 700,
        // wordSpacing: '8px',
        letterSpacing: '2px'

    },
    leftSecondDiv: {
        color: '#FFFFFF',
        fontSize: '0.75rem',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.75rem',
        },
        fontWeight: 700,

    },
    rightDiv: {
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        background: '#0000003d',
        // paddingTop: '20px',
        // marginRight:'8%'
        // opacity: 0.2,
        [theme.breakpoints.down('1024')]: {
            alignItems: 'center',
            // paddingTop: '17px'
        },
    },
    rightFirstdiv: {
        fontSize: '0.8rem',
        color: theme.palette.selectedTxtClr,
        marginRight: '7px',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.5rem',
        },
        fontWeight: 700,
    },
    rightFirstdivWithoutSub: {
        fontSize: '1rem',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.8rem',
        },
    },
    rightSecondDiv: {
        color: theme.palette.selectedTxtClr,
        fontSize: '0.71rem',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.5rem',
        },
        fontWeight: 700,

    },
    rightThirdDiv: {
        color: '#E1E1E1',
        fontSize: '1rem',
        [theme.breakpoints.up('1024')]: {
            fontSize: '1.5rem',
        },
        fontWeight: 700,

    },
    rightFourthdiv: {
        fontSize: '12px',
        color: '#E1E1E1',
        fontWeight: '700',
        // marginLeft: '-18px',
        [theme.breakpoints.up('1024')]: {
            marginLeft: '0px',
            fontSize: '1.5rem',
        },
    },
    premiumTextWeight: {
        fontSize: '1rem !important'
    },
    premiumAmountWeight: {
        fontSize: '1rem !important'
    },
    premiumFreeText: {
        fontSize: '1rem',
        marginLeft: '0px !important',
        // marginTop: '2px'
    },
    extraDiv: {
        width: '15%',
        background: '#0000003d'
    }
}));

const OverlayData = {

    leftFirstData: 'CLICK HERE TO',
    leftSecondData: 'Get All Premium',
    // rightFirstData: 'R3 per day',
    rightFourthData: 'First day FREE*'
}

const PremiumOverlay = (props) => {
    const classes = useStyles();
    const [premiumPayment, setPremiumPayment] = useState(false);
    const [premiumSubscribe, setPremiumSubscribe] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [first, setFirst] = useState(true);
    const [open, setOpen] = useState(true);
    const history = useHistory();
    const [openError, setOpenErrorModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [errorHeading, setErrorHeading] = useState('')
    const [openLogin, setOpenLogin] = useState(false);
    const [freeTrailText, setFreeTrailText] = useState(false);
    const isVideoPlayerPage = window.location.pathname.includes('/videoplayer') ? true : false;
    const isPostDetailPage = window.location.pathname.includes('/post/detail') ? true : false;
    const isLivePlayerPage = window.location.pathname.includes('/liveplayer') ? true : false;

    const mobile = useMediaQuery('(max-width:960px)');

    let premiumImage;
    if (APP_CONFIG.artistDetails) {
        premiumImage = APP_CONFIG.artistDetails.properties.premiumSubscribeImg;
    }



    useEffect(() => {
        if (props.isUserLoggedIn && !window.localStorage.getItem('allowedServices').includes('celebrityAdmin') && !window.localStorage.getItem('allowedServices').includes('productAdmin')) {
            if (!props.serviceOffers?.responseList[0]?.status) {
                console.log("in premiumoverlay !status");
                setPremiumPayment(true);
                setOpen(true);
                //log consent
                if (!premiumSubscribe) props.saveConsentLogsForArtistPortal(EN.FIRST_CONSENT_APPROVAL);
            }
            if (props.serviceOffers?.responseList?.length === 2) {
                if (props.serviceOffers?.responseList[0]?.promoCode === 'TRIAL' || props.serviceOffers?.responseList[1]?.promoCode === 'TRIAL') {
                    setFreeTrailText(true);
                } else {
                    setFreeTrailText(false);
                }
            } else {
                setFreeTrailText(false);
            }
        }
        if (!props.isUserLoggedIn) {
            setFreeTrailText(true);
        }
    }, [props.serviceOffers])

    useEffect(() => {
        setOpenModal(true)
        setOpen(true);
    }, [])

    const handleSubscriptionProcess = () => {
        window.localStorage.setItem('subscribeClick', true);
        saveLocationUrl(window.location.href)
        getSubscription();
    }

    const closeLoginModal = (premiumUser, userLoggedIn) => {
        setOpenLogin(false);
        switch (premiumUser?.status) {
            case '1': ; break;
            case '4': { setOpenErrorModal(true); setErrorHeading(EN.ERROR); setErrorMsg(EN.SUSPENDED_MSG); }; break;
            case '5': ; break;
            default: {
                // handleSubscriptionProcess();
                // setOpen(true)
            }; break;
        }
        if (!premiumUser?.status) {
            handleSubscriptionProcess();
        }
    }

    const redirectToPremiumPayment = () => {
        setOpen(false);
        setFirst(false)
        if ((window.sessionStorage.getItem('is_he_user') === "true" && window.sessionStorage.getItem('he_acr_value')) || props.isUserLoggedIn) {
            // log consent
            props.saveConsentLogsForArtistPortal(EN.FIRST_CONSENT_APPROVAL);
            setPremiumPayment(true);
            // setOpen(true);
            if (!props.serviceOffers?.responseList[0]?.status) {
                handleSubscriptionProcess();
            }
        } else {
            setOpenLogin(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setPremiumPayment(false);
        setPremiumSubscribe(false);
        setFirst(false);
    }

    const handleClosePremiumSubscribe = () => {
        setPremiumSubscribe(false)
        setPremiumPayment(true)
    }

    const handleChoosePremiumSubscribe = () => {
        setPremiumSubscribe(false)
        if (props.isUserLoggedIn) {
            //log consent
            props.saveConsentLogsForArtistPortal(EN.FIRST_CONSENT_APPROVAL);
        }
    }

    const handleOpenError = message => {
        setOpenErrorModal(message);
    };

    return (
        <>
            <div className={classes.mainDiv} onClick={redirectToPremiumPayment}>
                <div className={cn(freeTrailText ? 'w-50' : (((isVideoPlayerPage || isPostDetailPage || isLivePlayerPage) || mobile) ? 'w-100' : 'w-80'), classes.leftDiv)} >
                    <div className={cn(classes.leftFirstdiv, 'leftFirstdiv', (freeTrailText) && (isVideoPlayerPage) && (mobile) && classes.premiumTextWeight)}>{OverlayData.leftFirstData}</div>
                    <div className={cn(classes.leftSecondDiv, 'leftSecondDiv', (freeTrailText) && (isVideoPlayerPage) && (mobile) && classes.premiumTextWeight)}>{OverlayData.leftSecondData}</div>
                </div>
                {(!mobile && freeTrailText) && <div className={classes.extraDiv} />}
                {freeTrailText && <div className={cn(classes.rightDiv)}>
                    {/* <div className={cn(classes.rightFirstdiv, 'rightFirstdiv', (freeTrailText) && (isVideoPlayerPage) && (mobile) && classes.premiumAmountWeight, !freeTrailText && classes.rightFirstdivWithoutSub)}></div> */}
                    {freeTrailText && (
                        <div className={cn(classes.rightFourthdiv, (isVideoPlayerPage) && (mobile) && classes.premiumFreeText)}>{OverlayData.rightFourthData}</div>
                    )}
                </div>
                }
            </div>
            {/* {openModal && <PremiumPayment
                open={open}
                thumbnail={getContentPath(premiumImage)}
                handleClose={handleClose}
                loginRedirect={redirectToPremiumPayment}
                forSubscribe={premiumSubscribe}
                first={first}
                closePremiumSubscribe={handleClosePremiumSubscribe}
                handlePremiumSubscribe={handleChoosePremiumSubscribe}
                showLoginNotification={props.showLoginNotification}
                isRedirect={false}
                setOpenError={props.setOpenError}
                setErrorMsg={props.setErrorMsg}
                setErrorHeading={props.setErrorHeading}
                mediaDetails={props.mediaDetails}
            />} */}
            <LoginModal openLogin={openLogin} setOpenLogin={setOpenLogin} closeLoginModal={closeLoginModal} showLoginNotification={props.showLoginNotification} />
            {openError && (
                <ErrorModal
                    openError={openError}
                    setOpenError={handleOpenError}
                    errorMsg={errorMsg}
                    errorHeading={errorHeading}
                />
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        serviceOffers: state.payment.serviceOffers,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PremiumOverlay));
