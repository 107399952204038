import { useCallback, useRef, useState } from 'react';

function usePagination({ root = null, rootMargin = '0px', threshold = 1 }) {
  const observer = useRef();

  const [page, setPage] = useState(1);

  const lastElement = useCallback((element) => {
    if (element) {
      console.log(' I am Pagination ', element);
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setPage((no) => no + 1);
          }
        },
        {
          root,
          rootMargin,
          threshold,
        }
      );
      if (element) observer.current.observe(element);
    }
  }, []);

  return [lastElement, page, setPage];
}

export default usePagination;
