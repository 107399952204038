/* eslint-disable */
export const GET_CALLERTUNE_LIST_REQUESTED = 'rbtCallertune/GET_CALLERTUNE_LIST_REQUESTED';
export const GET_CALLERTUNE_LIST_SUCCESS = 'rbtCallertune/GET_CALLERTUNE_LIST_SUCCESS';
export const GET_CALLERTUNE_LIST_FAILURE = 'rbtCallertune/GET_CALLERTUNE_LIST_FAILURE';

export const POST_CALLERTUNE_PAYMENT_REQUESTED = 'rbtCallertune/POST_CALLERTUNE_PAYMENT_REQUESTED';
export const POST_CALLERTUNE_PAYMENT_SUCCESS = 'rbtCallertune/POST_CALLERTUNE_PAYMENT_SUCCESS';
export const POST_CALLERTUNE_PAYMENT_FAILURE = 'rbtCallertune/POST_CALLERTUNE_PAYMENT_FAILURE';

export const DELETE_CALLERTUNE_REQUESTED = 'rbtCallertune/DELETE_CALLERTUNE_REQUESTED';
export const DELETE_CALLERTUNE_SUCCESS = 'rbtCallertune/DELETE_CALLERTUNE_SUCCESS';
export const DELETE_CALLERTUNE_FAILURE = 'rbtCallertune/DELETE_CALLERTUNE_FAILURE';

export const GET_RBT_STATUS_REQUESTED = 'rbtCallertune/GET_RBT_STATUS_REQUESTED';
export const GET_RBT_STATUS_SUCCESS = 'rbtCallertune/GET_RBT_STATUS_SUCCESS';
export const GET_RBT_STATUS_FAILURE = 'rbtCallertune/GET_RBT_STATUS_FAILURE';

const initialState = {
    loading: false,
    loaded: false,
    error: false,
    rbtCallertuneList: [],
    rbtCallertunePayment: [],
    getRBTStatus:{}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_CALLERTUNE_LIST_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_CALLERTUNE_LIST_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          rbtCallertuneList: action.result.responseList,
          rbtCallerTuneMessage: action.result.message
        };
      }
      case GET_CALLERTUNE_LIST_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      } 
      case POST_CALLERTUNE_PAYMENT_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case POST_CALLERTUNE_PAYMENT_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          rbtCallertunePayment: action.result
        };
      }
      case POST_CALLERTUNE_PAYMENT_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case DELETE_CALLERTUNE_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case DELETE_CALLERTUNE_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
        };
      }
      case DELETE_CALLERTUNE_FAILURE: {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true
        };
      }
      case GET_RBT_STATUS_REQUESTED: {
        return {
          ...state,
          loading: true,
          loaded: false
        };
      }
      case GET_RBT_STATUS_SUCCESS: {
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          getRBTStatus: action.result.responseList[0]
        };
      }
      case GET_RBT_STATUS_FAILURE: {
        return {
          ...state,
          loading: false,
          error: true,
          loaded: false
        };
      } 
      default:
        return state;
    }
  };

  export const getRBTcallerTuneList = (msisdn) => {
    return {
      types: [GET_CALLERTUNE_LIST_REQUESTED, GET_CALLERTUNE_LIST_SUCCESS, GET_CALLERTUNE_LIST_FAILURE],
      promise: (client) => client.get(`getRBTcallerTuneList?msisdn=${msisdn}`, {
      })
    };
  };

  export const postRBTcallerturnePayment = (msisdn, clipId) => {
    return {
      types: [POST_CALLERTUNE_PAYMENT_REQUESTED, POST_CALLERTUNE_PAYMENT_SUCCESS, POST_CALLERTUNE_PAYMENT_FAILURE],
      promise: (client) => client.post(`postRBTcallerturnePayment?msisdn=${msisdn}&clipId=${clipId}`, {
      })
    };
  };

  export const deleteRBTCallertune = (clipId, msisdn) => {
    return {
      types: [DELETE_CALLERTUNE_REQUESTED, DELETE_CALLERTUNE_SUCCESS, DELETE_CALLERTUNE_FAILURE],
      promise: (client) => client.del(`deleteRBTCallertune?clipId=${clipId}&msisdn=${msisdn}`, {
      })
    };
  };

  export const getRBTStatus = (clipId, msisdn) => {
    return {
      types: [GET_RBT_STATUS_REQUESTED, GET_RBT_STATUS_SUCCESS, GET_RBT_STATUS_FAILURE],
      promise: (client) => client.get(`getRBTStatus?clipId=${clipId}&msisdn=${msisdn}`, {
      })
    };
  };