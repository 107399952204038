export const OAUTH_REQUESTED = 'oauth/OAUTH_REQUESTED';
export const OAUTH_SUCCESS = 'oauth/OAUTH_SUCCESS';
export const OAUTH_FAILURE = 'oauth/OAUTH_FAILURE';

export const GET_USER_REQUESTED = 'REGISTER/GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'REGISTER/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'REGISTER/GET_USER_FAILURE';

export const GET_OTP_COUNT_REQUESTED = 'REGISTER/GET_OTP_COUNT_REQUESTED';
export const GET_OTP_COUNT_SUCCESS = 'REGISTER/GET_OTP_COUNT_SUCCESS';
export const GET_OTP_COUNT_FAILURE = 'REGISTER/GET_OTP_COUNT_FAILURE';

export const UPDATE_IS_USER_LOGGED_IN_STATUS = 'oauth/UPDATE_IS_USER_LOGGED_IN_STATUS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  userdata: null,
  isUserLoggedIn: false,
  otpCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OAUTH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OAUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userdata: action.result
      };
    }
    case OAUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        otpCount: action.result
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_IS_USER_LOGGED_IN_STATUS: {
      const accessToken = window.localStorage.getItem('access_token');
      const userDetails = window.localStorage.getItem('userDetails');
      const allowedServices = window.localStorage.getItem('allowedServices');
      const phone = window.localStorage.getItem('phone');
      const countryCode = window.localStorage.getItem('countryCode');
      let isLoggedIn = false;
      if (!accessToken || accessToken === null || accessToken === 'null' || accessToken === ''
        || !userDetails || userDetails === null || userDetails === 'null' || userDetails === ''
        || !allowedServices || allowedServices === null || allowedServices === 'null' || allowedServices === [] || allowedServices === ''
        || !phone || phone === null || phone === 'null' || phone === ''
        || !countryCode || countryCode === null || countryCode === 'null' || countryCode === '') {
        isLoggedIn = false;
      } else {
        isLoggedIn = true;
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        isUserLoggedIn: isLoggedIn
      };
    }
    case GET_OTP_COUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_OTP_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        otpCount: action.result
      };
    }
    case GET_OTP_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: false
      };
    }
    default:
      return state;
  }
};

export const login = (data) => {
  const headers = { has_auth_token: 0 };
  return {
    types: [OAUTH_REQUESTED, OAUTH_SUCCESS, OAUTH_FAILURE],
    promise: (client) => client.post('login', {
      data,
      headers
    })
  };
};

export const getUserDetails = (email) => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: (client) => client.get(`getUserDetails/details?email=${email}`)
  };
};

export const updateIsUserLoggedInStatus = () => {
  return {
    type: UPDATE_IS_USER_LOGGED_IN_STATUS
  };
};

export const getOtpLength = () => {
  return {
    types: [GET_OTP_COUNT_REQUESTED, GET_OTP_COUNT_SUCCESS, GET_OTP_COUNT_FAILURE],
    promise: (client) => client.get('getOtpLength')
  };
};
