/* eslint-disable */
import React, { useState } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { renderEditPanel, renderInputPanel, renderProfilePanel, renderFeedbackPanel, renderSelectedCallerTunePanel } from '../../reducers/ui';
import { feedbackData } from '../../reducers/feedback';
import NotificationMessage from '../NotificationMessage';

const useStyles = makeStyles((theme) => ({
    top: {
        background: 'red',
        // margin: '15px',
        borderRadius: '10px',
    },
    header: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            background: theme.palette.bgThemeColor,
        },
        [theme.breakpoints.up('sm')]: {
            background: theme.palette.bgThemeColor,
        }
    },
    body: {
        // marginLeft: '15px',
        // marginRight: '15px',
        padding: '10px',
        margin: 'auto',
        borderRadius: '15px',
        background: '#242424',
        // [theme.breakpoints.down('1024')]: {
        //     background: '#b5a5a526',
        // },
        color: theme.palette.titleClr,
        [theme.breakpoints.down('1024')]: {
            width: '95%',
            background: theme.palette.bgThemeColor,
            padding: '0px',
        },
    },
    subBodyText: {
        color: theme.palette.selectedTxtClr,
        marginTop: '10px',
        width: '100%',
        marginLeft: '60px',
        marginRight: '25px',
        fontFamily: 'Arial',
        fontSize: '1.375rem',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1rem',
            width: '90%',
            marginLeft: '0px',
            display: 'flex',
            justifyContent: 'center',
        },
    },
    btns: {
        width: '95%',
        [theme.breakpoints.up('1024')]: {
            width: '100%',
        },
        gap: '5%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        marginTop: '10vh',
        padding: '20px',
    },
    sendBtn: {
        borderRadius: '15px',
        backgroundColor: theme.palette.selectedTxtClr,
        color: theme.palette.titleClr,
        textTransform: 'none',
        width: '150px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },
        // '&:hover': {
        //     backgroundColor: theme.palette.selectedTxtClr,
        //     color: theme.palette.titleClr,
        // },
    },
    cancelBtn: {
        borderRadius: '15px',
        color: theme.palette.titleClr,
        backgroundColor: '#4a4a4a',
        textTransform: 'none',
        width: '150px',
        textTransform: 'capitalize',
        fontWeight: '300',
        [theme.breakpoints.down('1024')]: {
            fontSize: '1.125rem'
        },
        // '&:hover': {
        //     color: theme.palette.titleClr,
        //     backgroundColor: '#4a4a4a',
        // },
    },
    textstyle: {
        // width: '70vw',
        background: '#242424',
        border: '1px solid #b9b9b9',
        borderRadius: '5px',
        color: theme.palette.titleClr,
        fontSize: '1.5rem',
        fontFamily: 'SegoeUI',
        [theme.breakpoints.up('1024')]: {
            width: '35vw',
            height: '25vh !important',
            // background: '#242424',
            color: theme.palette.titleClr,
            border: '1px solid #b9b9b9',
            borderRadius: '5px',
        },
        [theme.breakpoints.down('1024')]: {
            fontSize: '0.875rem',
            width: '100%',
            height: '45vh !important',
            marginTop: '10px',
            background: theme.palette.bgThemeColor,
        },
    },
    FeedTextMobile: {
        fontFamily: 'Arial',
        color: theme.palette.titleClr,
        fontSize:  '0.625rem'
    },
    FeedTextDesktop: {
        fontFamily: 'Arial',
        color: theme.palette.selectedTxtClr,
        fontSize: '1.25rem'
    }
}));
const Feedback = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const mobileView = useMediaQuery('(max-width:1024px)');
    const [loadFeedbackData, setLoadFeedbackData] = useState('');
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgType, setMsgType] = useState('');

    const redirectToProfile = () => {
        props.renderInputPanel(false);
        props.renderEditPanel(false);
        props.renderProfilePanel(true);
        props.renderFeedbackPanel(false);
        props.renderSelectedCallerTunePanel(false);
    }

    const sendFeedback = () => {
        props.feedbackData({ "feedback": loadFeedbackData }).then((result) => {
            setOpen(true);
            setMsg(result.message);
            setMsgType("success");
        }).catch((error) => {
            setOpen(true);
            setMsg(error.errorMessage);
            setMsgType("error");
        });
        setTimeout(() => {
            props.renderInputPanel(false);
            props.renderEditPanel(false);
            props.renderProfilePanel(true);
            props.renderFeedbackPanel(false);
            props.renderSelectedCallerTunePanel(false);
        }, 3000);

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <>
            <div className={classes.top}>
                <div className={classes.header}>
                    <div style={{ color: '#b5b5b5', fontFamily: 'Arial', fontSize: mobileView ? '1.25rem' : '2.3125rem' }}>
                        <p><b>Feedback</b></p>
                    </div>
                    <div className={classes.body}>
                        <span className={ mobileView ? classes.FeedTextMobile : classes.FeedTextDesktop }>Write Feedback</span><br />
                        <TextareaAutosize
                            className={classes.textstyle}
                            maxRows={15}
                            aria-label="empty textarea"
                            autoComplete="off"
                            // defaultValue={feedbackText}
                            onChange={(e) => {setLoadFeedbackData(e.target.value)}}
                        />

                    </div>
                    <div className={classes.subBodyText}>
                        <span>This will be feedback sent to our mail</span>
                    </div>
                    <div className={classes.btns}>
                        <Button
                            className={classes.cancelBtn}
                            variant="contained"
                            onClick={redirectToProfile}
                        >
                            <b>cancel</b>
                        </Button>
                        <Button
                            className={classes.sendBtn}
                            variant="contained"
                            // color="secondary"
                            onClick={sendFeedback}
                        >
                            <b>send</b>
                        </Button>
                    </div>
                </div>
                <NotificationMessage open={open} msgType={msgType} msg={msg} handleClose={handleClose} />
            </div>
        </>
    );
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    renderEditPanel,
    renderInputPanel,
    renderProfilePanel,
    renderFeedbackPanel,
    feedbackData,
    renderSelectedCallerTunePanel
}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Feedback));

